import React, { useCallback, useState } from "react";

import get from "lodash/get";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";

import FavAlertConfigForm from "./FavAlertConfigForm";
import FavAlertList from "./FavAlertList";

import ConfigSvgImg from "assets/config_table.svg";

const FavAlertListWidget = ({ widgetId, widgetConfig }) => {
  /**
   * Parent:
   *     CatvFavGraphsDash
   */

  const [setshowForm, setShowForm] = useState(false);

  const handleShowForm = useCallback(() => {
    setShowForm(true);
  }, []);

  const handleCloseForm = useCallback(() => {
    setShowForm(false);
  }, []);

  let Content;
  if (widgetConfig) {
    Content = (
      <Box height="100%" position="relative">
        <Stack
          justifyContent="space-between"
          p={1}
          pl={2}
          direction="row"
          alignItems="center"
        >
          <Typography variant="h6">
            {get(widgetConfig, "element_name", "")} alerts
          </Typography>
          <Box>
            <IconButton onClick={handleShowForm}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </Stack>
        <FavAlertList key={widgetConfig.version} widgetConfig={widgetConfig} />
      </Box>
    );
  } else {
    Content = (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <Stack pb={3} spacing={1}>
          <img src={ConfigSvgImg} height="60px" />
          <Typography variant="h5">
            Select region and device and monitor your alerts
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          startIcon={<SettingsIcon />}
          onClick={handleShowForm}
        >
          Configure
        </Button>
      </Stack>
    );
  }

  return (
    <>
      {Content}
      <Dialog onClose={handleCloseForm} open={setshowForm}>
        <FavAlertConfigForm
          widgetId={widgetId}
          widgetConfig={widgetConfig}
          onClose={handleCloseForm}
        />
      </Dialog>
    </>
  );
};

export default FavAlertListWidget;
