var connLinesRefs = [];

export const addIntoConnectionLine = (newConnection) => {
  connLinesRefs.push(newConnection);
};

export const removeAllConnectionLine = () => {
  for (let llInd = 0; llInd < connLinesRefs.length; llInd++) {
    try {
      connLinesRefs[llInd].remove();
    } catch (error) {}
  }
};

export const updateConnectionLinePositions = () => {
  for (let llInd = 0; llInd < connLinesRefs.length; llInd++) {
    try {
      connLinesRefs[llInd].position();
    } catch (error) {}
  }
};

export const showConnectionLines = () => {
  for (let llInd = 0; llInd < connLinesRefs.length; llInd++) {
    try {
      connLinesRefs[llInd].show();
    } catch (error) {}
  }
};

export const hideConnectionLines = () => {
  for (let llInd = 0; llInd < connLinesRefs.length; llInd++) {
    try {
      connLinesRefs[llInd].hide();
    } catch (error) {}
  }
};

export const getCableMaxCoreCount = (
  no_of_tube,
  core_per_tube,
  ribbon_count = 0
) => {
  let max = no_of_tube * core_per_tube;
  if (!!ribbon_count) {
    max = max * ribbon_count;
  }

  return max;
};

export const getFmsMaxPortCount = (no_of_ports) => no_of_ports;
