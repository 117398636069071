import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";

import differenceBy from "lodash/differenceBy";

import { default as Icon } from "assets/markers/pole_box.svg";
import { default as EditIcon } from "assets/markers/pole_pin.svg";
import { FIELD_TYPES } from "components/common/DynamicForm";

export const LAYER_KEY = "p_pole";
export const PRE_UID = "PL";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
  type_of_pole: "SL",
  permission_status: "YES",
};

export const POLY_TYPES = [
  {
    label: "Streetlight",
    value: "SL",
  },
  {
    label: "RCC",
    value: "RC",
  },
  {
    label: "Steel",
    value: "ST",
  },
];

export const PERMISSION_STATUS = [
  {
    label: "Yes",
    value: "YES",
  },
  {
    label: "WIP",
    value: "Wip",
  },
];

// replace field name from default element temmplate
let newElemFormAbsTemplate = differenceBy(
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  [{ field_key: "name" }],
  "field_key"
);
newElemFormAbsTemplate.unshift({
  field_key: "name",
  label: "Pole Reference Number",
  field_type: FIELD_TYPES.Input,
});

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {
  return {
    title: "Pole Form",
    sections: [
      {
        fieldConfigs: [
          ...newElemFormAbsTemplate,
          {
            field_key: "type_of_pole",
            label: "Type of Pole",
            field_type: FIELD_TYPES.Select,
            options: POLY_TYPES,
          },
          {
            field_key: "ownership",
            label: "Ownership",
            field_type: FIELD_TYPES.SelectCreatable,
            options: [
              {
                label: "State Electricity",
                value: "State Electricity",
              },
              {
                label: "Municipal Corporation",
                value: "Municipal Corporation",
              },
            ],
          },
          {
            field_key: "permission_status",
            label: "Permission Status",
            field_type: FIELD_TYPES.Select,
            options: PERMISSION_STATUS,
          },
        ],
      },
    ],
  };
};

// replace field name from default element temmplate
let newElemTableAbsTemplate = differenceBy(
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  [{ field: "name" }],
  "field"
);
newElemTableAbsTemplate.unshift({
  label: "Pole Reference Number",
  field: "name",
  type: "simple",
});

export const getElementTableFields = () => [
  ...newElemTableAbsTemplate,
  { label: "Type of Pole", field: "type_of_pole_display", type: "simple" },
  { label: "Ownership", field: "ownership", type: "simple" },
  {
    label: "Permission Status",
    field: "permission_status_display",
    type: "simple",
  },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "add_associations",
    data: ["p_splitter", "p_dp"],
  },
  {
    control: "association_list",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    field: "type_of_pole",
    label: "Type of Pole",
    field_type: FIELD_TYPES.Select,
    filter_type: FILTER_TYPES.equals,
    choices: POLY_TYPES,
  },
  {
    field: "ownership",
    label: "Ownership",
    filter_type: FILTER_TYPES.multi_choice,
    field_type: FIELD_TYPES.SelectMulti,
    choices: [
      {
        label: "State Electricity",
        value: "State Electricity",
      },
      {
        label: "Municipal Corporation",
        value: "Municipal Corporation",
      },
    ],
  },
];
