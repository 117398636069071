import get from "lodash/get";
import { createSelector } from "@reduxjs/toolkit";

// logical connection form
export const getTracebackFormData = (store) => store.traceback.tracebackForm;

export const getTracebackAllLayersData = (store) => store.traceback.layerData;
export const getTracebackAllLayersNetworkStatus = (store) =>
  store.traceback.layerNetworkState;

export const getTracebackLayerData = (layerKey) =>
  createSelector(getTracebackAllLayersData, (layerData) =>
    get(layerData, layerKey, [])
  );

export const getTracebackData = (store) => store.traceback.traceData;
export const getTraebackTreeData = (store) =>
  store.traceback.traceData?.tree || [];

export const getTraceShowElement = (store) => store.traceback.showElementList;
export const getTraceShowOnMap = (store) => store.traceback.showOnMap;
