import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";

import size from "lodash/size";
import get from "lodash/get";
import filter from "lodash/filter";

import {
  Divider,
  Stack,
  Typography,
  Box,
  IconButton,
  Button,
  Tooltip,
  Collapse,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import SwipeLeftAltIcon from "@mui/icons-material/SwipeLeftAlt";
import SwipeRightAltIcon from "@mui/icons-material/SwipeRightAlt";
import AddIcon from "@mui/icons-material/Add";
import LanguageIcon from "@mui/icons-material/Language";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "components/common/ExpandMore";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import GisMapPopupLoader from "planning/GisMap/components/GisMapPopups/GisMapPopupLoader";

import { setMapState } from "planning/data/planningGis.reducer";
import { fetchElementConnections } from "planning/data/layer.services";
import {
  onAssociatedElementShowOnMapClick,
  onElementAddConnectionEvent,
} from "planning/data/planning.actions";
import { getPlanningMapStateData } from "planning/data/planningGis.selectors";
import { LayerKeyMappings } from "planning/GisMap/utils";
import { showSplicingView } from "planning/data/event.actions";
import { addNotification } from "redux/reducers/notification.reducer";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";

const ListElementConnections = ({ layerKey }) => {
  const [leftElement, setLeftElement] = useState(null);
  const [rightElement, setRightElement] = useState(null);
  const [leftElementExpanded, setLeftElementExpanded] = useState(true);
  const [rightElementExpanded, setRightElementExpanded] = useState(true);

  const dispatch = useDispatch();
  const { elementId, elementGeometry } = useSelector(getPlanningMapStateData);

  const { data: elemConnectionData, isLoading } = useQuery(
    ["elementConnections", layerKey, elementId],
    fetchElementConnections
  );

  const handleClose = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handleAddConnection = () => {
    dispatch(
      onElementAddConnectionEvent({
        layerKey,
        elementGeometry,
        elementId,
      })
    );
  };

  const handleShowOnMap = useCallback(
    (layerKey, element) => () => {
      dispatch(onAssociatedElementShowOnMapClick(element, layerKey));
    },
    []
  );

  const handleSetLeftElement = useCallback(
    (connection) => () => {
      if (
        !!rightElement &&
        rightElement.element_id === connection.element.id &&
        rightElement.layer_key === connection.layer_info.layer_key
      ) {
        dispatch(
          addNotification({
            type: "error",
            title: "Selection incorrect",
            text: "Already selected this element for other side",
          })
        );
        return;
      }
      setLeftElement((currentValue) => {
        // toggle if same element selected
        if (currentValue?.element_id === connection.element.id) {
          setLeftElementExpanded(true);
          return null;
        } else {
          setLeftElementExpanded(false);
          return {
            element_id: connection.element.id,
            layer_key: connection.layer_info.layer_key,
            cable_end:
              connection.layer_info.layer_key === "p_cable"
                ? connection.element.cable_end
                : undefined,
          };
        }
      });
    },
    [rightElement]
  );

  const handleSetRightElement = useCallback(
    (connection) => () => {
      if (
        !!leftElement &&
        leftElement.element_id === connection.element.id &&
        leftElement.layer_key === connection.layer_info.layer_key
      ) {
        dispatch(
          addNotification({
            type: "error",
            title: "Selection incorrect",
            text: "Already selected this element for other side",
          })
        );
        return;
      }
      setRightElement((currentValue) => {
        // toggle if same element selected
        if (currentValue?.element_id === connection.element.id) {
          setRightElementExpanded(true);
          return null;
        } else {
          setRightElementExpanded(false);
          return {
            element_id: connection.element.id,
            layer_key: connection.layer_info.layer_key,
            cable_end:
              connection.layer_info.layer_key === "p_cable"
                ? connection.element.cable_end
                : undefined,
          };
        }
      });
    },
    [leftElement]
  );

  const handleSplicingClick = useCallback(() => {
    if (!rightElement && !leftElement) {
      // need atleast 1 input or output selected
      dispatch(
        addNotification({
          type: "error",
          title: "Selection incorrect",
          text: "Select input or output element for splicing",
        })
      );
    } else {
      let actionPayload = {
        middle: { element_id: elementId, layer_key: layerKey },
        left: !!leftElement ? { ...leftElement } : undefined,
        right: !!rightElement ? { ...rightElement } : undefined,
        // for back btn
        layerKey,
        elementId,
      };
      dispatch(showSplicingView(actionPayload));
    }
  }, [layerKey, elementId, leftElement, rightElement]);

  const toggleLeftElementExpand = useCallback(() => {
    setLeftElementExpanded(!leftElementExpanded);
  }, [leftElementExpanded]);

  const toggleRightElementExpand = useCallback(() => {
    setRightElementExpanded(!rightElementExpanded);
  }, [rightElementExpanded]);

  const isListEmpty = !size(elemConnectionData);
  const enablesplicingButton = !!leftElement || !!rightElement;

  if (isLoading) return <GisMapPopupLoader />;

  return (
    <GisMapPopups dragId="ElementConnections">
      <Box minWidth="350px" maxWidth="550px">
        {/* header */}
        <TableHeader
          showMinimizeBtn={false}
          title="Element Connections"
          handleCloseDetails={handleClose}
        />

        <Stack p={1} direction="row" spacing={2} justifyContent="space-between">
          <Tooltip title="Add Connection">
            <IconButton
              sx={{
                border: "1px solid",
                borderRadius: 1,
              }}
              onClick={handleAddConnection}
              variant="outlined"
              color="secondary"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="outlined"
            disabled={!enablesplicingButton}
            color="secondary"
            onClick={handleSplicingClick}
          >
            splicing
          </Button>
        </Stack>
        <Divider />
        {isListEmpty ? (
          <Box p={2}>
            <Typography variant="h6" color="text.secondary" textAlign="center">
              No Connections
            </Typography>
          </Box>
        ) : (
          <Stack
            spacing={1}
            py={1}
            divider={<Divider />}
            maxHeight="72vh"
            overflow="auto"
          >
            <Stack
              direction="row"
              width="100%"
              spacing={2}
              justifyContent="space-between"
            >
              <Typography variant="h6" px={1}>
                Left Connections
              </Typography>

              <Box onClick={toggleLeftElementExpand}>
                <ExpandMore
                  expand={leftElementExpanded}
                  aria-expanded={leftElementExpanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Box>
            </Stack>
            <Box py={1}>
              <Collapse in={leftElementExpanded}>
                <ConnectionList
                  connections={elemConnectionData}
                  handleShowOnMap={handleShowOnMap}
                  handleElementClick={handleSetLeftElement}
                  activeElementId={get(leftElement, "element_id")}
                />
              </Collapse>
            </Box>

            <Stack
              direction="row"
              width="100%"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" p={1}>
                Right Connections
              </Typography>

              <Box onClick={toggleRightElementExpand}>
                <ExpandMore
                  expand={rightElementExpanded}
                  aria-expanded={rightElementExpanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Box>
            </Stack>

            <Box py={1}>
              <Collapse in={rightElementExpanded}>
                <ConnectionList
                  connections={elemConnectionData}
                  handleShowOnMap={handleShowOnMap}
                  handleElementClick={handleSetRightElement}
                  activeElementId={get(rightElement, "element_id")}
                />
              </Collapse>
            </Box>
          </Stack>
        )}
      </Box>
    </GisMapPopups>
  );
};

const ConnectionList = ({
  connections,
  handleShowOnMap,
  handleElementClick,
  activeElementId,
}) => {
  if (!size(connections)) {
    return (
      <Box px={1} pt={2} pb={3}>
        <Typography variant="body1" color="text.secondary" textAlign="center">
          No connections available
        </Typography>
      </Box>
    );
  }

  return connections.map((connection) => {
    const { element, layer_info } = connection;
    const isActive = activeElementId === element.id;
    const EndIcon =
      element.cable_end === "A" ? (
        <SwipeRightAltIcon
          fontSize="small"
          sx={{
            color: "text.secondary",
          }}
        />
      ) : (
        <SwipeLeftAltIcon
          fontSize="small"
          sx={{
            color: "text.secondary",
          }}
        />
      );

    const Icon = LayerKeyMappings[layer_info.layer_key]["getViewOptions"](
      {}
    ).icon;

    return (
      <Stack
        key={element.id}
        direction="row"
        spacing={1}
        alignItems="center"
        py={0.5}
        sx={{
          borderLeft: "5px solid",
          borderLeftColor: isActive ? "secondary.dark" : "transparent",
        }}
      >
        <Paper
          sx={{
            width: "42px",
            height: "42px",
            lineHeight: "42px",
            textAlign: "center",
            marginLeft: "8px",
          }}
        >
          <img
            className="responsive-img"
            src={Icon}
            alt={layer_info.layer_key}
          />
        </Paper>
        <Stack flex={1} flexDirection="row">
          <Box
            flex={1}
            className="clickable"
            onClick={handleElementClick(connection)}
          >
            <Typography variant="subtitle1" lineHeight={1.1}>
              {get(element, "name", "")}
            </Typography>
            <Typography variant="caption">
              #{get(element, "network_id", "")}
            </Typography>
          </Box>
          <Tooltip title="Show on map" placement="top">
            <IconButton
              sx={{
                marginRight: "8px",
              }}
              aria-label="show-location"
              onClick={handleShowOnMap(layer_info.layer_key, element)}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>
          <Stack alignItems="center" pr={1} maxWidth="52px">
            <Typography variant="caption">{element.cable_end} End</Typography>
            {EndIcon}
          </Stack>
        </Stack>
      </Stack>
    );
  });
};

export default ListElementConnections;
