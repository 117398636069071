import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";

import size from "lodash/size";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import AddIcon from "@mui/icons-material/Add";

import UploadForm from "./UploadForm";

import { addNotification } from "redux/reducers/notification.reducer";
import { handlePreventDefault } from "utils/app.utils";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { uploadLayerImage } from "planning/data/layer.services";

const AddImage = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [showFilePicker, setShowFilePicker] = useState(false);

  const { data: mapStateData, layerKey } = useSelector(getPlanningMapState);

  const { mutate: uploadMutation, isLoading } = useMutation(
    (mutationData) =>
      uploadLayerImage({
        data: mutationData,
        layerKey,
        elementId: mapStateData.elementId,
      }),
    {
      onError: (err) => {
        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.ERROR,
            title: "Upload Image",
            text: "Invalid Image",
          })
        );
      },
      onSuccess: (res) => {
        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            title: "Upload Image",
            text: "Image uploaded successfully.",
          })
        );
        handleFilePickerCancel();
        queryClient.invalidateQueries("imageList");
      },
    }
  );

  const handleFilePickerCancel = useCallback(() => {
    setShowFilePicker(false);
  }, []);

  const handleFilePickerShow = useCallback((e) => {
    handlePreventDefault(e);
    setShowFilePicker(true);
  }, []);

  const handleFormSubmit = useCallback((data, files) => {
    if (!size(files)) {
      dispatch(
        addNotification({
          type: NOTIFICATION_TYPE.ERROR,
          title: "Upload Image",
          text: "Please select image.",
        })
      );
      return;
    }

    const formData = new FormData();
    formData.append("image", files[0], files[0].name);
    formData.append("caption", data.caption);
    uploadMutation(formData);
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" p={2} pb={0}>
        <Button
          variant="outlined"
          sx={{ minWidth: "150px" }}
          startIcon={<AddIcon />}
          onClick={handleFilePickerShow}
        >
          Add Image
        </Button>
      </Box>
      <Dialog
        onClose={handleFilePickerCancel}
        open={showFilePicker}
        scroll="paper" // used to scroll content into dialog
        aria-labelledby="img-scroll-dialog-title"
        aria-describedby="img-scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {showFilePicker ? (
          <UploadForm
            onClose={handleFilePickerCancel}
            handleFormSubmit={handleFormSubmit}
            isLoading={isLoading}
          />
        ) : null}
      </Dialog>
    </>
  );
};

export default AddImage;
