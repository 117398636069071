import orderBy from "lodash/orderBy";
import { coordsToLatLongMap } from "utils/map.utils";
import {
  FEATURE_TYPES,
  LAYER_STATUS_OPTIONS,
  POP_BUSSINESS_NATURE,
  POP_STRUCTURE_TYPE,
  CABINET_TYPE_OPTIONS,
} from "./layers/common/configuration";
import { customAlphabet } from "nanoid";

import * as RegionLayer from "./layers/region";
import * as TicketLayer from "./layers/ticket";
import * as DPLayer from "./layers/p_dp";
import * as SplitterLayer from "./layers/p_splitter";
import * as CableLayer from "./layers/p_cable";
import * as BuildingLayer from "./layers/p_survey_building";
import * as SAreaLayer from "./layers/p_survey_area";
import * as PopLayer from "./layers/p_pop";
import * as SPopLayer from "./layers/p_spop";
import * as FSALayer from "./layers/p_fsa";
import * as DSALayer from "./layers/p_dsa";
import * as CSALayer from "./layers/p_csa";
import * as PoleLayer from "./layers/p_pole";
import * as ManholeLayer from "./layers/p_manhole";
import * as JointCloserLayer from "./layers/p_jointcloser";
import * as FDCLayer from "./layers/p_fdc";
import * as OltLayer from "./layers/p_olt";
import * as FmsLayer from "./layers/p_fms";
import * as OnuLayer from "./layers/p_onu";
import * as CustomerLayer from "./layers/p_customer";
import * as SwitchLayer from "./layers/p_switch";
import * as IPQAMLayer from "./layers/p_ipqam";
import * as FirewallLayer from "./layers/p_firewall";

import { HIGHLIGHT_COLOR } from "App/theme";

// possible events that can happen on map
export const PLANNING_EVENT = {
  addElementGeometry: 1,
  editElementGeometry: 2,
  addElementForm: 3,
  editElementForm: 4,
  showElementDetails: 5,
  // special events
  showElementConnections: 6,
  showElementPatching: 16,
  addElementConnection: 7,
  showPortDetails: 10,
  showSplicingView: 14,
  showHistory: 17,
  showAttachmentView: 18,
  // association events
  showAssociatedElements: 9,
  showPossibleAddAssociatiation: 8,
  associateElementOnMapClick: 13,
  // map select elements on location
  selectElementsOnMapClick: 11,
  listElementsOnMap: 12,
  layerElementsOnMap: 15,
  //
  showTracebackForm: 19,
  showFilterPopup: 20,
  // CATV
  showGenericAttrsPopup: 21,
  showInterfaceAttrsPopup: 22,
  showTransportAttrsPopup: 24,
  showGraphPopup: 23,
};

export const LayerKeyMappings = {
  [RegionLayer.LAYER_KEY]: {
    // @TODO : test if we can use this way
    layerConfiguration: RegionLayer,
    preUid: RegionLayer.PRE_UID,
    featureType: RegionLayer.LAYER_FEATURE_TYPE,
    getViewOptions: RegionLayer.getViewOptions,
    initialElementData: RegionLayer.INITIAL_ELEMENT_DATA,
    getFormConfig: RegionLayer.getElementFormTemplate,
    getElementTableFields: RegionLayer.getElementTableFields,
    elementTableExtraControls: RegionLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getDependantFields: RegionLayer.getDependantFields,
  },
  [TicketLayer.LAYER_KEY]: {
    [PLANNING_EVENT.addElementForm]: TicketLayer.ElementForm,
    [PLANNING_EVENT.editElementForm]: TicketLayer.ElementForm,
    preUid: TicketLayer.PRE_UID,
    featureType: TicketLayer.LAYER_FEATURE_TYPE,
    getViewOptions: TicketLayer.getViewOptions,
    initialElementData: TicketLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: TicketLayer.getElementTableFields,
    elementTableExtraControls: TicketLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getDependantFields: TicketLayer.getDependantFields,
    filterFormConfig: TicketLayer.FILTER_FORM_CONFIG,
  },
  [DPLayer.LAYER_KEY]: {
    preUid: DPLayer.PRE_UID,
    featureType: DPLayer.LAYER_FEATURE_TYPE,
    getViewOptions: DPLayer.getViewOptions,
    getMarkerLabel: DPLayer.getMarkerLabel,
    showLabelZoom: DPLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: DPLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: DPLayer.getElementTableFields,
    elementTableExtraControls: DPLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: DPLayer.getElementFormTemplate,
    filterFormConfig: DPLayer.FILTER_FORM_CONFIG,
  },
  [SplitterLayer.LAYER_KEY]: {
    preUid: SplitterLayer.PRE_UID,
    featureType: SplitterLayer.LAYER_FEATURE_TYPE,
    getViewOptions: SplitterLayer.getViewOptions,
    getMarkerLabel: SplitterLayer.getMarkerLabel,
    showLabelZoom: SplitterLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: SplitterLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: SplitterLayer.getElementTableFields,
    elementTableExtraControls: SplitterLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: SplitterLayer.getElementFormTemplate,
    filterFormConfig: SplitterLayer.FILTER_FORM_CONFIG,
    // config fields
    ConfigFormTemplate: SplitterLayer.ELEMENT_CONFIG_TEMPLATE,
    ConfigInitData: SplitterLayer.INITIAL_CONFIG_DATA,
    TableColDefs: SplitterLayer.CONFIG_LIST_TABLE_COL_DEFS,
  },
  [CableLayer.LAYER_KEY]: {
    preUid: CableLayer.PRE_UID,
    featureType: CableLayer.LAYER_FEATURE_TYPE,
    getViewOptions: CableLayer.getViewOptions,
    getMarkerLabel: CableLayer.getMarkerLabel,
    showLabelZoom: CableLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: CableLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: CableLayer.getElementTableFields,
    getFormConfig: CableLayer.getElementFormTemplate,
    filterFormConfig: CableLayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: CableLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    // config fields
    ConfigFormTemplate: CableLayer.ELEMENT_CONFIG_TEMPLATE,
    ConfigInitData: CableLayer.INITIAL_CONFIG_DATA,
    TableColDefs: CableLayer.CONFIG_LIST_TABLE_COL_DEFS,
  },
  [BuildingLayer.LAYER_KEY]: {
    preUid: BuildingLayer.PRE_UID,
    [PLANNING_EVENT.addElementForm]: BuildingLayer.ElementForm,
    [PLANNING_EVENT.editElementForm]: BuildingLayer.ElementForm,
    featureType: BuildingLayer.LAYER_FEATURE_TYPE,
    getViewOptions: BuildingLayer.getViewOptions,
    getMarkerLabel: BuildingLayer.getMarkerLabel,
    showLabelZoom: BuildingLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: BuildingLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: BuildingLayer.getElementTableFields,
    elementTableExtraControls: BuildingLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: BuildingLayer.getElementFormTemplate,
    filterFormConfig: BuildingLayer.FILTER_FORM_CONFIG,
  },
  [SAreaLayer.LAYER_KEY]: {
    preUid: SAreaLayer.PRE_UID,
    featureType: SAreaLayer.LAYER_FEATURE_TYPE,
    getViewOptions: SAreaLayer.getViewOptions,
    getMarkerLabel: SAreaLayer.getMarkerLabel,
    showLabelZoom: SAreaLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: SAreaLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: SAreaLayer.getElementTableFields,
    elementTableExtraControls: SAreaLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: SAreaLayer.getElementFormTemplate,
    filterFormConfig: SAreaLayer.FILTER_FORM_CONFIG,
    getDependantFields: SAreaLayer.getDependantFields,
  },
  [PopLayer.LAYER_KEY]: {
    preUid: PopLayer.PRE_UID,
    featureType: PopLayer.LAYER_FEATURE_TYPE,
    getViewOptions: PopLayer.getViewOptions,
    getMarkerLabel: PopLayer.getMarkerLabel,
    showLabelZoom: PopLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: PopLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: PopLayer.getElementTableFields,
    getFormConfig: PopLayer.getElementFormTemplate,
    filterFormConfig: PopLayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: PopLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
  },
  [SPopLayer.LAYER_KEY]: {
    preUid: SPopLayer.PRE_UID,
    featureType: SPopLayer.LAYER_FEATURE_TYPE,
    getViewOptions: SPopLayer.getViewOptions,
    getMarkerLabel: SPopLayer.getMarkerLabel,
    showLabelZoom: SPopLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: SPopLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: SPopLayer.getElementTableFields,
    getFormConfig: SPopLayer.getElementFormTemplate,
    filterFormConfig: SPopLayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: SPopLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
  },
  [FSALayer.LAYER_KEY]: {
    preUid: FSALayer.PRE_UID,
    featureType: FSALayer.LAYER_FEATURE_TYPE,
    getViewOptions: FSALayer.getViewOptions,
    getMarkerLabel: FSALayer.getMarkerLabel,
    showLabelZoom: FSALayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: FSALayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: FSALayer.getElementTableFields,
    getFormConfig: FSALayer.getElementFormTemplate,
    filterFormConfig: FSALayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: FSALayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getDependantFields: FSALayer.getDependantFields,
  },
  [DSALayer.LAYER_KEY]: {
    preUid: DSALayer.PRE_UID,
    featureType: DSALayer.LAYER_FEATURE_TYPE,
    getViewOptions: DSALayer.getViewOptions,
    getMarkerLabel: DSALayer.getMarkerLabel,
    showLabelZoom: DSALayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: DSALayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: DSALayer.getElementTableFields,
    getFormConfig: DSALayer.getElementFormTemplate,
    filterFormConfig: DSALayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: DSALayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getDependantFields: DSALayer.getDependantFields,
  },
  [CSALayer.LAYER_KEY]: {
    preUid: CSALayer.PRE_UID,
    featureType: CSALayer.LAYER_FEATURE_TYPE,
    getViewOptions: CSALayer.getViewOptions,
    getMarkerLabel: CSALayer.getMarkerLabel,
    showLabelZoom: CSALayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: CSALayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: CSALayer.getElementTableFields,
    getFormConfig: CSALayer.getElementFormTemplate,
    filterFormConfig: CSALayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: CSALayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getDependantFields: CSALayer.getDependantFields,
  },
  [PoleLayer.LAYER_KEY]: {
    preUid: PoleLayer.PRE_UID,
    featureType: PoleLayer.LAYER_FEATURE_TYPE,
    getViewOptions: PoleLayer.getViewOptions,
    initialElementData: PoleLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: PoleLayer.getElementTableFields,
    getFormConfig: PoleLayer.getElementFormTemplate,
    filterFormConfig: PoleLayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: PoleLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
  },
  [ManholeLayer.LAYER_KEY]: {
    preUid: ManholeLayer.PRE_UID,
    featureType: ManholeLayer.LAYER_FEATURE_TYPE,
    getViewOptions: ManholeLayer.getViewOptions,
    getMarkerLabel: ManholeLayer.getMarkerLabel,
    showLabelZoom: ManholeLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: ManholeLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: ManholeLayer.getElementTableFields,
    getFormConfig: ManholeLayer.getElementFormTemplate,
    filterFormConfig: ManholeLayer.FILTER_FORM_CONFIG,
    elementTableExtraControls: ManholeLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
  },
  [JointCloserLayer.LAYER_KEY]: {
    preUid: JointCloserLayer.PRE_UID,
    featureType: JointCloserLayer.LAYER_FEATURE_TYPE,
    getViewOptions: JointCloserLayer.getViewOptions,
    getMarkerLabel: JointCloserLayer.getMarkerLabel,
    showLabelZoom: JointCloserLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: JointCloserLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: JointCloserLayer.getElementTableFields,
    elementTableExtraControls: JointCloserLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: JointCloserLayer.getElementFormTemplate,
    filterFormConfig: JointCloserLayer.FILTER_FORM_CONFIG,
  },
  [FDCLayer.LAYER_KEY]: {
    preUid: FDCLayer.PRE_UID,
    featureType: FDCLayer.LAYER_FEATURE_TYPE,
    getViewOptions: FDCLayer.getViewOptions,
    getMarkerLabel: FDCLayer.getMarkerLabel,
    initialElementData: FDCLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: FDCLayer.getElementTableFields,
    elementTableExtraControls: FDCLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: FDCLayer.getElementFormTemplate,
    filterFormConfig: JointCloserLayer.FILTER_FORM_CONFIG,
  },
  [OltLayer.LAYER_KEY]: {
    preUid: OltLayer.PRE_UID,
    featureType: OltLayer.LAYER_FEATURE_TYPE,
    getViewOptions: OltLayer.getViewOptions,
    getMarkerLabel: OltLayer.getMarkerLabel,
    showLabelZoom: OltLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: OltLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: OltLayer.getElementTableFields,
    elementTableExtraControls: OltLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: OltLayer.getElementFormTemplate,
    filterFormConfig: OltLayer.FILTER_FORM_CONFIG,
    // config fields
    ConfigFormTemplate: OltLayer.ELEMENT_CONFIG_TEMPLATE,
    ConfigInitData: OltLayer.INITIAL_CONFIG_DATA,
    TableColDefs: OltLayer.CONFIG_LIST_TABLE_COL_DEFS,
  },
  [FmsLayer.LAYER_KEY]: {
    preUid: FmsLayer.PRE_UID,
    featureType: FmsLayer.LAYER_FEATURE_TYPE,
    getViewOptions: FmsLayer.getViewOptions,
    getMarkerLabel: FmsLayer.getMarkerLabel,
    initialElementData: FmsLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: FmsLayer.getElementTableFields,
    elementTableExtraControls: FmsLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: FmsLayer.getElementFormTemplate,
    filterFormConfig: FmsLayer.FILTER_FORM_CONFIG,
    // config fields
    ConfigFormTemplate: FmsLayer.ELEMENT_CONFIG_TEMPLATE,
    ConfigInitData: FmsLayer.INITIAL_CONFIG_DATA,
    TableColDefs: FmsLayer.CONFIG_LIST_TABLE_COL_DEFS,
  },
  [OnuLayer.LAYER_KEY]: {
    preUid: OnuLayer.PRE_UID,
    featureType: OnuLayer.LAYER_FEATURE_TYPE,
    getViewOptions: OnuLayer.getViewOptions,
    getMarkerLabel: OnuLayer.getMarkerLabel,
    showLabelZoom: OnuLayer.SHOW_LABELS_AFTER_ZOOM,
    initialElementData: OnuLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: OnuLayer.getElementTableFields,
    elementTableExtraControls: OnuLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: OnuLayer.getElementFormTemplate,
    filterFormConfig: OnuLayer.FILTER_FORM_CONFIG,
    // config fields
    ConfigFormTemplate: OnuLayer.ELEMENT_CONFIG_TEMPLATE,
    ConfigInitData: OnuLayer.INITIAL_CONFIG_DATA,
    TableColDefs: OnuLayer.CONFIG_LIST_TABLE_COL_DEFS,
  },
  [CustomerLayer.LAYER_KEY]: {
    preUid: CustomerLayer.PRE_UID,
    featureType: CustomerLayer.LAYER_FEATURE_TYPE,
    getViewOptions: CustomerLayer.getViewOptions,
    getMarkerLabel: CustomerLayer.getMarkerLabel,
    initialElementData: CustomerLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: CustomerLayer.getElementTableFields,
    elementTableExtraControls: CustomerLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: CustomerLayer.getElementFormTemplate,
    filterFormConfig: CustomerLayer.FILTER_FORM_CONFIG,
    layerSearchShowExtraFields: CustomerLayer.LAYER_SEARCH_SHOW_EXTRA_FIELDS,
    layerSearchByFields: CustomerLayer.LAYER_SEARCH_BY_FIELDS,
  },
  [SwitchLayer.LAYER_KEY]: {
    preUid: SwitchLayer.PRE_UID,
    featureType: SwitchLayer.LAYER_FEATURE_TYPE,
    getViewOptions: SwitchLayer.getViewOptions,
    initialElementData: SwitchLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: SwitchLayer.getElementTableFields,
    elementTableExtraControls: SwitchLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: SwitchLayer.getElementFormTemplate,
    filterFormConfig: SwitchLayer.FILTER_FORM_CONFIG,
    transformAndValidateData: SwitchLayer.transformAndValidateData,
  },
  [IPQAMLayer.LAYER_KEY]: {
    preUid: IPQAMLayer.PRE_UID,
    featureType: IPQAMLayer.LAYER_FEATURE_TYPE,
    getViewOptions: IPQAMLayer.getViewOptions,
    initialElementData: IPQAMLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: IPQAMLayer.getElementTableFields,
    elementTableExtraControls: IPQAMLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: IPQAMLayer.getElementFormTemplate,
    filterFormConfig: IPQAMLayer.FILTER_FORM_CONFIG,
    transformAndValidateData: IPQAMLayer.transformAndValidateData,
  },
  [FirewallLayer.LAYER_KEY]: {
    preUid: FirewallLayer.PRE_UID,
    featureType: FirewallLayer.LAYER_FEATURE_TYPE,
    getViewOptions: FirewallLayer.getViewOptions,
    initialElementData: FirewallLayer.INITIAL_ELEMENT_DATA,
    getElementTableFields: FirewallLayer.getElementTableFields,
    elementTableExtraControls: FirewallLayer.ELEMENT_TABLE_EXTRA_CONTROLS,
    getFormConfig: FirewallLayer.getElementFormTemplate,
    filterFormConfig: FirewallLayer.FILTER_FORM_CONFIG,
    transformAndValidateData: FirewallLayer.transformAndValidateData,
  },
};

export const convertLayerServerData = (layerKey, serverData) => {
  let resultData = orderBy(serverData, ["id"], ["desc"]);
  const featureType = LayerKeyMappings[layerKey]["featureType"];

  // PolyLine / Polygon
  if (
    featureType === FEATURE_TYPES.POLYGON ||
    featureType === FEATURE_TYPES.POLYLINE
  ) {
    resultData.map((d) => {
      d.geometry = [...d.coordinates];
      // [ [lat, lng], ...] -> [{lat, lng}, ...]
      d.coordinates = coordsToLatLongMap(d.coordinates);
      d.center = coordsToLatLongMap([d.center])[0];
    });
    return resultData;
  }
  // Point gis layer
  else if (featureType === FEATURE_TYPES.POINT) {
    resultData.map((d) => {
      try {
        d.geometry = [...d.coordinates];
        d.coordinates = coordsToLatLongMap([d.coordinates])[0];
      } catch (error) {
        // could not find coordinates for this point
        console.log("🚀 ~ Line 353, convertLayerServerData ~ error:", error);
      }
    });
    return resultData;
  }
  // Multi polygon
  else if (featureType === FEATURE_TYPES.MULTI_POLYGON) {
    resultData.map((d) => {
      // [ [lat, lng], ...] -> [{lat, lng}, ...]
      d.coordinates = coordsToLatLongMap(d.coordinates, true);
      d.center = coordsToLatLongMap([d.center])[0];
    });
    return resultData;
  }
};

const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
export const nanoid = customAlphabet(alphabet, 6);

export const generateElementUid = (layerKey) => {
  return `${LayerKeyMappings[layerKey]["preUid"]}.${nanoid()}`;
};

/**
 * create id based on element uniqueId and highlightType
 * highlightType is required. unique_id optional for history or google search cases
 */
export const generateSpecialLayerId = ({ unique_id }, highlightType) => {
  return `${highlightType}${unique_id ? "_" + unique_id : ""}`;
};

export const SPECIAL_LAYER_HIGHLIGHT_TYPES = {
  on_map_click_highlight: "on_map_click_highlight",
  google_place_search: "google_place_search",
  google_latlng_search: "google_latlng_search",
  history: "history",
  view_on_map: "view_on_map",
};

export const ALL_SELECT_OPTIONS = [
  ...LAYER_STATUS_OPTIONS,
  ...DPLayer.DP_TYPE_OPTIONS,
  ...DPLayer.CABINET_TYPE_OPTIONS,
  ...SAreaLayer.AREA_TAG_OPTIONS,
  ...SAreaLayer.AREA_LOCALITY_OPTIONS,
  ...PoleLayer.PERMISSION_STATUS,
  ...PoleLayer.POLY_TYPES,
  ...CableLayer.CABLE_TYPE_OPTIONS,
  ...POP_BUSSINESS_NATURE,
  ...POP_STRUCTURE_TYPE,
  ...CableLayer.CABLE_SIGNAL_TYPES,
  ...FSALayer.DESIGN_TYPES,
  ...JointCloserLayer.JC_TYPES,
  ...CustomerLayer.CUST_CAT_OPTIONS,
  ...CustomerLayer.PLAN_STATUS_OPTIONS,
  ...CABINET_TYPE_OPTIONS,
];

export const COMMON_POLYGON_OPTIONS = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0.3,
  clickable: false,
  draggable: false,
  editable: false,
};

export const HIGHLIGHTED_POLYGON_OPTIONS = {
  fillColor: "transparent",
  strokeColor: HIGHLIGHT_COLOR,
  strokeOpacity: 1,
  strokeWeight: 6,
};

export const HIGHLIGHTED_POLYLINE_OPTIONS = {
  fillColor: HIGHLIGHT_COLOR,
  fillOpacity: 1,
  strokeColor: HIGHLIGHT_COLOR,
  strokeOpacity: 1,
  strokeWeight: 6,
  geodesic: true,
};

// show layer name instead of layer key
export const LayerKeyNameMapping = {
  [RegionLayer.LAYER_KEY]: "Region",
  [DPLayer.LAYER_KEY]: "Distribution Point",
  [SplitterLayer.LAYER_KEY]: "Splitter",
  [CableLayer.LAYER_KEY]: "Cable",
  [BuildingLayer.LAYER_KEY]: "Survey Building",
  [SAreaLayer.LAYER_KEY]: "Survey Area",
  [PopLayer.LAYER_KEY]: "Pop Location",
  [SPopLayer.LAYER_KEY]: "Sub Pop Location",
  [FSALayer.LAYER_KEY]: "Feeder Service Area",
  [DSALayer.LAYER_KEY]: "Distribution Service Area",
  [CSALayer.LAYER_KEY]: "Customer Service Area",
  [PoleLayer.LAYER_KEY]: "Pole",
  [ManholeLayer.LAYER_KEY]: "Manhole",
  [JointCloserLayer.LAYER_KEY]: "Joint Closer",
  [OltLayer.LAYER_KEY]: "OLT",
  [FmsLayer.LAYER_KEY]: "FMS",
  [OnuLayer.LAYER_KEY]: "ONU",
  [FDCLayer.LAYER_KEY]: "FDC",
  [CustomerLayer.LAYER_KEY]: "Customer",
};
