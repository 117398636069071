import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useController } from "react-hook-form";

import get from "lodash/get";
import map from "lodash/map";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { getSelectedConfigurations } from "planning/data/planningState.selectors";
import { fetchGroupTagList } from "planning/data/catv/catv.services";

const GeneralGroupSelect = ({
  name,
  control,
  rules,
  color,
  error,
  helperText,
  disabled,
  required,
}) => {
  const { layerKey } = useSelector(getPlanningMapState);
  const selectedConfigurations = useSelector(getSelectedConfigurations);
  const selectedConfigValue = get(selectedConfigurations, layerKey, null);
  const groupGeneralTag = get(selectedConfigValue, "group_general_tag");

  useEffect(() => {
    fetchTags(groupGeneralTag);
  }, [groupGeneralTag]);

  const { field } = useController({
    name,
    control,
    rules,
  });

  const {
    mutate: fetchTags,
    isLoading,
    data: groupList,
  } = useMutation(fetchGroupTagList, {
    onSuccess: (groupList) => {
      field.onChange(map(groupList, "group_id"));
    },
  });

  if (groupList) {
    return (
      <Box>
        <Typography
          sx={{
            marginLeft: 1,
            color: "rgba(0, 0, 0, 0.6)",
          }}
          variant="caption"
        >
          General Group
        </Typography>
        <Stack spacing={1} flexDirection="row" flexWrap="wrap">
          {groupList.map((group) => {
            return (
              <Chip label={group.name} key={group.id} variant={"filled"} />
            );
          })}
        </Stack>
      </Box>
    );
  }
  return null;
};

export default GeneralGroupSelect;
