import OneSignal from "react-onesignal";

import Api from "utils/api.utils";
import { queryClient } from "App/App";
import { logout } from "redux/reducers/auth.reducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPostLogout } from "utils/url.constants";
import getOrCreateOneSignalClient from "utils/onesignal.utils";

export const handleLogoutUser = (dispatch) => {
  queryClient.clear();
  const OneSignalClient = getOrCreateOneSignalClient();
  OneSignalClient.logout();
  dispatch(logout());
};

export const logoutService = async () => {
  // const pushSubscriptionId = OneSignal.User.PushSubscription.id;
  const pushSubscriptionId = undefined;

  const res = await Api.post(apiPostLogout(), {
    subscription_id: pushSubscriptionId,
    client_id: process.env.REACT_APP_CLIENT_ID,
  });
  return res.data;
};

export const logoutThunk = createAsyncThunk(
  "planningGis/logoutService",
  logoutService
);
