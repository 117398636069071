import get from "lodash/get";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import reject from "lodash/reject";
import {
  postAddPortConnectionThunk,
  postRemovePortConnectionThunk,
} from "./port.services";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  // elements
  left: null,
  right: null,
  middle: null,
  // selection data
  selectedPorts: [],
  // shape : [ {connType: new | old, elem1, elem2}]
  connections: [],
  // network states
  portUpdateLoading: false,
  portUpdateError: null,
};

const updatePortsFromPayload = (state, payload) => {
  for (let pInd = 0; pInd < payload.length; pInd++) {
    const newPort = payload[pInd];
    // find which element has this port
    let updateElement;
    if (
      !!state.left &&
      state.left.id == newPort.element &&
      state.left.layer_key == newPort.layer_key
    ) {
      updateElement = state.left;
    } else if (
      !!state.right &&
      state.right.id == newPort.element &&
      state.right.layer_key == newPort.layer_key
    ) {
      updateElement = state.right;
    } else if (
      !!state.middle &&
      state.middle.id == newPort.element &&
      state.middle.layer_key == newPort.layer_key
    ) {
      updateElement = state.middle;
    }
    if (!!updateElement) {
      // find port that needs to update
      const matchingPortIndex = updateElement.ports.findIndex(
        (p) => p.id === newPort.id
      );
      if (matchingPortIndex !== -1) {
        updateElement.ports[matchingPortIndex] = { ...newPort };
      }
    }
  }
};

const addConnectionFromPayload = (state, payload) => {
  for (let pInd = 0; pInd < payload.length; pInd++) {
    const {
      is_input,
      is_inverted = false,
      element_unique_id,
      connected_to,
    } = payload[pInd];
    // cosider inverted cable logic
    const actual_is_input = is_inverted ? !is_input : is_input;
    // create connection from all input ports
    if (actual_is_input) {
      state.connections.push({
        connType: "new",
        elem1: connected_to,
        elem2: element_unique_id,
      });
    }
  }
};

const removeConnectionFromPayload = (state, payload) => {
  let newConnections = [...state.connections];
  for (let pInd = 0; pInd < payload.length; pInd++) {
    const { is_input, is_inverted = false, element_unique_id } = payload[pInd];
    // cosider inverted cable logic
    const actual_is_input = is_inverted ? !is_input : is_input;
    // find and remove
    if (actual_is_input) {
      newConnections = reject(newConnections, function (d) {
        return d.elem1 === element_unique_id || d.elem2 === element_unique_id;
      });
    }
  }
  state.connections = newConnections;
};

const splicingSlice = createSlice({
  name: "splicing",
  initialState,
  reducers: {
    // elemPortData : { left, right, middle }
    // splicingData: splicingPostData from getPlanningMapStateData selector; used to detect cable inverted
    setSplicingElements: (state, { payload }) => {
      const { elemPortData, splicingData } = payload;
      state.left = get(elemPortData, "left", null);
      state.right = get(elemPortData, "right", null);
      state.middle = get(elemPortData, "middle", null);
      // create element id list
      const elemIdList = [];
      if (!!state.left) elemIdList.push(state.left.unique_id);
      if (!!state.right) elemIdList.push(state.right.unique_id);
      if (!!state.middle) elemIdList.push(state.middle.unique_id);
      // generate connections
      const newConnections = [];
      // draw connection from this 2 sides
      for (const side in elemPortData) {
        if (Object.hasOwnProperty.call(elemPortData, side)) {
          // ignore middle
          if (side === "middle") continue;
          const currSide = elemPortData[side];

          // if layer_key is cable check for inverted possiblity
          let isInverted = false;
          if (currSide.layer_key === "p_cable") {
            if (side === "right" && splicingData[side].cable_end === "B") {
              isInverted = true;
            }
            if (side === "left" && splicingData[side].cable_end === "A") {
              isInverted = true;
            }
          }
          // get input ports from left side, output from right
          let isInputFilter = side === "right";
          isInputFilter = isInverted ? !isInputFilter : isInputFilter;

          const inputPorts = filter(currSide.ports, [
            "is_input",
            isInputFilter,
          ]);

          // loop over ports and create connection from connected ports
          for (let pInd = 0; pInd < inputPorts.length; pInd++) {
            const { status, element_unique_id, connected_to } =
              inputPorts[pInd];
            if (status === "C") {
              // don't add duplicate connecttions
              if (findIndex(newConnections, ["elem2", connected_to]) === -1) {
                // check if the opposite connecting element is on splicing tray
                let hasOppositeSide = false;
                for (let sidInd = 0; sidInd < elemIdList.length; sidInd++) {
                  const currElemUid = elemIdList[sidInd];
                  if (connected_to.includes(currElemUid)) {
                    hasOppositeSide = true;
                    break;
                  }
                }

                if (hasOppositeSide) {
                  newConnections.push({
                    connType: "old",
                    elem1: connected_to,
                    elem2: element_unique_id,
                  });
                }
              }
            }
          }
        }
      }
      state.connections = newConnections;
    },
    // payload : {...user selected portData, elem_layer_key}
    setSelectedPorts: (state, { payload }) => {
      state.selectedPorts.push({ ...payload });
    },
    resetSelectedPorts: (state) => {
      state.selectedPorts = [];
    },
  },

  extraReducers: {
    [postAddPortConnectionThunk.pending]: (state) => {
      state.portUpdateLoading = true;
      state.portUpdateError = null;
    },
    // payload => res from add connection success, shape: [ port1, port2 ]
    [postAddPortConnectionThunk.fulfilled]: (state, { payload }) => {
      // reset selected ports
      state.selectedPorts = [];
      state.portUpdateLoading = false;
      state.portUpdateError = null;
      updatePortsFromPayload(state, payload);
      addConnectionFromPayload(state, payload);
    },
    [postAddPortConnectionThunk.rejected]: (state, { error }) => {
      state.portUpdateLoading = false;
      state.portUpdateError = true;
    },
    [postRemovePortConnectionThunk.pending]: (state) => {
      state.portUpdateLoading = true;
      state.portUpdateError = null;
    },
    [postRemovePortConnectionThunk.fulfilled]: (state, { payload }) => {
      state.selectedPorts = [];
      state.portUpdateLoading = false;
      state.portUpdateError = null;
      updatePortsFromPayload(state, payload);
      removeConnectionFromPayload(state, payload);
    },
    [postRemovePortConnectionThunk.rejected]: (state, { error }) => {
      state.portUpdateLoading = false;
      state.portUpdateError = true;
    },
  },
});

export const { setSplicingElements, setSelectedPorts, resetSelectedPorts } =
  splicingSlice.actions;
export default splicingSlice.reducer;
