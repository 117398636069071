import React, { useState, useCallback, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import filter from "lodash/filter";
import get from "lodash/get";
import size from "lodash/size";
import find from "lodash/find";
import includes from "lodash/includes";

import { Stack, Typography, Box, Divider, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import CustomSelect from "components/common/FormFields/CustomSelect";

import { fetchRegionList } from "region/data/services";
import {
  fetchLayerData,
  fetchLayerList,
} from "planning/data/actionBar.services";
import {
  getIsSuperAdminUser,
  getUserPermissions,
} from "redux/selectors/auth.selectors";
import { postCatvFavGraphConfig } from "pages/dashboard.service";
import { addNotification } from "redux/reducers/notification.reducer";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import { ALERT_POSSIBLE_LAYER_KEYS } from "pages/Dashboard/dashboard.utils";

const FavAlertConfigForm = ({ widgetId, widgetConfig, onClose }) => {
  /**
   * Parent:
   *    FavAlertListWidget
   */

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [region, setRegion] = useState(null);
  const [layer, setLayer] = useState(null);
  const [element, setElement] = useState(null);

  const permissions = useSelector(getUserPermissions);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);

  const layerKey = get(layer, "layer_key");
  const elementId = get(element, "id");
  const regionId = get(region, "id");

  const { isLoading: regionListLoading, data: regionList = [] } = useQuery(
    ["regionList", "data"],
    fetchRegionList,
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (widgetConfig && get(widgetConfig, "region_id")) {
          setRegion(find(res, ["id", widgetConfig.region_id]));
        }
      },
    }
  );

  const { isLoading: isLayerLoading, data: layerCofigs = [] } = useQuery(
    "planningLayerConfigs",
    fetchLayerList,
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (widgetConfig && get(widgetConfig, "layer_key")) {
          setLayer(find(res, ["layer_key", widgetConfig.layer_key]));
          fetchLayerDataMutation({
            regionIdList: [widgetConfig.region_id],
            layerKey: widgetConfig.layer_key,
          });
        }
      },
    }
  );

  const {
    mutate: fetchLayerDataMutation,
    isLoading: fetchingLayerData,
    data: elementList,
  } = useMutation(fetchLayerData, {
    onSuccess: (res) => {
      if (widgetConfig && get(widgetConfig, "element_id")) {
        setElement(
          find(res, {
            id: widgetConfig.element_id,
            layer_key: widgetConfig.layer_key,
          })
        );
      }
    },
  });

  const { mutate: postCatvFavGraphConfigMutation, isLoading: updatingConfig } =
    useMutation(postCatvFavGraphConfig);

  const handleRegionChange = useCallback((newValue) => {
    setRegion(newValue);
    setLayer(null);
    setElement(null);
  }, []);

  const handleLayerChange = useCallback(
    (newValue) => {
      setLayer(newValue);
      setElement(null);

      if (!newValue) return;
      fetchLayerDataMutation({
        regionIdList: [region.id],
        layerKey: newValue.layer_key,
      });
    },
    [region]
  );

  const handleElementChange = useCallback((newValue) => {
    setElement(newValue);
  }, []);

  const handleSubmit = () => {
    if (!regionId) {
      dispatch(
        addNotification({
          type: "error",
          title: "Invalid input",
          text: "Region is required",
        })
      );
      return;
    }
    //
    else if (regionId && layerKey && !elementId) {
      dispatch(
        addNotification({
          type: "error",
          title: "Invalid input",
          text: "Element is required",
        })
      );
      return;
    }

    const postData = {
      [widgetId]: {
        region_id: regionId,
        layer_key: layerKey,
        element_id: elementId,
        element_name: get(element, "name"),
        host_id: get(element, "host_id"),
        version: !!get(widgetConfig, "version") ? widgetConfig.version + 1 : 1,
      },
    };

    postCatvFavGraphConfigMutation(
      { dashkey: "CatvFavGraph", data: postData },
      {
        onSuccess: () => {
          onClose();
          queryClient.invalidateQueries("CatvFavGraphConfig");
        },
        onError: (err) => {
          const { fieldList, messageList } = parseErrorMessagesWithFields(err);
          for (let index = 0; index < fieldList.length; index++) {
            const field = fieldList[index];
            const errorMessage = messageList[index];
            dispatch(
              addNotification({
                type: "error",
                title: field,
                text: errorMessage,
              })
            );
          }
        },
      }
    );
  };

  const possibleLayerKeys = useMemo(() => {
    return filter(layerCofigs, (config) => {
      const layerKey = config.layer_key;
      return (
        (get(permissions, `${layerKey}_view`) || isSuperAdminUser) &&
        includes(ALERT_POSSIBLE_LAYER_KEYS, layerKey)
      );
    });
  }, [layerCofigs]);

  return (
    <Stack minWidth="600px">
      <Stack
        p={2}
        pb={1}
        direction="row"
        spacing={2}
        width="100%"
        alignItems="center"
      >
        <Typography
          color="primary.dark"
          flex={1}
          className="dtl-title"
          variant="h5"
        >
          Alert Config Form
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider flexItem />
      <Box p={4}>
        <Stack sx={{ margin: "0 auto", maxWidth: "480px" }} spacing={2}>
          <CustomSelect
            label="Select Region"
            name="region"
            options={regionList}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            menuPortalTarget={document.body}
            isLoading={regionListLoading}
            isDisabled={regionListLoading}
            value={region}
            onChange={handleRegionChange}
          />

          <CustomSelect
            label="Select Layer"
            name="layer"
            options={possibleLayerKeys}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.layer_key}
            menuPortalTarget={document.body}
            isLoading={isLayerLoading}
            value={layer}
            onChange={handleLayerChange}
            // isDisabled={isLayerLoading || !size(region)}
          />
          <CustomSelect
            label="Select Element"
            name="element"
            options={layer && size(elementList) ? elementList : []}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            menuPortalTarget={document.body}
            isLoading={fetchingLayerData}
            value={element}
            onChange={handleElementChange}
            // isDisabled={isLayerLoading || !size(region)|| fetchingLayerData}
          />

          <Stack flex={1} pt={2} direction="row" justifyContent="flex-end">
            <LoadingButton
              variant="outlined"
              color="success"
              endIcon={<DoneIcon />}
              loading={updatingConfig}
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default FavAlertConfigForm;
