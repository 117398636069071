import React, { useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import get from "lodash/get";
import size from "lodash/size";
import has from "lodash/has";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import LoadingButton from "@mui/lab/LoadingButton";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LinkIcon from "@mui/icons-material/Link";

import { styled } from "@mui/material/styles";

import PortCell from "./PortCell";
import ColorCell from "./ColorCell";
import ConnectedPortDetails from "./ConnectedPortDetails";

import useGetConnectedPort from "planning/GisMap/hooks/useGetConnectedPort";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { LAYER_KEY as CableLayerKey } from "planning/GisMap/layers/p_cable";
import { checkUserPermission } from "redux/selectors/auth.selectors";
import { openLCFormWithData } from "planning/data/planning.actions";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };
});

const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    color: theme.palette.primary.contrastText,
    textAlign: "center",
  };
});

const PortList = ({ portList, tableConfig }) => {
  /**
   * Parent:
   *    CablePortDetails
   *    DefaultElemPortDetails
   *    OltPortDetails
   */
  const dispatch = useDispatch();
  const { layerKey } = useSelector(getPlanningMapState);
  const canTraceBackView = useSelector(checkUserPermission("trace_back_view"));

  const { getConnectedPort, connectedPortData } = useGetConnectedPort();

  const handleGetConnection = useCallback(
    (port_id, removePort = null) =>
      () => {
        // call port get connection api
        getConnectedPort(layerKey, port_id, removePort);
      },
    [layerKey, connectedPortData]
  );

  // on click open LC form with element and port selected
  const handleLcFormClick = useCallback(
    /**
     * Open LC Trace form with traceType, element and port selected
     * @param {Id} portId int id of the port to set on trace form
     * @param {Json} port full data of the port user clicked on
     * @param {String} config_type check if its cable type connection with this
     * @param {String} port_end only required for cable type connection to get which port is this A / B
     */
    (portId, port, config_type, port_end = null) =>
      () => {
        let isInput = port.is_input;
        let true_portId = portId;
        if (config_type === "get_connection_two_end") {
          // from CablePortDetails tableConfig
          // for cable types decide ip / op from port end string
          isInput = port_end === "port_id_A_end";

          // handle inverted ports
          if (port.is_inverted) {
            // toggle input based on inverted
            isInput = !isInput;
            // if port was A end than convert to B, else covert to A
            const portIdKey =
              port_end === "port_id_A_end" ? "port_id_B_end" : "port_id_A_end";
            true_portId = port[portIdKey];
          }
        }

        let actionPayload;
        if (isInput) {
          actionPayload = {
            traceType: "U",
            to_layer: layerKey,
            to_element: port.element,
            to_port: true_portId,
          };
        } else {
          actionPayload = {
            traceType: "D",
            from_layer: layerKey,
            from_element: port.element,
            from_port: true_portId,
          };
        }
        dispatch(openLCFormWithData(actionPayload));
      },
    [layerKey]
  );

  if (!size(portList)) {
    return (
      <Box pb={1}>
        <Typography variant="h6" color="text.secondary">
          No port details available
        </Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper} sx={{ marginBottom: 1 }}>
      <Table>
        <StyledTableHead>
          <TableRow>
            {tableConfig.map((conf) => {
              // do not show port btn if user  dont have permission
              if (
                !canTraceBackView &&
                (conf.type === "get_connection" ||
                  conf.type === "get_connection_two_end")
              ) {
                return null;
              }
              return (
                <StyledTableCell key={conf.label}>{conf.label}</StyledTableCell>
              );
            })}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {portList.map((port) => {
            let portId;
            if (layerKey === CableLayerKey) {
              if (has(connectedPortData, port.port_id_A_end)) {
                portId = port.port_id_A_end;
              } else if (has(connectedPortData, port.port_id_B_end)) {
                portId = port.port_id_B_end;
              }
            } else {
              portId = port.id;
            }
            const connectedData = get(connectedPortData, [portId, "data"]);
            const errorMessage = get(connectedPortData, [portId, "error"]);
            const expandRow = Boolean(connectedData || errorMessage);

            return (
              <Fragment key={port.id}>
                <StyledTableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tableConfig.map((conf) => {
                    const value = get(port, conf.key, "");
                    // do not show port btn if user  dont have permission
                    if (
                      !canTraceBackView &&
                      (conf.type === "get_connection" ||
                        conf.type === "get_connection_two_end")
                    ) {
                      return null;
                    }

                    if (conf.type === "port_number") {
                      return <PortCell key={conf.key} value={value} />;
                    }
                    //
                    else if (conf.type === "color") {
                      return <ColorCell key={conf.key} value={value} />;
                    }
                    // btn to fetch connected element and show new row if succesfull
                    else if (
                      conf.type === "get_connection" ||
                      conf.type === "get_connection_two_end"
                    ) {
                      if (
                        // don't show button if lc entry is null, specifically for FMS make sure port status is connected
                        // for cable get A / B end connection status and check null, cause lc entry will be common for both ports
                        (conf.type === "get_connection" &&
                          (!port.lc_entry || port.status !== "C")) ||
                        (conf.type === "get_connection_two_end" &&
                          (!get(port, conf.lc_entry_val) || !port.lc_entry))
                      ) {
                        return <TableCell key={conf.key} />;
                      }

                      const isSelected =
                        conf.type === "get_connection_two_end"
                          ? expandRow && portId == value
                          : expandRow;

                      const isLoading = !!get(connectedPortData, [
                        value,
                        "isFetching",
                      ]);

                      // pass list to clear ports in new fetch
                      const removePort = get(port, conf.other_port_id);

                      return (
                        <TableCell key={conf.key}>
                          <Stack spacing={1} direction="row">
                            <Tooltip title="Connected Link" placement="top">
                              <LoadingButton
                                variant="outlined"
                                // value is port id
                                onClick={handleGetConnection(value, removePort)}
                                size="small"
                                loading={isLoading}
                                color={isSelected ? "success" : "primary"}
                              >
                                <LinkIcon />
                              </LoadingButton>
                            </Tooltip>
                            <Tooltip title="View Trace Back" placement="top">
                              <Button
                                variant="outlined"
                                // value is port id
                                onClick={handleLcFormClick(
                                  value,
                                  port,
                                  conf.type,
                                  conf.key
                                )}
                                size="small"
                              >
                                <AccountTreeIcon />
                              </Button>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      );
                    }
                    //
                    else {
                      // show inverted cable indicator
                      if (
                        (conf.key === "common_name" || conf.key === "name") &&
                        port.is_inverted
                      ) {
                        return <TableCell key={conf.key}>I- {value}</TableCell>;
                      }
                      return <TableCell key={conf.key}>{value}</TableCell>;
                    }
                  })}
                </StyledTableRow>
                <StyledTableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    colSpan={size(tableConfig)}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <Collapse
                      in={Boolean(connectedData || errorMessage)}
                      timeout="auto"
                      unmountOnExit
                    >
                      {errorMessage ? (
                        <Box p={2}>
                          <Typography
                            variant="h6"
                            color="text.secondary"
                            textAlign="center"
                          >
                            {errorMessage}
                          </Typography>
                        </Box>
                      ) : null}
                      {connectedData ? (
                        <ConnectedPortDetails
                          connectedPortData={connectedData}
                        />
                      ) : null}
                    </Collapse>
                  </TableCell>
                </StyledTableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PortList;
