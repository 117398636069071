import React from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import PermissionNotFound from "components/common/PermissionNotFound";
import AlertList from "gis_alert/components/AlertList";

import {
  checkUserPermission,
  getIsSuperAdminUser,
} from "redux/selectors/auth.selectors";

/**
 * Parent:
 *    App
 */
const AlertAdminPage = () => {
  const canUserView = useSelector(checkUserPermission("alert_list_view"));
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);
  const canView = canUserView || isSuperAdminUser;

  if (canView) {
    return (
      <Box p={2} height="100%">
        <Paper sx={{ height: "100%" }}>
          <AlertList />
        </Paper>
      </Box>
    );
  } else {
    return (
      <Container>
        <Paper
          sx={{
            mt: 3,
            minHeight: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PermissionNotFound />
        </Paper>
      </Container>
    );
  }
};

export default AlertAdminPage;
