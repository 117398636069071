// used to select an option if Trace Back form
export const LC_TRACE_TYPE = [
  {
    label: "Downstream",
    value: "D",
  },
  {
    label: "Upstream",
    value: "U",
  },
  {
    label: "From - To",
    value: "B",
  },
];

export const LC_LAYERS = [
  "p_fms",
  "p_splitter",
  "p_onu",
  "p_olt",
  "p_cable",
  "p_customer",
];
