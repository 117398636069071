import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";

import { setMapState } from "planning/data/planningGis.reducer";
import FilterForm from "./FilterForm";

const FilterPopup = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */
  const dispatch = useDispatch();
  const [minimized, setMinimized] = useState(false);

  const handlePopupMinimize = useCallback(() => {
    setMinimized((val) => !val);
  }, [setMinimized]);

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  return (
    <GisMapPopups dragId="FilterPopup">
      <Box minWidth="450px">
        {/* Table header */}
        <TableHeader
          title="Filters"
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
        {minimized ? null : <FilterForm closeFilterForm={handleCloseDetails} />}
      </Box>
    </GisMapPopups>
  );
};

export default FilterPopup;
