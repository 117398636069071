import Api from "utils/api.utils";
import {
  apiGetAlertDeviceAggData,
  apiGetCatvFavGraphConfig,
  apiGetDashboard,
  apiGetDashboardSurveyTicketSummery,
  apiGetDashboardSurveyTicketSummeryExport,
  apiPostCatvFavGraphConfig,
  apiPostCatvFavAlertList,
} from "utils/url.constants";

export const fetchDashboardData = async () => {
  const res = await Api.get(apiGetDashboard());
  return res.data;
};

export const fetchDashSurveySummery = async () => {
  const res = await Api.get(apiGetDashboardSurveyTicketSummery());
  return res.data;
};

export const fetchExportSurveyTicketSummery = async () => {
  const res = await Api.get(apiGetDashboardSurveyTicketSummeryExport(), null, {
    responseType: "blob",
  });
  return res.data;
};

export const fetchCatvFavGraphConfig = async ({ queryKey }) => {
  const res = await Api.get(apiGetCatvFavGraphConfig(queryKey[1]));
  return res.data;
};

export const postCatvFavGraphConfig = async ({ dashkey, data }) => {
  const res = await Api.post(apiPostCatvFavGraphConfig(dashkey), data);
  return res.data;
};

export const postCatvFavAlertList = async ({ alertStatus, data }) => {
  const res = await Api.post(apiPostCatvFavAlertList(alertStatus), data);
  return res.data;
};

export const fetchAlertDeviceAggData = async ({ queryKey }) => {
  const [_, min_count, duration_days] = queryKey;
  const res = await Api.get(apiGetAlertDeviceAggData(min_count, duration_days));
  return res.data;
};
