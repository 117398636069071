import Api from "utils/api.utils";
import { createAsyncThunk } from "@reduxjs/toolkit";

import size from "lodash/size";

import {
  apiGetRegionList,
  apiGetPlanningConfigs,
  apiGetPlanningConfigsDetails,
  apiGetPlanningLayerData,
  apiGetTicketLayerData,
} from "utils/url.constants";
import { getCurrentLayerFilters } from "./planningGis.selectors";

export const fetchRegionList = async () => {
  const res = await Api.get(apiGetRegionList("data"));
  return res.data;
};

export const fetchLayerList = async () => {
  const res = await Api.get(apiGetPlanningConfigs());
  return res.data;
};

export const fetchLayerListDetails = async () => {
  const res = await Api.get(apiGetPlanningConfigsDetails());
  return res.data;
};

// get layer gis data only for regions given
export const fetchLayerData = async ({ regionIdList, layerKey }, thunkAPI) => {
  let filter_data;
  // analysis form use this function as mutation function directly which dont have thunkAPI support
  // in that case filter_data is not required. filter_data used into planning tab only
  if (thunkAPI) {
    const { getState } = thunkAPI;
    // get and apply layer wise filters before sending fetch request to server
    const storeState = getState();
    const layerFilters = getCurrentLayerFilters(layerKey)(storeState);
    filter_data = !!size(layerFilters) ? layerFilters : undefined;
  }

  let res;
  if (layerKey === "region") {
    res = await Api.get(apiGetRegionList("detail"), {
      ids: regionIdList.join(","),
    });
  } else if (layerKey === "ticket") {
    res = await Api.post(apiGetTicketLayerData(), {
      regions: regionIdList,
      filter_data,
    });
  } else {
    res = await Api.post(apiGetPlanningLayerData(), {
      regions: regionIdList,
      layer_key: layerKey,
      filter_data,
    });
  }
  return res.data;
};

export const fetchLayerDataThunk = createAsyncThunk(
  "planningGis/fetchLayerData",
  fetchLayerData
);
