import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import get from "lodash/get";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import LanguageIcon from "@mui/icons-material/Language";

import { LayerKeyMappings } from "../../utils";
import { onLayerElementListItemClick } from "planning/data/planning.actions";

const ConnectedPortDetails = ({ connectedPortData }) => {
  const { element, layer_key, port } = connectedPortData;
  const dispatch = useDispatch();

  const handleShowOnMap = useCallback(
    (element, layerKey) => () => {
      dispatch(onLayerElementListItemClick(element, layerKey));
    },
    []
  );

  const Icon = LayerKeyMappings[layer_key]["getViewOptions"](element).icon;
  const networkId = get(element, "network_id", "");

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      py={0.5}
      sx={{
        maxWidth: "70%",
        margin: "18px auto",
      }}
    >
      <Paper
        sx={{
          width: "42px",
          height: "42px",
          lineHeight: "42px",
          textAlign: "center",
          marginLeft: "8px",
        }}
      >
        <img className="responsive-img" src={Icon} alt={layer_key} />
      </Paper>
      <Stack flex={1} flexDirection="row">
        <Box className="clickable" flex={1}>
          <Typography
            variant="subtitle1"
            lineHeight={1.1}
            sx={{
              wordBreak: "keep-all",
            }}
          >
            {`${element.name} ( ${port.name} )`}
          </Typography>
          <Typography variant="caption">#{networkId}</Typography>
        </Box>
        <Tooltip title="Show on map">
          <IconButton
            sx={{
              marginLeft: "8px",
              marginRight: "8px",
            }}
            aria-label="show-location"
            onClick={handleShowOnMap(element, layer_key)}
          >
            <LanguageIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ConnectedPortDetails;
