export const TicketTypeList = [
  { label: "Survey", value: "S" },
  { label: "Network", value: "P" },
  { label: "Customer", value: "C" },
];
export const NetworkTypeList = [
  { label: "As Build", value: "B" },
  { label: "As Planned", value: "P" },
];

export const TicketStatusList = [
  { label: "Active", value: "A" },
  { label: "Completed", value: "C" },
  { label: "In Active", value: "I" },
];

export const workOrderStatusTypes = {
  S: {
    value: "S",
    label: "Submited",
    color: "warning",
  },
  V: {
    value: "V",
    label: "Verified",
    color: "success",
  },
  R: {
    value: "R",
    label: "Rejected",
    color: "error",
  },
};

export const workOrderStatusFormTypes = {
  V: {
    value: "V",
    label: "Verified",
    color: "success",
  },
  R: {
    value: "R",
    label: "Rejected",
    color: "error",
  },
};

export const BroadbandProviders = [
  {
    label: "GTPL",
    value: "GTPL",
  },
  {
    label: "JIO",
    value: "JIO",
  },
  {
    label: "Airtel",
    value: "Airtel",
  },
];

export const TVProviders = [
  {
    label: "GTPL",
    value: "GTPL",
  },
  {
    label: "DEN",
    value: "DEN",
  },
  {
    label: "IN CABLE",
    value: "IN CABLE",
  },
];

export const SURVEY_TAG_LIST = [
  { label: "Residential", value: "residential" },
  { label: "Commercial", value: "commercial" },
  { label: "Government", value: "government" },
  { label: "Hospital", value: "hospital" },
  { label: "Educational", value: "educational" },
];

export const LOCALITY_OPTS = [
  { label: "High", value: "1" },
  { label: "Medium", value: "2" },
  { label: "Average", value: "3" },
  { label: "Poor", value: "4" },
];

export const ESCALATION_TAGS = [
  { label: "DHE NOC", value: "telco.servicedesk.escalation" },
  { label: "BRANCH/STATE Technical Head", value: "telco.techlead.escalation" },
  { label: "Circle Business Head", value: "telco.businesshead.escalation" },
  { label: "L1 /L2 + DHE NOC", value: "non-telco.servicedesk.escalation" },
  {
    label: "Branch/STATE Technical Head",
    value: "non-telco.techlead.escalation",
  },
  { label: "Circle Technical Head", value: "non-telco.techhead.escalation" },
  { label: "Circle Business Head", value: "non-telco.businesshead.escalation" },
];

export const EMAIL_PATTERN_REGEX =
  /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i;
export const PHONE_PATTERN_REGEX = /^[0-9-+()\[\] ]{6,30}$/;

/**
 * Common message over the app
 */

export const getRequiredFieldMessage = (field) => `${field} is required.`;
export const getValidFieldMessage = (field) => `Please enter valid ${field}.`;

export const DRAG_ICON_WIDTH = 36;
