import React from "react";
import { useDispatch, useSelector } from "react-redux";

import get from "lodash/get";

import Box from "@mui/material/Box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  onElementListConnectionEvent,
  openElementDetails,
} from "planning/data/planning.actions";
import { PLANNING_EVENT } from "planning/GisMap/utils";
import { removeSpecialLayerDataById } from "planning/data/planningGis.reducer";
import { IconButton } from "@mui/material";
import { PAGE_SELCTIONS } from "redux/reducers/appState.reducer";

/**
 * Parent:
 *    TableHeader
 */
const TableBackButton = ({ currentPage }) => {
  const dispatch = useDispatch();
  const mapState = useSelector(getPlanningMapState);
  const event = get(mapState, "event");
  const layerKey = get(mapState, "layerKey");
  const elementId = get(mapState, "data.elementId");

  const handleBack = () => {
    if (event === PLANNING_EVENT.addElementConnection) {
      dispatch(
        onElementListConnectionEvent({
          layerKey,
          elementId,
          elementGeometry: get(mapState, "data.elementGeometry"),
        })
      );
    }
    // remove history highlight elements from map
    else if (event === PLANNING_EVENT.showHistory) {
      dispatch(removeSpecialLayerDataById("history"));
      dispatch(openElementDetails({ layerKey, elementId }));
    } else {
      dispatch(openElementDetails({ layerKey, elementId }));
    }
  };

  const showBackButton =
    event === PLANNING_EVENT.showPossibleAddAssociatiation ||
    event === PLANNING_EVENT.showAssociatedElements ||
    event === PLANNING_EVENT.showAttachmentView ||
    event === PLANNING_EVENT.showHistory ||
    event === PLANNING_EVENT.showPortDetails ||
    event === PLANNING_EVENT.showElementConnections ||
    event === PLANNING_EVENT.editElementForm ||
    event === PLANNING_EVENT.showSplicingView ||
    event === PLANNING_EVENT.showElementPatching ||
    event === PLANNING_EVENT.addElementConnection ||
    event === PLANNING_EVENT.showGenericAttrsPopup ||
    event === PLANNING_EVENT.showInterfaceAttrsPopup ||
    event === PLANNING_EVENT.showTransportAttrsPopup ||
    event === PLANNING_EVENT.showGraphPopup;

  if (!showBackButton) return null;

  return (
    // <Box
    //   sx={{
    //     alignSelf: "stretch",
    //     display: "flex",
    //     alignItems: "center",
    //     width: "28px",
    //     cursor: "pointer",
    //     paddingLeft: 1,
    //     fontSize: "1.5rem",
    //   }}
    //   onClick={handleBack}
    // >
    // </Box>
    <IconButton
      aria-label="go back"
      color={
        currentPage === PAGE_SELCTIONS.DEVICES_PAGE ? "primary" : "inherit"
      }
      size="large"
      onClick={handleBack}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};

export default TableBackButton;
