import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";

import get from "lodash/get";

import Paper from "@mui/material/Paper";

import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import FavGraphWidget from "../widgets/FavGraphWidget";
import FavAlertListWidget from "../widgets/FavAlertListWidget";
import CenterLoader from "components/common/CenterLoader";

import { setDefaultDashboard } from "redux/reducers/auth.reducer";
import { fetchCatvFavGraphConfig } from "pages/dashboard.service";

const CatvFavGraphsDash = () => {
  const dispatch = useDispatch();

  const { isLoading, data: graphConfig } = useQuery(
    ["CatvFavGraphConfig", "CatvFavGraph"],
    fetchCatvFavGraphConfig
  );

  useEffect(() => {
    dispatch(setDefaultDashboard("CatvFavGraph"));
  }, []);

  return (
    <PanelGroup
      autoSaveId="catv-dash"
      id="catv-dash"
      direction="vertical"
      style={{ padding: "5px" }}
    >
      <Panel id="row-1" defaultSize={50}>
        <PanelGroup autoSaveId="gp-row-1" id="gp-row-1" direction="horizontal">
          <Panel id="row-1-wid-1" style={{ padding: "3px" }}>
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              {isLoading ? (
                <CenterLoader />
              ) : (
                <FavGraphWidget
                  key={get(graphConfig, "config.graph_1_configs.version", 0)}
                  widgetConfig={get(graphConfig, "config.graph_1_configs")}
                  widgetId="graph_1_configs"
                />
              )}
            </Paper>
          </Panel>

          <PanelResizeHandle />

          <Panel id="row-1-wid-2" style={{ padding: "3px" }}>
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              {isLoading ? (
                <CenterLoader />
              ) : (
                <FavGraphWidget
                  key={get(graphConfig, "config.graph_2_configs.version", 0)}
                  widgetConfig={get(graphConfig, "config.graph_2_configs")}
                  widgetId="graph_2_configs"
                />
              )}
            </Paper>
          </Panel>
        </PanelGroup>
      </Panel>

      <PanelResizeHandle />

      <Panel id="row-2" defaultSize={50} style={{ paddingTop: "3px" }}>
        <PanelGroup autoSaveId="gp-row-2" id="gp-row-2" direction="horizontal">
          <Panel id="row-2-wid-3" style={{ padding: "3px" }}>
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              {isLoading ? (
                <CenterLoader />
              ) : (
                <FavGraphWidget
                  key={get(graphConfig, "config.graph_3_configs.version", 0)}
                  widgetConfig={get(graphConfig, "config.graph_3_configs")}
                  widgetId="graph_3_configs"
                />
              )}
            </Paper>
          </Panel>

          <PanelResizeHandle />

          <Panel id="row-2-wid-4" style={{ padding: "3px" }}>
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              {isLoading ? (
                <CenterLoader />
              ) : (
                <FavAlertListWidget
                  key={get(graphConfig, "config.graph_4_configs.version", 0)}
                  widgetConfig={get(graphConfig, "config.graph_4_configs")}
                  widgetId="graph_4_configs"
                />
              )}
            </Paper>
          </Panel>
        </PanelGroup>
      </Panel>
    </PanelGroup>
  );
};

export default CatvFavGraphsDash;
