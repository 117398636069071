import React, { useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";

import TicketListDummyLoader from "ticket/components/TicketListDummyLoader";
import AgAlertList from "gis_alert/components/AgAlertList";

import { postCatvFavAlertList } from "pages/dashboard.service";

const FavAlertList = ({ widgetConfig }) => {
  /**
   * Parent:
   *    FavAlertListWidget
   */
  const gridRef = useRef();
  const alertStatus = "P";

  const {
    mutate: postCatvFavAlertListMutation,
    isLoading,
    data: alertListData,
  } = useMutation(postCatvFavAlertList);

  useEffect(() => {
    postCatvFavAlertListMutation({
      alertStatus,
      data: {
        region_id: widgetConfig.region_id,
        host_id: widgetConfig.host_id,
      },
    });
  }, []);

  return isLoading ? (
    <TicketListDummyLoader />
  ) : (
    <AgAlertList
      ref={gridRef}
      viewAs="FavAlertListWidget"
      alertStatus={alertStatus}
      alertList={alertListData}
      pagination={false}
      height="calc(100% - 50px)"
    />
  );
};

export default FavAlertList;
