import React, { Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";

import groupBy from "lodash/groupBy";
import get from "lodash/get";
import size from "lodash/size";

import {
  Box,
  Divider,
  Stack,
  Typography,
  Button,
  Collapse,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "components/common/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";

import DummyListLoader from "../DummyListLoader";
import LayerTab from "./LayerTab";

import {
  fetchLayerDataThunk,
  fetchLayerList,
} from "planning/data/actionBar.services";
import {
  getSelectedGroups,
  getSelectedLayerKeys,
  getSelectedRegionIds,
} from "planning/data/planningState.selectors";
import {
  handleGroupSelect,
  handleLayerSelect,
  removeGroupSelect,
  removeLayerSelect,
  setActiveTab,
} from "planning/data/planningState.reducer";
import { getAllLayersNetworkStatus } from "planning/data/planningGis.selectors";
import { addNotification } from "redux/reducers/notification.reducer";
// import { onFilterSubmit } from "planning/data/filters.actions";

const LAYER_GROUPS = [
  "Core Area",
  "Network Locations",
  "Inside Plant Elements",
  "Outside Plant Elements",
  "Survey",
  "Task Layers",
  "CaTv",
];

const LayersTabContent = () => {
  /**
   * Render list of elements user can view on map
   * User can click and get data of layers
   * handle layer config data loading
   *
   * Parent
   *  ActionBar
   */
  const dispatch = useDispatch();

  const regionIdList = useSelector(getSelectedRegionIds);
  const selectedLayerKeys = useSelector(getSelectedLayerKeys);
  const selectedGroups = useSelector(getSelectedGroups);
  const layersNetworkState = useSelector(getAllLayersNetworkStatus);

  const [expandedGroups, setExpandedGroups] = useState(new Set(LAYER_GROUPS));

  const { isLoading, data: layerConfigs = [] } = useQuery(
    "planningLayerConfigs",
    fetchLayerList,
    {
      staleTime: Infinity,
      select: (res) => {
        return groupBy(res, "group");
      },
    }
  );

  const handleGroupExpandClick = useCallback(
    (groupName) => () => {
      setExpandedGroups((grpSet) => {
        let newSet = new Set(grpSet);
        if (newSet.has(groupName)) {
          newSet.delete(groupName);
        } else {
          newSet.add(groupName);
        }
        return newSet;
      });
    },
    []
  );

  const handleGroupClick = useCallback(
    (groupName) => () => {
      if (!size(regionIdList)) {
        // show error notification to select regions first
        dispatch(
          addNotification({
            type: "error",
            title: "Select Region first",
            text: "Please select region to narrow down your search of elements.",
          })
        );
        // change tab to regions
        dispatch(setActiveTab(0));
        return;
      }
      // check if group is active
      if (selectedGroups.includes(groupName)) {
        // if active remove group from selection
        dispatch(removeGroupSelect(groupName));
        // remove all layers inside that group from selection
        const grpLayerList = layerConfigs[groupName];
        for (let lInd = 0; lInd < grpLayerList.length; lInd++) {
          const currLayerConf = grpLayerList[lInd];
          dispatch(removeLayerSelect(currLayerConf.layer_key));
        }
      } else {
        // add group to selection
        dispatch(handleGroupSelect(groupName));
        // add all layers inside that group to selection
        const grpLayerList = layerConfigs[groupName];
        for (let lInd = 0; lInd < grpLayerList.length; lInd++) {
          const currLayerConf = grpLayerList[lInd];
          dispatch(handleLayerSelect(currLayerConf.layer_key));
          // check if layer data is already fetched or not
          const isCurrLayerDataFetched = get(
            layersNetworkState,
            [currLayerConf.layer_key, "isFetched"],
            false
          );
          if (!isCurrLayerDataFetched) {
            dispatch(
              fetchLayerDataThunk({
                regionIdList,
                layerKey: currLayerConf.layer_key,
              })
            );
          }
        }
      }
    },
    [regionIdList, selectedGroups, layerConfigs, layersNetworkState]
  );

  const handleFullDataRefresh = useCallback(() => {
    for (let l_ind = 0; l_ind < selectedLayerKeys.length; l_ind++) {
      const currLayerKey = selectedLayerKeys[l_ind];
      // remove all layer wise filters on refresh data
      // dispatch(onFilterSubmit(currLayerKey, []));
      dispatch(fetchLayerDataThunk({ regionIdList, layerKey: currLayerKey }));
    }
  }, [regionIdList, selectedLayerKeys]);

  if (isLoading) return <DummyListLoader />;

  return (
    <Stack>
      <Stack p={2} direction="row" justifyContent="space-between">
        <Typography variant="h6" color="primary">
          Select Layers
        </Typography>
        <Button
          variant="outlined"
          color="success"
          size="small"
          startIcon={<SyncIcon />}
          onClick={handleFullDataRefresh}
        >
          Refresh
        </Button>
      </Stack>
      <Divider />
      {LAYER_GROUPS.map((group) => {
        const layerTabsConfigData = layerConfigs[group];
        // don't render this grp if user don't have permissions for any of its layers
        if (!layerTabsConfigData) return null;

        const isExpanded = expandedGroups.has(group);
        const isActive = selectedGroups.includes(group);
        return (
          <Fragment key={group}>
            <Stack direction="row" width="100%" spacing={2}>
              <Box onClick={handleGroupExpandClick(group)}>
                <ExpandMore
                  expand={isExpanded}
                  aria-expanded={isExpanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Box>
              <Stack
                direction="row"
                flex={1}
                sx={{
                  color: "secondary.main",
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                onClick={handleGroupClick(group)}
              >
                <span>{group}</span>
                {isActive ? <VisibilityIcon /> : null}
              </Stack>
            </Stack>

            <Divider flexItem />

            <Collapse in={isExpanded}>
              {layerTabsConfigData.map((layer) => {
                return (
                  <LayerTab
                    key={layer.layer_key}
                    layerConfig={layer}
                    regionIdList={regionIdList}
                  />
                );
              })}
            </Collapse>
          </Fragment>
        );
      })}
    </Stack>
  );
};

export default LayersTabContent;
