import Api from "utils/api.utils";
import { apiGetHealthCheck, apiGetVersion } from "utils/url.constants";

export const fetchHealthCheck = async () => {
  const res = await Api.get(apiGetHealthCheck());
  return res.data;
};

export const fetchVersionData = async () => {
  const res = await Api.get(apiGetVersion());
  return res.data;
};
