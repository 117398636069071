import React from "react";
import { useSelector } from "react-redux";

import { Container, Paper, Box } from "@mui/material";

import PermissionNotFound from "components/common/PermissionNotFound";
import AnalysisContent from "./components/AnalysisContent";
import AnalysisForm from "./components/AnalysisForm";
import {
  checkUserPermission,
  getIsAdminUser,
  getIsSuperAdminUser,
} from "redux/selectors/auth.selectors";

const AnalysisPage = () => {
  const canUserView = useSelector(checkUserPermission("analysis_view"));
  const isAdminUser = useSelector(getIsAdminUser);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);

  const canView = canUserView || isAdminUser || isSuperAdminUser;

  if (canView) {
    return (
      <Box>
        <AnalysisForm />
        <AnalysisContent />
      </Box>
    );
  } else {
    return (
      <Container>
        <Paper
          sx={{
            mt: 3,
            minHeight: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PermissionNotFound />
        </Paper>
      </Container>
    );
  }
};

export default AnalysisPage;
