import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";

import some from "lodash/some";
import size from "lodash/size";
import noop from "lodash/noop";

import { Divider, Stack, Typography, Box, IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

import LanguageIcon from "@mui/icons-material/Language";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import GisMapPopupLoader from "planning/GisMap/components/GisMapPopups/GisMapPopupLoader";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";
import ConfirmDialog from "components/common/ConfirmDialog";

import { getPlanningMapStateData } from "planning/data/planningGis.selectors";
import { setMapState } from "planning/data/planningGis.reducer";
import {
  addElementConnection,
  fetchElementConnections,
  fetchPossibleConnections,
} from "planning/data/layer.services";
import { getSelectedRegionIds } from "planning/data/planningState.selectors";
import { fetchLayerDataThunk } from "planning/data/actionBar.services";
import { addNotification } from "redux/reducers/notification.reducer";
import { LayerKeyMappings } from "planning/GisMap/utils";
import {
  onElementListItemClick,
  openElementDetails,
} from "planning/data/planning.actions";

const AddElementConnection = () => {
  const dispatch = useDispatch();
  const selectedRegionIds = useSelector(getSelectedRegionIds);
  const { elementId, layerKey, circlAreaCoords } = useSelector(
    getPlanningMapStateData
  );

  const [selectedConnectionData, setSelectedConnectionData] = useState({});

  const { data: elementList, isLoading: possibleConnectionsLoading } = useQuery(
    ["possibleConnections", circlAreaCoords],
    fetchPossibleConnections
  );

  const {
    data: existingConnections,
    isLoading: connectionsLoading,
    refetch,
  } = useQuery(
    ["elementConnections", layerKey, elementId],
    fetchElementConnections
  );

  const { mutate: updateConnectionMutation } = useMutation(
    addElementConnection,
    {
      onSuccess: (res) => {
        // refetch cable layer gis data
        dispatch(
          fetchLayerDataThunk({
            regionIdList: selectedRegionIds,
            layerKey: "p_cable",
          })
        );
        // success notification
        dispatch(
          addNotification({
            type: "success",
            title: "Connection updated",
            text: "Element to table connection was updated successfully",
          })
        );
        refetch();
        // close conformation popup
        handleRemovePopup();
      },
      onError: (err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Connection update failed",
          })
        );
      },
    }
  );

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handleConnect = useCallback(
    (cableId, cable_end) => {
      // required data = cable id, cable end
      // element id, element layer key
      const data = {
        connection: {
          element_id: elementId,
          element_layer_key: layerKey,
          cable_end,
        },
      };
      updateConnectionMutation({ data, cableId });
    },
    [elementId, layerKey]
  );

  const handleRemove = useCallback(
    (cableId, cable_end) => {
      setSelectedConnectionData({
        cableId,
        cableEnd: cable_end,
        elementId,
        element_layer_key: layerKey,
      });
    },
    [elementId, layerKey]
  );

  const handleRemoveConfirm = useCallback(() => {
    // required data = cable id, cable end
    // element id, element layer key
    const data = {
      connection: {
        element_id: selectedConnectionData.elementId,
        element_layer_key: selectedConnectionData.element_layer_key,
        cable_end: selectedConnectionData.cableEnd,
        is_delete: true,
      },
    };
    updateConnectionMutation({ data, cableId: selectedConnectionData.cableId });
  }, [selectedConnectionData]);

  const handleRemovePopup = useCallback(() => {
    setSelectedConnectionData({});
  }, []);

  const handleShowDetails = useCallback(
    (element) => () => {
      dispatch(
        openElementDetails({
          layerKey: element.layerKey,
          elementId: element.id,
        })
      );
    },
    []
  );

  const handleShowOnMap = useCallback(
    (element) => () => {
      dispatch(onElementListItemClick(element));
    },
    []
  );

  if (connectionsLoading || possibleConnectionsLoading)
    return <GisMapPopupLoader />;

  // loop over possible connections, This will be p_cable elements only for element connections
  const ConnectionList = elementList.map((element) => {
    const { id, name, cable_end, network_id, layerKey } = element;
    // check if same layer_key, id data in existingConnections
    const isConnected = some(existingConnections, (d) => {
      // For small cables A and B end can be in existing conn. list, so check for both cable id and cable_end
      return d.element.id === id && d.element.cable_end === cable_end;
    });
    // if true than disable connect btn
    const Icon = LayerKeyMappings[layerKey]["getViewOptions"](element).icon;
    return (
      <Stack
        key={`${network_id}-${cable_end}`}
        direction="row"
        spacing={1}
        alignItems="center"
        py={0.5}
        className="change-bg-on-hover"
      >
        <Paper
          sx={{
            width: "42px",
            height: "42px",
            lineHeight: "42px",
            textAlign: "center",
            marginLeft: "8px",
          }}
        >
          <img className="responsive-img" src={Icon} alt={layerKey} />
        </Paper>
        <Stack flex={1} flexDirection="row">
          <Box
            className="clickable"
            flex={1}
            onClick={handleShowDetails(element)}
          >
            <Typography variant="subtitle1" lineHeight={1.1}>
              {name}{" "}
              <Typography
                variant="caption"
                lineHeight={1}
              >{`(${cable_end} End)`}</Typography>
            </Typography>
            <Typography variant="caption">#{network_id}</Typography>
          </Box>
          <Tooltip title="Show on map">
            <IconButton
              sx={{
                marginLeft: "8px",
              }}
              aria-label="show-location"
              onClick={handleShowOnMap(element)}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={isConnected ? "Remove" : "Add Connection"}>
            <IconButton
              sx={{
                marginLeft: "8px",
                marginRight: "8px",
              }}
              aria-label={isConnected ? "Remove" : "Add Connection"}
              onClick={() => {
                if (isConnected) {
                  handleRemove(id, cable_end);
                } else {
                  handleConnect(id, cable_end);
                }
              }}
            >
              {isConnected ? (
                <DeleteOutlineOutlinedIcon />
              ) : (
                <AddBoxOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    );
  });

  return (
    <GisMapPopups dragId="AddElementConnection">
      <Box minWidth="350px" maxWidth="550px">
        <TableHeader
          title="Add Connection"
          minimized={false}
          showMinimizeBtn={false}
          handlePopupMinimize={noop}
          handleCloseDetails={handleCloseDetails}
        />

        {size(elementList) ? (
          <Stack
            spacing={1}
            divider={<Divider />}
            py={1}
            maxHeight="72vh"
            overflow="auto"
          >
            {ConnectionList}
          </Stack>
        ) : (
          <Box p={2}>
            <Typography variant="h6" color="text.secondary">
              No near by elements for connect
            </Typography>
          </Box>
        )}
        <ConfirmDialog
          show={!!size(selectedConnectionData)}
          onClose={handleRemovePopup}
          onConfirm={handleRemoveConfirm}
          title="Remove Cable Connection"
          text="Are you sure you want to remove connection ? This will clear all existing splicing between this elements !"
          confirmText="Remove"
        />
      </Box>
    </GisMapPopups>
  );
};

export default AddElementConnection;
