import { fetchLayerDataThunk } from "./actionBar.services";
import { setLayerFilter } from "./planningGis.reducer";
import { getSelectedRegionIds } from "./planningState.selectors";

export const onFilterSubmit = (layerKey, filters) => (dispatch, getState) => {
  const storeState = getState();
  const regionIdList = getSelectedRegionIds(storeState);

  dispatch(
    setLayerFilter({
      layerKey,
      filters: filters.length ? filters : undefined,
    })
  );
  dispatch(
    fetchLayerDataThunk({
      regionIdList,
      layerKey,
    })
  );
};
