import React from "react";
import { useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";

import get from "lodash/get";
import { format } from "date-fns";

import { getContentHeight } from "redux/selectors/appState.selectors";
import { LayerKeyMappings } from "planning/GisMap/utils";
import {
  ELEMENT_TABLE_TYPES,
  FEATURE_TYPES,
} from "planning/GisMap/layers/common/configuration";

import { getIsSuperAdminUser } from "redux/selectors/auth.selectors";

/**
 * Parent:
 *    ElementDetailsTable
 */
const TableContent = ({ layerKey, elemData }) => {
  const windowHeight = useSelector(getContentHeight);
  const isSuperUser = useSelector(getIsSuperAdminUser);
  // contentHeight = windowHeight - (10% margin * 2 top & bot) - (title + action btns)
  const contentHeight = windowHeight - windowHeight * 0.1 - (60 + 70);

  const getElementTableFieldsFn = get(LayerKeyMappings, [
    layerKey,
    "getElementTableFields",
  ]);

  const rowDefs = getElementTableFieldsFn ? getElementTableFieldsFn() : [];
  const featureType = get(LayerKeyMappings, [layerKey, "featureType"]);

  const handleLocationClick = (latLng) => () => {
    if (!latLng) return;
    window.open("https://maps.google.com?q=" + latLng[1] + "," + latLng[0]);
  };

  const elementId = get(elemData, "id");

  return (
    <Stack
      sx={{
        maxHeight: `${contentHeight}px`,
        overflowY: "auto",
      }}
      divider={<Divider />}
    >
      {/* show id if super user */}
      {isSuperUser && elementId ? (
        <Stack direction="row" p={2}>
          <Typography color="primary.main" textAlign="left" width={"50%"}>
            ID
          </Typography>
          <Typography textAlign="center" width={"50%"}>
            {elementId}
          </Typography>
        </Stack>
      ) : null}
      {rowDefs.map((row) => {
        const { label, field, type } = row;
        let ValueCell;

        switch (type) {
          case ELEMENT_TABLE_TYPES.status:
            const elemStatus = get(elemData, field);
            if (!elemStatus) return null;
            const color =
              elemStatus === "RFS"
                ? "success"
                : elemStatus === "L1" || elemStatus === "L2"
                ? "warning"
                : "error"; // IA: In active

            ValueCell = (
              <Box textAlign="center" width={"50%"}>
                <Chip label={get(elemData, `${field}_display`)} color={color} />
              </Box>
            );
            break;

          case ELEMENT_TABLE_TYPES.boolean:
            const elemBoolData = get(elemData, field);

            ValueCell = (
              <Box textAlign="center" width={"50%"}>
                <IconButton color={elemBoolData ? "success" : "error"}>
                  {elemBoolData ? <CheckIcon /> : <ClearIcon />}
                </IconButton>
              </Box>
            );
            break;

          case ELEMENT_TABLE_TYPES.date:
            const dateVal = get(elemData, field);
            let formattedDate = "--";
            if (dateVal) {
              formattedDate = format(new Date(dateVal), "dd/MM/YYY");
            }

            ValueCell = (
              <Typography
                sx={{ whiteSpace: "pre" }}
                textAlign="center"
                width={"50%"}
              >
                {formattedDate}
              </Typography>
            );
            break;

          case ELEMENT_TABLE_TYPES.point_geometry: {
            // for point, show geometry, for others show center.
            let value =
              featureType === FEATURE_TYPES.POINT
                ? get(elemData, field)
                : get(elemData, "center");

            ValueCell = (
              <Box
                onClick={handleLocationClick(value)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  width: "50%",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <ShareLocationIcon
                  color="primary"
                  sx={{ display: "block", marginRight: "0.5em" }}
                />
                <Box>
                  <Typography>{get(value, "0")}</Typography>
                  <Typography>{get(value, "1")}</Typography>
                </Box>
              </Box>
            );
            break;
          }
          case ELEMENT_TABLE_TYPES.multi_array:
            const valArray = get(elemData, field);

            ValueCell = (
              <Typography
                sx={{ whiteSpace: "pre" }}
                textAlign="center"
                width={"50%"}
              >
                {!!valArray ? valArray.join(" ,") : ""}
              </Typography>
            );
            break;

          default:
            ValueCell = (
              <Typography textAlign="center" width={"50%"}>
                {get(elemData, field, "--") || "--"}
              </Typography>
            );
            break;
        }

        return (
          <Stack direction="row" key={field} p={2}>
            <Typography color="primary.main" textAlign="left" width={"50%"}>
              {label}
            </Typography>
            {ValueCell}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default TableContent;
