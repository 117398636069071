import React from "react";
import { Controller } from "react-hook-form";

import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";

import { UploadButton } from "../FilePickerDialog";

import get from "lodash/get";

export const FormFileField = ({
  label,
  name,
  control,
  rules,
  error,
  disabled,
  required,
  helperText,
  ...rest
}) => {
  return (
    <Controller
      render={({ field }) => {
        const currFileName = get(field.value, "name", "");
        return (
          <FormControl
            error={error}
            disabled={!!disabled}
            required={!!required}
          >
            <FormLabel
              sx={{
                paddingLeft: 1,
                paddingBottom: 0.5,
              }}
              component="legend"
            >
              {label}
            </FormLabel>
            <UploadButton
              text="Select File"
              variant="contained"
              // accept=".xlsx, .xls, .csv"
              onChange={(e) => {
                const file = e.target.files[0];
                field.onChange(file);
              }}
            />
            {currFileName ? (
              <Box display="flex" alignItems="center">
                <Typography variant="body2" pl={1} pt={0.5}>
                  {currFileName}
                </Typography>
                <CloseIcon
                  className="clickable"
                  onClick={() => field.onChange(null)}
                />
              </Box>
            ) : null}
            {error ? <FormHelperText>{helperText}</FormHelperText> : null}
          </FormControl>
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
};
