import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TraceElementList from "./TraceElementList";
import TraceTable from "./TraceTable";

import ListIcon from "@mui/icons-material/List";
import LanguageIcon from "@mui/icons-material/Language";

import TraceTableDownload from "./TraceTableDownload";
import TraceKmlDownload from "./TraceKmlDownload";

import {
  getTraceShowElement,
  getTraceShowOnMap,
} from "planning/data/traceback/traceback.selectors";
import { setShowElementList } from "planning/data/traceback/traceback.reducer";
import { onTraceShowOnMapClick } from "planning/data/traceback/traceback.actions";
import { checkUserPermission } from "redux/selectors/auth.selectors";

/**
 * Parent:
 *    TracebackForm
 *
 * render download and highlight btns, show table if data fetched
 * handle empty
 */
const TracebackDetails = ({
  traceBackFetched,
  traceBackTableEntries,
  traceBackTree,
  traceBackError,
}) => {
  /**
   * Parent:
   *    TracebackForm
   */
  const dispatch = useDispatch();

  const canDownloadTraceback = useSelector(
    checkUserPermission("trace_back_download")
  );
  const showElementList = useSelector(getTraceShowElement);
  const showOnMap = useSelector(getTraceShowOnMap);

  const handleToggle = useCallback(() => {
    dispatch(setShowElementList(!showElementList));
  }, [showElementList]);

  const handleShowMap = useCallback(() => {
    dispatch(onTraceShowOnMapClick());
  }, [showOnMap]);

  if (traceBackFetched) {
    if (traceBackTableEntries.length) {
      return (
        <Box>
          <Stack direction="row" justifyContent="space-between" pb={2}>
            <Box>
              <Button
                color={showOnMap ? "success" : "secondary"}
                startIcon={<ListIcon />}
                onClick={handleToggle}
                sx={{ ml: 1 }}
              >
                {showElementList ? "Tables" : "Elements"}
              </Button>
            </Box>
            <Box>
              <Button
                color={showOnMap ? "success" : "secondary"}
                startIcon={<LanguageIcon />}
                onClick={handleShowMap}
                sx={{ ml: 1 }}
              >
                {showOnMap ? "Hide From Map" : "Show On Map"}
              </Button>
              {canDownloadTraceback ? (
                <>
                  <TraceTableDownload
                    traceBackTableEntries={traceBackTableEntries}
                  />
                  <TraceKmlDownload traceBackTree={traceBackTree} />
                </>
              ) : null}
            </Box>
          </Stack>
          <Box>
            {showElementList ? (
              <TraceElementList traceBackTree={traceBackTree} />
            ) : (
              <TraceTable traceBackTableEntries={traceBackTableEntries} />
            )}
          </Box>
        </Box>
      );
    } else {
      <Box pb={1}>
        <Typography variant="h6" color="text.secondary" textAlign="center">
          No Details
        </Typography>
      </Box>;
    }
  } else if (traceBackError) {
    return (
      <Box pb={1}>
        <Typography variant="h6" color="text.secondary" textAlign="center">
          {traceBackError}
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
};

export default TracebackDetails;
