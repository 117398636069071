import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";

import CustomSelect from "components/common/FormFields/CustomSelect";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { getSelectedConfigurations } from "planning/data/planningState.selectors";
import { fetchTemplateTagList } from "planning/data/catv/catv.services";

const TemplateSelect = ({
  name,
  control,
  rules,
  color,
  error,
  helperText,
  disabled,
  required,
}) => {
  const { layerKey } = useSelector(getPlanningMapState);
  const selectedConfigurations = useSelector(getSelectedConfigurations);
  const selectedConfigValue = get(selectedConfigurations, layerKey, null);
  const templateTag = get(selectedConfigValue, "template_optional_tag");

  useEffect(() => {
    fetchTags(templateTag);
  }, [templateTag]);

  const {
    mutate: fetchTags,
    isLoading,
    data: templateList,
  } = useMutation(fetchTemplateTagList);

  return (
    <Controller
      render={({ field }) => {
        return (
          <CustomSelect
            label="Select Template"
            name={name}
            value={filter(templateList, (d) =>
              includes(field.value, d.template_id)
            )}
            options={templateList}
            getOptionLabel={(o) => o["name"]}
            getOptionValue={(o) => o["template_id"]}
            isMulti={true}
            isDisabled={!!disabled}
            isLoading={isLoading}
            onChange={(newValue) =>
              field.onChange(map(newValue, "template_id"))
            }
            menuPortalTarget={document.body}
          />
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

export default TemplateSelect;
