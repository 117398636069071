import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OneSignal from "react-onesignal";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { getHomePath } from "utils/url.constants";
import { getLoggedUserDetails } from "redux/selectors/auth.selectors";
import getOrCreateOneSignalClient from "utils/onesignal.utils";

const SupportNotification = () => {
  /**
   * Parent:
   *    ProfilePage
   */
  const navigate = useNavigate();
  const { username } = useSelector(getLoggedUserDetails);

  const [hasPermission, setHasPermission] = useState(false);
  const [hasSupported, setHasSupported] = useState(false);
  const [pushSubscriptionId, setPushSubscriptionId] = useState("");

  useEffect(() => {
    const OneSignalClient = getOrCreateOneSignalClient();
    const permission = OneSignalClient.hasPermission();
    setHasPermission(permission);

    OneSignalClient.canRequestPermission().then((value) =>
      setHasSupported(value)
    );
    const isSupported = OneSignalClient.isPushSupported();
    setHasSupported(isSupported);

    const pushSubscriptionId = OneSignalClient.getPushSubscriptionId();
    setPushSubscriptionId(pushSubscriptionId);
  }, [getOrCreateOneSignalClient]);

  const handlePermissionEnable = useCallback(() => {
    if (!hasPermission) {
      OneSignal.Slidedown.promptPush();
      // call request
      OneSignal.Notifications.requestPermission().then(() => {
        // get pushSubscriptionId
        const pushSubscriptionId = OneSignal.User.PushSubscription.id;
        console.log(
          "file: SupportNotification.js:44 ~ pushSubscriptionId:",
          pushSubscriptionId
        );

        if (pushSubscriptionId) {
          const OneSignalClient = getOrCreateOneSignalClient();
          OneSignalClient.loginOneSignal(username);
        }
        // need to change page, to refresh OneSignal
        navigate(getHomePath());
      });
    }
  }, [hasPermission, OneSignal, username]);

  return (
    <Box>
      <Stack p={2} direction="row" spacing={2} width="100%">
        <Typography
          color="primary.dark"
          flex={1}
          className="dtl-title"
          variant="h5"
        >
          Settings
        </Typography>
      </Stack>
      <Divider flexItem />
      <Stack p={2} px={4} direction="row" spacing={2} width="100%">
        <Typography
          color="primary.dark"
          flex={1}
          className="dtl-title"
          variant="button"
        >
          Notification Supported
        </Typography>
        {hasSupported ? (
          <DoneIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </Stack>
      <Stack p={2} px={4} direction="row" spacing={2} width="100%">
        <Typography
          color="primary.dark"
          flex={1}
          className="dtl-title"
          variant="button"
        >
          Notification Subscribed
        </Typography>
        {pushSubscriptionId ? (
          <DoneIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </Stack>
      <FormGroup sx={{ padding: 2 }}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              onChange={handlePermissionEnable}
              checked={hasPermission}
            />
          }
          label="Notification Enabled"
          labelPlacement="start"
          sx={{ justifyContent: "flex-end" }}
        />
      </FormGroup>
    </Box>
  );
};

export default SupportNotification;
