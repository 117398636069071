import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CachedIcon from "@mui/icons-material/Cached";
import CircularProgress from "@mui/material/CircularProgress";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import { GisElementTableContentLoader } from "planning/GisMap/components/GisMapPopups/GisMapPopupLoader";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";
import AttrDetailsTable from "planning/GisMap/components/Catv/AttrDetailsTable";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  setMapState,
  setPopupMinimize,
} from "planning/data/planningGis.reducer";
import { fetchElementGenericFieldsLastData } from "planning/data/catv/catv.services";
import useOnDemand from "planning/GisMap/hooks/useOnDemand";

const GenericAttrsPopup = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const {
    data: mapStateData,
    isPopupMinimize,
    layerKey,
  } = useSelector(getPlanningMapState);
  const { elementId } = mapStateData;

  const { handleOnDemandClick, isOnDemandLoading } = useOnDemand({
    layerKey,
    elementId,
    refreshType: "generic",
    queryName: "elementGenericDataList",
  });

  const {
    isLoading,
    data: elementGenericDataList,
    isError,
    isFetched,
  } = useQuery(
    ["elementGenericDataList", layerKey, elementId],
    fetchElementGenericFieldsLastData,
    { refetchOnWindowFocus: false }
  );

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handlePopupMinimize = useCallback(() => {
    dispatch(setPopupMinimize(!isPopupMinimize));
  }, [isPopupMinimize]);

  let Content;
  if (!isPopupMinimize) {
    if (isLoading) {
      Content = <GisElementTableContentLoader />;
    } else if (isError) {
      Content = (
        <Box p={2}>
          <Typography variant="h5">
            Error occured while fetching data
          </Typography>
        </Box>
      );
    } else if (isFetched) {
      Content = (
        <Box>
          <Stack
            sx={{ boxShadow: "0px 5px 7px -3px rgba(122,122,122,0.51)" }}
            p={2}
            direction="row-reverse"
            spacing={2}
          >
            <Tooltip title={"Refresh"} key={"ondemand"}>
              <IconButton
                aria-label={"ondemand"}
                color={"secondary"}
                sx={{
                  border: "1px solid",
                  borderRadius: 1,
                  minWidth: "42px",
                  minHeight: "42px",
                }}
                onClick={handleOnDemandClick}
              >
                {isOnDemandLoading ? (
                  <CircularProgress color="secondary" size={12} />
                ) : (
                  <CachedIcon />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
          <AttrDetailsTable dataList={elementGenericDataList} />
        </Box>
      );
    }
  }

  return (
    <GisMapPopups dragId="GenericAttrsPopup">
      <Box minWidth="350px" maxWidth="650px">
        <TableHeader
          title="Generic Attribute"
          minimized={isPopupMinimize}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
        {Content}
      </Box>
    </GisMapPopups>
  );
};

export default GenericAttrsPopup;
