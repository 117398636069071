import { createAsyncThunk } from "@reduxjs/toolkit";

import Api from "utils/api.utils";
import { apiGenerateTraceBack, apiExportTraceBack } from "utils/url.constants";
import { fetchLayerData } from "../actionBar.services";

export const generateTraceBackThunk = createAsyncThunk(
  "traceback/generateTraceBack",
  async (data, thunkAPI) => {
    try {
      const response = await Api.post(apiGenerateTraceBack(), data);
      return response.data;
    } catch (error) {
      // You can access the error code like this:
      if (error.response) {
        return thunkAPI.rejectWithValue({
          status: error.response.status,
          data: error.response.data,
        });
      } else {
        throw error;
      }
    }
  }
);

export const fetchTracebackLayerDataThunk = createAsyncThunk(
  "traceback/fetchLayerData",
  fetchLayerData
);

export const exportTraceBack = async (data) => {
  const res = await Api.post(apiExportTraceBack(), data, null, {
    responseType: "blob",
  });
  return res.data;
};
