import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import size from "lodash/size";
import capitalize from "lodash/capitalize";

import { subMinutes, subHours, subDays, subMonths, subYears } from "date-fns";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";
import GraphsContent from "./GraphsContentClass";
import CustomSelect from "components/common/FormFields/CustomSelect";
import { CustomDateTimePicker } from "components/common/FormFields";
import DummyListLoader from "planning/ActionBar/components/DummyListLoader";

import {
  fetchElementPortDetails,
  fetchElementTransportDetails,
} from "planning/data/port.services";
import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  fetchAttrConfig,
  fetchAttrTimeseriesThunk,
} from "planning/data/catv/catv.services";
import { addNotification } from "redux/reducers/notification.reducer";
import { openElementDetails } from "planning/data/planning.actions";
import {
  resetCATVData,
  setGraphOptions,
} from "planning/data/catv/catv.reducer";
import { LAYER_KEY as IQLayerKey } from "planning/GisMap/layers/p_ipqam";

const GRAPH_QUICK_ACTIONS = [
  {
    id: 1,
    label: "Last 5 Minutes",
    timeDelta: 5,
    getTime: () => {
      const now = new Date();
      return [subMinutes(now, 5), now];
    },
  },
  {
    id: 2,
    label: "Last 15 Minutes",
    timeDelta: 15,
    getTime: () => {
      const now = new Date();
      return [subMinutes(now, 15), now];
    },
  },
  {
    id: 3,
    label: "Last 30 Minutes",
    timeDelta: 30,
    getTime: () => {
      const now = new Date();
      return [subMinutes(now, 30), now];
    },
  },
  {
    id: 4,
    label: "Last 1 Hour",
    timeDelta: 60,
    getTime: () => {
      const now = new Date();
      return [subHours(now, 1), now];
    },
  },
  {
    id: 5,
    label: "Last 3 Hour",
    timeDelta: 180,
    getTime: () => {
      const now = new Date();
      return [subHours(now, 3), now];
    },
  },
  {
    id: 6,
    label: "Last 6 Hour",
    timeDelta: 360,
    getTime: () => {
      const now = new Date();
      return [subHours(now, 6), now];
    },
  },
  {
    id: 7,
    label: "Last 12 Hour",
    timeDelta: 720,
    getTime: () => {
      const now = new Date();
      return [subHours(now, 12), now];
    },
  },
  {
    id: 8,
    label: "Last 1 Days",
    timeDelta: 1440,
    getTime: () => {
      const now = new Date();
      return [subDays(now, 1), now];
    },
  },
  {
    id: 9,
    label: "Last 2 Days",
    timeDelta: 2880,

    getTime: () => {
      const now = new Date();
      return [subDays(now, 2), now];
    },
  },
  {
    id: 10,
    label: "Last 7 Days",
    timeDelta: 10080,
    getTime: () => {
      const now = new Date();
      return [subDays(now, 7), now];
    },
  },
  {
    id: 11,
    label: "Last 30 Days",
    timeDelta: 43200,
    getTime: () => {
      const now = new Date();
      return [subDays(now, 30), now];
    },
  },
  {
    id: 12,
    label: "Last 3 Months",
    getTime: () => {
      const now = new Date();
      return [subMonths(now, 3), now];
    },
  },
  {
    id: 13,
    label: "Last 6 Months",
    getTime: () => {
      const now = new Date();
      return [subMonths(now, 6), now];
    },
  },
  {
    id: 14,
    label: "Last 1 Year",
    getTime: () => {
      const now = new Date();
      return [subYears(now, 1), now];
    },
  },
];

export const generateGraphTitle = ({
  hostName,
  dataType = "interface",
  interfaceKey,
  interfaceDesc,
}) => {
  let interfaceTitle = "";
  if (interfaceKey) {
    interfaceTitle = `: ${capitalize(dataType)} ${interfaceKey}`;
    if (interfaceDesc) interfaceTitle += ` (${interfaceDesc})`;
  }
  let graphTitle = hostName;
  if (interfaceTitle) graphTitle += interfaceTitle;
  return graphTitle;
};

/**
 * Parent:
 *  GisMapEvenLayer
 *
 * Renders:
 *  GraphsContentClass
 */
const Graphs = () => {
  const dispatch = useDispatch();
  const [minimized, setMinimized] = useState(false);

  const { fetching, fetched, fetchError } = useSelector((store) => store.catv);
  const mapState = useSelector(getPlanningMapState);
  const { data: mapStateData, layerKey } = mapState;
  const {
    elementId,
    selectedInterface: initInterface,
    dataType: initDataType,
    fieldKey: initFieldKey,
    graphTitleData: initGraphTitleData,
  } = mapStateData;

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [dataType, setDataType] = useState(initDataType);
  const [fieldKeys, setFieldKeys] = useState([]);
  const [selectedTimeId, setSelectedTimeId] = useState(null);
  const [graphTitle, setGraphTitle] = useState("");

  const isInterface = dataType === "interface";
  const isIQLayerKey = layerKey === IQLayerKey;
  const isTransport = isIQLayerKey && dataType === "transport";

  const [selectedInterface, setSelectedInterface] = useState(
    isInterface ? initInterface : null
  );
  const [selectedTransport, setSelectedTransport] = useState(
    isTransport ? initInterface : null
  );

  useEffect(() => {
    const newTitle = generateGraphTitle({ ...initGraphTitleData, dataType });
    setGraphTitle(newTitle);
    return () => {
      dispatch(resetCATVData());
    };
  }, []);

  const { data: portDetails, isLoading } = useQuery(
    ["elementPortDetails", layerKey, elementId],
    fetchElementPortDetails,
    { refetchOnWindowFocus: false }
  );

  const { data: transportDetails, isLoading: transportLoading } = useQuery(
    ["elementTransportDetails", layerKey, elementId],
    fetchElementTransportDetails,
    { refetchOnWindowFocus: false, enabled: isIQLayerKey }
  );

  const { data: attrConfigList, isLoading: isFieldsLoading } = useQuery(
    ["attrConfigList", dataType, layerKey, elementId],
    fetchAttrConfig,
    {
      refetchOnWindowFocus: false,
      select: (resList) => {
        const filteredList = filter(resList, ["show_chart", true]);
        return map(filteredList, (d) => ({ label: d.label, value: d.key }));
      },
      onSuccess: (resList) => {
        setFieldKeys(filter(resList, ["value", initFieldKey]));
      },
    }
  );

  const handlePopupMinimize = useCallback(() => {
    setMinimized((val) => !val);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(openElementDetails({ layerKey, elementId }));
  }, []);

  const handleDataTypeChange = useCallback((event) => {
    setDataType(event.target.value);
  }, []);

  const handleStartTimeChange = useCallback((dateTime) => {
    setStartTime(dateTime);
    setSelectedTimeId(null);
  }, []);

  const handleEndTimeChange = useCallback((dateTime) => {
    setEndTime(dateTime);
    setSelectedTimeId(null);
  }, []);

  const handleSubmit = (startTime, endTime) => () => {
    let interface_key, interfaceDesc;
    if (dataType === "interface") {
      interface_key = selectedInterface?.key;
      interfaceDesc = selectedInterface?.description;
    } else if (dataType === "transport") {
      interface_key = selectedTransport?.key;
      interfaceDesc = selectedTransport?.description;
    }
    // generate title for graph
    const graphTitleData = {
      ...initGraphTitleData,
      interfaceKey: interface_key,
      interfaceDesc,
      dataType,
    };

    const graphTitle = generateGraphTitle(graphTitleData);

    setGraphTitle(graphTitle);

    const postData = {
      element_id: elementId,
      layer_key: layerKey,
      data_type: dataType,
      interface_key,
      fields: map(fieldKeys, "value"),
      start_time: startTime,
      end_time: endTime,
    };

    if (!size(postData.fields) || !postData.start_time) {
      dispatch(
        addNotification({
          type: "error",
          title: "Input Error",
          text: "Please fill required inputes.",
        })
      );
      return;
    }
    dispatch(
      setGraphOptions({
        startTime: Number(startTime),
        endTime: endTime ? Number(endTime) : new Date(),
      })
    );
    dispatch(fetchAttrTimeseriesThunk(postData));
  };

  return (
    <GisMapPopups dragId="graphs" isFullScreen>
      <Box width="100%">
        {/* Table header */}
        <TableHeader
          title={"Graphs"}
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleClose}
          showMinimizeBtn={false}
        />
        {fetchError ? (
          <Box p={2}>
            <Typography variant="h5">
              Error occured while fetching data
            </Typography>
          </Box>
        ) : minimized ? null : (
          <Box>
            <Stack
              sx={{ boxShadow: "0px 5px 7px -3px rgba(122,122,122,0.51)" }}
              p={2}
              spacing={2}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                flexWrap="wrap"
              >
                <FormControl>
                  <FormLabel id="data-type">Data Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="data-type"
                    name="data-type-group"
                    value={dataType}
                    onChange={handleDataTypeChange}
                  >
                    <FormControlLabel
                      value="generic"
                      control={<Radio />}
                      label="Generic"
                    />
                    <FormControlLabel
                      value="interface"
                      control={<Radio />}
                      label="Interface"
                    />
                    {isIQLayerKey ? (
                      <FormControlLabel
                        value="transport"
                        control={<Radio />}
                        label="Transport"
                      />
                    ) : null}
                  </RadioGroup>
                </FormControl>
                {isInterface ? (
                  <Box width="300px">
                    <CustomSelect
                      label="Select Interface"
                      options={portDetails}
                      menuPortalTarget={document.body}
                      value={selectedInterface}
                      onChange={setSelectedInterface}
                      isLoading={isLoading}
                      getOptionLabel={(o) => o["description"]}
                      getOptionValue={(o) => o["key"]}
                      formatOptionLabel={(data) => {
                        return (
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ lineHeight: 1.1 }}
                            >
                              {get(data, "key", "")}
                            </Typography>
                            <Typography variant="caption">
                              {get(data, "description", "")}
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                ) : null}
                {isTransport ? (
                  <Box width="300px">
                    <CustomSelect
                      label="Select Transport"
                      options={transportDetails}
                      menuPortalTarget={document.body}
                      value={selectedTransport}
                      onChange={setSelectedTransport}
                      isLoading={transportLoading}
                      getOptionLabel={(o) => o["description"]}
                      getOptionValue={(o) => o["key"]}
                      formatOptionLabel={(data) => {
                        return (
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ lineHeight: 1.1 }}
                            >
                              {get(data, "key", "")}
                            </Typography>
                            <Typography variant="caption">
                              {get(data, "description", "")}
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                ) : null}
                <Box width="300px">
                  <CustomSelect
                    label="Select Fields"
                    options={attrConfigList}
                    menuPortalTarget={document.body}
                    value={fieldKeys}
                    onChange={setFieldKeys}
                    isLoading={isFieldsLoading}
                    isMulti
                  />
                </Box>
                <Box minWidth="250px">
                  <CustomDateTimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={handleStartTimeChange}
                  />
                </Box>
                <Box minWidth="250px">
                  <CustomDateTimePicker
                    label="End Time"
                    value={endTime}
                    onChange={handleEndTimeChange}
                  />
                </Box>
                <LoadingButton
                  sx={{ minWidth: "100px" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit(startTime, endTime)}
                  loading={fetching}
                >
                  Submit
                </LoadingButton>
              </Stack>
              <Box>
                <FormControl>
                  <FormLabel id="data-type">Quick Actions</FormLabel>
                  <Stack direction="row" spacing={2} flexWrap="wrap">
                    {GRAPH_QUICK_ACTIONS.map((action) => {
                      return (
                        <Button
                          key={action.label}
                          variant={
                            action.id === selectedTimeId ? "contained" : "text"
                          }
                          onClick={() => {
                            let startTime;
                            if (action.timeDelta) {
                              startTime = subMinutes(
                                new Date(),
                                action.timeDelta
                              );
                            } else {
                              startTime = action.getTime()[0];
                            }
                            setStartTime(startTime);
                            setEndTime(null);
                            setSelectedTimeId(action.id);
                            handleSubmit(startTime, null)();
                          }}
                        >
                          {action.label}
                        </Button>
                      );
                    })}
                  </Stack>
                </FormControl>
              </Box>
            </Stack>
            {fetching ? (
              <DummyListLoader />
            ) : fetched ? (
              <GraphsContent graphTitle={graphTitle} />
            ) : null}
          </Box>
        )}
      </Box>
    </GisMapPopups>
  );
};

export default Graphs;
