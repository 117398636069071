import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import trim from "lodash/trim";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CableIcon from "@mui/icons-material/Cable";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";

import { handleThroughConnect } from "planning/data/splicing.actions";
import { addNotification } from "redux/reducers/notification.reducer";
import ConfirmDialog from "components/common/ConfirmDialog";
import { getCableMaxCoreCount, getFmsMaxPortCount } from "./splicing.utils";
import { Divider } from "@mui/material";

const CableThroughConnect = ({ fromData, toData }) => {
  const dispatch = useDispatch();
  // calculate max sr no for from and to
  let fromMax = 0;
  let toMax = 0;

  if (fromData.layer_key === "p_cable") {
    fromMax = getCableMaxCoreCount(
      fromData.configuration.no_of_tube,
      fromData.configuration.core_per_tube,
      fromData.configuration.ribbon_count
    );
  } else if (fromData.layer_key === "p_fms") {
    fromMax = getFmsMaxPortCount(fromData.configuration.no_of_ports);
  }

  if (toData.layer_key === "p_cable") {
    toMax = getCableMaxCoreCount(
      toData.configuration.no_of_tube,
      toData.configuration.core_per_tube,
      toData.configuration.ribbon_count
    );
  } else if (toData.layer_key === "p_fms") {
    toMax = getFmsMaxPortCount(toData.configuration.no_of_ports);
  }

  const [showPopup, setShowPopup] = useState(false);
  const [fromStart, setFromStart] = useState(1);
  const [fromEnd, setFromEnd] = useState(fromMax);
  const [toStart, setToStart] = useState(1);
  const [toEnd, setToEnd] = useState(toMax);

  const onChangeMapper = useMemo(
    () => ({
      setFromStart,
      setFromEnd,
      setToStart,
      setToEnd,
    }),
    []
  );

  const handleTextUpdates = useCallback(
    (handlerName) => (e) => {
      const val = trim(e.target.value);
      // allow numeric only
      if (isNaN(val)) return;
      // check min max validations
      onChangeMapper[handlerName](val);
    },
    [onChangeMapper]
  );

  const handleShowPopup = () => {
    const connectionCount = Number(fromEnd) - Number(fromStart);
    const validateCount = Number(toEnd) - Number(toStart);
    if (validateCount !== connectionCount) {
      dispatch(
        addNotification({
          type: "error",
          title: "Invalid port selection",
          text: "From port and To port counts must be same",
        })
      );
      return;
    }
    setShowPopup(true);
  };

  const handleHidePopup = useCallback(() => setShowPopup(false), []);

  const handleConnectClick = () => {
    const connectionCount = Number(fromEnd) - Number(fromStart);
    const fromConnData = {
      layer_key: fromData.layer_key,
      ports: fromData.ports,
      sr_no: [Number(fromStart), Number(fromEnd)],
      isInverted: fromData.isInverted,
    };
    const toConnData = {
      layer_key: toData.layer_key,
      ports: toData.ports,
      sr_no: [Number(toStart), Number(toEnd)],
      isInverted: toData.isInverted,
    };

    dispatch(handleThroughConnect(fromConnData, toConnData, connectionCount));
    handleHidePopup();
  };

  const inputStyles = {
    maxWidth: "3.5em",
  };

  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Stack direction="row" spacing={2}>
          <Box display="flex" alignItems="center">
            <Typography>From</Typography>
          </Box>
          <TextField
            label="start"
            value={fromStart}
            sx={inputStyles}
            onChange={handleTextUpdates("setFromStart")}
          />
          <TextField
            label="end"
            value={fromEnd}
            sx={inputStyles}
            onChange={handleTextUpdates("setFromEnd")}
          />
        </Stack>

        <Button
          onClick={handleShowPopup}
          variant="outlined"
          startIcon={<CableIcon />}
          endIcon={<SettingsInputComponentIcon />}
        >
          Connect
        </Button>

        <Stack direction="row" spacing={2}>
          <Box display="flex" alignItems="center">
            <Typography>To</Typography>
          </Box>
          <TextField
            label="start"
            value={toStart}
            sx={inputStyles}
            onChange={handleTextUpdates("setToStart")}
          />
          <TextField
            label="end"
            value={toEnd}
            sx={inputStyles}
            onChange={handleTextUpdates("setToEnd")}
          />
        </Stack>
      </Stack>
      <ConfirmDialog
        show={!!showPopup}
        onClose={handleHidePopup}
        onConfirm={handleConnectClick}
        title="Connect Ports"
        text="This will connect multiple ports, do you want to proceed ?"
        confirmText="Connect"
      />
      <Divider sx={{ marginY: "2em" }} />
    </>
  );
};

export default CableThroughConnect;
