import React from "react";
import { Typography, Container, Paper } from "@mui/material";

import Image from "assets/under_construction.svg";

const EmptyDashboard = () => {
  return (
    <Container sx={{ height: "100%", py: 2 }}>
      <Paper className="coming-soon">
        <div className="coming-soon-content">
          <img src={Image} />
          <Typography variant="h5" py={2}>
            No Dashboard found for you !! Please contact admin to assign you{" "}
            relevant dashboard
          </Typography>
        </div>
      </Paper>
    </Container>
  );
};

export default EmptyDashboard;
