import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_FORM_CONFIG_ABSTRACT_SECTION,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";

import { default as Icon } from "assets/markers/fms_box.svg";
import { default as EditIcon } from "assets/markers/fms_pin.svg";

import get from "lodash/get";

export const LAYER_KEY = "p_fms";
export const PRE_UID = "FMS";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
  labelOrigin: { x: 10, y: -8 },
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

export const getElementFormTemplate = () => {
  return {
    title: "FMS Form",
    sections: [
      {
        fieldConfigs: [...ELEMENT_FORM_ABSTRACT_TEMPLATE],
      },
    ],
  };
};

export const INITIAL_CONFIG_DATA = {
  config_name: "",
  splitter_type: "P",
  ratio: "",
  specification: "",
  vendor: "",
};

export const ELEMENT_CONFIG_TEMPLATE = {
  sections: [
    {
      title: "Spliter Form",
      fieldConfigs: [
        {
          field_key: "config_name",
          label: "Name",
          field_type: "input",
          validationProps: {
            required: "Name is required",
          },
        },
        {
          field_key: "no_of_ports",
          label: "No. of ports",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "slots",
          label: "No of slots",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "specification",
          label: "Specification",
          field_type: "textArea",
        },
        {
          field_key: "vendor",
          label: "Vendor",
          field_type: "input",
        },
      ],
    },
  ],
};

export const CONFIG_LIST_TABLE_COL_DEFS = [
  { headerName: "Name", field: "config_name" },
  { headerName: "Port Count", field: "no_of_ports" },
  { headerName: "Slots", field: "slots" },
];

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "Config Name", field: "config_name", type: "simple" },
  { label: "Port Count", field: "no_of_ports", type: "simple" },
  { label: "Slots", field: "slots", type: "simple" },
  { label: "Specification", field: "specification", type: "simple" },
  { label: "Vendor", field: "vendor", type: "simple" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "patching",
    data: {
      can_patch_with: ["p_olt"],
    },
  },
  {
    control: "connections",
  },
  {
    control: "ports",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    label: "Configuration",
    field: "configuration_id",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.ConfigSelect,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
