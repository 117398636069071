import OneSignal from "react-onesignal";
import get from "lodash/get";

import { createPushSubscription } from "pages/Authentication/services";

let OneSignalClient;

class OneSignalUtilWrapper {
  constructor() {
    OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID }).then(
      () => {
        OneSignal.Slidedown.promptPush();

        OneSignal.User.PushSubscription.addEventListener("change", (event) => {
          console.log(
            "file: onesignal.utils.js:12 ~ OneSignalUtilWrapper ~ event:",
            event
          );
          const currentToken = get(event, "current.id");
          if (!!currentToken) {
            this.checkAndCreatePushSubscription();
          }
        });
      }
    );
  }

  loginOneSignal = async (username) => {
    this.hasPermission();
    this.canRequestPermission();
    // this.triggerInAppMessage();
    console.log(
      "🚀 ~ file: onesignal.utils.js:12 ~ OneSignalUtilWrapper ~ username:",
      username
    );
    OneSignal.login(username);
  };

  hasPermission = () => {
    let permission = OneSignal.Notifications.permission; // boolean
    console.log(
      "file: onesignal.utils.js:48 ~ OneSignalUtilWrapper ~ hasPermission= ~ permission:",
      permission
    );
    return permission;
  };

  canRequestPermission = async () => {
    const canRequestPermission = OneSignal.Notifications.permission;
    console.log(
      "🚀 ~ file: onesignal.utils.js:28 ~ OneSignalUtilWrapper ~ canRequestPermission:",
      canRequestPermission
    );
    if (!canRequestPermission) {
      console.log("Platform does not support notification");
    }
    return canRequestPermission;
  };

  getPushSubscriptionId = () => {
    const pushSubscriptionId = OneSignal.User.PushSubscription.id;
    console.log(
      "file: onesignal.utils.js:27 ~ OneSignalUtilWrapper ~ pushSubscriptionId:",
      pushSubscriptionId
    );
    return pushSubscriptionId;
  };

  checkAndCreatePushSubscription = () => {
    const pushSubscriptionId = this.getPushSubscriptionId();
    if (pushSubscriptionId) {
      createPushSubscription({ subscription_id: pushSubscriptionId });
    }
  };

  isPushSupported = () => {
    const isSupported = OneSignal.Notifications.isPushSupported();
    return isSupported;
  };

  logout = () => {
    console.log("Logout");
    OneSignal.logout();
  };
}

export const logoutOneSignal = () => {
  console.log("Logout");
  OneSignal.logout();
};

function getOrCreateOneSignalClient() {
  if (!OneSignalClient) {
    OneSignalClient = new OneSignalUtilWrapper();
  }
  return OneSignalClient;
}

export default getOrCreateOneSignalClient;
