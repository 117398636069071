import React, { useMemo } from "react";

import groupBy from "lodash/groupBy";
import size from "lodash/size";
import get from "lodash/get";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import TicketWorkOrderList from "./TicketWorkOrderList";

const CustomerTicketCollapsibleWOList = ({
  isFilterActive,
  workOrderList = [],
}) => {
  /**
   * render collapsible wo list layer wise
   * Parent:
   *    TicketSideBar
   */

  const { customerWoList, networkWoList, customerWoCount, networkWoCount } =
    useMemo(() => {
      const groupByData = groupBy(workOrderList, "wo_type");

      const customerWoList = get(groupByData, "customer") || [];
      const networkWoList = get(groupByData, "network") || [];
      const customerWoCount = size(customerWoList);
      const networkWoCount = size(networkWoList);
      return { customerWoList, networkWoList, customerWoCount, networkWoCount };
    }, [workOrderList]);

  if (!size(workOrderList)) {
    return (
      <Box px={2} py="20%" textAlign="center">
        <Typography variant="h5">
          {isFilterActive
            ? "No workorders for selected Filter !!"
            : "No workorders added to this ticket yet !!"}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Accordion defaultExpanded={customerWoCount < 5}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" alignItems="center">
            <Typography variant="button" lineHeight={1.1}>
              {`Customer Workorders ( ${customerWoCount} )`}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Divider />
          <TicketWorkOrderList
            workOrderList={customerWoList}
            isFilterActive={false}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={networkWoCount < 5}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" alignItems="center">
            <Typography variant="button" lineHeight={1.1}>
              {`Network Workorders ( ${networkWoCount} )`}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Divider />
          <TicketWorkOrderList
            workOrderList={networkWoList}
            isFilterActive={false}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CustomerTicketCollapsibleWOList;
