import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_FORM_CONFIG_ABSTRACT_SECTION,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";

import { default as Icon } from "assets/markers/olt_box.svg";
import { default as EditIcon } from "assets/markers/olt_pin.svg";

import get from "lodash/get";

export const LAYER_KEY = "p_olt";
export const PRE_UID = "OLT";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;
export const SHOW_LABELS_AFTER_ZOOM = 15;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
  labelOrigin: { x: 10, y: -8 },
});

export const OLT_PORT_TYPE_OPTIONS = [
  { value: "C", label: "Copper" },
  { value: "F", label: "Fiber" },
];

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
  active_customer: null,
};

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {
  return {
    title: "OLT Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "spop_code",
            label: "SPOP Code",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "ip_address",
            label: "IP Address",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "nms_ref_name",
            label: "NMS Reference Name",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "active_customer",
            label: "Active Customers",
            field_type: FIELD_TYPES.NumericInput,
          },
          {
            field_key: "date_of_commisioning",
            label: "Date of commisioning",
            field_type: FIELD_TYPES.DateTime,
          },
        ],
      },
    ],
  };
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  {
    field: "spop_code",
    label: "SPOP Code",
    type: "simple",
  },
  {
    label: "Date of commisioning",
    field: "date_of_commisioning",
    type: "date",
  },
  { label: "Config Name", field: "config_name", type: "simple" },
  { label: "IP Address", field: "ip_address", type: "simple" },
  { label: "NMS Reference Name", field: "nms_ref_name", type: "simple" },
  { label: "Active Customers", field: "active_customer", type: "simple" },
  { label: "Card Count", field: "card_count", type: "simple" },
  { label: "Uplink Port Count", field: "uplink_port_count", type: "simple" },
  {
    label: "Downlink Port Count",
    field: "downlink_port_count",
    type: "simple",
  },
];

export const INITIAL_CONFIG_DATA = {
  config_name: "",
  uplink_port_count: "",
  downlink_port_count: "",
  card_count: 1,
};

export const CONFIG_LIST_TABLE_COL_DEFS = [
  { headerName: "Name", field: "config_name" },
  { headerName: "Card count", field: "card_count" },
  { headerName: "Uplink port count", field: "uplink_port_count" },
  { headerName: "Downlink port count", field: "downlink_port_count" },
];

export const ELEMENT_CONFIG_TEMPLATE = {
  sections: [
    {
      title: "OLT Form",
      fieldConfigs: [
        {
          field_key: "config_name",
          label: "Name",
          field_type: "input",
          validationProps: {
            required: "Name is required",
          },
        },
        {
          field_key: "card_count",
          label: "Card count",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "uplink_port_count",
          label: "Uplink port count",
          field_type: "input",
          disable_on_edit: true,
          validationProps: {
            required: "Uplink port count is required",
          },
        },
        {
          field_key: "downlink_port_count",
          label: "Downlink port count",
          field_type: "input",
          disable_on_edit: true,
          validationProps: {
            required: "Downlink port count is required",
          },
        },
      ],
    },
  ],
};

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "patching",
    data: {
      can_patch_with: ["p_fms"],
    },
  },
  {
    control: "ports",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    label: "Configuration",
    field: "configuration_id",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.ConfigSelect,
  },
  {
    field: "active_customer",
    label: "Active Customers",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.NumericInput,
    hasOperator: true,
  },
  {
    field: "date_of_commisioning",
    label: "Date of commisioning",
    field_type: FIELD_TYPES.Date,
    filter_type: FILTER_TYPES.date,
    hasOperator: true,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "nms_ref_name");
};
