import get from "lodash/get";

import { getTraceShowOnMap, getTracebackData } from "./traceback.selectors";
import { LayerKeyMappings } from "planning/GisMap/utils";
import { pointCoordsToLatLongMap } from "utils/map.utils";
import { FEATURE_TYPES } from "planning/GisMap/layers/common/configuration";
import { setShowOnMap } from "./traceback.reducer";
import { setMapPosition, toggleMapPopupMinimize } from "../planningGis.reducer";

export const onTraceShowOnMapClick = () => (dispatch, getState) => {
  const storeState = getState();
  const tracebackData = getTracebackData(storeState);
  const showOnMap = getTraceShowOnMap(storeState);

  if (showOnMap) {
    // hide highlighted elements
    dispatch(setShowOnMap(false));
  }
  //
  else {
    // center to firsh element and show highlighted
    const traceTree = get(tracebackData, "tree", []);
    const firstElement = traceTree[0];
    const featureType = get(LayerKeyMappings, [
      firstElement.layer_key,
      "featureType",
    ]);
    let center;

    if (featureType === FEATURE_TYPES.POINT) {
      center = firstElement.coordinates;
      // google expects lat long obj, if coordinates are array of lat long -> convert to obj
      if (Array.isArray(center)) {
        center = pointCoordsToLatLongMap(center);
      }
    }
    //
    else if (featureType === FEATURE_TYPES.POLYLINE) {
      center = firstElement.center;
      // google expects lat long obj, if coordinates are array of lat long -> convert to obj
      if (Array.isArray(center)) {
        center = pointCoordsToLatLongMap(center);
      }
    }

    dispatch(
      setMapPosition({
        center: center,
        currentZoom: 17.8,
      })
    );
    dispatch(toggleMapPopupMinimize(false));
    dispatch(setShowOnMap(true));
  }
};
