// utils
export const REPORT_TYPES = [
  {
    value: "bom",
    label: "Bom Report",
    permissionName: "bom_report_view",
  },
  {
    value: "layer_data",
    label: "Layer Data Report",
    permissionName: "layer_data_view",
  },
  {
    value: "customer_tagging_report",
    label: "DP Customer Tagging Report",
    permissionName: "customer_tagging_view",
  },
  {
    value: "customer_tagging_completion_view",
    label: "Customer Tagging Progress Report",
    permissionName: "customer_tagging_completion_view",
  },
  {
    value: "customer_tagging_progress_view",
    label: "Customer Tagging Progress Report - Daily Activity",
    permissionName: "customer_tagging_progress_view",
  },
  {
    value: "alerts_report",
    label: "Alerts Report",
    permissionName: "alert_report_view",
  },
];
