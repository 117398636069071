import pick from "lodash/pick";

import Api from "utils/api.utils";
import {
  apiGetElementAssociations,
  apiGetElementConnections,
  apiGetElementDetails,
  apiGetRegionDetails,
  apiGetTicketDetails,
  apiPostAddElement,
  apiPostLayerDownload,
  apiPostLayerUpload,
  apiPostRegionAdd,
  apiPostValidateElementGeometry,
  apiPutEditElement,
  apiDeteleElement,
  apiPutRegionEdit,
  apiUpdateElementConnections,
  apiGetElementHistory,
  apiGetImageList,
  apiGetDocumentList,
  apiPostAddImage,
  apiPostAddDocument,
  apiPostLayerUploadEdit,
  apiPostDwnloadLayerByArea,
  apiPostUploadDetele,
  apiPostPossibleConnections,
  apiDeleteImage,
} from "utils/url.constants";

export const fetchElementDetails = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  if (layerKey === "ticket") {
    const res = await Api.get(apiGetTicketDetails(elementId));
    return res.data;
  } else if (layerKey === "region") {
    const res = await Api.get(apiGetRegionDetails(elementId, "detail"));
    return res.data;
  } else {
    const res = await Api.get(apiGetElementDetails(layerKey, elementId));
    return res.data;
  }
};

// data : { layerKey, element_id*, featureType, geometry, region_id_list / ticket_id }
export const validateElementGeometry = async (data) => {
  const res = await Api.post(apiPostValidateElementGeometry(), data);
  return res.data;
};

// get list of cables in given polygon coordinates around an element
export const fetchPossibleConnections = async ({ queryKey }) => {
  const [_key, circlAreaCoords] = queryKey;
  const res = await Api.post(apiPostPossibleConnections(), {
    searchAreaCoordinates: circlAreaCoords,
  });
  return res.data;
};

export const fetchElementConnections = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetElementConnections(layerKey, elementId));
  return res.data;
};

export const addNewElement = async ({ data, layerKey }) => {
  if (layerKey === "region") {
    let submitData = pick(data, ["name", "unique_id", "layer", "parentId"]);
    submitData.coordinates = data.geometry;
    const res = await Api.post(apiPostRegionAdd(), submitData);
    return res.data;
  } else {
    const res = await Api.post(apiPostAddElement(layerKey), data);
    return res.data;
  }
};

export const editElementDetails = async ({ data, layerKey, elementId }) => {
  if (layerKey === "region") {
    let submitData = pick(data, ["name", "unique_id", "layer", "parentId"]);
    if (data?.geometry && Array.isArray(data.geometry)) {
      submitData.coordinates = data.geometry;
    }
    const res = await Api.put(apiPutRegionEdit(elementId), submitData);
    return res.data;
  } else {
    const res = await Api.put(apiPutEditElement(layerKey, elementId), data);
    return res.data;
  }
};

export const addElementConnection = async ({ data, cableId }) => {
  const res = await Api.put(apiUpdateElementConnections(cableId), data);
  return res.data;
};

export const fetchElementAssociations = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetElementAssociations(layerKey, elementId));
  return res.data;
};

export const fetchDownloadLayerData = async ({ data, layerKey }) => {
  const res = await Api.post(apiPostLayerDownload(layerKey), data, null, {
    responseType: "blob",
  });
  return res.data;
};

export const uploadLayerData = async ({ data, layerKey }) => {
  const res = await Api.post(apiPostLayerUpload(layerKey), data);
  return res.data;
};

export const deleteLayer = async ({ layerKey, elementId }) => {
  const res = await Api.delete(apiDeteleElement(layerKey, elementId));
  return res.data;
};

export const fetchHistory = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetElementHistory(layerKey, elementId));
  return res.data;
};

export const fetchImageList = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetImageList(layerKey, elementId));
  return res.data;
};

export const deleteImage = async ({ layerKey, imageId }) => {
  const res = await Api.delete(apiDeleteImage(layerKey, imageId));
  return res.data;
};

export const fetchDocumentList = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetDocumentList(layerKey, elementId));
  return res.data;
};

export const uploadLayerImage = async ({ data, layerKey, elementId }) => {
  const res = await Api.post(apiPostAddImage(layerKey, elementId), data, null, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return res.data;
};

export const uploadLayerDocument = async ({ data, layerKey, elementId }) => {
  const res = await Api.post(
    apiPostAddDocument(layerKey, elementId),
    data,
    null,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const uploadEditLayer = async ({ data, layerKey, elementId }) => {
  const res = await Api.post(
    apiPostLayerUploadEdit(layerKey, elementId),
    data,
    null,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const downloadHomePassData = async ({ data, layerKey }) => {
  const res = await Api.post(apiPostDwnloadLayerByArea(layerKey), data, null, {
    responseType: "blob",
  });
  return res.data;
};

export const layerElementsDeleteUpload = async ({ data, layerKey }) => {
  const res = await Api.post(apiPostUploadDetele(layerKey), data, null, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return res.data;
};
