import React, { useCallback, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { useDispatch } from "react-redux";

import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";

import {
  setMapPosition,
  setSpecialLayerData,
} from "planning/data/planningGis.reducer";
import { addNotification } from "redux/reducers/notification.reducer";
import { FEATURE_TYPES } from "../../layers/common/configuration";
import { CUSTOM_ICONS } from "planning/GisMap/GisIcons";
import {
  SPECIAL_LAYER_HIGHLIGHT_TYPES,
  generateSpecialLayerId,
} from "planning/GisMap/utils";

const GoogleAutoComplete = () => {
  /**
   * Parent:
   *    MapSearchbox
   */
  const dispatch = useDispatch();
  const autoComplete = useRef();

  const onLoad = useCallback((autoCompleteEvent) => {
    autoComplete.current = autoCompleteEvent;
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (!autoComplete?.current) {
      dispatch(
        addNotification({
          type: "error",
          title: "Autocomplete not ready yet!",
        })
      );
    }
    // get all places details
    const place = autoComplete.current.getPlace();
    // get place location;
    const location = place.geometry.location.toJSON();
    dispatch(
      setMapPosition({
        center: location,
        currentZoom: 18,
        isHighlighted: true,
      })
    );
    const specialLayerId = generateSpecialLayerId(
      {},
      SPECIAL_LAYER_HIGHLIGHT_TYPES.google_place_search
    );
    dispatch(
      setSpecialLayerData([
        {
          id: specialLayerId,
          featureType: FEATURE_TYPES.POINT,
          coordinates: location,
          viewOptions: {
            iconType: CUSTOM_ICONS.blue_marker,
          },
        },
      ])
    );
  }, []);

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      restrictions={{
        country: process.env.REACT_APP_GOOGLE_COUNTRY,
      }}
    >
      <InputBase
        label="Search"
        type="search"
        placeholder="Search by name of the place..."
        sx={{
          width: "100%",
          height: "100%",
        }}
        endAdornment={
          <SearchIcon
            sx={{
              color: "rgb(102, 102, 102)",
              marginLeft: "6px",
              fontSize: "1.3rem",
            }}
          />
        }
      />
    </Autocomplete>
  );
};

export default GoogleAutoComplete;
