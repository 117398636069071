import size from "lodash/size";

import Api from "utils/api.utils";
import {
  apiGetAlertActionsList,
  apiGetAlertDetails,
  apiGetAlertList,
  apiPostAlertAdd,
  apiPostAlertBulkUploadAction,
} from "utils/url.constants";

export const fetchAlertList = async ({ queryKey }) => {
  // queryKey shape = [key, alertStatus, pageLimit (optional), pageOffset (optional)]
  const [_, alertStatus] = queryKey;
  let queryParams = null;
  // if we pass limit and offset in query arguments,
  if (size(queryKey) > 2) {
    queryParams = {
      limit: queryKey[2], // pageLimit
      offset: queryKey[3], // pageOffset
    };
  }
  const res = await Api.get(apiGetAlertList(alertStatus), queryParams);
  return res.data;
};

export const fetchAlertDetails = async ({ queryKey }) => {
  const [_, alertId] = queryKey;
  const res = await Api.get(apiGetAlertDetails(alertId));
  return res.data;
};

export const fetchAlertActionsList = async ({ queryKey }) => {
  const [_, alertId] = queryKey;
  const res = await Api.get(apiGetAlertActionsList(alertId));
  return res.data;
};

export const addAlertService = async (data) => {
  const res = await Api.post(apiPostAlertAdd(), data);
  return res.data;
};

export const uploadBulkAlertActions = async (data) => {
  const res = await Api.post(apiPostAlertBulkUploadAction(), data, null, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return res.data;
};
