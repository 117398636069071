import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_FORM_CONFIG_ABSTRACT_SECTION,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
  CABINET_TYPE_OPTIONS,
} from "../common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";

import PrimaryOnuIcon from "assets/markers/onu_nowifi_icon.svg";
import PrimaryOnuEditIcon from "assets/markers/onu_nowifi_pin.svg";
import SecondaryOnuIcon from "assets/markers/onu_wifi_icon.svg";
import SecondaryOnuEditIcon from "assets/markers/onu_wifi_pin.svg";

import get from "lodash/get";

export const LAYER_KEY = "p_onu";
export const PRE_UID = "ONU";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;
export const SHOW_LABELS_AFTER_ZOOM = 17;

export const getViewOptions = ({ onu_type }) => ({
  icon: onu_type === "N" ? PrimaryOnuIcon : SecondaryOnuIcon,
  pin: onu_type === "N" ? PrimaryOnuEditIcon : SecondaryOnuEditIcon,
  labelOrigin: { x: 10, y: -8 },
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

export const getElementFormTemplate = () => {
  return {
    title: "ONU Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "address",
            label: "Address",
            field_type: FIELD_TYPES.TextArea,
          },
          {
            field_key: "serial_no",
            label: "Serial No",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "mac_address",
            label: "Mac Address",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "cabinet_type",
            label: "Cabinet Type",
            field_type: FIELD_TYPES.Select,
            options: CABINET_TYPE_OPTIONS,
          },
        ],
      },
    ],
    // this shows where dependant template data comes from
    metaData: {
      getElementAddressData: (address, submitData) => {
        submitData.address = address.address;
      },
    },
  };
};

export const INITIAL_CONFIG_DATA = {
  config_name: "",
  make: "",
  erp_reff_number: "",
  onu_type: "N",
  input_ports: "",
  output_ports: "",
  specification: "",
  vendor: "",
};

export const ONU_TYPE_OPTIONS = [
  { value: "N", label: "Non Wifi" },
  { value: "W", label: "Wifi" },
];

export const ELEMENT_CONFIG_TEMPLATE = {
  sections: [
    {
      title: "ONU Form",
      fieldConfigs: [
        {
          field_key: "config_name",
          label: "Name",
          field_type: "input",
          validationProps: {
            required: "Name is required",
          },
        },
        {
          field_key: "onu_type",
          label: "ONU Type",
          field_type: "select",
          options: ONU_TYPE_OPTIONS,
        },
        {
          field_key: "make",
          label: "Make",
          field_type: "input",
          validationProps: {
            required: "Make is required",
          },
        },
        {
          field_key: "erp_reff_number",
          label: "Erp Ref Number",
          field_type: "input",
          disable_on_edit: true,
          validationProps: {
            required: "Erp Ref Number is required",
          },
        },
        {
          field_key: "input_ports",
          label: "Input Ports",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "output_ports",
          label: "Output Ports",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "specification",
          label: "Specification",
          field_type: "textArea",
        },
        {
          field_key: "vendor",
          label: "Vendor",
          field_type: "input",
        },
      ],
    },
  ],
};

export const CONFIG_LIST_TABLE_COL_DEFS = [
  { headerName: "Name", field: "config_name" },
  { headerName: "Input Ports", field: "input_ports" },
  { headerName: "Output Ports", field: "output_ports" },
  { headerName: "ONU Type", field: "onu_type_display" },
];

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "ONU Type", field: "onu_type_display", type: "simple" },
  { label: "Config Name", field: "config_name", type: "simple" },
  { label: "Address", field: "address", type: "simple" },
  { label: "Serial No", field: "serial_no", type: "simple" },
  { label: "Cabinet Type", field: "cabinet_type_display", type: "simple" },
  { label: "Make", field: "make", type: "simple" },
  { label: "Erp Ref Number", field: "erp_reff_number", type: "simple" },
  { label: "Input Ports", field: "input_ports", type: "simple" },
  { label: "Output Ports", field: "output_ports", type: "simple" },
  { label: "Specification", field: "specification", type: "simple" },
  { label: "Vendor", field: "vendor", type: "simple" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "connections",
  },
  {
    control: "ports",
  },
  {
    control: "attachments",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    label: "ONU Type",
    field: "onu_type",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Select,
    choices: ONU_TYPE_OPTIONS,
  },
  {
    field: "cabinet_type",
    label: "Cabinet Type",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Select,
    choices: CABINET_TYPE_OPTIONS,
  },
  {
    field: "serial_no",
    label: "Serial No",
    filter_type: FILTER_TYPES.contains,
    field_type: FIELD_TYPES.Input,
  },
  {
    label: "Configuration",
    field: "configuration_id",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.ConfigSelect,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "serial_no") || "";
};
