import React from "react";
import { useQuery } from "react-query";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { GisElementTableLoader } from "planning/GisMap/components/GisMapPopups/GisMapPopupLoader";

import { fetchAlertDetails } from "gis_alert/data/alert.actions";
import { parseErrorMessagesWithFields } from "utils/api.utils";

const AlertDetails = ({ alertData, onClose }) => {
  /**
   * Parent:
   *    AlertList
   */
  const { id, problem_name, triggercode } = alertData;
  const { isLoading, data: alertDetails } = useQuery(
    ["alertDetails", id],
    fetchAlertDetails,
    {
      retry: false,
      onError: (err) => {
        parseErrorMessagesWithFields(err);
        onClose();
      },
    }
  );

  if (isLoading) return <GisElementTableLoader />;
  return (
    <Box>
      <Stack
        p={2}
        pb={1}
        direction="row"
        spacing={2}
        width="100%"
        alignItems="center"
        sx={{
          backgroundColor: "primary.main",
          color: "background.default",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      >
        <Typography color="white" flex={1} className="dtl-title" variant="h5">
          {triggercode}: {problem_name}
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider flexItem />
      <Stack
        p={2}
        sx={{
          overflow: "auto",
        }}
      >
        <Typography variant="h6" color="primary">
          Event High Level Definition:
        </Typography>
        <Typography variant="p" mb={3}>
          {alertDetails.definition}
        </Typography>

        <Typography variant="h6" color="primary">
          Action Steps to be taken:
        </Typography>
        <Typography variant="p" sx={{ whiteSpace: "pre-wrap" }}>
          {alertDetails.action_steps}
        </Typography>
      </Stack>
      <Divider flexItem />
    </Box>
  );
};

export default AlertDetails;
