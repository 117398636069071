import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";

import GisMapPopups from "planning/GisMap/components/GisMapPopups/GisMapPopups";
import TableHeader from "../ElementDetailsTable/TableHeader";
import AddAssociationList from "./AddAssociationList";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { setMapState } from "planning/data/planningGis.reducer";

const ShowPossibleAddAssociation = () => {
  const dispatch = useDispatch();

  const { layerKey, data } = useSelector(getPlanningMapState);
  const { elementData, listOfLayers } = data;

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  return (
    <GisMapPopups dragId="ShowPossibleAddAssociation">
      <Box minWidth="350px" maxWidth="550px">
        {/* header */}
        <TableHeader
          title={elementData.name}
          showMinimizeBtn={false}
          handleCloseDetails={handleCloseDetails}
        />
        <AddAssociationList
          parentData={elementData}
          parentLayerKey={layerKey}
          listOfLayers={listOfLayers}
        />
      </Box>
    </GisMapPopups>
  );
};

export default ShowPossibleAddAssociation;
