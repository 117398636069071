import React from "react";

import Select from "react-select";
import { MuiSelectOverride } from "./FormSelect";

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      placeholder=" "
      className={`form-select`}
      classNamePrefix="form-select"
      components={{ Control: MuiSelectOverride }}
    />
  );
};

export default CustomSelect;
