import cloneDeep from "lodash/cloneDeep";
import { CUSTOM_ICONS } from "planning/GisMap/GisIcons";

import {
  FEATURE_TYPES,
  zIndexMapping,
} from "planning/GisMap/layers/common/configuration";
import { LayerKeyMappings } from "planning/GisMap/utils";
import { coordsToLatLongMap, pointCoordsToLatLongMap } from "utils/map.utils";

/**
 * Get trace back tree data and replace coordinates to ready for show on map
 * @param {JSON} traceBackData [{layer_key, coordinates, **element Data}, ...]
 * @returns [{**same data as above, updated coordinates, added featureType}, ...]
 */
export const convertTraceBackServerData = (traceBackData) => {
  let resultList = cloneDeep(traceBackData);

  for (let tbInd = 0; tbInd < resultList.length; tbInd++) {
    const currElem = resultList[tbInd];

    const featureType = LayerKeyMappings[currElem.layer_key]["featureType"];
    // PolyLine / Polygon
    if (featureType === FEATURE_TYPES.POLYLINE) {
      currElem.coordinates = coordsToLatLongMap(currElem.coordinates);
      currElem.center = pointCoordsToLatLongMap(currElem.center);
    }
    // Point gis layer
    else if (featureType === FEATURE_TYPES.POINT) {
      currElem.coordinates = pointCoordsToLatLongMap(currElem.coordinates);
    }
    currElem.featureType = featureType;
  }

  return resultList;
};

export const getTraceBackElementViewOptions = (elementData) => {
  if (elementData.featureType == FEATURE_TYPES.POINT) {
    return {
      zIndex: zIndexMapping.highlightedTraceback,
      iconType: CUSTOM_ICONS.red_marker,
    };
  } else if (elementData.featureType === FEATURE_TYPES.POLYLINE) {
    return {
      zIndex: zIndexMapping.highlightedTraceback,
      iconType: CUSTOM_ICONS.red_marker,
      strokeColor: "#d85b53",
      strokeOpacity: 0.8,
      strokeWeight: 5,
      fillColor: "#d85b53",
      fillOpacity: 1,
      clickable: false,
      draggable: false,
      editable: false,
      radius: 30000,
    };
  }
};
