import React, { useCallback } from "react";

import get from "lodash/get";
import includes from "lodash/includes";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import FormHelperText from "@mui/material/FormHelperText";

import TracebackDetails from "./TracebackDetails";
import { FormSelect, FormChipSelect } from "components/common/FormFields";

import useLogicalConnectionForm from "./useTracebackForm";

import { LC_TRACE_TYPE } from "planning/data/planning.constants";

const filterOptions = (candidate, input) => {
  if (input) {
    // filter via label or unique id, case insensetive
    const label = get(candidate, "data.name", "");
    const uniqueId = get(candidate, "data.unique_id", "");
    return (
      includes(label.toLowerCase(), input.toLowerCase()) ||
      includes(uniqueId.toLowerCase(), input.toLowerCase())
    );
  }
  return true;
};

const TracebackForm = () => {
  /**
   * Parent:
   *    TracebackPopup
   */

  const {
    control,
    errors,
    showDownstreamForm,
    showUpstreamForm,
    showFormDivider,
    from_layer,
    from_element,
    to_layer,
    to_element,
    layerCofigs,
    layersLoading,
    fromElementList,
    fromElementListLoading,
    fromPortList,
    fromPortListLoading,
    toElementList,
    toElementListLoading,
    toPortList,
    toPortListLoading,
    traceBackLoading,
    traceBackFetched,
    traceBackError,
    traceBackTableEntries,
    traceBackTree,
    handleSubmit,
    onFormSubmit,
    handleFromLayerChange,
    handleFromElementChange,
    handleToLayerChange,
    handleToElementChange,
  } = useLogicalConnectionForm();

  const renderCustomLabel = useCallback((element) => {
    return `${get(element, "name", "")} ( ${get(element, "unique_id", "")} )`;
  }, []);

  return (
    <Stack
      sx={{ margin: "0 auto", padding: 2, maxHeight: "72vh", overflow: "auto" }}
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <FormChipSelect
        label={"Select Trace Type"}
        name={"traceType"}
        control={control}
        rules={{
          required: "Trace Type is required",
        }}
        options={LC_TRACE_TYPE}
        required={true}
        error={!!get(errors, ["traceType"])}
        helperText={get(errors, ["traceType", "message"], "")}
        color="primary"
      />
      <Stack spacing={2} direction="row">
        {showDownstreamForm ? (
          <Stack spacing={2} flex={1}>
            <Typography variant="h6" color="primary" pl={1.2}>
              From Element
            </Typography>
            <FormSelect
              label="Select Layer"
              name="from_layer"
              control={control}
              rules={{
                required: "Layer is required",
              }}
              required={true}
              options={layerCofigs}
              error={!!get(errors, ["from_layer"])}
              helperText={get(errors, ["from_layer", "message"], "")}
              isLoading={layersLoading}
              labelKey="name"
              valueKey="layer_key"
              onChange={handleFromLayerChange}
            />
            {from_layer ? (
              <FormSelect
                label="Select Element"
                name="from_element"
                control={control}
                rules={{
                  required: "Element is required",
                }}
                required={true}
                options={fromElementList}
                error={!!get(errors, ["from_element"])}
                helperText={get(errors, ["from_element", "message"], "")}
                isLoading={fromElementListLoading}
                labelKey="name"
                valueKey="id"
                onChange={handleFromElementChange}
                formatOptionLabel={renderCustomLabel}
                filterOption={filterOptions}
              />
            ) : null}
            {from_layer && from_element ? (
              <FormSelect
                label="Select Port"
                name="from_port"
                control={control}
                options={fromPortList}
                error={!!get(errors, ["from_port"])}
                helperText={get(errors, ["from_port", "message"], "")}
                isLoading={fromPortListLoading}
                isClearable
                labelKey="name"
                valueKey="id"
              />
            ) : null}
          </Stack>
        ) : null}
        {showFormDivider ? <Divider orientation="vertical" flexItem /> : null}
        {showUpstreamForm ? (
          <Stack spacing={2} flex={1}>
            <Typography variant="h6" color="primary">
              To Element
            </Typography>
            <FormSelect
              label="Select Layer"
              name="to_layer"
              control={control}
              rules={{
                required: "Layer is required",
              }}
              required={true}
              options={layerCofigs}
              error={!!get(errors, ["to_layer"])}
              helperText={get(errors, ["to_layer", "message"], "")}
              isLoading={layersLoading}
              labelKey="name"
              valueKey="layer_key"
              onChange={handleToLayerChange}
            />
            {to_layer ? (
              <FormSelect
                label="Select Element"
                name="to_element"
                control={control}
                rules={{
                  required: "Element is required",
                }}
                required={true}
                options={toElementList}
                error={!!get(errors, ["to_element"])}
                helperText={get(errors, ["to_element", "message"], "")}
                isLoading={toElementListLoading}
                labelKey="name"
                valueKey="id"
                onChange={handleToElementChange}
                formatOptionLabel={renderCustomLabel}
                filterOption={filterOptions}
              />
            ) : null}
            {to_layer && to_element ? (
              <FormSelect
                label="Select Port"
                name="to_port"
                control={control}
                options={toPortList}
                error={!!get(errors, ["to_port"])}
                helperText={get(errors, ["to_port", "message"], "")}
                isLoading={toPortListLoading}
                isClearable
                labelKey="name"
                valueKey="id"
              />
            ) : null}
          </Stack>
        ) : null}
      </Stack>

      <Stack p={1} spacing={3} direction="row" justifyContent="flex-end">
        <LoadingButton
          sx={{ minWidth: "10em" }}
          variant="contained"
          disableElevation
          color="success"
          type="submit"
          loading={traceBackLoading}
        >
          Submit
        </LoadingButton>
      </Stack>

      {traceBackFetched ? <Divider /> : null}

      <TracebackDetails
        traceBackFetched={traceBackFetched}
        traceBackTableEntries={traceBackTableEntries}
        traceBackTree={traceBackTree}
        traceBackError={traceBackError}
      />
    </Stack>
  );
};

export default TracebackForm;
