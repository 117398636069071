import Api from "utils/api.utils";
import {
  apiGetAttrConfig,
  apiGetCTGroupTagList,
  apiGetCTTemplateTagList,
  apiGetElementGenericFieldsLastData,
  apiGetElementInterfaceFieldsLastData,
  apiPostAttrTimeseries,
  apiPostElementDataRefresh,
} from "utils/url.constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchGroupTagList = async (tags) => {
  const res = await Api.get(apiGetCTGroupTagList(), { tags });
  return res.data;
};

export const fetchTemplateTagList = async (tags) => {
  const res = await Api.get(apiGetCTTemplateTagList(), { tags });
  return res.data;
};

export const fetchElementGenericFieldsLastData = async ({ queryKey }) => {
  const [_, layerKey, elementId] = queryKey;
  const res = await Api.get(
    apiGetElementGenericFieldsLastData(layerKey, elementId)
  );
  return res.data;
};

export const fetchAttrConfig = async ({ queryKey }) => {
  const [_, attrType, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetAttrConfig(attrType, layerKey, elementId));
  return res.data;
};

export const fetchElementInterfaceFieldsLastData = async ({
  layerKey,
  elementId,
  interfaceKey,
  dataType = "interface",
}) => {
  const res = await Api.get(
    apiGetElementInterfaceFieldsLastData(
      layerKey,
      elementId,
      interfaceKey,
      dataType
    )
  );
  return res.data;
};

export const refreshElementAttrData = async ({
  layerKey,
  elementId,
  refreshType,
}) => {
  const res = await Api.post(apiPostElementDataRefresh(layerKey, elementId), {
    refresh_type: refreshType,
  });
  return res.data;
};

export const fetchAttrTimeseries = async (data) => {
  const res = await Api.post(apiPostAttrTimeseries(), data);
  return res.data;
};

export const fetchAttrTimeseriesThunk = createAsyncThunk(
  "catv/fetchAttrTimeseries",
  fetchAttrTimeseries
);
