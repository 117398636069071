import React from "react";

import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const COMMION_CELL_STYLE_PROPERTIES = {
  cellStyle: {
    whiteSpace: "normal",
    lineHeight: "1.8em",
    // userSelect: "text",
  },
  autoHeight: true,
};

const CIRCUIT_ID_CELL_STYLE_PROPERTIES = {
  cellStyle: {
    whiteSpace: "normal",
    lineHeight: "1.8em",
    // userSelect: "text",
    wordBreak: "break-all",
  },
  autoHeight: true,
};

export const getAlertTableConfigs = (
  alertStatus,
  onGetAlertDetails,
  onAlertAck,
  viewAs = "normal" // normal || compact || FavAlertListWidget
) => {
  if (viewAs === "FavAlertListWidget") {
    return [
      {
        field: "problem_start_timestamp",
        headerName: "Start Time",
        minWidth: 180,
        ...COMMION_CELL_STYLE_PROPERTIES,
        valueFormatter: (data) => {
          if (data.value) {
            const currDate = new Date(data.value);
            return format(currDate, "dd-MM-yyyy HH:mm:ss");
          }
          return "";
        },
      },
      {
        field: "severity_display",
        headerName: "Severity",
        cellRenderer: StatusCell,
        minWidth: 130,
      },
      {
        field: "host_name",
        headerName: "Device name",
        minWidth: 200,
        ...COMMION_CELL_STYLE_PROPERTIES,
      },
      {
        field: "problem_name",
        headerName: "Problem name",
        minWidth: 250,
        ...COMMION_CELL_STYLE_PROPERTIES,
      },
      {
        field: "problem_start_timestamp",
        headerName: "Duration",
        minWidth: 150,
        ...COMMION_CELL_STYLE_PROPERTIES,
        // auto calculate duration from start time
        valueFormatter: (data) => {
          if (data.value) {
            return formatDistanceToNow(new Date(data.value), {
              addSuffix: false,
            });
          }
          return "";
        },
      },
    ];
  }

  let commonFields = [
    {
      field: "problem_start_timestamp",
      headerName: "Start Time",
      minWidth: 180,
      ...COMMION_CELL_STYLE_PROPERTIES,
      valueFormatter: (data) => {
        if (data.value) {
          const currDate = new Date(data.value);
          return format(currDate, "dd-MM-yyyy HH:mm:ss");
        }
        return "";
      },
    },
    {
      field: "severity_display",
      headerName: "Severity",
      cellRenderer: StatusCell,
      minWidth: 130,
    },
    {
      field: "host_name",
      headerName: "Device name",
      minWidth: 200,
      ...COMMION_CELL_STYLE_PROPERTIES,
    },
    {
      field: "host_ip",
      headerName: "Device IP",
      minWidth: 126,
      cellStyle: {
        userSelect: "text",
      },
    },
    {
      field: "problem_name",
      headerName: "Problem name",
      minWidth: 250,
      ...COMMION_CELL_STYLE_PROPERTIES,
    },
    {
      field: "circuit_id",
      headerName: "Circuit ID",
      minWidth: 200,
      ...CIRCUIT_ID_CELL_STYLE_PROPERTIES,
    },
    {
      field: "problem_operational_data",
      headerName: "Trigger Val. Problem",
      minWidth: 200,
      ...COMMION_CELL_STYLE_PROPERTIES,
    },
    {
      field: "regions",
      headerName: "Regions",
      minWidth: 140,
      // NOW WE ARE DIRECTLY SENDING REGION NAMES, only use this if backend sends region objects which has issue with search
      // valueFormatter: (data) => {
      //   if (size(data.value)) {
      //     // value is list of [{id, name}, ...] regions
      //     const region_name_list = data.value.map((reg) => reg.name);
      //     return region_name_list.join(" ,");
      //   }
      //   return "";
      // },
    },
    {
      field: "action_count",
      headerName: "Ack",
      minWidth: 130,
      cellRenderer: AckCell,
      cellRendererParams: {
        onAlertAck,
      },
    },
    {
      field: "assignee",
      headerName: "Assignee",
      minWidth: 120,
      ...COMMION_CELL_STYLE_PROPERTIES,
    },
    {
      field: "is_telco",
      headerName: "Escalation.Matrix",
      minWidth: 180,
      valueFormatter: (data) => {
        return data.value ? "Telco" : "Non-Telco";
      },
      ...COMMION_CELL_STYLE_PROPERTIES,
    },
    {
      field: "escalation_status_display",
      headerName: "Escalation",
      ...CIRCUIT_ID_CELL_STYLE_PROPERTIES,
      minWidth: 150,
    },
  ];

  if (viewAs === "normal") {
    commonFields.push({
      headerName: "Info",
      field: "id",
      minWidth: 100,
      cellRenderer: ActionCell,
      cellRendererParams: {
        onGetAlertDetails,
      },
    });
  }

  // process based on status
  if (alertStatus === "P") {
    // for status "PROBLEM"
    let problemFields = [
      ...commonFields,
      {
        field: "problem_start_timestamp",
        headerName: "Duration",
        minWidth: 150,
        ...COMMION_CELL_STYLE_PROPERTIES,
        // auto calculate duration from start time
        valueFormatter: (data) => {
          if (data.value) {
            return formatDistanceToNow(new Date(data.value), {
              addSuffix: false,
            });
          }
          return "";
        },
      },
      {
        field: "triggercode",
        headerName: "Trigger Code",
        minWidth: 150,
        cellStyle: {
          userSelect: "text",
        },
      },
    ];

    if (viewAs === "normal") {
      problemFields.push({
        headerName: "ID",
        field: "id",
        minWidth: 80,
        cellStyle: {
          userSelect: "text",
        },
      });
    }

    return problemFields;
  }
  //
  else if (alertStatus === "R" || alertStatus === "C") {
    let resolvedFields = [
      ...commonFields,
      {
        field: "resolved_operational_data",
        headerName: "Trigger Val. Resolved",
        minWidth: 140,
        ...COMMION_CELL_STYLE_PROPERTIES,
      },
      {
        field: "problem_resolved_duration",
        headerName: "Duration",
        minWidth: 150,
        ...COMMION_CELL_STYLE_PROPERTIES,
      },
      {
        field: "problem_resolved_timestamp",
        headerName: "Recovery Time",
        minWidth: 180,
        ...COMMION_CELL_STYLE_PROPERTIES,
        valueFormatter: (data) => {
          if (data.value) {
            const currDate = new Date(data.value);
            return format(currDate, "dd-MM-yyyy HH:mm:ss");
          }
          return "";
        },
      },
      {
        field: "triggercode",
        headerName: "Trigger Code",
        minWidth: 150,
        cellStyle: {
          userSelect: "text",
        },
      },
    ];

    if (viewAs === "normal") {
      resolvedFields.push({
        headerName: "ID",
        field: "id",
        minWidth: 80,
        cellStyle: {
          userSelect: "text",
        },
      });
    }

    return resolvedFields;
  }
};

/**
 * Render Status Cell with colors
 */
const StatusCell = (props) => {
  let bgColor, label;
  if (props.value === "Information") {
    bgColor = "#799cf8";
    label = "Information";
  }
  //
  else if (props.value === "Warning") {
    bgColor = "#ffc56d";
    label = "Warning";
  }
  //
  else if (props.value === "High") {
    bgColor = "#fe8c3e";
    label = "High";
  }
  //
  else if (props.value === "Critical") {
    bgColor = "#f73837";
    label = "Critical";
  }
  //
  else if (props.value === "Disaster") {
    bgColor = "#7c1c1c";
    label = "Disaster";
  }

  return (
    <Chip
      size="small"
      label={label}
      sx={{
        minWidth: "86px",
        backgroundColor: bgColor,
        color: "#fff",
      }}
    />
  );
};

const BooleanCell = (props) => {
  const boolVal = props.value;
  return (
    <Stack>
      <IconButton color={boolVal ? "success" : "error"}>
        {boolVal ? <CheckIcon /> : <ClearIcon />}
      </IconButton>
    </Stack>
  );
};

const AckCell = (props) => {
  const actionCount = props.value;
  return (
    <Stack>
      <Button
        sx={{ marginY: 1 }}
        variant="outlined"
        color={actionCount ? "success" : "error"}
        onClick={props.onAlertAck(props.data.id)}
      >
        {actionCount} ACK
      </Button>
    </Stack>
  );
};

const ActionCell = ({ data, onGetAlertDetails }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Details">
        <IconButton
          aria-label="view"
          color="primary"
          onClick={onGetAlertDetails(data)}
        >
          <HelpCenterIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
