import React, { useCallback, useState } from "react";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import GoogleIcon from "@mui/icons-material/Google";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import GoogleAutoComplete from "./GoogleAutoComplete";
import LatLongSearch from "./LatLongSearch";

/**
 * Parent:
 *    GisMap
 */
const MapSearchbox = () => {
  // 1 = Google | 2 = lat, long
  const [searchType, setSearchType] = useState(1);
  // menu render logic
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSearchTypeChange = useCallback(
    (newSearchType) => () => {
      setSearchType(newSearchType);
      handleClose();
    },
    []
  );

  return (
    <Paper
      elevation={2}
      sx={{
        position: "relative",
        width: "30%",
        height: "42px",
        marginLeft: "auto",
        marginRight: "64px",
        marginTop: "10px",
        paddingLeft: 1,
        paddingRight: 0.5,
        // this required to give Autocomplete from google div full h+w
        "&>div": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Button
        id="search-type-select-btn"
        variant="contained"
        color="primary"
        size="small"
        startIcon={searchType === 2 ? <ShareLocationIcon /> : <GoogleIcon />}
        sx={{
          position: "absolute",
          left: "-64px",
          width: "64px",
          height: "42px",
        }}
        aria-controls={open ? "search-type-select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="search-type-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "search-type-select-btn",
        }}
      >
        <MenuItem onClick={handleSearchTypeChange(1)}>Google</MenuItem>
        <MenuItem onClick={handleSearchTypeChange(2)}>Location</MenuItem>
      </Menu>

      {searchType === 2 ? <LatLongSearch /> : <GoogleAutoComplete />}
    </Paper>
  );
};

export default MapSearchbox;
