import React from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  getGraphChartConfig,
  getGraphFieldData,
} from "planning/data/catv/catv.selector";
import { find, get } from "lodash";

function FieldDataTable() {
  // {}
  const fieldData = useSelector(getGraphFieldData);
  // series[0].data
  const chartConfig = useSelector(getGraphChartConfig);

  let content = [];
  for (const fieldKey in fieldData) {
    if (Object.hasOwnProperty.call(fieldData, fieldKey)) {
      const { config, data } = fieldData[fieldKey];
      // get chartConfig data
      const chartSeries = find(chartConfig.series, ["name", config.label]);
      const seriesData = chartSeries?.data;

      content.push(
        <Box key={config.key}>
          <Typography mt={5} mb={3} variant="h6" textAlign="center">
            {config.label}
          </Typography>

          <TableContainer
            component={Paper}
            sx={{ maxHeight: "35em", borderBottom: "1px solid silver" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell align="right">
                    {config.unit ? config.unit : "Value"}
                  </TableCell>
                  <TableCell>Comp Time</TableCell>
                  <TableCell align="right">Comp Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, ind) => {
                  const currSerD = seriesData[ind] || null;
                  // check if processed data is different than server data
                  let isDataSame = false;
                  if (currSerD) isDataSame = row.value === currSerD.y;

                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {format(new Date(row.timestamp), "dd/MM/yy HH:mm:ss")}
                      </TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                      <TableCell component="th" scope="row">
                        {currSerD
                          ? format(new Date(currSerD.x), "dd/MM/yy HH:mm:ss")
                          : "NULL"}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ color: isDataSame ? "blue" : "red" }}
                      >
                        {currSerD ? currSerD.y : "--"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    }
  }
  return <Box>{content}</Box>;
}

export default FieldDataTable;
