import {
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";

import { default as Icon } from "assets/markers/building_view.svg";
import { default as EditIcon } from "assets/markers/building_pin.svg";

import get from "lodash/get";
import isNumber from "lodash/isNumber";

import { FIELD_TYPES } from "components/common/DynamicForm";
import { SURVEY_TAG_LIST } from "utils/constant";

export const LAYER_KEY = "p_survey_building";
export const PRE_UID = "SB";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;
export const SHOW_LABELS_AFTER_ZOOM = 18;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
  labelOrigin: { x: 10, y: -8 },
});

export const BUILDING_CATEGORY_OPTIONS = [
  { value: "M", label: "MDU" },
  { value: "S", label: "SDU" },
];

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  tags: "",
  category: "M",
  floors: 0,
  house_per_floor: 0,
  total_home_pass: 0,
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {};

export const getElementTableFields = () => [
  { label: "Boundary Name", field: "boundary_name", type: "simple" },
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "Address", field: "address", type: "simple" },
  { label: "Tags", field: "tags", type: "simple" },
  { label: "Categories", field: "category_display", type: "simple" },
  { label: "Floors", field: "floors", type: "simple" },
  { label: "House Per Floor", field: "house_per_floor", type: "simple" },
  { label: "Total Home Pass", field: "total_home_pass", type: "simple" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "add_associations",
    data: ["p_pop", "p_spop", "p_dp", "p_fdc", "p_splitter", "p_onu"],
  },
  {
    control: "association_list",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    label: "Tags",
    field: "tags",
    filter_type: FILTER_TYPES.multi_choice,
    field_type: FIELD_TYPES.SelectMulti,
    choices: SURVEY_TAG_LIST,
  },
  {
    label: "Categories",
    field: "category",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Select,
    choices: BUILDING_CATEGORY_OPTIONS,
  },
  {
    label: "Home Pass",
    field: "total_home_pass",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.NumericInput,
    hasOperator: true,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  const homePass = get(element, "total_home_pass");
  return isNumber(homePass) ? String(homePass) : "";
};
