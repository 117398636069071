import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { format } from "date-fns";

import LoadingButton from "@mui/lab/LoadingButton";
import GetAppIcon from "@mui/icons-material/GetApp";

import { exportTraceBack } from "planning/data/traceback/traceback.services";
import { addNotification } from "redux/reducers/notification.reducer";

const TraceTableDownload = ({ traceBackTableEntries }) => {
  /**
   * Parent:
   *    TracebackDetails
   */
  const dispatch = useDispatch();
  const { mutate: exportMutation, isLoading: loadingExport } = useMutation(
    exportTraceBack,
    {
      onSuccess: (res) => {
        const report_name =
          "trace" +
          "_" +
          format(new Date(), "dd/MM/yyyy") +
          "_" +
          format(new Date(), "hh:mm");

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${report_name}.xlsx`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Error",
            text: err.message,
          })
        );
      },
    }
  );

  const handleDownloadClick = useCallback(() => {
    exportMutation({
      download_type: "excel",
      download_data: traceBackTableEntries,
    });
  }, [traceBackTableEntries]);

  return (
    <LoadingButton
      color="secondary"
      startIcon={<GetAppIcon />}
      loading={loadingExport}
      onClick={handleDownloadClick}
      sx={{ ml: 1 }}
    >
      Excel
    </LoadingButton>
  );
};

export default TraceTableDownload;
