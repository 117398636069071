import React, { useCallback, useState } from "react";
import { Controller } from "react-hook-form";

import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { DateTimePicker } from "react-datetime-range-super-picker";
import { getDateValue } from "./field.utils";

export const CustomDateTimePicker = ({
  value,
  label,
  errors,
  required,
  helperText,
  onChange,
  format = undefined,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.target);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const dateDisplayValue = getDateValue(value, true);
  return (
    <Box>
      <Box onClick={handleClick}>
        <TextField
          className="full-width"
          InputLabelProps={{
            required: required,
            shrink: !!value,
          }}
          error={errors}
          label={label}
          helperText={helperText}
          value={dateDisplayValue}
        />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableAutoFocus
        disableEnforceFocus
      >
        <DateTimePicker
          date={value ? new Date(value) : undefined}
          format={format}
          onDateTimeUpdate={(dateRes) => {
            onChange(!!format ? dateRes.formatted : dateRes.date.date);
            handleClose();
          }}
          {...rest}
        />
      </Popover>
    </Box>
  );
};
