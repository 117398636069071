import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import get from "lodash/get";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import TicketListDummyLoader from "ticket/components/TicketListDummyLoader";
import AgAlertList from "./AgAlertList";
import AlertBulkActionUpload from "./AlertBulkActionUpload";

import { fetchAlertList } from "gis_alert/data/alert.actions";

import { checkUserPermission } from "redux/selectors/auth.selectors";

import "./alert-list.scss";

const PAGE_LIMIT = 100;

const AlertList = () => {
  /**
   * Parent:
   *    AlertAdminPage
   */
  const gridRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();
  const alertStatus = searchParams.get("status") || "P";
  const canUserUploadActions = useSelector(
    checkUserPermission("alert_action_upload")
  );

  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [pageOffset, setPageOffset] = useState(0);

  const { isLoading, data: alertListData } = useQuery(
    ["alertList", alertStatus, pageLimit, pageOffset],
    fetchAlertList,
    {
      refetchInterval: 60000, // 60 sec
    }
  );

  const dataList = get(alertListData, "results");
  const totalRecords = get(alertListData, "count");
  const nextUrl = get(alertListData, "next");
  const previousUrl = get(alertListData, "previous");

  const handleAlertStatusUpdate = useCallback(
    (newStatus) => () => {
      setSearchParams({ status: newStatus });
      setPageLimit(PAGE_LIMIT);
      setPageOffset(0);
    },
    []
  );

  const handleNextClick = () => {
    if (!nextUrl) return;
    setPageOffset(pageOffset + pageLimit);
    gridRef.current.clearFilters();
  };

  const handlePreviousClick = () => {
    if (!previousUrl) return;
    setPageOffset(pageOffset - pageLimit);
    gridRef.current.clearFilters();
  };

  return (
    <Stack height="100%" divider={<Divider flexItem />}>
      <Stack p={2} direction="row" spacing={2} width="100%" alignItems="center">
        <Typography flex={1} className="dtl-title" color="primary" variant="h5">
          Alerts :
        </Typography>

        <Stack spacing={1} direction="row">
          <LoadingButton
            variant="outlined"
            disabled={alertStatus === "P"}
            loading={alertStatus === "P" && isLoading}
            onClick={handleAlertStatusUpdate("P")}
          >
            Problem
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            disabled={alertStatus === "R"}
            loading={alertStatus === "R" && isLoading}
            onClick={handleAlertStatusUpdate("R")}
          >
            Resolved
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            disabled={alertStatus === "C"}
            loading={alertStatus === "C" && isLoading}
            onClick={handleAlertStatusUpdate("C")}
          >
            Closed
          </LoadingButton>

          {canUserUploadActions ? <AlertBulkActionUpload /> : null}
        </Stack>
      </Stack>

      {isLoading ? (
        <TicketListDummyLoader />
      ) : (
        <Box className="ag-pagination-list">
          <AgAlertList
            ref={gridRef}
            viewAs="normal"
            alertStatus={alertStatus}
            alertList={dataList}
            height="calc(100% - 56px)"
            pagination={false}
          />
          <Box className="ag-custom-pagination">
            <Box></Box>
            <Box className="page-navigation-wrapper">
              <Box className="page-info">
                {pageOffset} To {pageLimit + pageOffset} of{" "}
                <b>{totalRecords}</b>
              </Box>
              <Box className="page-navigation-actions">
                <Box className="action">
                  <ChevronLeftIcon onClick={handlePreviousClick} />
                </Box>
                <Box className="action">
                  Page {pageOffset ? Math.ceil(pageOffset / pageLimit) + 1 : 1}{" "}
                  / {Math.ceil(totalRecords / pageLimit)}
                </Box>
                <Box className="action">
                  <ChevronRightIcon onClick={handleNextClick} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export default AlertList;
