import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";

import Box from "@mui/material/Box";

import GisMapPopups from "../GisMapPopups";
import TableHeader from "../ElementDetailsTable/TableHeader";
import SplicingContainer from "./SplicingContainer";
import SplicingContainerLoader from "./SplicingContainerLoader";

import { getPlanningMapStateData } from "planning/data/planningGis.selectors";
import { setMapState } from "planning/data/planningGis.reducer";
import {
  resetSelectedPorts,
  setSplicingElements,
} from "planning/data/splicing.reducer";
import { fetchElementPortSplicingDetails } from "planning/data/port.services";

const SplicingView = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const splicingPostData = useSelector(getPlanningMapStateData);

  const [minimized, setMinimized] = useState(false);
  // mutation isLoading is not working as expected due to cache so use state instead
  const [isSplicingApiLoading, setSplicingApiLoading] = useState(true);

  const { mutate: getElementSplicingData } = useMutation(
    fetchElementPortSplicingDetails,
    {
      onSuccess: (res) => {
        dispatch(
          setSplicingElements({
            elemPortData: res,
            splicingData: splicingPostData,
          })
        );
        setSplicingApiLoading(false);
      },
    }
  );

  useEffect(() => {
    setSplicingApiLoading(true);
    getElementSplicingData(splicingPostData);
    // clear port selection on window close
    return () => dispatch(resetSelectedPorts());
  }, []);

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handlePopupMinimize = useCallback(() => {
    setMinimized((val) => !val);
  }, []);

  return (
    <GisMapPopups dragId="SplicingView">
      <Box minWidth="350px">
        <TableHeader
          title="Splicing View"
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
      </Box>
      {minimized ? null : isSplicingApiLoading ? (
        <SplicingContainerLoader />
      ) : (
        <SplicingContainer />
      )}
    </GisMapPopups>
  );
};

export default SplicingView;
