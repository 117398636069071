import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import filter from "lodash/filter";
import includes from "lodash/includes";
import get from "lodash/get";
import orderBy from "lodash/orderBy";

import { fetchLayerList } from "planning/data/actionBar.services";
import { fetchElementPortDetails } from "planning/data/port.services";

import {
  fetchTracebackLayerDataThunk,
  generateTraceBackThunk,
} from "planning/data/traceback/traceback.services";
import {
  resetTracebackData,
  setTracebackForm,
} from "planning/data/traceback/traceback.reducer";
import {
  getTracebackAllLayersData,
  getTracebackAllLayersNetworkStatus,
  getTracebackData,
  getTracebackFormData,
} from "planning/data/traceback/traceback.selectors";
import { getSelectedRegionIds } from "planning/data/planningState.selectors";

import { LC_LAYERS, LC_TRACE_TYPE } from "planning/data/planning.constants";

const TracebackPopup = () => {
  const dispatch = useDispatch();

  const regionIdList = useSelector(getSelectedRegionIds);
  // get initial form data from planning gis reducer
  const tracebackFormData = useSelector(getTracebackFormData);
  const allLayerData = useSelector(getTracebackAllLayersData);
  const layerNetworkState = useSelector(getTracebackAllLayersNetworkStatus);
  const tracebackData = useSelector(getTracebackData);
  const {
    isLoading: traceBackLoading,
    isFetched: traceBackFetched,
    error: traceBackError,
    table_entries,
    tree,
  } = tracebackData;

  const {
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: tracebackFormData,
    shouldUnregister: true,
  });

  // watch all fields and render components as per values, un-used variable for maintain sequence
  const allWatchFields = watch([
    "traceType",
    "from_layer",
    "from_element",
    "from_port",
    "to_layer",
    "to_element",
    "to_port",
  ]);
  const [
    traceType,
    from_layer,
    from_element,
    from_port,
    to_layer,
    to_element,
    to_port,
  ] = allWatchFields;

  const { isLoading: layersLoading, data: layerCofigs = [] } = useQuery(
    "planningLayerConfigs",
    fetchLayerList,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  // filter some of layers from all layers
  const availableLayers = useMemo(() => {
    return filter(
      layerCofigs,
      (layer) => !!includes(LC_LAYERS, layer.layer_key)
    );
  }, [layerCofigs]);

  // from & to port separate fetch using their layerKey and element id
  const { data: fromPortList, isLoading: fromPortListLoading } = useQuery(
    ["fromElementPortDetails", from_layer, from_element],
    fetchElementPortDetails,
    {
      enabled: Boolean(from_layer && from_element),
      select: (res) => {
        return orderBy(filter(res, ["is_input", false]), ["sr_no"], ["asc"]);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: toPortList, isLoading: toPortListLoading } = useQuery(
    ["toElementPortDetails", to_layer, to_element],
    fetchElementPortDetails,
    {
      enabled: Boolean(to_layer && to_element),
      select: (res) => {
        return orderBy(filter(res, ["is_input", true]), ["sr_no"], ["asc"]);
      },
      refetchOnWindowFocus: false,
    }
  );

  // reset values when from layer change
  const handleFromLayerChange = useCallback(
    (from_layer) => {
      setValue("from_element", null);
      setValue("from_port", null);
      const isFetched = !!get(layerNetworkState, [from_layer, "isFetched"]);
      if (!isFetched) {
        dispatch(
          fetchTracebackLayerDataThunk({
            regionIdList,
            layerKey: from_layer,
          })
        );
      }
    },
    [regionIdList, layerNetworkState]
  );

  // reset values when from element change
  const handleFromElementChange = useCallback(() => {
    setValue("from_port", null);
  }, []);

  // reset values when to layer change
  const handleToLayerChange = useCallback(
    (to_layer) => {
      setValue("to_element", null);
      setValue("to_port", null);
      const isFetched = !!get(layerNetworkState, [to_layer, "isFetched"]);
      if (!isFetched) {
        dispatch(
          fetchTracebackLayerDataThunk({
            regionIdList,
            layerKey: to_layer,
          })
        );
      }
    },
    [regionIdList]
  );

  // reset values when to element change
  const handleToElementChange = useCallback(() => {
    setValue("to_port", null);
  }, []);

  // transform form data into backend data shape and call mutation
  const onFormSubmit = useCallback((formData) => {
    dispatch(setTracebackForm(formData));
    dispatch(resetTracebackData());
    dispatch(
      generateTraceBackThunk({
        traceType: formData.traceType,
        traceElementData: {
          from_element_id: formData.from_element,
          from_element_layer_key: formData.from_layer,
          from_element_port_id: formData.from_port,
          to_element_id: formData.to_element,
          to_element_layer_key: formData.to_layer,
          to_element_port_id: formData.to_port,
        },
      })
    );
  }, []);

  // Show two forms if trace type is From - To
  const showFormDivider = traceType === LC_TRACE_TYPE[2].value;
  // show from if trace type Downstream
  const showDownstreamForm =
    traceType === LC_TRACE_TYPE[0].value || showFormDivider;
  // show to if trace type Upstream
  const showUpstreamForm =
    traceType === LC_TRACE_TYPE[1].value || showFormDivider;

  return {
    control,
    errors,
    showDownstreamForm,
    showUpstreamForm,
    showFormDivider,
    from_layer,
    from_element,
    from_port,
    to_layer,
    to_element,
    to_port,
    layerCofigs: availableLayers,
    layersLoading,
    fromElementList: get(allLayerData, from_layer) || [],
    fromElementListLoading: get(
      layerNetworkState,
      [from_layer, "isLoading"],
      false
    ),
    fromPortList,
    fromPortListLoading,
    toElementList: get(allLayerData, to_layer) || [],
    toElementListLoading: get(
      layerNetworkState,
      [from_layer, "isLoading"],
      false
    ),
    toPortList,
    toPortListLoading,
    traceBackLoading: traceBackLoading,
    traceBackFetched: traceBackFetched,
    traceBackError: traceBackError,
    traceBackTableEntries: table_entries,
    traceBackTree: tree,
    handleSubmit,
    onFormSubmit,
    handleFromLayerChange,
    handleFromElementChange,
    handleToLayerChange,
    handleToElementChange,
  };
};

export default TracebackPopup;
