import React, { useCallback, useState, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { useQuery } from "react-query";

import findIndex from "lodash/findIndex";
import differenceBy from "lodash/differenceBy";
import has from "lodash/has";
import get from "lodash/get";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import CustomSelect from "components/common/FormFields/CustomSelect";
import {
  FormSelect,
  FormDatePicker,
  FormDateTimePicker,
  FormCheckbox,
} from "components/common/FormFields";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { LayerKeyMappings } from "planning/GisMap/utils";
import { OPERATOR_OPTIONS } from "planning/GisMap/layers/common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";
import { addNotification } from "redux/reducers/notification.reducer";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { onFilterSubmit } from "planning/data/filters.actions";
import { fetchLayerListDetails } from "planning/data/actionBar.services";
import { onFetchLayerListDetailsSuccess } from "planning/data/planning.actions";
import { getSingleLayerConfigurationList } from "planning/data/planningState.selectors";

const FilterForm = ({ closeFilterForm }) => {
  /**
   * Parent:
   *    FilterPopup
   */
  const { layerKey, data: formInitData } = useSelector(getPlanningMapState);
  const configurationOptions = useSelector(
    getSingleLayerConfigurationList(layerKey)
  );

  const layerFilterFormConfigs = LayerKeyMappings[layerKey]["filterFormConfig"];
  const hasConfigurationField =
    findIndex(layerFilterFormConfigs, ["field", "configuration_id"]) !== -1;

  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: formInitData || { filters: [] },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filters",
  });

  const [filters] = watch(["filters"]);

  const { isLoading } = useQuery(
    "planningLayerConfigsDetails",
    fetchLayerListDetails,
    {
      staleTime: Infinity,
      enabled: hasConfigurationField,
      onSuccess: (layerConfData) => {
        dispatch(onFetchLayerListDetailsSuccess(layerConfData));
      },
    }
  );

  const onFormSubmit = useCallback(
    (formData) => {
      dispatch(onFilterSubmit(layerKey, formData.filters));
      closeFilterForm();
    },
    [layerKey]
  );

  const handleAddFilter = useCallback(() => {
    const filterIndex = findIndex(filters, ["field", selectedFilter?.field]);
    if (filterIndex === -1) {
      // create append data
      let appendData = { ...selectedFilter, value: "" };
      if (selectedFilter?.hasOperator) {
        appendData["operator"] = "";
      }
      append(appendData);
      setSelectedFilter(null);
    } else {
      // fire notification
      dispatch(
        addNotification({
          type: NOTIFICATION_TYPE.WARNING,
          title: "Invalid Filter Add",
          text: "This filter is already selected.",
        })
      );
    }
  }, [append, selectedFilter, filters]);

  const handleRemove = useCallback(
    (index) => () => {
      remove(index);
    },
    [remove]
  );

  const handleResetClick = useCallback(() => {
    dispatch(onFilterSubmit(layerKey, []));
    closeFilterForm();
  }, [layerKey]);

  const renderFieldInput = useCallback(
    (currField, index) => {
      const field_key = `filters.${index}.value`;
      // value input type will be based on currField.field_type
      switch (currField.field_type) {
        case FIELD_TYPES.Input:
        case FIELD_TYPES.NumericInput: {
          return (
            <TextField
              className="full-width"
              label={"Value"}
              {...register(field_key, {
                required: "Please enter filter value",
                pattern: currField.pattern,
              })}
              error={!!get(errors, field_key)}
              helperText={get(errors, `${field_key}.message`, "")}
              type={
                currField.field_type === FIELD_TYPES.NumericInput
                  ? "number"
                  : "text"
              }
              InputLabelProps={{
                required: true,
              }}
            />
          );
        }

        case FIELD_TYPES.CheckBox:
          return (
            <FormCheckbox
              label={""}
              name={field_key}
              control={control}
              // rules={{
              //   required: "Value is required",
              // }}
              required
              error={!!get(errors, field_key)}
              helperText={get(errors, `${field_key}.message`, "")}
            />
          );

        case FIELD_TYPES.ConfigSelect:
          return (
            <FormSelect
              isMulti
              label={"Value"}
              name={field_key}
              control={control}
              rules={{
                required: "Value is required",
              }}
              required
              options={configurationOptions || []}
              error={!!get(errors, field_key)}
              helperText={get(errors, `${field_key}.message`, "")}
              labelKey="config_name"
              valueKey="id"
              isLoading={isLoading}
            />
          );
        case FIELD_TYPES.Select:
        case FIELD_TYPES.SelectMulti:
          return (
            <FormSelect
              isMulti={currField.field_type === FIELD_TYPES.SelectMulti}
              label={"Value"}
              name={field_key}
              control={control}
              rules={{
                required: "Value is required",
              }}
              required
              options={currField.choices || []}
              error={!!get(errors, field_key)}
              helperText={get(errors, `${field_key}.message`, "")}
            />
          );

        case FIELD_TYPES.SelectMultiArray:
          return (
            <FormSelect
              isMulti
              simpleValue
              label={"Value"}
              name={field_key}
              control={control}
              rules={{
                required: "Value is required",
              }}
              required
              options={currField.choices || []}
              error={!!get(errors, field_key)}
              helperText={get(errors, `${field_key}.message`, "")}
            />
          );

        case FIELD_TYPES.Date:
          return (
            <Stack width="100%">
              <FormDatePicker
                label={"Value"}
                format="yyyy-MM-dd"
                name={field_key}
                control={control}
                rules={{
                  required: "Value is required",
                }}
                error={!!get(errors, field_key)}
                helperText={get(errors, `${field_key}.message`, "")}
              />
            </Stack>
          );

        case FIELD_TYPES.DateTime:
          return (
            <Stack width="100%">
              <FormDateTimePicker
                label={"Value"}
                name={field_key}
                control={control}
                rules={{
                  required: "Value is required",
                }}
                error={!!get(errors, field_key)}
                helperText={get(errors, `${field_key}.message`, "")}
              />
            </Stack>
          );

        default: {
          return null;
        }
      }
    },
    [errors, isLoading, configurationOptions]
  );

  const layerFilterFormConfigList = useMemo(() => {
    return differenceBy(layerFilterFormConfigs, fields, "field");
  }, [layerFilterFormConfigs, fields]);

  return (
    <Box
      p={2}
      style={{ minWidth: "45em", maxHeight: "75vh", overflow: "auto" }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        {fields.map((currField, index) => {
          return (
            <Fragment key={`${currField.id}-${currField.field}`}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={4}>
                  <Typography variant="button" color="text.secondary">
                    {currField.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  {/* checkk currField has operator | value */}
                  {has(currField, "operator") ? (
                    <Box pb={2}>
                      <FormSelect
                        label="Operator"
                        name={`filters.${index}.operator`}
                        control={control}
                        rules={{
                          required: "Operator is required",
                        }}
                        required
                        options={OPERATOR_OPTIONS}
                        error={!!get(errors, `filters.${index}.operator`)}
                        helperText={get(
                          errors,
                          `filters.${index}.operator.message`,
                          ""
                        )}
                      />
                    </Box>
                  ) : null}
                  {renderFieldInput(currField, index)}
                </Grid>
                <Grid item xs={12} md={1}>
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={handleRemove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider flexItem sx={{ my: 2 }} />
            </Fragment>
          );
        })}
        <Stack my={1} direction="row" spacing={1}>
          <Box flex={1}>
            <CustomSelect
              name="selectedFilter"
              label="Select field to filter"
              options={layerFilterFormConfigList}
              value={selectedFilter}
              onChange={setSelectedFilter}
              menuPortalTarget={document.body}
            />
          </Box>
          <Button
            variant="outlined"
            color="success"
            startIcon={<AddIcon />}
            disabled={!selectedFilter}
            onClick={handleAddFilter}
          >
            add
          </Button>
        </Stack>
        <Divider />
        <Stack my={1} direction="row" justifyContent="end" spacing={1}>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleResetClick}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FilterAltIcon />}
            type="submit"
          >
            Apply
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default FilterForm;
