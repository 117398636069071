import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";

import { default as Icon } from "assets/markers/joincloser_box.svg";
import { default as EditIcon } from "assets/markers/joincloser_pin.svg";
import { FIELD_TYPES } from "components/common/DynamicForm";

import get from "lodash/get";

export const LAYER_KEY = "p_jointcloser";
export const PRE_UID = "JC";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;
export const SHOW_LABELS_AFTER_ZOOM = 17;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
  labelOrigin: { x: 10, y: -8 },
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

export const JC_TYPES = [
  { value: "BMB", label: "Bamboo" },
  { value: "CMP", label: "Compass" },
  { value: "FAT", label: "FAT" },
  { value: "FDC", label: "FDC" },
  { value: "PTP", label: "PATCH Panel" },
];

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {
  return {
    title: "Joint Closer Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "jc_type",
            label: "JC Type",
            field_type: FIELD_TYPES.Select,
            options: JC_TYPES,
          },
        ],
      },
    ],
  };
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "JC Type", field: "jc_type_display", type: "simple" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "connections",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    field: "jc_type",
    label: "JC Type",
    field_type: FIELD_TYPES.Select,
    choices: JC_TYPES,
    filter_type: FILTER_TYPES.equals,
  },
  {
    field: "ref_code",
    label: "Reff Code",
    field_type: FIELD_TYPES.Input,
    filter_type: FILTER_TYPES.contains,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
