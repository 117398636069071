import React from "react";
import { useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

import TableBackButton from "./TableBackButton";

import { getCurrentPage } from "redux/selectors/appState.selectors";
import { PAGE_SELCTIONS } from "redux/reducers/appState.reducer";

/**
 * Parent:
 *    ElementDetailsTable
 */
const TableHeader = ({
  title,
  minimized,
  handlePopupMinimize,
  handleCloseDetails,
  showMinimizeBtn = true,
}) => {
  const currentPage = useSelector(getCurrentPage);

  return (
    <>
      <Stack
        sx={{
          backgroundColor:
            currentPage === PAGE_SELCTIONS.PLANNING_PAGE
              ? "primary.main"
              : "inherit",
          color:
            currentPage === PAGE_SELCTIONS.PLANNING_PAGE
              ? "background.default"
              : "primary.main",
          borderTopRightRadius: 4,
        }}
        direction="row"
        alignItems="center"
        p={1}
      >
        <TableBackButton currentPage={currentPage} />
        <Typography variant="h6" textAlign="left" flex={1} pl={1}>
          {title}
        </Typography>
        {showMinimizeBtn && currentPage === PAGE_SELCTIONS.PLANNING_PAGE ? (
          <Tooltip title={minimized ? "Maximize" : "Minimize"}>
            <IconButton onClick={handlePopupMinimize}>
              {minimized ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="Close">
          <IconButton onClick={handleCloseDetails}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {currentPage === PAGE_SELCTIONS.DEVICES_PAGE ? <Divider /> : null}
    </>
  );
};

export default TableHeader;
