import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";

import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import Paper from "@mui/material/Paper";

import TicketListDummyLoader from "ticket/components/TicketListDummyLoader";
import AgAlertList from "gis_alert/components/AgAlertList";
import SeverityPieChart from "./SeverityPieChart";
import RegionAlertPieChart from "./RegionAlertPieChart";
import AlertDeviceAggCountWidget from "../widgets/AlertDeviceAggCountWidget";

import { fetchAlertList } from "gis_alert/data/alert.actions";
import { setDefaultDashboard } from "redux/reducers/auth.reducer";

import "./catv-dashboard.scss";

const CatvAlertsDashboard = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    // open this dashboard by default
    dispatch(setDefaultDashboard("CatvAlerts"));
  }, []);

  const alertStatus = "P";
  const { isLoading, data: alertListData } = useQuery(
    ["alertList", alertStatus],
    fetchAlertList,
    {
      refetchInterval: 60000, // 60 sec
    }
  );

  const filterAgGridByFieldKey = ({ field_key, value_key }) => {
    gridRef.current.filter(field_key, value_key);
  };

  const clearAgGridFilters = (filterKey = null) => {
    gridRef.current.clearFilters(filterKey);
  };

  return (
    <PanelGroup
      autoSaveId="catv-dash"
      id="catv-dash"
      direction="vertical"
      style={{ padding: "5px" }}
    >
      <Panel id="row-1" defaultSize={70} style={{ padding: "3px" }}>
        <Paper sx={{ height: "100%", backgroundColor: "#5c91a4" }}>
          {/* <Typography mb={5} variant="h4">
              Alerts List
            </Typography> */}
          {isLoading ? (
            <TicketListDummyLoader />
          ) : (
            <AgAlertList
              ref={gridRef}
              viewAs="compact"
              alertStatus={alertStatus}
              alertList={alertListData}
              pagination={false}
            />
          )}
        </Paper>
      </Panel>

      <PanelResizeHandle />

      <Panel id="row-2" defaultSize={30}>
        <PanelGroup autoSaveId="gp-row-2" id="gp-row-2" direction="horizontal">
          <Panel id="wid-1" style={{ padding: "3px" }}>
            <Paper
              className="catv-dash-pie-wrapper"
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#384455",
              }}
            >
              <SeverityPieChart
                alertListData={alertListData || []}
                onPieClick={filterAgGridByFieldKey}
                clearFilters={clearAgGridFilters}
              />
            </Paper>
          </Panel>
          <PanelResizeHandle />
          <Panel id="wid-2" style={{ padding: "3px" }}>
            <Paper
              className="catv-dash-pie-wrapper"
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#495b29",
              }}
            >
              <RegionAlertPieChart
                alertListData={alertListData || []}
                onPieClick={filterAgGridByFieldKey}
                clearFilters={clearAgGridFilters}
              />
            </Paper>
          </Panel>
          <PanelResizeHandle />
          <Panel id="row-2-wid-3" style={{ padding: "3px" }}>
            <Paper
              className="catv-dash-alert-wid-wrapper"
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "#5c91a4",
                overflowY: "auto",
              }}
            >
              <AlertDeviceAggCountWidget />
            </Paper>
          </Panel>
        </PanelGroup>
      </Panel>
    </PanelGroup>
  );
};

export default CatvAlertsDashboard;
