import React, { useMemo } from "react";
import { useQuery } from "react-query";

import size from "lodash/size";
import { subMinutes } from "date-fns";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { fetchAttrTimeseries } from "planning/data/catv/catv.services";
import {
  getChartConfig,
  tooltipFormatter,
} from "planning/data/catv/catv.utils";

const FavGraph = ({ widgetConfig, widgetId }) => {
  /**
   * Parent:
   *     FavGraphWidget
   */

  const { isLoading, data: attrTimeSeries } = useQuery(
    ["fetchAttrTimeseries", widgetId],
    async () => {
      const startTime = subMinutes(new Date(), widgetConfig.time_delta);
      return fetchAttrTimeseries({ ...widgetConfig, start_time: startTime });
    },
    {
      refetchInterval: 60000,
    }
  );

  const { finalChartConfig, emptyDataMessages } = useMemo(() => {
    const now = new Date();
    const startTime = subMinutes(now, widgetConfig.time_delta);

    const chartOptions = {
      startTime: startTime,
      endTime: now,
      interval: 120000,
      breakInterval: 300000,
    };
    const { chartConfig, emptyDataMessages } = getChartConfig(
      attrTimeSeries,
      chartOptions
    );

    let finalChartConfig = { ...chartConfig };
    // add tooltip function here as its not serializable on redux /state
    finalChartConfig.tooltip = {
      formatter: tooltipFormatter,
      shared: true,
    };
    // add spacingTop; need this so top axes label don't cut off
    finalChartConfig.chart.spacingTop = 25;

    return { finalChartConfig, emptyDataMessages };
  }, [attrTimeSeries, widgetConfig]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  //
  else if (finalChartConfig) {
    return (
      <Box
        sx={{
          height: "calc(100% - 70px)",
          width: "100%",
        }}
      >
        <HighchartsReact
          containerProps={{
            style: {
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            },
          }}
          highcharts={Highcharts}
          options={finalChartConfig}
          constructorType={"stockChart"}
        />
        {!!size(emptyDataMessages) ? (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              zIndex: 1,
              paddingLeft: 4,
              color: "red",
            }}
          >
            {emptyDataMessages.map((message, ind) => (
              <Typography variant="caption" key={ind}>
                &#9679; {message}
              </Typography>
            ))}
          </Box>
        ) : null}
      </Box>
    );
  }
  //
  else {
    return null;
  }
};

export default FavGraph;
