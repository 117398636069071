import { find } from "lodash";

export const AVAILABLE_DASHBOARDS = [
  { permission_key: "survey_dash", dash_key: "Survey", label: "Survey" },
  {
    permission_key: "catv_alerts_dash",
    dash_key: "CatvAlerts",
    label: "Catv Alerts",
  },
  {
    permission_key: "catv_fav_graph_dash",
    dash_key: "CatvFavGraph",
    label: "Favourite Graphs",
  },
];

export const getDashboardConfig = (dash_key) => {
  return find(AVAILABLE_DASHBOARDS, ["dash_key", dash_key]);
};

export const ALERT_POSSIBLE_LAYER_KEYS = ["p_switch", "p_ipqam", "p_firewall"];
