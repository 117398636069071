import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import GeneralDashboard from "./GeneralDashboard";
import CatvAlerts from "./CatvAlerts";
import EmptyDashboard from "./EmptyDashboard";
import CatvFavGraphs from "./CatvFavGraphs";

import { getUserDefaultDashboard } from "redux/selectors/auth.selectors";

const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const defaultDashboard = useSelector(getUserDefaultDashboard);

  const selectedDashboard =
    searchParams.get("dashboard") || defaultDashboard?.dash_key;

  switch (selectedDashboard) {
    case "Survey":
      return <GeneralDashboard />;
    case "CatvAlerts":
      return <CatvAlerts />;
    case "CatvFavGraph":
      return <CatvFavGraphs />;
    // user has no dashboard access
    default:
      return <EmptyDashboard />;
  }
};

export default Dashboard;
