import Api from "utils/api.utils";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetTicketWorkorderElements,
  apiPostTicketAcceptAll,
  apiPostTicketStatusUpdate,
  apiPutCustomerTicketWorkorderEdit,
  apiPutTicketWorkorderEdit,
} from "utils/url.constants";

export const fetchTicketWorkorderData = async (ticketId) => {
  const res = await Api.get(apiGetTicketWorkorderElements(ticketId));
  return res.data;
};

export const fetchTicketWorkorderDataThunk = createAsyncThunk(
  "planningGis/fetchTicketWorkorderData",
  fetchTicketWorkorderData
);

// data: { status, remark }
export const updateTicketWorkOrder = async ({ workOrderId, data, wo_type }) => {
  const url =
    wo_type === "customer"
      ? apiPutCustomerTicketWorkorderEdit(workOrderId)
      : apiPutTicketWorkorderEdit(workOrderId);
  const res = await Api.put(url, data);
  return res.data;
};

export const ticketAcceptAllWo = async (ticketId) => {
  const res = await Api.post(apiPostTicketAcceptAll(ticketId));
  return res.data;
};

export const ticketStatusUpdate = async ({ status, ticketId }) => {
  const res = await Api.post(apiPostTicketStatusUpdate(ticketId), { status });
  return res.data;
};
