import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import size from "lodash/size";
import find from "lodash/find";
import map from "lodash/map";
import capitalize from "lodash/capitalize";
import trim from "lodash/trim";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import CloseIcon from "@mui/icons-material/Close";

import { FormSelect } from "components/common/FormFields/FormSelect";

import { addNotification } from "redux/reducers/notification.reducer";
import { uploadLayerData } from "planning/data/layer.services";
import { LayerKeyMappings } from "planning/GisMap/utils";
import { getSelectedRegionIds } from "planning/data/planningState.selectors";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { setActiveTab } from "planning/data/planningState.reducer";
import { FormChipSelect, FormFileField } from "components/common/FormFields";
import { fetchLayerDataThunk } from "planning/data/actionBar.services";

const UploadForm = ({ importLayerCofigs, onClose }) => {
  /**
   * Parent
   *    AddElementContent
   */
  const dispatch = useDispatch();
  const selectedRegionIds = useSelector(getSelectedRegionIds);

  const [errorList, setErrorList] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [messageType, setMessageType] = useState(null);

  const { mutate: uploadLayerDataMutation, isLoading } = useMutation(
    uploadLayerData,
    {
      onError: (err) => {
        const statusCode = get(err, "response.status");
        if (statusCode === 403) {
          dispatch(
            addNotification({
              type: "error",
              title: "Upload layer data",
              text: "Permission required to upload this layer",
            })
          );
        } else {
          dispatch(
            addNotification({
              type: "error",
              title: "Upload layer data",
              text: "Invalid file data",
            })
          );
        }
      },
      onSuccess: (res) => {
        const success_count = get(res, "success_count", 0);
        const edit_count = get(res, "edit_count", 0);
        const error_list = get(res, "error_list", []);

        if (error_list?.length) {
          let successMsg = "";
          if (success_count) {
            successMsg = `${success_count} element(s) added successfully.`;
          }
          if (edit_count) {
            successMsg =
              successMsg + ` ${edit_count} element(s) updated successfully`;
          }
          if (error_list?.length) {
            successMsg =
              successMsg +
              ` ${error_list?.length} element(s) are failed to add / edit.`;
          }

          setSuccessMsg(trim(successMsg));
          setMessageType("warning.main");
          setErrorList(error_list);
        }
        //
        else {
          let successMsg = `${success_count} element(s) added successfully.`;
          if (edit_count) {
            successMsg =
              successMsg + ` ${edit_count} element(s) updated successfully`;
          }

          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.SUCCESS,
              title: "Upload layer data",
              text: trim(successMsg),
            })
          );

          onClose();
        }

        // refetch layer data
        dispatch(
          fetchLayerDataThunk({
            regionIdList: selectedRegionIds,
            layerKey: selectedLayerKey,
          })
        );
      },
    }
  );

  const {
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: { layerKey: "", file: null, fileType: null },
  });

  const selectedLayerKey = watch("layerKey");
  const selectedLayerData = find(importLayerCofigs, [
    "layer_key",
    selectedLayerKey,
  ]);
  const importAs = useMemo(() => {
    let importList = map(get(selectedLayerData, "import_as", []), (d) => ({
      label: capitalize(d),
      value: d,
    }));
    return importList;
  }, [selectedLayerData]);

  const onFormSubmit = useCallback(
    (data) => {
      setErrorList([]);
      setSuccessMsg("");
      setMessageType(null);

      if (!size(selectedRegionIds)) {
        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.ERROR,
            title: "Select Region first",
            text: "Please select region to narrow down your upload of elements.",
          })
        );
        // change tab to regions
        dispatch(setActiveTab(0));
        return;
      }

      const formData = new FormData();
      formData.append("file", data.file, data.file.name);

      const featureType = get(LayerKeyMappings, [data.layerKey, "featureType"]);
      formData.append("featureType", featureType);
      formData.append("region_ids", selectedRegionIds);
      formData.append("file_type", data.fileType);

      uploadLayerDataMutation({ layerKey: data.layerKey, data: formData });
    },
    [selectedRegionIds]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Box />
        <Typography variant="h6" color="primary.dark">
          Upload File
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        dividers
        sx={{
          padding: 0,
        }}
      >
        <Box p={4} pb={1}>
          <FormSelect
            label="Layer"
            name="layerKey"
            control={control}
            rules={{
              required: "Layer is required",
            }}
            required={true}
            options={importLayerCofigs}
            error={!!get(errors, "layerKey")}
            helperText={get(errors, "layerKey.message", "")}
            labelKey="name"
            valueKey="layer_key"
          />
          {size(importAs) ? (
            <Stack direction="row">
              <Box pt={1.5} flex={1}>
                <FormChipSelect
                  label="File Type"
                  name="fileType"
                  control={control}
                  rules={{
                    required: "File Type is required",
                  }}
                  required={true}
                  options={importAs}
                  error={!!get(errors, "fileType")}
                  helperText={get(errors, "fileType.message", "")}
                />
              </Box>
              <Box py={1.5} flex={1}>
                <FormFileField
                  label="Upload File"
                  name="file"
                  control={control}
                  rules={{
                    required: "File is required",
                  }}
                  required={true}
                  error={!!get(errors, "file")}
                  helperText={get(errors, "file.message", "")}
                />
              </Box>
            </Stack>
          ) : null}
        </Box>
        {!!size(errorList) ? (
          <Box>
            <Typography
              variant="h6"
              color="primary.dark"
              textAlign="center"
              py={1}
            >
              Errors
            </Typography>
            <Divider />
            {successMsg ? (
              <Typography
                variant="body2"
                color={messageType}
                textAlign="center"
                py={2}
              >
                {successMsg}
              </Typography>
            ) : null}
            <TableContainer
              component={Paper}
              sx={{
                width: "88%",
                margin: "0 auto",
                marginBottom: "1em",
                // marginTop: "1em",
              }}
            >
              <Table stickyHeader size="small" aria-label="sticky dense table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "90px" }}>Index</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorList.map((error, index) => {
                    let errMsg = error.message;

                    if (errMsg && typeof errMsg === "object") {
                      errMsg = JSON.stringify(errMsg);
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ width: "90px" }}>
                          {error.index + 1}
                        </TableCell>
                        <TableCell>{errMsg}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          onClick={handleSubmit(onFormSubmit)}
          loading={isLoading}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UploadForm;
