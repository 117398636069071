import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";

import get from "lodash/get";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import CloseIcon from "@mui/icons-material/Close";

import { addNotification } from "redux/reducers/notification.reducer";
import { uploadEditLayer } from "planning/data/layer.services";
import { FormChipSelect, FormFileField } from "components/common/FormFields";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { parseErrorMessagesWithFields } from "utils/api.utils";

const FILE_TYPE_OPTIONS = [
  // {
  //   label: "Shapefile",
  //   value: "shapefile",
  // },
  {
    label: "KML",
    value: "kml",
  },
];

const FileUploadForm = ({ layerKey, elementData, onClose }) => {
  /**
   * Parent
   *    TableActions
   */
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate: uploadEditLayerMutation, isLoading } = useMutation(
    uploadEditLayer,
    {
      onError: (err) => {
        const { fieldList, messageList } = parseErrorMessagesWithFields(err);
        for (let index = 0; index < fieldList.length; index++) {
          const field = fieldList[index];
          const errorMessage = messageList[index];
          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.ERROR,
              title: field,
              text: errorMessage,
            })
          );
        }
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("elementDetails");
        onClose();
        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            title: "Upload layer data",
            text: "Geometry updated successfully.",
          })
        );
      },
    }
  );

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: { file: null, fileType: null },
  });

  const onFormSubmit = useCallback(
    (data) => {
      const formData = new FormData();
      formData.append("file", data.file, data.file.name);
      formData.append("file_type", data.fileType);

      uploadEditLayerMutation({
        layerKey: layerKey,
        elementId: elementData.id,
        data: formData,
      });
    },
    [layerKey, elementData]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Box />
        <Typography variant="h6" color="primary.dark">
          Upload File
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        dividers
        sx={{
          padding: 0,
        }}
      >
        <Stack px={4} py={3} spacing={2}>
          <Box>
            <FormChipSelect
              label="File Type"
              name="fileType"
              control={control}
              rules={{
                required: "File Type is required",
              }}
              required={true}
              options={FILE_TYPE_OPTIONS}
              error={!!get(errors, "fileType")}
              helperText={get(errors, "fileType.message", "")}
            />
          </Box>
          <Box>
            <FormFileField
              label="Upload File"
              name="file"
              control={control}
              rules={{
                required: "File is required",
              }}
              required={true}
              error={!!get(errors, "file")}
              helperText={get(errors, "file.message", "")}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          onClick={handleSubmit(onFormSubmit)}
          loading={isLoading}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default FileUploadForm;
