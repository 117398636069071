import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";

import map from "lodash/map";
import get from "lodash/get";
import size from "lodash/size";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";

import CustomSelect from "components/common/FormFields/CustomSelect";
import AlertActionsList from "./AlertActionsList";

import {
  addAlertService,
  fetchAlertDetails,
} from "gis_alert/data/alert.actions";
import { parseErrorMessagesWithFields } from "utils/api.utils";

import { addNotification } from "redux/reducers/notification.reducer";

const AlertAckPopup = ({ alertId, alertStatus, onClose }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [message, setMessage] = useState("");
  const [closed, setClosed] = useState(false);
  const [assignToMe, setAssignToMe] = useState(false);
  const [rfoText, setRfoText] = useState(null);
  const [subRfoText, setSubRfoText] = useState(null);

  const { isLoading, data: alertDetails } = useQuery(
    ["alertDetails", alertId],
    fetchAlertDetails,
    {
      retry: false,
      staleTime: 15 * 60000, // 15 minutes
      onError: (err) => {
        parseErrorMessagesWithFields(err);
        onClose();
      },
    }
  );

  const rfoTextList = get(alertDetails, "rfo_list") || [];

  const subRfoTextList = map(get(rfoText, "sub_rfo_list"), (d) => ({
    label: d,
    value: d,
  }));

  const { mutate: addAlertMutation, isLoading: addingAlert } = useMutation(
    addAlertService,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["alertList"] });
        onClose();
      },
      onError: (err) => {
        parseErrorMessagesWithFields(err);
      },
    }
  );

  const handleRfoChange = useCallback((newValue) => {
    setRfoText(newValue);
    setSubRfoText(null);
  }, []);

  const handleSubmit = () => {
    if (!message && !assignToMe && !closed) {
      dispatch(
        addNotification({
          type: "error",
          title: "Input Error",
          text: "Please add details about action taken",
          timeout: 5000,
        })
      );
      return null;
    }

    if (closed) {
      // RFO selected, check sub RFO selected or not
      if (rfoText?.rfo) {
        if (size(subRfoTextList) && !subRfoText) {
          dispatch(
            addNotification({
              type: "error",
              title: "Input Error",
              text: "Please select sub RFO",
              timeout: 5000,
            })
          );
          return null;
        }
      }
      //
      else {
        dispatch(
          addNotification({
            type: "error",
            title: "Input Error",
            text: "Please select RFO",
            timeout: 5000,
          })
        );
        return null;
      }
    }

    const postData = {
      alertId,
      message,
      assign_to_user: assignToMe,
      closed,
      rfo_text: rfoText?.rfo,
      sub_rfo_text: subRfoText?.value,
    };

    addAlertMutation(postData);
  };

  return (
    <Box minWidth="600px">
      <Stack
        p={2}
        pb={1}
        direction="row"
        spacing={2}
        width="100%"
        alignItems="center"
        sx={{
          backgroundColor: "primary.main",
          color: "background.default",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      >
        <Typography color="white" flex={1} className="dtl-title" variant="h5">
          Alert Actions
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack
        p={2}
        sx={{
          overflow: "auto",
        }}
        spacing={1}
      >
        <Typography
          mt={2}
          mb={1}
          variant="h5"
          textAlign="center"
          color="primary"
        >
          Add Action
        </Typography>
        <Divider />
        {/* form starts here */}
        <Stack mt={4}>
          <TextField
            id="outlined-multiline-static"
            label="Message"
            multiline
            rows={3}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={assignToMe}
                  onChange={() => setAssignToMe(!assignToMe)}
                />
              }
              label="Assign to me"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={closed}
                  onChange={() => setClosed(!closed)}
                />
              }
              label="Close this alert"
            />
          </FormGroup>
          {closed ? (
            <>
              <Box mt={2} mb={1.5}>
                <CustomSelect
                  label="Select RFO"
                  options={rfoTextList}
                  isClearable
                  isSearchable
                  menuPortalTarget={document.body}
                  isLoading={isLoading}
                  value={rfoText}
                  onChange={handleRfoChange}
                  getOptionLabel={(o) => o.rfo}
                  getOptionValue={(o) => o.code}
                />
              </Box>
              {size(subRfoTextList) ? (
                <Box my={0.5}>
                  <CustomSelect
                    label="Select Sub RFO"
                    options={subRfoTextList}
                    isClearable
                    isSearchable
                    menuPortalTarget={document.body}
                    isLoading={isLoading}
                    value={subRfoText}
                    onChange={setSubRfoText}
                  />
                </Box>
              ) : null}
            </>
          ) : null}
        </Stack>

        <Box textAlign="right" mt={1.5}>
          <LoadingButton
            variant="outlined"
            color="secondary"
            startIcon={<CheckCircleOutline />}
            sx={{
              minWidth: "15em",
              py: 2,
            }}
            onClick={handleSubmit}
            loading={addingAlert}
            size="small"
          >
            Submit
          </LoadingButton>
        </Box>
      </Stack>
      <Divider flexItem />
      <AlertActionsList alertData={{ id: alertId }} />
    </Box>
  );
};

export default AlertAckPopup;
