import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";

import { default as Icon } from "assets/markers/fdc_box.svg";
import { default as EditIcon } from "assets/markers/fdc_pin.svg";
import { FIELD_TYPES } from "components/common/DynamicForm";
import { CABINET_TYPE_OPTIONS } from "../p_dp";

import get from "lodash/get";

export const LAYER_KEY = "p_fdc";
export const PRE_UID = "FDC";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;
export const SHOW_LABELS_AFTER_ZOOM = 18;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {
  return {
    title: "FDC Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "cabinet_type",
            label: "Cabinet Type",
            field_type: FIELD_TYPES.Select,
            options: CABINET_TYPE_OPTIONS,
          },
        ],
      },
    ],
  };
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "Cabinet Type", field: "cabinet_type_display", type: "simple" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "add_associations",
    data: ["p_splitter"],
  },
  {
    control: "association_list",
  },
  {
    control: "attachments",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    field: "cabinet_type",
    label: "Cabinet Type",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Select,
    choices: CABINET_TYPE_OPTIONS,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
