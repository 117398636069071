import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import get from "lodash/get";
import filter from "lodash/filter";
import difference from "lodash/difference";

import { Box, Button, TextField, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

import {
  FormCheckbox,
  FormSelect,
  FormHelperTextControl,
} from "components/common/FormFields";

import {
  addNewUser,
  fetchApplicationList,
  fetchUserList,
} from "../data/services";
import { addNotification } from "redux/reducers/notification.reducer";
import {
  getIsSuperAdminUser,
  getLoggedUserDetails,
} from "redux/selectors/auth.selectors";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import { getUserListPage } from "utils/url.constants";
import {
  EMAIL_PATTERN_REGEX,
  ESCALATION_TAGS,
  PHONE_PATTERN_REGEX,
  getRequiredFieldMessage,
  getValidFieldMessage,
} from "utils/constant";

/**
 * Render user Add form
 * display empty / filled user form data
 * Handle submit
 *
 * Parent
 *  UserAdminForm
 */
const UserAddForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedUserDetails);
  const isSuperAdmin = useSelector(getIsSuperAdminUser);

  const { isLoading, data } = useQuery("applicationList", fetchApplicationList);
  const { isLoading: userListLoading, data: userList = [] } = useQuery(
    "userList",
    fetchUserList,
    {
      select: (users) => {
        // no need to filter if user is super admin
        if (isSuperAdmin) return users;
        // else compare and get all users having less region access than logged user
        return filter(users, (user) => {
          const hasRegion = !!user.regions.length;
          const userHasMorePerms = !difference(
            user.regions,
            loggedInUser.regions
          ).length;
          return hasRegion && userHasMorePerms;
        });
      },
    }
  );

  const { mutate: addNewUserMutation, isLoading: isUserAdding } = useMutation(
    addNewUser,
    {
      onSuccess: (res) => {
        onSubmit(res);
        dispatch(
          addNotification({
            type: "success",
            title: "New user created",
          })
        );
      },
      onError: (err) => {
        const { fieldList, messageList } = parseErrorMessagesWithFields(err);
        for (let index = 0; index < fieldList.length; index++) {
          const field = fieldList[index];
          const errorMessage = messageList[index];
          setError(field, { message: errorMessage });
        }
      },
    }
  );

  const handleAddUserSubmit = useCallback((formData) => {
    const submitData = {
      ...formData,
      reporting_to: get(formData, "reporting_to.id", null),
    };
    addNewUserMutation(submitData);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      is_active: true,
      is_staff: false,
      access_ids: [],
      escalation_tag: [],
    },
  });

  const password = useRef({});
  password.current = watch("password", "");

  return (
    <Box p={2} component="form" onSubmit={handleSubmit(handleAddUserSubmit)}>
      <Stack spacing={2} direction={{ md: "row", xs: "column" }}>
        <Stack
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <TextField
            error={!!errors.username}
            label="User Name *"
            {...register("username", {
              required: getRequiredFieldMessage("User Name"),
            })}
            helperText={errors.username?.message}
          />
          <TextField
            error={!!errors.name}
            label="Full Name *"
            {...register("name", {
              required: getRequiredFieldMessage("Full Name"),
            })}
            helperText={errors.name?.message}
          />
          <TextField
            error={!!errors.mobile_number}
            label="Phone Number *"
            {...register("mobile_number", {
              required: getRequiredFieldMessage("Phone Number"),
              pattern: {
                value: PHONE_PATTERN_REGEX,
                message: getValidFieldMessage("phone number"),
              },
            })}
            helperText={errors.mobile_number?.message}
          />
          <TextField
            error={!!errors.email}
            label="Email"
            {...register("email", {
              pattern: {
                value: EMAIL_PATTERN_REGEX,
                message: getValidFieldMessage("email"),
              },
            })}
            helperText={errors.email?.message}
          />

          <FormSelect
            label="Reporting to"
            simpleValue
            name="reporting_to"
            control={control}
            options={userList}
            labelKey="name"
            valueKey="id"
            formatOptionLabel={(data) => {
              return (
                <Box>
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.1 }}>
                    {get(data, "name", "")}
                  </Typography>
                  <Typography variant="caption">
                    {get(data, "username", "")}
                  </Typography>
                </Box>
              );
            }}
            error={!!errors.reporting_to}
            helperText={errors.reporting_to?.message}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <TextField
            error={!!errors.password}
            label="Password *"
            type="password"
            {...register("password", {
              required: getRequiredFieldMessage("Password"),
            })}
            helperText={errors.password?.message}
          />
          <TextField
            error={!!errors.confirm_password}
            label="Confirm Password *"
            type="password"
            {...register("confirm_password", {
              required: getRequiredFieldMessage("Confirm Password"),
              validate: (value) =>
                value === password.current || "The passwords do not match",
            })}
            helperText={errors.confirm_password?.message}
          />

          <FormSelect
            label="Access Type *"
            isMulti
            name="access_ids"
            control={control}
            options={data || []}
            labelKey="name"
            valueKey="id"
            error={!!errors.access_ids}
            helperText={errors.access_ids?.message}
            rules={{
              required: getRequiredFieldMessage("Access Type"),
            }}
          />

          <FormSelect
            label="Escalation"
            isMulti
            simpleValue
            name="escalation_tag"
            control={control}
            options={ESCALATION_TAGS}
            error={!!errors.escalation_tag}
            helperText={errors.escalation_tag?.message}
            formatOptionLabel={(data) => {
              return (
                <Box>
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.1 }}>
                    {get(data, "label", "")}
                  </Typography>
                  <Typography variant="caption">
                    {get(data, "value", "")}
                  </Typography>
                </Box>
              );
            }}
          />

          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
            }}
          >
            <FormCheckbox
              label="Active"
              name="is_active"
              control={control}
              error={!!errors.is_active}
              helperText={errors.is_active?.message}
            />
            <FormCheckbox
              label="Admin"
              name="is_staff"
              control={control}
              error={!!errors.is_staff}
              helperText={errors.is_staff?.message}
            />
            {!!errors.__all__ ? (
              <FormHelperTextControl error={!!errors.__all__} className="mt-8">
                {get(errors, "__all__.message", "")}
              </FormHelperTextControl>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
      <Stack flex={1} direction="row" p={4} justifyContent="space-between">
        <Button
          variant="outlined"
          component={Link}
          to={getUserListPage()}
          startIcon={<CloseIcon />}
          color="error"
        >
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          type="submit"
          endIcon={<ArrowForwardIosIcon />}
          loading={isUserAdding}
        >
          Next
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default UserAddForm;
