import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";

import CustomSelect from "components/common/FormFields/CustomSelect";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { getSelectedConfigurations } from "planning/data/planningState.selectors";
import { fetchGroupTagList } from "planning/data/catv/catv.services";

const OptionalGroupSelect = ({
  name,
  control,
  rules,
  color,
  error,
  helperText,
  disabled,
  required,
}) => {
  const { layerKey } = useSelector(getPlanningMapState);
  const selectedConfigurations = useSelector(getSelectedConfigurations);
  const selectedConfigValue = get(selectedConfigurations, layerKey, null);
  const groupOptionalTag = get(selectedConfigValue, "group_optional_tag");

  useEffect(() => {
    fetchTags(groupOptionalTag);
  }, [groupOptionalTag]);

  const {
    mutate: fetchTags,
    isLoading,
    data: groupList,
  } = useMutation(fetchGroupTagList);

  return (
    <Controller
      render={({ field }) => {
        const value = filter(groupList, (d) =>
          includes(field.value, d.group_id)
        );

        return (
          <CustomSelect
            label="Select Optional Group"
            name={name}
            value={value}
            options={groupList}
            getOptionLabel={(o) => o["name"]}
            getOptionValue={(o) => o["group_id"]}
            isMulti={true}
            isLoading={isLoading}
            isDisabled={!!disabled}
            onChange={(newValue) => field.onChange(map(newValue, "group_id"))}
            menuPortalTarget={document.body}
          />
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

export default OptionalGroupSelect;
