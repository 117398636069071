import React, { useCallback, useEffect, useState } from "react";

import groupBy from "lodash/groupBy";
import get from "lodash/get";
import size from "lodash/size";
import some from "lodash/some";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import PieChart from "components/graphs/PieChart";
import PieChartLegend from "./PieChartLegend";
import { uniq_colors } from "utils/map.utils";

function RegionAlertPieChart({ alertListData, onPieClick, clearFilters }) {
  const [regionPieChartData, setRegionPieChartData] = useState([]);

  useEffect(() => {
    if (!size(alertListData)) setRegionPieChartData([]);

    let newSeverityPieChartData = [];
    const regionWiseAlerts = groupBy(alertListData, "base_region_name");

    let colorCounter = 0;
    for (const regionName in regionWiseAlerts) {
      if (Object.hasOwnProperty.call(regionWiseAlerts, regionName)) {
        const currAlertList = regionWiseAlerts[regionName];
        newSeverityPieChartData.push({
          field_key: "regions",
          label: regionName,
          value: currAlertList.length,
          value_key: regionName,
          color: uniq_colors[colorCounter++],
        });
        // reset color counter to 0 if data points more than colors we have
        if (colorCounter >= uniq_colors.length) {
          colorCounter = 0;
        }
      }
    }

    setRegionPieChartData(newSeverityPieChartData);
  }, [alertListData]);

  const handlePieClick = useCallback(
    (hightlightData) => {
      if (onPieClick) {
        const { value_key } = hightlightData;
        onPieClick(hightlightData);
        // get value_key from data and add highlight flag to it
        setRegionPieChartData((dataList) => {
          let newList = [...dataList];
          for (let dInd = 0; dInd < newList.length; dInd++) {
            const currD = newList[dInd];
            currD.highlight = currD.value_key === value_key;
          }
          return newList;
        });
      }
    },
    [onPieClick]
  );

  const handleClearFilters = useCallback(() => {
    if (clearFilters) {
      clearFilters("regions");
      // set all highlights to false
      setRegionPieChartData((dataList) => {
        let newList = [...dataList];
        for (let dInd = 0; dInd < newList.length; dInd++) {
          const currD = newList[dInd];
          currD.highlight = false;
        }
        return newList;
      });
    }
  }, [clearFilters]);

  const showFilterBtn = some(regionPieChartData, "highlight");

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between">
        <Typography mb={2} variant="h5" sx={{ color: "#e0e0e3" }}>
          Regional Alerts Distribution
        </Typography>
        {showFilterBtn ? (
          <IconButton
            onClick={handleClearFilters}
            size="large"
            color="secondary"
          >
            <FilterAltOffIcon />
          </IconButton>
        ) : null}
      </Stack>

      <Divider flexItem sx={{ borderColor: "#9d9d9f" }} />

      <Stack direction="row" spacing={2} pt={4} height="100%">
        <PieChartLegend
          pieChartData={regionPieChartData}
          onPieLegendClick={handlePieClick}
        />
        <PieChart
          pie={false}
          ringWidth={20}
          data={regionPieChartData}
          onPieClick={handlePieClick}
        />
      </Stack>
    </Stack>
  );
}

export default RegionAlertPieChart;
