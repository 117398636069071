import isNaN from "lodash/isNaN";
import isNil from "lodash/isNil";
import round from "lodash/round";

const uptimeFormattor = (value) => {
  // value is number of seconds
  let result = "";
  // if value is Null | undefined return NaN, so next condition returns proper null handling
  let val = isNil(value) ? NaN : Number(value);
  if (isNaN(val)) return { value, formattedValue: null, unit: null };
  // more than a day
  if (val > 86400) {
    result += `${Math.floor(val / 86400)}d`;
    val = val % 86400;
  }
  // more than an hour
  if (val > 3600) {
    result += ` ${Math.floor(val / 3600)}h`;
    val = val % 3600;
  }
  // more than a minute
  if (val > 60) {
    result += ` ${Math.floor(val / 60)}m`;
    val = val % 60;
  }
  if (val > 0) {
    result += ` ${val}s`;
  }
  return { value, formattedValue: result, unit: null };
};

const bpsFormattor = (value) => {
  // if value is Null | undefined return NaN, so next condition returns proper null handling
  let val = isNil(value) ? NaN : Number(value);
  let unit = "bps";
  if (isNaN(val)) return { value, formattedValue: null, unit };

  // more than gbps
  if (val > 1000000000) {
    val = round(val / 1000000000, 2);
    unit = "Gbps";
  }
  // more than mbps
  else if (val > 1000000) {
    val = round(val / 1000000, 2);
    unit = "Mbps";
  }
  // more than kbps
  else if (val > 1000) {
    val = round(val / 1000, 2);
    unit = "Kbps";
  } else {
    // just round off the value
    val = round(val, 2);
  }

  return { value, formattedValue: val, unit };
};

const rssiFormattor = (value) => {
  // if value is Null | undefined return NaN, so next condition returns proper null handling
  let val = isNil(value) ? NaN : Number(value);
  if (isNaN(val)) return { value, formattedValue: null, unit: "dBm" };
  let result = "";
  val = round(val, 3);
  if (val > -65) {
    result = `${val} (Excellent)`;
  } else if (val > -75) {
    result = `${val} (Good)`;
  } else if (val > -85) {
    result = `${val} (Fair)`;
  } else {
    result = `${val} (Poor)`;
  }

  return { value, formattedValue: result, unit: "dBm" };
};

const dbmFormattor = (value) => {
  if (isNil(value)) {
    return { value, formattedValue: null, unit: "dBm" };
  }
  return { value, formattedValue: round(value, 3), unit: "dBm" };
};

export const FORMATTOR_USED_FOR = {
  graph_tooltip: "graph_tooltip",
  graph_axes: "graph_axes",
  table_details: "table_details",
};

export const formatterFunctionsMap = (fieldKey, usedFor) => {
  /**
   * fieldKey maps live attribute field and unit
   * usedFor: where this formattor will be used
   * we can change formattor based on where this formattor will be used
   */
  if (fieldKey === "uptime") {
    return uptimeFormattor;
  } else if (
    fieldKey === "bits_received" ||
    fieldKey === "bits_sent" ||
    fieldKey === "input_total_bitrate" ||
    fieldKey === "ts_input_bitrate"
  ) {
    // handle bps unit conversions
    return bpsFormattor;
  } else if (fieldKey === "sim_network_signal_strength") {
    // RSSI signal: don't format for axes
    if (usedFor === FORMATTOR_USED_FOR.graph_axes) return null;
    else return rssiFormattor;
  } else if (
    fieldKey === "optical_rx_power" ||
    fieldKey === "optical_tx_power"
  ) {
    return dbmFormattor;
  } else {
    return null;
  }
};
