import React, { useRef } from "react";

import { useSelector } from "react-redux";

import {
  Container,
  Paper,
  Skeleton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import {
  getFetchedReportType,
  getReportDataState,
} from "Analysis/data/analysis.selectors";

import get from "lodash/get";
import isNumber from "lodash/isNumber";
import round from "lodash/round";
import size from "lodash/size";

import "../report-table.scss";

const CustomerTaggingContent = () => {
  /**
   * Parent:
   *    AnalysisContent
   */
  const reportType = useSelector(getFetchedReportType);
  const reportStateData = useSelector(getReportDataState);
  const { fetching, fetched, fetchError, reportData } = reportStateData;

  if (fetching) {
    return (
      <ReportWrapper title={reportType?.label}>
        <Skeleton animation="wave" height="200px" />
      </ReportWrapper>
    );
  } else if (fetchError) {
    return (
      <ReportWrapper title={reportType?.label}>
        <Box pt={3}>
          <Typography variant="h6" color="error.main" textAlign="center">
            Failed to fetch data
          </Typography>
        </Box>
      </ReportWrapper>
    );
  } else if (fetched) {
    if (size(reportData)) {
      return (
        <ReportWrapper title={reportType?.label}>
          <ReportContent reportData={reportData} />
        </ReportWrapper>
      );
    } else {
      return (
        <ReportWrapper title={reportType?.label}>
          <Box pt={3}>
            <Typography variant="h6" color="text.secondary" textAlign="center">
              No element available in selected area
            </Typography>
          </Box>
        </ReportWrapper>
      );
    }
  }
  return null;
};

const ReportWrapper = ({ title, children }) => {
  return (
    <Container sx={{ marginTop: 2, paddingBottom: 6 }}>
      <Paper sx={{ padding: 3, paddingTop: 0, position: "relative" }}>
        <Typography
          color="primary.dark"
          flex={1}
          variant="h5"
          textAlign="center"
          py={2}
        >
          {title}
        </Typography>
        <Divider />
        {children}
      </Paper>
    </Container>
  );
};

const REPORT_CONFIG = [
  { label: "Region", field: "region" },
  { label: "SPOP Name", field: "spop_name" },
  { label: "SPOP Reff code", field: "spop_code" },
  { label: "FSA Name", field: "fsa_name" },
  { label: "IP Address", field: "ip_address" },
  { label: "OLT Card", field: "olt_card" },
  { label: "OLT Port", field: "olt_port" },
  { label: "(DP Splitter/ONU) Name", field: "node_name" },
  { label: "Config", field: "config_name" },
  { label: "Total Customer Count", field: "customer_count" },
];

const ReportContent = ({ reportData }) => {
  const tableRef = useRef();

  return (
    <Box pt={3}>
      <table className="report-table" ref={tableRef}>
        <tbody>
          <tr>
            <th className="sr-col">Sr.no</th>
            {REPORT_CONFIG.map((config) => (
              <th key={config.field}>{config.label}</th>
            ))}
          </tr>
          {reportData.map((data, ind) => {
            return (
              <tr key={ind}>
                <td className="sr-col">{ind + 1}</td>
                {REPORT_CONFIG.map((config) => {
                  let value = get(data, config.field);
                  if (isNumber(value)) {
                    value = round(value, 2);
                  } else {
                    value = value || "--";
                  }
                  return <td key={config.field + ind}>{value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default CustomerTaggingContent;
