import pick from "lodash/pick";

export const getAnalysisFormData = (store) =>
  pick(store.analysis, ["region", "isAdvance", "layer", "element", "status"]);

export const getReportType = (store) => store.analysis.reportType;
export const getReportFileName = (store) => store.analysis.fileName;

export const getReportDatafetching = (store) => store.analysis.fetching;
// show report content based on this
export const getFetchedReportType = (store) => store.analysis.fetchedReportType;

export const getReportDataState = (store) =>
  pick(store.analysis, [
    "fetching",
    "fetched",
    "fetchError",
    "fetchedReportType",
    "reportData",
  ]);

export const getReportFormUniqueId = (store) =>
  store.analysis.reportFormUniqueId;
