import React from "react";

import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";

import { Controller } from "react-hook-form";

export const FormChipSelect = ({
  label,
  name,
  control,
  rules,
  error,
  helperText,
  disabled,
  required,
  color,
  onChange,
  options = [],
}) => {
  return (
    <Controller
      render={({ field }) => {
        return (
          <FormControl
            error={error}
            disabled={!!disabled}
            required={!!required}
          >
            <FormLabel
              sx={{
                paddingLeft: 1,
                paddingBottom: 0.5,
              }}
              component="legend"
            >
              {label}
            </FormLabel>
            <Stack ml={1.5} flexDirection="row">
              {options.map((option) => {
                return (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Chip
                        label={option.label}
                        key={option.value}
                        onClick={() => {
                          field.onChange(option.value);
                          if (!!onChange) onChange(option.value);
                        }}
                        variant={
                          option.value === field.value ? "filled" : "outlined"
                        }
                        color={color}
                      />
                    }
                  />
                );
              })}
            </Stack>

            {error ? (
              <FormHelperText sx={{ ml: 1 }}>{helperText}</FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
};
