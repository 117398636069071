import React from "react";
import { useQuery } from "react-query";

import range from "lodash/range";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

import { fetchAlertDeviceAggData } from "pages/dashboard.service";

function AlertDeviceAggCountWidget() {
  /**
   * Parent:
   *    CatvAlertsDashboard
   */

  const { isLoading, data: alertCountData } = useQuery(
    ["alerDeviceAggCount", 5, 7], // min_count (5), duration_day (7)
    fetchAlertDeviceAggData,
    {
      refetchInterval: 60000, // 60 sec
    }
  );

  if (isLoading) return <AlertWidgetDummyLoader />;

  return (
    <Stack p={2} spacing={2}>
      <Typography variant="h5" sx={{ color: "#e5e5ea" }}>
        Frequent Alerts (Last 7 days)
      </Typography>
      <Divider flexItem sx={{ borderColor: "#9d9d9f" }} />
      {alertCountData.map((alert) => {
        const { count, host_name, triggercode, host_id } = alert;
        return (
          <Box key={host_id} sx={{ width: "100%", backgroundColor: "white" }}>
            <Stack spacing={1} px={2} py={1}>
              <Typography variant="h6" component="div">
                {host_name}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="text.secondary">{triggercode}</Typography>
                <Chip label={count} color="secondary" />
              </Stack>
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
}

const AlertWidgetDummyLoader = () => {
  const rowPills = range(6);

  return (
    <Stack p={2} spacing={2}>
      {rowPills.map((ind) => {
        return (
          <Skeleton
            key={ind}
            height="60px"
            width="100%"
            sx={{ transform: "unset" }}
          />
        );
      })}
    </Stack>
  );
};

export default AlertDeviceAggCountWidget;
