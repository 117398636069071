import React, { useRef } from "react";
import { useMutation } from "react-query";

import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Paper,
  Skeleton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import {
  getFetchedReportType,
  getReportDataState,
  getReportFileName,
} from "Analysis/data/analysis.selectors";

import get from "lodash/get";
import isNumber from "lodash/isNumber";
import round from "lodash/round";
import size from "lodash/size";

import LoadingButton from "@mui/lab/LoadingButton";
import { fetchBomPdfData } from "Analysis/data/analysis.service";
import { addNotification } from "redux/reducers/notification.reducer";

import "../report-table.scss";

const BomContent = () => {
  const reportType = useSelector(getFetchedReportType);
  const reportStateData = useSelector(getReportDataState);
  const { fetching, fetched, fetchError, reportData } = reportStateData;

  if (fetching) {
    return (
      <ReportWrapper title={reportType?.label}>
        <Skeleton animation="wave" height="200px" />
      </ReportWrapper>
    );
  } else if (fetchError) {
    return (
      <ReportWrapper title={reportType?.label}>
        <Box pt={3}>
          <Typography variant="h6" color="error.main" textAlign="center">
            Failed to fetch data
          </Typography>
        </Box>
      </ReportWrapper>
    );
  } else if (fetched) {
    if (size(reportData)) {
      return (
        <ReportWrapper title={reportType?.label}>
          <ReportContent reportData={reportData} />
        </ReportWrapper>
      );
    } else {
      return (
        <ReportWrapper title={reportType?.label}>
          <Box pt={3}>
            <Typography variant="h6" color="text.secondary" textAlign="center">
              No element available in selected area
            </Typography>
          </Box>
        </ReportWrapper>
      );
    }
  }
  return null;
};

const ReportWrapper = ({ title, children }) => {
  return (
    <Container sx={{ marginTop: 2, paddingBottom: 6 }}>
      <Paper sx={{ padding: 3, paddingTop: 0, position: "relative" }}>
        <Typography
          color="primary.dark"
          flex={1}
          variant="h5"
          textAlign="center"
          py={2}
        >
          {title}
        </Typography>
        <Divider />
        {children}
      </Paper>
    </Container>
  );
};

const REPORT_CONFIG = [
  { label: "Item Name", field: "item_name" },
  { label: "Item Code", field: "item_code" },
  { label: "ERP Code", field: "erp_code" },
  { label: "GIS Qty", field: "gis_qty" },
  { label: "Unit", field: "unit" },
  { label: "Buffer @ 20%", field: "buffer" },
  { label: "Total Qty", field: "total_qty" },
];

const ReportContent = ({ reportData }) => {
  const tableRef = useRef();

  const dispatch = useDispatch();
  const reportFileName = useSelector(getReportFileName);

  const { mutate: exportMutation, isLoading: isPdfLoading } = useMutation(
    fetchBomPdfData,
    {
      onSuccess: (res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportFileName}.pdf`);
        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Error",
            text: err.message,
          })
        );
      },
    }
  );

  const handleGeneratePdf = () => {
    exportMutation(tableRef.current.outerHTML);
  };

  return (
    <Box pt={3}>
      <Box
        sx={{
          position: "absolute",
          right: "26px",
          top: "14px",
        }}
      >
        {/* <LoadingButton
          variant="outlined"
          color={"success"}
          // startIcon={<DownloadForOfflineIcon />}
          // onClick={exportUserMutation}
          sx={{ ml: 1 }}
          // loading={loadingExportUser}
        >
          Download xlsx
        </LoadingButton> */}
        <LoadingButton
          variant="outlined"
          color={"error"}
          onClick={handleGeneratePdf}
          sx={{ ml: 1 }}
          loading={isPdfLoading}
        >
          Download Pdf
        </LoadingButton>
      </Box>
      <table className="report-table" ref={tableRef}>
        <tbody>
          <tr>
            <th className="sr-col">Sr.no</th>
            {REPORT_CONFIG.map((config) => (
              <th key={config.field}>{config.label}</th>
            ))}
          </tr>
          {reportData.map((data, ind) => {
            return (
              <tr key={ind}>
                <td className="sr-col">{ind + 1}</td>
                {REPORT_CONFIG.map((config) => {
                  let value = get(data, config.field, "");
                  if (isNumber(value)) {
                    value = round(value, 2);
                  }
                  return <td key={config.field + ind}>{value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default BomContent;
