import { createSlice } from "@reduxjs/toolkit";

import { logout } from "redux/reducers/auth.reducer";
import { fetchAttrTimeseriesThunk } from "./catv.services";
import { getChartConfig } from "./catv.utils";

const initialState = {
  fetching: false,
  fetched: false,
  fetchError: false,
  // master data directly from server
  // shape: { field_key: {config, data: [{id, timestamp, value, created_on}, ...] }, field_key_2: {...}}
  fieldData: null,
  // chart configuration required by highcharts
  chartConfig: null,
  // user selected start time and end time, unix timestamp number
  startTime: null,
  endTime: null,
  // in miliseconds, default value : 2 min ( 2 * 60 * 1000 )
  interval: 120000,
  // if graph don't have any values for x interval, system will show this interval values as nulls
  // this will help graph to render error areas, default value : 5 min ( 5 * 60 * 1000 )
  breakInterval: 300000,
  // if error in one of the data sources ( like data is empty ) show error on graph load
  emptyDataMessages: [],
};

const catvSlice = createSlice({
  name: "catv",
  initialState,
  reducers: {
    setGraphOptions: (state, { payload }) => {
      const { startTime, endTime } = payload;
      state.startTime = startTime;
      state.endTime = endTime;
    },
    resetCATVData: () => initialState,
  },
  extraReducers: {
    [fetchAttrTimeseriesThunk.pending]: (state) => {
      state.fetching = true;
      state.fetched = false;
      state.fetchError = false;
      state.emptyDataMessages = [];
    },
    [fetchAttrTimeseriesThunk.rejected]: (state) => {
      state.fetching = false;
      state.fetched = false;
      state.fetchError = true;
      state.emptyDataMessages = [];
    },
    [fetchAttrTimeseriesThunk.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.fetched = true;
      state.fetchError = false;
      // setup chart data
      const chartOptions = {
        startTime: state.startTime,
        endTime: state.endTime,
        interval: state.interval,
        breakInterval: state.breakInterval,
      };
      const { chartConfig, emptyDataMessages } = getChartConfig(
        payload,
        chartOptions
      );
      state.fieldData = payload;
      state.chartConfig = chartConfig;
      state.emptyDataMessages = emptyDataMessages;
    },
    [logout]: () => {
      return initialState;
    },
  },
});

export const { setGraphOptions, resetCATVData } = catvSlice.actions;
export default catvSlice.reducer;
