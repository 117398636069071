import React, { useCallback } from "react";

import size from "lodash/size";
import get from "lodash/get";
import last from "lodash/last";
import split from "lodash/split";
import { format } from "date-fns";

import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import AddDocument from "./AddDocument";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { fetchDocumentList } from "planning/data/layer.services";

const DocumentList = () => {
  const { data: mapStateData, layerKey } = useSelector(getPlanningMapState);
  const {
    isLoading,
    data: documents,
    isError,
  } = useQuery(
    ["documentList", layerKey, mapStateData.elementId],
    fetchDocumentList
  );

  const handleDownload = useCallback(
    (fileUrl) => (e) => {
      if (e) e.preventDefault();

      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", last(split(fileUrl, "/")));
      // have to add element to doc for firefox
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    []
  );

  if (!size(documents)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ minHeight: "400px", minWidth: "300px" }}
      >
        <Typography variant="h5">No documents uploaded</Typography>
        <AddDocument />
      </Box>
    );
  }

  return (
    <>
      <AddDocument />
      <Divider />
      <List>
        {documents.map((item) => {
          const name = get(item, "created_by.name");
          const time = format(
            new Date(item.timestamp),
            "do MMM yyyy, h:mm aaa"
          );
          const ext = item.file.split(".").pop();
          return (
            <ListItem key={item.id} alignItems="flex-start" divider>
              <ListItemText
                primary={item.caption}
                secondary={name ? `${name} @ ${time}` : time}
              />
              <Typography
                variant="button"
                sx={{ alignSelf: "center", color: "rgba(0,0,0,0.6)" }}
                px={2}
              >
                {ext}
              </Typography>
              <Button
                color="secondary"
                sx={{ alignSelf: "center" }}
                onClick={handleDownload(item.file)}
              >
                Download
              </Button>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default DocumentList;
