import { circle } from "@turf/turf";

import get from "lodash/get";
import has from "lodash/has";
import size from "lodash/size";
import merge from "lodash/merge";
import filter from "lodash/filter";
import pick from "lodash/pick";
import findIndex from "lodash/findIndex";

import {
  getLayerSelectedConfiguration,
  getSelectedLayerKeys,
  getSelectedRegionIds,
} from "./planningState.selectors";
import {
  handleLayerSelect,
  handleRegionSelect,
  selectConfiguration,
  setLayerConfigurations,
} from "./planningState.reducer";
import { fetchLayerDataThunk } from "./actionBar.services";
import {
  getAllLayersData,
  getMasterViewData,
  getPlanningMapStateData,
  getPlanningMapStateEvent,
  getPlanningMapPosition,
  getPlanningSpecialLayerData,
} from "./planningGis.selectors";
import {
  resetUnselectedLayerGisData,
  setMapPosition,
  setMapState,
  setPopupMinimize,
  setSpecialLayerData,
  toggleMapPopupMinimize,
} from "./planningGis.reducer";
import {
  generateElementUid,
  generateSpecialLayerId,
  HIGHLIGHTED_POLYGON_OPTIONS,
  HIGHLIGHTED_POLYLINE_OPTIONS,
  LayerKeyMappings,
  PLANNING_EVENT,
  SPECIAL_LAYER_HIGHLIGHT_TYPES,
} from "planning/GisMap/utils";
import { addNotification } from "redux/reducers/notification.reducer";
import {
  coordsToLatLongMap,
  pointCoordsToLatLongMap,
  pointLatLongMapToCoords,
} from "utils/map.utils";
import {
  FEATURE_TYPES,
  zIndexMapping,
} from "planning/GisMap/layers/common/configuration";
import { listElementsOnMap } from "./event.actions";
import {
  filterGisDataByPolygon,
  generateNetworkIdFromParent,
} from "./planning.utils";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { COLORS } from "App/theme";
import { CUSTOM_ICONS } from "planning/GisMap/GisIcons";
import { setTracebackForm } from "./traceback/traceback.reducer";
import { fetchTracebackLayerDataThunk } from "./traceback/traceback.services";
import { getTracebackAllLayersNetworkStatus } from "./traceback/traceback.selectors";
import * as CustomerLayer from "planning/GisMap/layers/p_customer";

export const onGisMapClick = (mapMouseEvent) => (dispatch, getState) => {
  const storeState = getState();
  const mapStateEvent = getPlanningMapStateEvent(storeState);

  if (
    mapStateEvent === PLANNING_EVENT.selectElementsOnMapClick ||
    mapStateEvent === PLANNING_EVENT.associateElementOnMapClick
  ) {
    const clickLatLong = mapMouseEvent.latLng.toJSON();

    const layerData = getAllLayersData(storeState);
    const selectedLayerKeys = getSelectedLayerKeys(storeState);

    // if ths is select elements event get list of elements around user click
    const clickPoint = pointLatLongMapToCoords(clickLatLong);
    // create a circle at user click location
    const circPoly = circle(clickPoint, 0.01, {
      steps: 10,
      units: "kilometers",
    });

    let whiteList,
      blackList,
      elementData = {},
      extraParent = {};
    if (mapStateEvent === PLANNING_EVENT.selectElementsOnMapClick) {
      whiteList = selectedLayerKeys;
      blackList = ["region"];
    } else if (mapStateEvent === PLANNING_EVENT.associateElementOnMapClick) {
      const mapStateData = getPlanningMapStateData(storeState);
      elementData = mapStateData.elementData;
      extraParent = mapStateData.extraParent;
      // listOfLayers will be all the possible layers user can associate with current parent
      whiteList = mapStateData.listOfLayers;
      blackList = [];
    }

    const elementResultList = filterGisDataByPolygon({
      filterPolygon: circPoly,
      gisData: layerData,
      whiteList,
      blackList,
    });
    const filterCoords = coordsToLatLongMap(circPoly.geometry.coordinates[0]);
    // fire next event : listElementsOnMap, with new list data
    dispatch(
      listElementsOnMap({
        // association related fields
        elementData,
        extraParent,
        // actual filtered elements
        elementList: elementResultList,
        // polygon coords used to filter
        filterCoords,
        // info for next event that current filter was for association list or not
        isAssociationList:
          mapStateEvent === PLANNING_EVENT.associateElementOnMapClick,
      })
    );
  }
};

export const onRegionSelectionUpdate =
  (updatedRegionIdList) => (dispatch, getState) => {
    const storeState = getState();
    const selectedLayerKeys = getSelectedLayerKeys(storeState);

    // set selected regions
    dispatch(handleRegionSelect(updatedRegionIdList));
    // add region in selectedLayerKeys if not
    if (selectedLayerKeys.indexOf("region") === -1) {
      dispatch(handleLayerSelect("region"));
    }
    // fetch gis data for all region polygons
    dispatch(
      fetchLayerDataThunk({
        regionIdList: updatedRegionIdList,
        layerKey: "region",
      })
    );
    // re fetch data for each selected layers
    for (let l_ind = 0; l_ind < selectedLayerKeys.length; l_ind++) {
      const currLayerKey = selectedLayerKeys[l_ind];
      dispatch(
        fetchLayerDataThunk({
          regionIdList: updatedRegionIdList,
          layerKey: currLayerKey,
        })
      );
    }
    dispatch(resetUnselectedLayerGisData(selectedLayerKeys));
  };

/**
 * canPatchWith : list of layer keys, this will be shown in right / left select drop downs
 * extraFilter: filter function to add more filtering options after gis filter applied, ex. get only FTTH Onu
 */
export const showPossiblePatchingElements =
  ({
    layerKey,
    elementId,
    elementGeometry,
    canPatchWith,
    extraFilter = null,
  }) =>
  (dispatch, getState) => {
    const storeState = getState();
    const layerData = getAllLayersData(storeState);
    // create a circle at user click location
    const circPoly = circle(elementGeometry, 0.01, {
      steps: 10,
      units: "kilometers",
    });

    let elementResultList = filterGisDataByPolygon({
      filterPolygon: circPoly,
      gisData: layerData,
      // get only elements canPatchWith has layer keys of
      whiteList: canPatchWith,
    });

    if (!!extraFilter) {
      elementResultList = filter(elementResultList, extraFilter);
    }
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showElementPatching,
        layerKey,
        data: {
          layerKey,
          elementId,
          elementList: elementResultList,
        },
      })
    );
  };

/**
 * TableAction btn click -> take user to see list of connections and choose 1 for splicing
 * @param {elementGeometry} List of coordinates, shape : [{lat, lng}, ...]
 * @returns Go to ListElementConnections and show selection for splicing
 */
export const onElementListConnectionEvent =
  ({ layerKey, elementId, elementGeometry }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showElementConnections,
        layerKey,
        data: {
          elementId,
          elementGeometry,
        },
      })
    );
  };

/**
 * When user click "+" on ListElementConnections even
 * Draw a circle around a point element, which will be our search circle
 * @param {elementGeometry} Point point coordinates
 * @returns Go to AddElementConnection
 */
export const onElementAddConnectionEvent =
  ({ layerKey, elementId, elementGeometry }) =>
  (dispatch) => {
    // create a circle around element
    const circPoly = circle(elementGeometry, 0.01, {
      steps: 10,
      units: "kilometers",
    });
    const circlAreaCoords = circPoly.geometry.coordinates;

    // dispatch addElementConection
    dispatch(
      setMapState({
        event: PLANNING_EVENT.addElementConnection,
        layerKey,
        data: {
          circlAreaCoords,
          elementId,
          layerKey,
          elementGeometry,
        },
      })
    );
  };

export const openElementDetails =
  ({ layerKey, elementId }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showElementDetails,
        layerKey,
        data: { elementId },
      })
    );
  };

// add geometry with optinal associations
export const onAddElementGeometry =
  ({ layerKey, restriction_ids = null }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.addElementGeometry,
        layerKey,
        data: { restriction_ids },
      })
    );
  };

// called when user going to AddGisLayerFORM
export const onAddElementDetails =
  ({ layerKey, submitData, validationRes, extraParent }) =>
  (dispatch, getState) => {
    const initialData = get(LayerKeyMappings, [layerKey, "initialElementData"]);
    const storeState = getState();
    const selectedConfig = getLayerSelectedConfiguration(layerKey)(storeState);

    // generate ids
    let unique_id = generateElementUid(layerKey);

    // generate parent association data from parents res
    // shape: { layerKey : [{id, name, uid, netid}, ... ], ...]
    const parents = get(validationRes, "data.parents", {});
    const region_list = get(validationRes, "data.region_list");
    const network_id = generateNetworkIdFromParent(
      unique_id,
      parents,
      region_list
    );
    // generate children association data from children res
    const children = get(validationRes, "data.children", {});

    const getDependantFields = get(
      LayerKeyMappings,
      [layerKey, "getDependantFields"],
      ({ submitData }) => submitData
    );
    submitData = getDependantFields({
      submitData,
      children,
      parents,
      region_list,
    });

    // add config id if layer is configurable

    // complete current event -> fire next event
    dispatch(
      setMapState({
        event: PLANNING_EVENT.addElementForm, // event for "layerForm"
        layerKey,
        data: {
          ...initialData,
          // submit data will have all geometry related fields submitted by AddGisMapLayer
          ...submitData,
          unique_id,
          network_id,
          association: { parents: merge(parents, extraParent), children },
        },
      })
    );
  };

//////////////////////////////////////////////
//         show on map utils                //
//////////////////////////////////////////////

/**
 * get mapPosition and specialLayerDataList based on element and layerKey
 * handle validation - not append same element in special layer based on currentSpecialLayerData
 *
 * return {isExist, mapPosition, specialLayerDataList}
 */
export const getSpecialLayerDataFromElement = (
  element,
  layerKey,
  currentSpecialLayerData,
  mapPosition
) => {
  const featureType = get(LayerKeyMappings, [layerKey, "featureType"]);
  const viewOptions = LayerKeyMappings[layerKey]["getViewOptions"](element);
  const zIndex = zIndexMapping[layerKey];
  const specialLayerId = generateSpecialLayerId(element);
  const specialLayerIdIndex = findIndex(currentSpecialLayerData, [
    "id",
    specialLayerId,
  ]);

  // if current element exist into special layer, dont add new.
  // let user redirect to clicked location.
  if (specialLayerIdIndex !== -1) {
    return { isExist: true };
  }

  switch (featureType) {
    case FEATURE_TYPES.POINT: {
      let coords = element.coordinates;
      // google expects lat long obj, if coordinates are array of lat long -> convert to obj
      if (Array.isArray(element.coordinates)) {
        coords = pointCoordsToLatLongMap(element.coordinates);
      }

      return {
        isExist: false,
        mapPosition: {
          center: coords,
          currentZoom: 18,
        },
        specialLayerDataList: [
          {
            id: specialLayerId,
            featureType: FEATURE_TYPES.POINT,
            coordinates: coords,
            viewOptions: {
              ...element,
              ...viewOptions,
              zIndex: zIndexMapping.highlighted,
              iconType: CUSTOM_ICONS.blue_marker,
              pin: undefined,
              // animation: 1,
              ...pick(mapPosition, [
                "iconWidth",
                "iconHeight",
                "anchorX",
                "anchorY",
              ]),
            },
          },
        ],
      };
    }

    case FEATURE_TYPES.POLYGON: {
      let coords = element.coordinates;
      let center = element.center;
      // google expects lat long obj, if coordinates are array of lat long -> convert to obj
      if (Array.isArray(get(coords, "0"))) {
        coords = coordsToLatLongMap(coords);
      }

      if (Array.isArray(center)) {
        center = pointCoordsToLatLongMap(center);
      }

      return {
        isExist: false,
        mapPosition: {
          center: center,
          currentZoom: 18,
        },
        specialLayerDataList: [
          {
            id: specialLayerId,
            featureType: FEATURE_TYPES.POLYGON,
            coordinates: coords,
            viewOptions: {
              ...viewOptions,
              ...HIGHLIGHTED_POLYGON_OPTIONS,
              zIndex: zIndexMapping.highlighted,
              icon: undefined,
              pin: undefined,
            },
          },
        ],
      };
    }

    case FEATURE_TYPES.MULTI_POLYGON: {
      let coords = element.coordinates;
      let center = element.center;
      // google expects lat long obj, if coordinates are array of lat long -> convert to obj
      if (Array.isArray(get(coords, "0.0"))) {
        coords = coordsToLatLongMap(coords, true);
      }

      if (Array.isArray(center)) {
        center = pointCoordsToLatLongMap(center);
      }

      return {
        isExist: false,
        mapPosition: {
          center: center,
          currentZoom: 18,
        },
        specialLayerDataList: [
          {
            id: specialLayerId,
            featureType: FEATURE_TYPES.MULTI_POLYGON,
            coordinates: coords,
            viewOptions: {
              ...viewOptions,
              ...HIGHLIGHTED_POLYGON_OPTIONS,
              zIndex: zIndexMapping.highlighted,
              icon: undefined,
              pin: undefined,
            },
          },
        ],
      };
    }

    case FEATURE_TYPES.POLYLINE: {
      let coords = element.coordinates;
      let center = element.center;
      // google expects lat long obj, if coordinates are array of lat long -> convert to obj
      if (Array.isArray(get(coords, "0"))) {
        coords = coordsToLatLongMap(coords);
      }

      if (Array.isArray(center)) {
        center = pointCoordsToLatLongMap(center);
      }

      return {
        isExist: false,
        mapPosition: {
          center: center,
          currentZoom: 18,
        },
        specialLayerDataList: [
          {
            id: specialLayerId,
            featureType: FEATURE_TYPES.POLYLINE,
            coordinates: coords,
            viewOptions: {
              ...viewOptions,
              ...HIGHLIGHTED_POLYLINE_OPTIONS,
              zIndex: zIndexMapping.highlighted,
              icon: undefined,
              pin: undefined,
              icons: [
                {
                  icon: {
                    path: 2.0,
                    strokeOpacity: 1,
                  },
                  offset: "100%",
                },
                {
                  icon: {
                    path: 2.0,
                    strokeOpacity: 1,
                  },
                  offset: "50%",
                },
                {
                  icon: {
                    path: 0.0,
                    strokeOpacity: 1,
                  },
                  offset: "0%",
                },
              ],
            },
          },
        ],
      };
    }

    default:
      return { isExist: true };
  }
};

export const onShowOnMapClick =
  (element, layerKey, hidePopup = true) =>
  (dispatch, getState) => {
    const featureType = get(LayerKeyMappings, [layerKey, "featureType"]);
    const viewOptions = LayerKeyMappings[layerKey]["getViewOptions"](element);

    // required to scale highlighted point element
    const storeState = getState();
    const mapPosition = getPlanningMapPosition(storeState);
    const currentSpecialLayerData = getPlanningSpecialLayerData(storeState);

    const specialLayerId = generateSpecialLayerId(
      element,
      SPECIAL_LAYER_HIGHLIGHT_TYPES.view_on_map
    );
    const specialLayerIdIndex = findIndex(currentSpecialLayerData, [
      "id",
      specialLayerId,
    ]);

    switch (featureType) {
      case FEATURE_TYPES.POINT: {
        let coords = element.coordinates;
        // google expects lat long obj, if coordinates are array of lat long -> convert to obj
        if (Array.isArray(element.coordinates)) {
          coords = pointCoordsToLatLongMap(element.coordinates);
        }

        dispatch(
          setMapPosition({
            center: coords,
            currentZoom: 18,
          })
        );

        // if current element exist into special layer, dont add new.
        // let user redirect to clicked location.
        if (specialLayerIdIndex !== -1) {
          break;
        }

        dispatch(
          setSpecialLayerData([
            {
              id: specialLayerId,
              featureType: FEATURE_TYPES.POINT,
              coordinates: coords,
              viewOptions: {
                ...element,
                ...viewOptions,
                zIndex: zIndexMapping.highlighted,
                iconType: CUSTOM_ICONS.blue_marker,
                pin: undefined,
                // animation: 1,
                ...pick(mapPosition, [
                  "iconWidth",
                  "iconHeight",
                  "anchorX",
                  "anchorY",
                ]),
              },
            },
          ])
        );
        break;
      }

      case FEATURE_TYPES.POLYGON: {
        let coords = element.coordinates;
        let center = element.center;
        // google expects lat long obj, if coordinates are array of lat long -> convert to obj
        if (Array.isArray(get(coords, "0"))) {
          coords = coordsToLatLongMap(coords);
        }

        if (Array.isArray(center)) {
          center = pointCoordsToLatLongMap(center);
        }

        dispatch(
          setMapPosition({
            center: center,
            currentZoom: 18,
          })
        );

        // if current element exist into special layer, dont add new.
        // let user redirect to clicked location.
        if (specialLayerIdIndex !== -1) {
          break;
        }

        dispatch(
          setSpecialLayerData([
            {
              id: specialLayerId,
              featureType: FEATURE_TYPES.POLYGON,
              coordinates: coords,
              viewOptions: {
                ...viewOptions,
                ...HIGHLIGHTED_POLYGON_OPTIONS,
                zIndex: zIndexMapping.highlighted,
                icon: undefined,
                pin: undefined,
              },
            },
          ])
        );

        break;
      }

      case FEATURE_TYPES.MULTI_POLYGON: {
        let coords = element.coordinates;
        let center = element.center;
        // google expects lat long obj, if coordinates are array of lat long -> convert to obj
        if (Array.isArray(get(coords, "0.0"))) {
          coords = coordsToLatLongMap(coords, true);
        }

        if (Array.isArray(center)) {
          center = pointCoordsToLatLongMap(center);
        }

        dispatch(
          setMapPosition({
            center: center,
            currentZoom: 18,
          })
        );

        // if current element exist into special layer, dont add new.
        // let user redirect to clicked location.
        if (specialLayerIdIndex !== -1) {
          break;
        }

        dispatch(
          setSpecialLayerData([
            {
              id: specialLayerId,
              featureType: FEATURE_TYPES.MULTI_POLYGON,
              coordinates: coords,
              viewOptions: {
                ...viewOptions,
                ...HIGHLIGHTED_POLYGON_OPTIONS,
                zIndex: zIndexMapping.highlighted,
                icon: undefined,
                pin: undefined,
              },
            },
          ])
        );

        break;
      }

      case FEATURE_TYPES.POLYLINE: {
        let coords = element.coordinates;
        let center = element.center;
        // google expects lat long obj, if coordinates are array of lat long -> convert to obj
        if (Array.isArray(get(coords, "0"))) {
          coords = coordsToLatLongMap(coords);
        }

        if (Array.isArray(center)) {
          center = pointCoordsToLatLongMap(center);
        }

        dispatch(
          setMapPosition({
            center: center,
            currentZoom: 18,
          })
        );

        // if current element exist into special layer, dont add new.
        // let user redirect to clicked location.
        if (specialLayerIdIndex !== -1) {
          break;
        }

        dispatch(
          setSpecialLayerData([
            {
              id: specialLayerId,
              featureType: FEATURE_TYPES.POLYLINE,
              coordinates: coords,
              viewOptions: {
                ...viewOptions,
                ...HIGHLIGHTED_POLYLINE_OPTIONS,
                zIndex: zIndexMapping.highlighted,
                icon: undefined,
                pin: undefined,
                icons: [
                  {
                    icon: {
                      path: 2.0,
                      strokeOpacity: 1,
                    },
                    offset: "100%",
                  },
                  {
                    icon: {
                      path: 2.0,
                      strokeOpacity: 1,
                    },
                    offset: "50%",
                  },
                  {
                    icon: {
                      path: 0.0,
                      strokeOpacity: 1,
                    },
                    offset: "0%",
                  },
                ],
              },
            },
          ])
        );
        break;
      }

      default:
        break;
    }

    // hide popup if required.
    if (hidePopup) {
      dispatch(toggleMapPopupMinimize(false));
    }
  };

export const onTicketWoShowOnMapClick = (woData) => (dispatch) => {
  const { element, layer_key, wo_type } = woData;
  if (wo_type === "customer") {
    dispatch(onCustomerShowOnMapClick(woData));
  } else {
    dispatch(onShowOnMapClick(element, layer_key));
  }
};

// src/CustomerTicket/screens/CustomerWorkorderListScreen.js
export const onCustomerShowOnMapClick = (element) => (dispatch, getState) => {
  const storeState = getState();
  const currentSpecialLayerData = getPlanningSpecialLayerData(storeState);
  const currMapPosition = getPlanningMapPosition(storeState);

  let specialLayerData = [];
  let mapPosition;
  const elementList = get(element, "element_list") || [];

  for (let index = 0; index < elementList.length; index++) {
    const element = elementList[index];
    const elementLayerKey = element.layer_key;
    const specialLayerDetails = getSpecialLayerDataFromElement(
      element,
      elementLayerKey,
      currentSpecialLayerData,
      currMapPosition
    );

    if (!specialLayerDetails.isExist) {
      specialLayerData = specialLayerData.concat(
        specialLayerDetails.specialLayerDataList
      );
      if (elementLayerKey === CustomerLayer.LAYER_KEY) {
        mapPosition = specialLayerDetails.mapPosition;
      }
    }
  }

  if (mapPosition) {
    dispatch(setMapPosition(mapPosition));
  }

  if (!!size(specialLayerData)) {
    dispatch(setSpecialLayerData(specialLayerData));
  }
};

export const onTableDetailsShowOnMapClick =
  (element, layerKey) => (dispatch) => {
    const featureType = get(LayerKeyMappings, [layerKey, "featureType"]);
    let coordinates;
    let center;

    if (featureType === FEATURE_TYPES.POINT) {
      coordinates = pointCoordsToLatLongMap(element.coordinates);
    }
    //
    else if (featureType === FEATURE_TYPES.POLYGON) {
      coordinates = coordsToLatLongMap(element.coordinates);
      center = pointCoordsToLatLongMap(element.center);
    }
    //
    else if (featureType === FEATURE_TYPES.MULTI_POLYGON) {
      coordinates = coordsToLatLongMap(element.coordinates, true);
      center = pointCoordsToLatLongMap(element.center);
    }
    //
    else if (featureType === FEATURE_TYPES.POLYLINE) {
      coordinates = coordsToLatLongMap(element.coordinates);
      center = pointCoordsToLatLongMap(element.center);
    }

    dispatch(
      onShowOnMapClick(
        {
          ...element,
          coordinates,
          center,
        },
        layerKey
      )
    );
  };

export const onAssociatedElementShowOnMapClick =
  (element, layerKey) => (dispatch) => {
    dispatch(onShowOnMapClick(element, layerKey));
  };
// shape: { element: gis element, hidePopup: decide fire action or not for redux popup}
export const onElementListItemClick =
  (element, hidePopup = true) =>
  (dispatch) => {
    const layerKey = element.layerKey;
    dispatch(onShowOnMapClick(element, layerKey, hidePopup));
  };

export const onLayerElementListItemClick =
  (element, layerKey) => (dispatch) => {
    dispatch(onShowOnMapClick(element, layerKey));
  };

export const onFetchLayerListDetailsSuccess = (layerConfData) => (dispatch) => {
  // res shape same as layerConfigs bellow
  if (!!size(layerConfData)) {
    for (let lc_ind = 0; lc_ind < layerConfData.length; lc_ind++) {
      const { layer_key, is_configurable, configuration } =
        layerConfData[lc_ind];
      if (is_configurable) {
        // if layerConfData is there set layer configs in redux
        dispatch(
          setLayerConfigurations({
            layerKey: layer_key,
            configurationList: configuration,
          })
        );
        // select default configs to show first
        dispatch(
          selectConfiguration({
            layerKey: layer_key,
            configuration: configuration[0],
          })
        );
      }
    }
  }
};

export const onLayerTabElementList = (layerKey) => (dispatch, getState) => {
  const storeState = getState();
  // element list based on cached or master data list
  let elementResultList = getMasterViewData(layerKey);
  // fire next event : listElementsOnMap, with new list data
  dispatch(
    setMapState({
      event: PLANNING_EVENT.layerElementsOnMap,
      data: {
        elementList: elementResultList,
        elementLayerKey: layerKey,
      },
    })
  );
};

export const onHistoryClick = (layerKey, elementId) => (dispatch) => {
  dispatch(
    setMapState({
      event: PLANNING_EVENT.showHistory,
      layerKey,
      data: {
        elementId,
        layerKey,
      },
    })
  );
};

export const onHistoryGeomClick = (geomChanges, layerKey) => (dispatch) => {
  const isGeomChanges = !!size(geomChanges);
  const isRegion = layerKey === "region";

  if (isGeomChanges) {
    const featureType = get(LayerKeyMappings, [layerKey, "featureType"]);
    const viewOptions = LayerKeyMappings[layerKey]["getViewOptions"]({});
    const hasOldGeometry = isRegion
      ? !!size(geomChanges.polygon.old)
      : !!size(geomChanges.geometry.old);

    let newCoordinates, oldCoordinates;
    // featureType specific view options
    let newViewOptions,
      oldViewOptions = {};
    let newCenter;
    let newZoomLevel = 18;

    switch (featureType) {
      case FEATURE_TYPES.POINT: {
        newCoordinates = pointCoordsToLatLongMap(geomChanges.geometry.new);
        if (hasOldGeometry)
          oldCoordinates = pointCoordsToLatLongMap(geomChanges.geometry.old);

        newViewOptions = {
          iconType: CUSTOM_ICONS.green_marker,
        };

        oldViewOptions = {
          iconType: CUSTOM_ICONS.blue_marker,
        };

        newCenter = pointCoordsToLatLongMap(geomChanges.geometry.new);

        break;
      }
      case FEATURE_TYPES.POLYGON: {
        newCoordinates = coordsToLatLongMap(geomChanges.geometry.new);
        oldCoordinates = hasOldGeometry
          ? coordsToLatLongMap(geomChanges.geometry.old)
          : [];

        newViewOptions = {
          strokeColor: COLORS.success.main,
          fillColor: COLORS.success.main,
        };

        oldViewOptions = {
          strokeColor: COLORS.error.main,
          fillColor: COLORS.error.main,
        };

        if (!!get(geomChanges, "center.new")) {
          newCenter = pointCoordsToLatLongMap(geomChanges.center.new);
        }

        break;
      }

      case FEATURE_TYPES.MULTI_POLYGON: {
        // geomKey can be polygon or geometry
        const geomKey = has(geomChanges, "polygon") ? "polygon" : "geometry";
        const newGeom = get(geomChanges, [geomKey, "new"]);
        const oldGeom = get(geomChanges, [geomKey, "new"]);

        newCoordinates = coordsToLatLongMap(newGeom, true);
        oldCoordinates = hasOldGeometry
          ? coordsToLatLongMap(oldGeom, true)
          : [];

        newViewOptions = {
          strokeColor: COLORS.success.main,
          fillColor: COLORS.success.main,
        };

        oldViewOptions = {
          strokeColor: COLORS.error.main,
          fillColor: COLORS.error.main,
        };

        if (!!get(geomChanges, "center.new")) {
          newCenter = pointCoordsToLatLongMap(geomChanges.center.new);
        }
        break;
      }

      case FEATURE_TYPES.POLYLINE: {
        newCoordinates = coordsToLatLongMap(geomChanges.geometry.new);
        oldCoordinates = hasOldGeometry
          ? coordsToLatLongMap(geomChanges.geometry.old)
          : [];

        newViewOptions = {
          strokeColor: COLORS.success.main,
          fillColor: COLORS.success.main,
        };

        oldViewOptions = {
          strokeColor: COLORS.error.main,
          fillColor: COLORS.error.main,
        };

        if (!!get(geomChanges, "center.new")) {
          newCenter = pointCoordsToLatLongMap(geomChanges.center.new);
        }

        break;
      }

      default:
        break;
    }
    const newSpecialLayerId = generateSpecialLayerId(
      {},
      SPECIAL_LAYER_HIGHLIGHT_TYPES.history + "_new"
    );
    const specialLayerData = [
      {
        id: newSpecialLayerId,
        featureType,
        coordinates: newCoordinates,
        viewOptions: {
          ...viewOptions,
          zIndex: zIndexMapping.highlighted + 1,
          ...newViewOptions,
        },
      },
    ];

    if (hasOldGeometry) {
      const oldSpecialLayerId = generateSpecialLayerId(
        {},
        SPECIAL_LAYER_HIGHLIGHT_TYPES.history + "_old"
      );

      specialLayerData.push({
        id: oldSpecialLayerId,
        featureType,
        coordinates: oldCoordinates,
        viewOptions: {
          ...viewOptions,
          zIndex: zIndexMapping.highlighted,
          ...oldViewOptions,
        },
      });
    }

    dispatch(setSpecialLayerData(specialLayerData));
    dispatch(setPopupMinimize(true));
    if (!!newCenter) {
      dispatch(
        setMapPosition({
          center: newCenter, // center always to new geom
          currentZoom: newZoomLevel,
        })
      );
    }
  } else {
    dispatch(
      addNotification({
        type: NOTIFICATION_TYPE.INFO,
        title: "History",
        text: "No geometry changes found for this history",
      })
    );
  }
};

export const polygonOnMapClick = (coordinates) => (dispatch) => {
  const specialLayerId = generateSpecialLayerId(
    {},
    SPECIAL_LAYER_HIGHLIGHT_TYPES.on_map_click_highlight
  );
  dispatch(
    setSpecialLayerData([
      {
        id: specialLayerId,
        featureType: FEATURE_TYPES.POLYGON,
        coordinates,
        viewOptions: {
          strokeColor: "yellow",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "yellow",
          fillOpacity: 0.3,
          clickable: false,
          draggable: false,
          editable: false,
        },
      },
    ])
  );
};

///////////////////////////////////
//         LC utils              //
//////////////////////////////////

// use to oppe empty LC form
export const openLCForm = () => (dispatch) => {
  dispatch(
    setMapState({
      event: PLANNING_EVENT.showTracebackForm,
    })
  );
};

// use to oppe LC form with data
export const openLCFormWithData = (payload) => (dispatch, getState) => {
  const storeState = getState();

  const layerNetworkState = getTracebackAllLayersNetworkStatus(storeState);
  const regionIdList = getSelectedRegionIds(storeState);

  let layerKey;
  if (payload.to_layer && payload.from_layer) {
    return;
  } else if (payload.to_layer) {
    layerKey = payload.to_layer;
  } else if (payload.from_layer) {
    layerKey = payload.from_layer;
  }

  const isFetched = !!get(layerNetworkState, [layerKey, "isFetched"]);
  if (!isFetched) {
    dispatch(
      fetchTracebackLayerDataThunk({
        regionIdList,
        layerKey,
      })
    );
  }
  dispatch(setTracebackForm(payload));

  // open trace form after setting data into reducer
  setTimeout(() => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showTracebackForm,
      })
    );
  }, 1000);
};
