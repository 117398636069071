import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CachedIcon from "@mui/icons-material/Cached";
import CircularProgress from "@mui/material/CircularProgress";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";
import AttrDetailsTable from "planning/GisMap/components/Catv/AttrDetailsTable";
import CustomSelect from "components/common/FormFields/CustomSelect";
import { GisElementTableContentLoader } from "planning/GisMap/components/GisMapPopups/GisMapPopupLoader";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  setMapState,
  setPopupMinimize,
} from "planning/data/planningGis.reducer";
import { fetchElementPortDetails } from "planning/data/port.services";
import { fetchElementInterfaceFieldsLastData } from "planning/data/catv/catv.services";
import useOnDemand from "planning/GisMap/hooks/useOnDemand";

const InterfaceAttrsPopup = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const mapState = useSelector(getPlanningMapState);
  const { data: mapStateData, isPopupMinimize, layerKey } = mapState;
  const { elementId, selectedInterface } = mapStateData;

  const { data: portDetails, isLoading } = useQuery(
    ["elementPortDetails", layerKey, elementId],
    fetchElementPortDetails,
    { refetchOnWindowFocus: false }
  );

  const {
    mutate: fetchElementInterfaceMutation,
    isLoading: isInterfaceLoading,
    data: interfaceData,
    isError,
  } = useMutation(fetchElementInterfaceFieldsLastData);

  const { handleOnDemandClick, isOnDemandLoading } = useOnDemand({
    layerKey,
    elementId,
    refreshType: "interface",
    onApiSuccess: () => {
      fetchElementInterfaceMutation({
        layerKey,
        elementId,
        interfaceKey: selectedInterface?.key,
      });
    },
  });

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handlePopupMinimize = useCallback(() => {
    dispatch(setPopupMinimize(!isPopupMinimize));
  }, [isPopupMinimize]);

  const handleInterfaceSelect = useCallback(
    (selectedValue) => {
      let updatedMapState = cloneDeep(mapState);
      updatedMapState.data.selectedInterface = selectedValue;
      dispatch(setMapState(updatedMapState));
      fetchElementInterfaceMutation({
        layerKey,
        elementId,
        interfaceKey: selectedValue.key,
      });
    },
    [layerKey, elementId, mapState]
  );

  let Content;
  if (!isPopupMinimize) {
    Content = (
      <Box>
        <Stack
          sx={{ boxShadow: "0px 5px 7px -3px rgba(122,122,122,0.51)" }}
          p={2}
          direction="row-reverse"
          spacing={2}
        >
          <Tooltip title={"Refresh"} key={"ondemand"}>
            <IconButton
              aria-label={"ondemand"}
              color={"secondary"}
              sx={{
                border: "1px solid",
                borderRadius: 1,
                minWidth: "42px",
                minHeight: "42px",
              }}
              onClick={handleOnDemandClick}
              disabled={!selectedInterface}
            >
              {isOnDemandLoading ? (
                <CircularProgress color="secondary" size={12} />
              ) : (
                <CachedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
        <Box p={2}>
          <CustomSelect
            label="Select Interface"
            options={portDetails}
            menuPortalTarget={document.body}
            value={selectedInterface}
            onChange={handleInterfaceSelect}
            isLoading={isLoading}
            getOptionLabel={(o) => o["description"]}
            getOptionValue={(o) => o["key"]}
            formatOptionLabel={(data) => {
              return (
                <Box>
                  <Typography variant="subtitle1" sx={{ lineHeight: 1.1 }}>
                    {get(data, "key", "")}
                  </Typography>
                  <Typography variant="caption">
                    {get(data, "description", "")}
                  </Typography>
                </Box>
              );
            }}
          />
        </Box>
        {selectedInterface ? (
          <>
            <Divider />
            {isInterfaceLoading ? (
              <GisElementTableContentLoader />
            ) : isError ? (
              <Box p={2} pt={10} sx={{ minHeight: "300px" }}>
                <Typography variant="h5">
                  Error occured while fetching data
                </Typography>
              </Box>
            ) : (
              <AttrDetailsTable dataList={interfaceData} />
            )}
          </>
        ) : (
          <>
            <Divider />
            <Box p={2} pt={10} sx={{ minHeight: "300px" }}>
              <Typography textAlign="center" variant="h5">
                No Interface selected
              </Typography>
            </Box>
          </>
        )}
      </Box>
    );
  }

  return (
    <GisMapPopups dragId="InterfaceAttrsPopup">
      <Box minWidth="350px" maxWidth="650px">
        <TableHeader
          title="Interface Attribute"
          minimized={isPopupMinimize}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
        {Content}
      </Box>
    </GisMapPopups>
  );
};

export default InterfaceAttrsPopup;
