import has from "lodash/has";

import BlueMarkerIcon from "assets/markers/blue_marker.svg";
import RedMarkerIcon from "assets/markers/red_marker.svg";
import GreenMarkerIcon from "assets/markers/green_marker.svg";

import { LayerKeyMappings } from "./utils";

export const CUSTOM_ICONS = {
  blue_marker: "blue_marker",
  red_marker: "red_marker",
  green_marker: "green_marker",
};

/**
 * return icon based on iconType which can be layer element icn or custom icon from above
 * this icon property can be used directly in Marker.icon to show on map
 */
export const getSpecialLayerIcon = (options) => {
  const iconType = options?.iconType;

  // if there is no iconType than directly return icon from options
  if (!iconType) {
    // run conversions here if required for few cases
    // i.e. convert anchor / labelOrigin to google point classes
    return options.icon;
  }
  // this can change based on iconType
  let anchor = { x: 14, y: 24 };
  let scaledSize = null;

  // custom Icons
  const iconMapper = {
    [CUSTOM_ICONS.blue_marker]: BlueMarkerIcon,
    [CUSTOM_ICONS.red_marker]: RedMarkerIcon,
    [CUSTOM_ICONS.green_marker]: GreenMarkerIcon,
  };

  let url;
  // handle if iconType is one of the layerKeys
  if (has(LayerKeyMappings, iconType)) {
    const layerViewOptions =
      LayerKeyMappings[iconType]["getViewOptions"](options);
    url = layerViewOptions.icon;
  }
  // handle if iconType is one of the custom Icons
  else if (has(iconMapper, iconType)) {
    url = iconMapper[iconType];
  }
  // if no such iconType found throw error
  else {
    throw `Invalid iconType, Please implement custom icon type: ${iconType}`;
  }

  // set anchor if exist
  if (options.anchorX && options.anchorY) {
    anchor = new window.google.maps.Point(options.anchorX, options.anchorY);
  }

  // set scaledSize if exist
  if (options.iconWidth && options.iconHeight) {
    scaledSize = new window.google.maps.Size(
      options.iconWidth,
      options.iconHeight
    );
  }

  return {
    url,
    anchor,
    scaledSize,
  };
};
