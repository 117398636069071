import React from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";

import noop from "lodash/noop";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Portal from "@mui/material/Portal";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import styled from "@mui/material/styles/styled";

import { getCurrentPage } from "redux/selectors/appState.selectors";
import { PAGE_SELCTIONS } from "redux/reducers/appState.reducer";

const StyledDragIcon = styled(DragIndicatorIcon)(({ theme }) => ({
  position: "absolute",
  color: "white",
  cursor: "move",
  backgroundColor: theme.palette.primary.main,
  width: `34px`,
  height: "56px",
  left: `-34px`,
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
}));

/**
 * Wrapper around any popup shown on PlanningPage over GisMap
 * handles positioning
 * children have to handle height / width cap
 *
 * Children
 *  ElementDetailsTable
 *  DynamicForm
 *  Paper popups on map add / edit events
 */
const GisMapPopups = ({
  children,
  dragId,
  isFullScreen = false,
  onDrag = noop,
  onDragStop = noop,
}) => {
  const currentPage = useSelector(getCurrentPage);

  if (currentPage === PAGE_SELCTIONS.PLANNING_PAGE) {
    const style = isFullScreen
      ? { position: "fixed", top: 0, right: 0, left: 0, bottom: 0, zIndex: 2 }
      : {
          position: "fixed",
          top: "10%",
          right: "10%",
          zIndex: 2,
        };

    return (
      <Portal>
        <Draggable
          handle={`#${dragId}`}
          disabled={!Boolean(dragId)}
          bounds="body" // do not overflow out of body
          onDrag={onDrag}
          onStop={onDragStop}
        >
          <Box sx={style}>
            <Paper
              elevation={3}
              className={`g-relative ${isFullScreen ? "full-height" : ""}`}
            >
              {dragId ? <StyledDragIcon id={dragId} /> : null}
              {children}
            </Paper>
          </Box>
        </Draggable>
      </Portal>
    );
  } else {
    // Devices page
    const wrapperStyle = isFullScreen
      ? {
          position: "absolute",
          left: "5px",
          right: "5px",
          top: "5px",
          bottom: "5px",
          zIndex: 2,
        }
      : // left calculation: .pl-sidebar-abs width + padding
        { position: "absolute", left: "390px", top: "5px" };

    return (
      <Box style={wrapperStyle}>
        <Paper
          elevation={3}
          className={`g-relative ${isFullScreen ? "full-height" : ""}`}
        >
          {children}
        </Paper>
      </Box>
    );
  }
};

export default GisMapPopups;
