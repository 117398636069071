import React from "react";
import { useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { getPlanningMapPosition } from "planning/data/planningGis.selectors";

const MapZoomLevel = () => {
  /**
   * Parent:
   *    GisMap
   */
  const { currentZoom } = useSelector(getPlanningMapPosition);

  return (
    <Paper className="zoom-block">
      <Typography variant="button" color="text.secondary">
        Zoom: {currentZoom}
      </Typography>
    </Paper>
  );
};

export default MapZoomLevel;
