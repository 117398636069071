import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import includes from "lodash/includes";
import get from "lodash/get";
import find from "lodash/find";
import size from "lodash/size";

import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { ALL_SELECT_OPTIONS, LayerKeyMappings } from "planning/GisMap/utils";
import { onHistoryGeomClick } from "planning/data/planning.actions";

const HistoryTable = ({ history }) => {
  const { field_changes, geom_changes, layer_key, user, timestamp } = history;

  const dispatch = useDispatch();
  const fieldChanges = JSON.parse(field_changes);
  const geomChanges = JSON.parse(geom_changes);
  const isGeomChanges = !!size(geomChanges);

  const handleGeomClick = useCallback(() => {
    dispatch(onHistoryGeomClick(geomChanges, layer_key));
  }, [geomChanges, layer_key]);

  const rowDefs = useMemo(() => {
    const getElementTableFieldsFn = get(LayerKeyMappings, [
      layer_key,
      "getElementTableFields",
    ]);
    const currentRowDefs = getElementTableFieldsFn
      ? getElementTableFieldsFn()
      : [];

    const fieldKeys = Object.keys(fieldChanges);
    const newCurrentRowDefs = [];
    // create new config
    for (let index = 0; index < currentRowDefs.length; index++) {
      const defs = currentRowDefs[index];
      const hasDisplayAt = defs.field.indexOf("_display");
      // config fields includes in history
      if (includes(fieldKeys, defs.field)) {
        newCurrentRowDefs.push(defs);
      }
      // choice fields includes in history
      if (hasDisplayAt !== -1) {
        const field = defs.field.slice(0, hasDisplayAt);
        if (includes(fieldKeys, defs.field)) {
          newCurrentRowDefs.push({ ...defs, field: field });
        }
      }
    }

    return newCurrentRowDefs;
  }, [layer_key, fieldChanges]);

  if (!size(rowDefs)) {
    return (
      <Box pb={2}>
        <TableContainer
          component={Paper}
          sx={{
            width: "max-content",
            minWidth: "350px",
          }}
        >
          <Typography variant="body1" p={2}>
            No field changes.
          </Typography>
        </TableContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
        >
          <Typography variant="button" textAlign="center">
            {get(user, "name", "")} @{" "}
            {format(new Date(timestamp), "dd/MM/YYY hh:mm")}
          </Typography>
          {isGeomChanges && (
            <Button onClick={handleGeomClick}>View Geom Changes</Button>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box pb={2}>
      <TableContainer
        component={Paper}
        sx={{
          width: "max-content",
          minWidth: "350px",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "90px" }}>Change</TableCell>
              {rowDefs.map((def) => {
                return (
                  <TableCell sx={{ width: "90px" }} key={def.field}>
                    {def.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "90px" }}>New</TableCell>
              {rowDefs.map((row) => {
                return (
                  <HistoryTableCell
                    key={row.field}
                    row={row}
                    elemData={get(fieldChanges, [row.field, "new"], "")}
                  />
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell sx={{ width: "90px" }}>Old</TableCell>
              {rowDefs.map((row) => {
                return (
                  <HistoryTableCell
                    key={row.field}
                    row={row}
                    elemData={get(fieldChanges, [row.field, "old"], "")}
                  />
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
      >
        <Typography variant="button" textAlign="center">
          {get(user, "name", "")} @{" "}
          {format(new Date(timestamp), "dd/MM/YYY hh:mm")}
        </Typography>
        {isGeomChanges && (
          <Button onClick={handleGeomClick}>View Geom Changes</Button>
        )}
      </Box>
    </Box>
  );
};

const HistoryTableCell = ({ elemData, row }) => {
  const { type, field } = row;
  let ValueCell;

  switch (type) {
    case "status":
      const elemStatus = elemData;
      if (!elemStatus) {
        ValueCell = <Typography> </Typography>;
        break;
      }
      const color =
        elemStatus === "RFS"
          ? "success"
          : elemStatus === "L1" || elemStatus === "L2"
          ? "warning"
          : "error"; // IA: In active
      const elemStatusLabel = get(
        find(ALL_SELECT_OPTIONS, ["value", elemStatus]),
        "label",
        elemStatus
      );
      ValueCell = (
        <Box textAlign="center">
          <Chip label={elemStatusLabel} color={color} />
        </Box>
      );
      break;

    case "boolean":
      const elemBoolData = elemData;

      ValueCell = (
        <Box textAlign="center">
          <IconButton color={elemBoolData ? "success" : "error"}>
            {elemBoolData ? <CheckIcon /> : <ClearIcon />}
          </IconButton>
        </Box>
      );
      break;

    default:
      ValueCell = <Typography>{elemData}</Typography>;
      break;
  }

  return (
    <TableCell id={field} sx={{ width: "90px" }}>
      {ValueCell}
    </TableCell>
  );
};

export default HistoryTable;
