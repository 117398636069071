import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import get from "lodash/get";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

import LanguageIcon from "@mui/icons-material/Language";

import { LayerKeyMappings } from "planning/GisMap/utils";
import {
  onElementListItemClick,
  openElementDetails,
} from "planning/data/planning.actions";

const TraceElementList = ({ traceBackTree }) => {
  /**
   * Parent:
   *    TracebackDetails
   */

  const dispatch = useDispatch();

  const handleShowOnMap = useCallback(
    (element) => () => {
      dispatch(
        onElementListItemClick(
          { ...element, layerKey: element.layer_key },
          false
        )
      );
    },
    []
  );

  const handleShowDetails = useCallback(
    (element) => () => {
      dispatch(
        openElementDetails({
          layerKey: element.layer_key,
          elementId: element.id,
        })
      );
    },
    []
  );

  return (
    <Stack
      spacing={1}
      divider={
        <Divider
          sx={{
            minWidth: "60%",
          }}
        />
      }
      py={1}
      alignItems="center"
    >
      {traceBackTree.map((element) => {
        const {
          lc_id,
          layer_key,
          name = "",
          unique_id,
          is_through,
          parent,
          parent_port,
        } = element;
        const Icon =
          LayerKeyMappings[layer_key]["getViewOptions"](element).icon;
        const parentStr = `${parent} ${parent_port ? "-" + parent_port : ""} ${
          is_through ? "- " : "" // Add bold T after this
        }`;

        return (
          <Stack
            key={parentStr}
            direction="row"
            spacing={1}
            alignItems="center"
            py={0.5}
            sx={{
              minWidth: "60%",
            }}
          >
            <Paper
              sx={{
                width: "42px",
                height: "42px",
                lineHeight: "42px",
                textAlign: "center",
                marginLeft: "8px",
              }}
            >
              <img className="responsive-img" src={Icon} alt={layer_key} />
            </Paper>
            <Stack flex={1} flexDirection="row">
              <Box
                className="clickable"
                flex={1}
                onClick={handleShowDetails(element)}
              >
                <Typography variant="subtitle1" lineHeight={1.1}>
                  {name}
                </Typography>
                <Typography variant="caption">
                  P: {parentStr}
                  {is_through ? <b>T</b> : null}
                </Typography>
                <br />
                <Typography variant="caption">
                  ({lc_id}) #{unique_id}
                </Typography>
              </Box>
              <Tooltip title="Show on map">
                <IconButton
                  sx={{
                    marginLeft: "8px",
                    marginRight: "8px",
                  }}
                  aria-label="show-location"
                  onClick={handleShowOnMap(element)}
                >
                  <LanguageIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default TraceElementList;
