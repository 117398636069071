import React, { useCallback, useState } from "react";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import get from "lodash/get";
import size from "lodash/size";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";

import AddImage from "./AddImage";
import ConfirmDialog from "components/common/ConfirmDialog";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { deleteImage, fetchImageList } from "planning/data/layer.services";

const ImageGrid = () => {
  const { data: mapStateData, layerKey } = useSelector(getPlanningMapState);
  const {
    isLoading,
    data: images,
    isError,
  } = useQuery(["imageList", layerKey, mapStateData.elementId], fetchImageList);

  const handleLocationClick = (latLng) => () => {
    if (!latLng) return;
    window.open("https://maps.google.com?q=" + latLng[1] + "," + latLng[0]);
  };

  if (!size(images)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ minHeight: "400px", minWidth: "300px" }}
      >
        <Typography variant="h5">No images uploaded</Typography>
        <AddImage />
      </Box>
    );
  } else {
    return (
      <Box px={2}>
        <AddImage />
        <ImageList>
          {images.map((item) => {
            const name = get(item, "created_by.name");
            const upload_location = get(item, "upload_location");
            const time = format(
              new Date(item.timestamp),
              "do MMM yyyy, h:mm aaa"
            );
            return (
              <ImageListItem key={item.id}>
                <img
                  src={`${item.image}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.caption}
                  subtitle={name ? `${name} @ ${time}` : time}
                  actionIcon={
                    upload_location ? (
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${item.title}`}
                        onClick={handleLocationClick(upload_location)}
                      >
                        <ShareLocationIcon />
                      </IconButton>
                    ) : null
                  }
                />
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 1,
                  }}
                >
                  <DeleteImageButton layerKey={layerKey} imageId={item.id} />
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    );
  }
};

/**
 * Seperate delete icon and confirm popup
 * show delete loader and refetch image list after success delete
 */
const DeleteImageButton = ({ layerKey, imageId }) => {
  const [showPopup, setShowPopup] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: deleteImageMutation, isLoading } = useMutation(deleteImage, {
    onSuccess: () => {
      queryClient.invalidateQueries("imageList");
    },
  });

  const handleDeleteImage = useCallback(() => {
    deleteImageMutation({ layerKey, imageId });
  }, []);

  const handleShowPopup = useCallback(() => setShowPopup(true), []);
  const handleHidePopup = useCallback(() => setShowPopup(false), []);

  return (
    <>
      <IconButton color="error" onClick={handleShowPopup}>
        <DeleteOutlineOutlined />
      </IconButton>
      <ConfirmDialog
        show={showPopup}
        onClose={handleHidePopup}
        onConfirm={handleDeleteImage}
        isLoading={isLoading}
        title="Delete Image"
        text="Are you sure you want to delete this image ?"
      />
    </>
  );
};

export default ImageGrid;
