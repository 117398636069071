import React, { useCallback } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import get from "lodash/get";
import map from "lodash/map";
import snakeCase from "lodash/snakeCase";
import capitalize from "lodash/capitalize";
import { format } from "date-fns";

import { downloadHomePassData } from "planning/data/layer.services";
import { addNotification } from "redux/reducers/notification.reducer";

export const useDownloadHomePassData = (layerKey, coordinates) => {
  const dispatch = useDispatch();
  // download home pass mutation
  const { mutate: dwnloadHpDataMutation, isLoading: downloadLoading } =
    useMutation(downloadHomePassData, {
      onSuccess: (res) => {
        const file_name =
          snakeCase(layerKey) +
          "_" +
          format(new Date(), "dd/MM/yyyy") +
          "_" +
          format(new Date(), "hh:mm");
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        //
        let filename = "";
        if (
          res.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          filename = `${file_name}.xlsx`;
        } else if (res.type === "application/vnd.google-earth.kml+xml") {
          filename = `${file_name}.kml`;
        } else if (res.type === "application/zip") {
          filename = `${file_name}.zip`;
        }
        link.setAttribute("download", filename);

        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        dispatch(
          addNotification({
            type: "success",
            title: "Download Complete",
            text: "Your file download is successful",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "Download Failed",
            text: "failed to download data file",
          })
        );
      },
    });

  const handleDownloadHomePassData = useCallback(() => {
    // call download mutation
    dwnloadHpDataMutation({
      data: { export_type: "xlsx", coordinates },
      layerKey,
    });
  }, []);

  return {
    handleDownloadHomePassData,
    downloadLoading,
  };
};
