import { createAsyncThunk } from "@reduxjs/toolkit";
import get from "lodash/get";

import { addNotification } from "redux/reducers/notification.reducer";
import Api from "utils/api.utils";
import {
  apiGetConnectedPort,
  apiGetElementPortDetails,
  apiGetElementTransportDetails,
  apiPostAddPortConnection,
  apiPostElementSplicingDetails,
  apiPostRemovePortConnection,
} from "utils/url.constants";

export const fetchElementPortDetails = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetElementPortDetails(layerKey, elementId));
  return res.data;
};

export const fetchElementTransportDetails = async ({ queryKey }) => {
  const [_key, layerKey, elementId] = queryKey;
  const res = await Api.get(apiGetElementTransportDetails(layerKey, elementId));
  return res.data;
};

export const fetchElementPortSplicingDetails = async (data) => {
  const res = await Api.post(apiPostElementSplicingDetails(), data);
  return res.data;
};

export const postAddPortConnection = async (data, thunkAPI) => {
  try {
    const res = await Api.post(apiPostAddPortConnection(), data);
    return res.data;
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      let errMessage;
      if (status === 404) {
        errMessage = "Port not found.";
      } else if (status === 400) {
        const errorData = get(error, "response.data", {});
        for (const key in errorData) {
          if (Object.hasOwnProperty.call(errorData, key)) {
            const errorList = errorData[key];
            if (key === "__all__") {
              errMessage = get(errorList, 0, "Undefined Error");
            } else {
              errMessage = get(errorList, 0, "Undefined Error");
              errMessage = `${key} - ${errMessage}`;
            }
          }
        }
      } else {
        errMessage = "Something went wrong at our side. Please contact admin.";
      }

      thunkAPI.dispatch(
        addNotification({
          type: "error",
          title: "Error",
          text: errMessage,
        })
      );
    }
    throw error;
  }
};

export const postAddPortConnectionThunk = createAsyncThunk(
  "splicing/postAddPortConnection",
  postAddPortConnection
);

export const postRemovePortConnection = async (data, thunkAPI) => {
  try {
    const res = await Api.post(apiPostRemovePortConnection(), data);
    return res.data;
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      let errMessage;
      if (status === 404) {
        errMessage = "Port not found.";
      } else if (status === 400) {
        errMessage = "Invalid data";
        // handle bad data
        let errData = get(error.response, "data", {});
        for (const fieldKey in errData) {
          if (Object.hasOwnProperty.call(errData, fieldKey)) {
            const errList = errData[fieldKey];
            errMessage = get(errList, "0", "");
            break;
          }
        }
      } else {
        errMessage = "Something went wrong at our side. Please contact admin.";
      }

      thunkAPI.dispatch(
        addNotification({
          type: "error",
          title: "Error",
          text: errMessage,
        })
      );
    }
    throw error;
  }
};

export const postRemovePortConnectionThunk = createAsyncThunk(
  "splicing/postRemovePortConnection",
  postRemovePortConnection
);

export const getConnectedPortService = async ({ layerKey, portId }) => {
  const res = await Api.get(apiGetConnectedPort(layerKey, portId));
  return res.data;
};
