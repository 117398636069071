import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";

import { default as Icon } from "assets/markers/manhole_box.svg";
import { default as EditIcon } from "assets/markers/manhole_pin.svg";

import get from "lodash/get";

export const LAYER_KEY = "p_manhole";
export const PRE_UID = "CM";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;
export const SHOW_LABELS_AFTER_ZOOM = 17;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
  labelOrigin: { x: 10, y: -8 },
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

export const CHAMBER_TYPE = [
  {
    label: "Man hole",
    value: "MNH",
  },
  {
    label: "Hand hole",
    value: "HDH",
  },
  {
    label: "Coupler Point",
    value: "COP",
  },
];

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {
  return {
    title: "Chamber Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "chamber_type",
            label: "Chamber Type",
            field_type: FIELD_TYPES.Select,
            options: CHAMBER_TYPE,
          },
          {
            field_key: "loop",
            label: "Loop",
            field_type: FIELD_TYPES.Input,
          },
        ],
      },
    ],
  };
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "Chamber Type", field: "chamber_type_display" },
  { label: "Loop", field: "loop" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "add_associations",
    data: ["p_jointcloser"],
  },
  {
    control: "association_list",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    field: "chamber_type",
    label: "Chamber Type",
    field_type: FIELD_TYPES.Select,
    filter_type: FILTER_TYPES.equals,
    choices: CHAMBER_TYPE,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
