import isNumber from "lodash/isNumber";
import round from "lodash/round";
import isArray from "lodash/isArray";

import Box from "@mui/material/Box";

import { uniq_colors } from "components/graphs/graph.utils";

function PieChartLegend({ pieChartData, onPieLegendClick }) {
  // calculate total value
  let totalVal = 0;
  for (let index = 0; index < pieChartData.length; index++) {
    const curr_tag = pieChartData[index];
    if (isNumber(curr_tag.value)) {
      totalVal = totalVal + curr_tag.value;
    }
  }

  const handleLegendClick = (currTag) => () => {
    if (onPieLegendClick) {
      onPieLegendClick(currTag);
    }
  };

  return (
    <Box className="pie-chart-legend">
      {pieChartData.map((tag, id) => {
        // render chart legend
        let percentage_val, tagValue;
        if (isNumber(tag.value)) {
          percentage_val = round((tag.value / totalVal) * 100, 2);
          tagValue = tag.value;
        } else {
          percentage_val = tagValue = "--";
        }
        let color = tag.color;
        if (color) {
          color = isArray(color) ? color[id] : color;
        } else {
          // data has no color
          // generate auto
          color = uniq_colors[id];
        }
        return (
          <Box className="pie-legend" key={id} onClick={handleLegendClick(tag)}>
            <Box className="pie-indicator-wrapper">
              <Box
                className="pie-indicator"
                style={{ backgroundColor: `${color}` }}
              />
            </Box>
            <Box
              className="pie-label"
              sx={{ fontWeight: tag.highlight ? "bolder" : "normal" }}
            >
              <b>{`${percentage_val}%`}</b> {tag.label}{" "}
              {Boolean(tagValue) && (
                <>
                  - <b>{`(${tagValue})`}</b>
                </>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default PieChartLegend;
