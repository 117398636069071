import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import get from "lodash/get";
import Fuse from "fuse.js";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  onLayerElementListItemClick,
  openElementDetails,
} from "planning/data/planning.actions";
import { LayerKeyMappings } from "planning/GisMap/utils";

export const useLayerElementList = () => {
  const dispatch = useDispatch();
  const elementListSearch = useRef();

  const { data: eventData } = useSelector(getPlanningMapState);
  const { elementList, elementLayerKey } = eventData;

  const layerSearchShowExtraFields = get(
    LayerKeyMappings,
    [elementLayerKey, "layerSearchByFields"],
    ["name"]
  );

  useEffect(() => {
    elementListSearch.current = new Fuse(elementList, {
      keys: layerSearchShowExtraFields,
      ignoreFieldNorm: true,
      fieldNormWeight: 0,
    });
  }, []);

  const [searchedKey, setSearchedKey] = useState("");

  const handleShowOnMap = useCallback(
    (element, layerKey) => () => {
      dispatch(onLayerElementListItemClick(element, layerKey));
    },
    []
  );

  const handleShowDetails = useCallback(
    (elementId, layerKey) => () => {
      dispatch(
        openElementDetails({
          layerKey,
          elementId,
        })
      );
    },
    []
  );

  const handleElementListFilter = useCallback((searchText) => {
    setSearchedKey(searchText);
  }, []);

  return {
    elementLayerKey,
    elementList: !!searchedKey
      ? elementListSearch.current.search(searchedKey)
      : elementList,
    handleShowOnMap,
    handleShowDetails,
    handleElementListFilter,
    searchedKey,
  };
};
