import { createAsyncThunk } from "@reduxjs/toolkit";
import { addNotification } from "redux/reducers/notification.reducer";

import Api, { parseErrorMessagesWithFields } from "utils/api.utils";
import {
  apiPostBomPdfReport,
  apiPostBomReport,
  apiPostlayerDataReport,
  apiPostCustomerDataReport,
  apiPostCustomerCompletionReport,
  apiPostCustomerProgressReport,
  apiPostAlertReport,
  apiGetTriggercodeList,
} from "utils/url.constants";

export const fetchBomData = async (data) => {
  const res = await Api.post(apiPostBomReport(), data);
  return res.data;
};

export const fetchBomDataThunk = createAsyncThunk(
  "analysis/fetchBomData",
  fetchBomData
);

export const fetchBomPdfData = async (data) => {
  const res = await Api.post(apiPostBomPdfReport(), data, null, {
    responseType: "blob",
  });
  return res.data;
};

export const fetchLayerDataReport = async (data) => {
  const res = await Api.post(apiPostlayerDataReport(), data, null, {
    responseType: "blob",
  });
  return res.data;
};

export const fetchAlertReport = async (data) => {
  const res = await Api.post(apiPostAlertReport(), data, null, {
    responseType: "blob",
  });
  return res.data;
};

export const fetchCustomerTaggingDataReport = async (data, thunkAPI) => {
  try {
    const res = await Api.post(apiPostCustomerDataReport(), data, null, {
      responseType: data.return_type === "xlsx" ? "blob" : undefined,
    });
    return res.data;
  } catch (error) {
    const { fieldList, messageList } = parseErrorMessagesWithFields(error);
    for (let index = 0; index < fieldList.length; index++) {
      const field = fieldList[index];
      const errorMessage = messageList[index];
      thunkAPI.dispatch(
        addNotification({
          type: "error",
          title: field,
          text: errorMessage,
        })
      );
    }
    throw error;
  }
};

export const fetchCustomerTaggingDataThunk = createAsyncThunk(
  "analysis/fetchCustomerTaggingDataReport",
  fetchCustomerTaggingDataReport
);

export const fetchCustomerTaggingCompletionReport = async (data, thunkAPI) => {
  try {
    const res = await Api.post(apiPostCustomerCompletionReport(), data, null, {
      responseType: data.return_type === "xlsx" ? "blob" : undefined,
    });
    return res.data;
  } catch (error) {
    const { fieldList, messageList } = parseErrorMessagesWithFields(error);
    for (let index = 0; index < fieldList.length; index++) {
      const field = fieldList[index];
      const errorMessage = messageList[index];
      thunkAPI.dispatch(
        addNotification({
          type: "error",
          title: field,
          text: errorMessage,
        })
      );
    }
    throw error;
  }
};

export const fetchCustomerTaggingCompletionThunk = createAsyncThunk(
  "analysis/fetchCustomerTaggingCompletionReport",
  fetchCustomerTaggingCompletionReport
);

export const fetchCustomerTaggingProgressReport = async (data, thunkAPI) => {
  try {
    const res = await Api.post(apiPostCustomerProgressReport(), data, null, {
      responseType: data.return_type === "xlsx" ? "blob" : undefined,
    });
    return res.data;
  } catch (error) {
    const { fieldList, messageList } = parseErrorMessagesWithFields(error);
    for (let index = 0; index < fieldList.length; index++) {
      const field = fieldList[index];
      const errorMessage = messageList[index];
      thunkAPI.dispatch(
        addNotification({
          type: "error",
          title: field,
          text: errorMessage,
        })
      );
    }
    throw error;
  }
};

export const fetchCustomerTaggingProgressThunk = createAsyncThunk(
  "analysis/fetchCustomerTaggingProgressReport",
  fetchCustomerTaggingProgressReport
);

export const fetchTriggercodeList = async () => {
  const res = await Api.get(apiGetTriggercodeList());
  return res.data;
};
