import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
  POP_BUSSINESS_NATURE,
  POP_STRUCTURE_TYPE,
} from "planning/GisMap/layers/common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";

import { default as Icon } from "assets/markers/pop_box.svg";
import { default as EditIcon } from "assets/markers/pop_pin.svg";

import get from "lodash/get";

export const LAYER_KEY = "p_pop";
export const PRE_UID = "POP";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;
export const SHOW_LABELS_AFTER_ZOOM = 14;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
  labelOrigin: { x: 10, y: -8 },
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {
  return {
    title: "Pop Location Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "address",
            label: "Address",
            field_type: FIELD_TYPES.TextArea,
          },
          {
            field_key: "nature_of_bussiness",
            label: "Nature of bussiness",
            field_type: FIELD_TYPES.Select,
            options: POP_BUSSINESS_NATURE,
          },
          {
            field_key: "structure_type",
            label: "Structure type",
            field_type: FIELD_TYPES.Select,
            options: POP_STRUCTURE_TYPE,
          },
          {
            field_key: "contact_name",
            label: "Contact Name",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "contact_no",
            label: "Contact No",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "is_rented",
            label: "Rented",
            field_type: FIELD_TYPES.CheckBox,
          },
          {
            field_key: "agreement_start_date",
            label: "Agreement start date",
            field_type: FIELD_TYPES.DateTime,
            isHidden: (props) => {
              return !props.is_rented;
            },
          },
          {
            field_key: "agreement_end_date",
            label: "Agreement end date",
            field_type: FIELD_TYPES.DateTime,
            isHidden: (props) => {
              return !props.is_rented;
            },
          },
          {
            field_key: "ups_avaibility",
            label: "UPS avaibility",
            field_type: FIELD_TYPES.CheckBox,
          },
          {
            field_key: "backup_capacity",
            label: "Backup Capacity ( In KVA )",
            field_type: FIELD_TYPES.NumericInput,
            isHidden: (props) => {
              return !props.ups_avaibility;
            },
          },
        ],
      },
    ],
    dependencyFields: ["is_rented", "ups_avaibility"],
    // this shows where dependant template data comes from
    metaData: {
      getElementAddressData: (address, submitData) => {
        submitData.address = address.address;
      },
    },
  };
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "Address", field: "address", type: "simple" },
  {
    label: "Nature of bussiness",
    field: "nature_of_bussiness_display",
    type: "simple",
  },
  { label: "Structure type", field: "structure_type_display", type: "simple" },
  { label: "Contact Name", field: "contact_name", type: "simple" },
  { label: "Contact No", field: "contact_no", type: "simple" },
  { label: "Rented", field: "is_rented", type: "boolean" },
  { label: "Rent Amount", field: "rent_amount", type: "simple" },
  {
    label: "Agreement start date",
    field: "agreement_start_date",
    type: "date",
  },
  { label: "Agreement end date", field: "agreement_end_date", type: "date" },
  { label: "UPS avaibility", field: "ups_avaibility", type: "boolean" },
  {
    label: "Backup Capacity ( In KVA )",
    field: "backup_capacity",
    type: "simple",
  },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "add_associations",
    data: ["p_olt", "p_fms"],
  },
  {
    control: "association_list",
  },
  {
    control: "attachments",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    field: "ref_code",
    label: "Reff Code",
    field_type: FIELD_TYPES.Input,
    filter_type: FILTER_TYPES.contains,
  },
  {
    field: "nature_of_bussiness",
    label: "Nature of bussiness",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Select,
    choices: POP_BUSSINESS_NATURE,
  },
  {
    field: "structure_type",
    label: "Structure type",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Select,
    choices: POP_STRUCTURE_TYPE,
  },
  {
    field: "is_rented",
    label: "Rented",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.CheckBox,
  },
  {
    field: "ups_avaibility",
    label: "UPS avaibility",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.CheckBox,
  },
  {
    field: "backup_capacity",
    label: "Backup Capacity ( In KVA )",
    filter_type: FILTER_TYPES.contains,
    field_type: FIELD_TYPES.NumericInput,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
