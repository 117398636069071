import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";

import { Polyline, Marker, Polygon } from "@react-google-maps/api";

import { getPlanningTicketData } from "planning/data/planningGis.selectors";
import { LayerKeyMappings } from "planning/GisMap/utils";
import {
  FEATURE_TYPES,
  zIndexMapping,
} from "planning/GisMap/layers/common/configuration";

import TicketIndicator from "assets/markers/ticket_indicator_dot.svg";
import TicketLineIndicator from "assets/markers/ticket_line_indicator_dot.svg";
import { TICKET_HIGHLIGHT_COLOR } from "App/theme";

const COMMON_POLYGON_OPTIONS = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0.3,
  clickable: false,
  draggable: false,
  editable: false,
  // only for ticket element
  strokeColor: TICKET_HIGHLIGHT_COLOR,
};

const TicketMapViewLayers = React.memo(() => {
  const { work_orders = [], area_pocket } = useSelector(getPlanningTicketData);

  return (
    <>
      {area_pocket?.coordinates ? (
        <Polygon
          path={area_pocket.coordinates}
          options={{
            strokeColor: TICKET_HIGHLIGHT_COLOR,
            strokeOpacity: 0.8,
            strokeWeight: 4,
            fillColor: "#88B14B",
            fillOpacity: 0.3,
            clickable: false,
            draggable: false,
            editable: false,
            zIndex: 1,
          }}
        />
      ) : null}
      {work_orders.map((workOrder) => {
        const { id, element, element_list, wo_type } = workOrder;
        // render element as per wo_type
        if (wo_type === "network") {
          return <RenderMapElement key={id} element={element} />;
        }
        //
        else if (wo_type === "customer") {
          return element_list.map((element) => (
            <RenderMapElement key={`${id}-${element?.id}`} element={element} />
          ));
        }
        //
        else {
          return null;
        }
      })}
    </>
  );
});

const RenderMapElement = ({ element }) => {
  const { layer_key, id } = element;

  if (id) {
    const featureType = LayerKeyMappings[layer_key]["featureType"];
    const viewOptions = LayerKeyMappings[layer_key]["getViewOptions"](element);

    switch (featureType) {
      case FEATURE_TYPES.POINT:
        return (
          <Fragment key={id}>
            <Marker
              icon={{
                // add default icon here
                url: viewOptions.icon,
              }}
              zIndex={zIndexMapping[layer_key]}
              position={element.coordinates}
            />
            <Marker
              icon={{
                // add default icon here
                url: TicketIndicator,
              }}
              zIndex={zIndexMapping.highlightedDot}
              position={element.coordinates}
            />
          </Fragment>
        );

      case FEATURE_TYPES.POLYGON:
        return (
          <Fragment key={id}>
            <Polygon
              options={{
                ...COMMON_POLYGON_OPTIONS,
                ...viewOptions,
                strokeWeight: 4,
                strokeColor: TICKET_HIGHLIGHT_COLOR,
                zIndex: zIndexMapping[layer_key],
              }}
              paths={element.coordinates}
            />
          </Fragment>
        );

      case FEATURE_TYPES.POLYLINE:
        return (
          <Fragment key={id}>
            <Polyline
              options={{
                ...viewOptions,
                zIndex: zIndexMapping[layer_key],
              }}
              path={element.coordinates}
            />
            <Marker
              icon={{
                // add default icon here
                url: TicketLineIndicator,
              }}
              zIndex={zIndexMapping.highlightedDot}
              position={element.center}
            />
          </Fragment>
        );

      default:
        return null;
    }
  }
  return null;
};

const HIGHLIGHT_COLOR = "#446eca";
const lineSymbol = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  // scale: 4, // default scale is handle from polyline strokeWeight
};

export default TicketMapViewLayers;
