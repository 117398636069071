import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import get from "lodash/get";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import CustomSelect from "components/common/FormFields/CustomSelect";
import BomForm from "./Bom/BomForm";
import LayerData from "./LayerData";
import CustomerTaggingReport from "./CustomerTaggingReport";
import CustomerTaggingCompletionReport from "./CustomerTaggingCompletionReport";
import CustomerTaggingProgressReport from "./CustomerTaggingProgressReport";
import AlertReport from "./AlertReport";

import { getReportType } from "Analysis/data/analysis.selectors";
import { setReportType } from "Analysis/data/analysis.reducer";
import { REPORT_TYPES } from "Analysis/data/utils";
import {
  getIsSuperAdminUser,
  getUserPermissions,
} from "redux/selectors/auth.selectors";

const AnalysisForm = () => {
  /**
   * Parent:
   *    AnalysisPage
   */
  const dispatch = useDispatch();
  const reportType = useSelector(getReportType);
  const permissions = useSelector(getUserPermissions);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);

  const handleReportTypeChange = useCallback((newValue) => {
    dispatch(setReportType(newValue));
  }, []);

  const reportContent = useMemo(() => {
    switch (reportType?.value) {
      case REPORT_TYPES[0].value:
        return <BomForm key={reportType} />;
      case REPORT_TYPES[1].value:
        return <LayerData key={reportType} />;
      case REPORT_TYPES[2].value:
        return <CustomerTaggingReport key={reportType} />;
      case REPORT_TYPES[3].value:
        return <CustomerTaggingCompletionReport key={reportType} />;
      case REPORT_TYPES[4].value:
        return <CustomerTaggingProgressReport key={reportType} />;
      case REPORT_TYPES[5].value:
        return <AlertReport key={reportType} />;
      default:
        return null;
    }
  }, [reportType]);

  const reportTypeList = useMemo(() => {
    const reportList = [];
    for (let index = 0; index < REPORT_TYPES.length; index++) {
      const report = REPORT_TYPES[index];
      if (get(permissions, report.permissionName) || isSuperAdminUser) {
        reportList.push(report);
      }
    }
    return reportList;
  }, [permissions]);

  return (
    <Container>
      <Paper sx={{ marginTop: 2, padding: 3 }}>
        <Typography
          color="primary.dark"
          flex={1}
          variant="h5"
          textAlign="center"
        >
          Analysis Report
        </Typography>
        <Divider sx={{ paddingTop: 2.5 }} />
        <Box sx={{ margin: "24px auto", maxWidth: "480px" }}>
          <CustomSelect
            label="Select Report Type"
            name="report_type"
            options={reportTypeList}
            menuPortalTarget={document.body}
            value={reportType}
            onChange={handleReportTypeChange}
          />
        </Box>
        {reportContent}
      </Paper>
    </Container>
  );
};

export default AnalysisForm;
