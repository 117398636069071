import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";

import { default as Icon } from "assets/markers/fsa.svg";

import get from "lodash/get";
import isNumber from "lodash/isNumber";

export const LAYER_KEY = "p_fsa";
export const PRE_UID = "FSA";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POLYGON;
export const SHOW_LABELS_AFTER_ZOOM = 15;

const STROKE_COLOR = "#EFAA33";

export const getViewOptions = () => ({
  strokeColor: STROKE_COLOR,
  fillColor: STROKE_COLOR,
  icon: Icon,
  pin: Icon,
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
  utilization: null,
  active_customer: null,
};

export const DESIGN_TYPES = [
  { value: "GRF", label: "Greenfield" },
  { value: "MGR", label: "Migration" },
  { value: "BRW", label: "Brownfield" },
  { value: "CRN", label: "Correction" },
];

// this will become function -> generate From Configs
export const getElementFormTemplate = () => {
  return {
    title: "FSA Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "spop_code",
            label: "SPOP Code",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "design_type",
            label: "Design type",
            field_type: FIELD_TYPES.Select,
            options: DESIGN_TYPES,
          },
          {
            field_key: "survey_area_count",
            label: "Survey Area Count",
            field_type: FIELD_TYPES.Input,
            disabled: true,
          },
          {
            field_key: "building_count",
            label: "Building Count",
            field_type: FIELD_TYPES.Input,
            disabled: true,
          },
          {
            field_key: "home_pass",
            label: "Home Pass",
            field_type: FIELD_TYPES.Input,
            disabled: true,
          },
          {
            field_key: "ip_address",
            label: "IP Address",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "penetration",
            label: "Penetration ( % )",
            field_type: FIELD_TYPES.Input,
            disabled: true,
          },
          {
            field_key: "utilization",
            label: "Utilization ( % )",
            field_type: FIELD_TYPES.NumericInput,
          },
          {
            field_key: "active_customer",
            label: "Active Customers",
            field_type: FIELD_TYPES.NumericInput,
          },
          {
            field_key: "gis_area",
            label: "Gis Area",
            field_type: FIELD_TYPES.Input,
            disabled: true,
          },
        ],
      },
    ],
    // this shows where dependant template data comes from
    metaData: {
      geometryUpdateFields: ["gis_area"],
    },
  };
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  {
    field: "spop_code",
    label: "SPOP Code",
    type: "simple",
  },
  { label: "Design type", field: "design_type_display", type: "simple" },
  { label: "Survey Area Count", field: "survey_area_count", type: "simple" },
  { label: "Building Count", field: "building_count", type: "simple" },
  { label: "Home Pass", field: "home_pass", type: "simple" },
  { label: "Penetration ( % )", field: "penetration", type: "simple" },
  { label: "Utilization ( % )", field: "utilization", type: "simple" },
  { label: "Active Customers", field: "active_customer", type: "simple" },
  { label: "IP Address", field: "ip_address", type: "simple" },
  { label: "Gis Area", field: "gis_area", type: "simple" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "add_associations",
    data: ["p_olt"],
  },
  {
    control: "association_list",
  },
  {
    control: "dwnld_survey_area_excel",
  },
];

// submitData : { ...elemPartialData }
// children : { layerKey: [child1, child2]}
export const getDependantFields = ({ submitData, children }) => {
  let survey_area_count = 0;
  let building_count = 0;
  let home_pass = 0;
  let dsa_count = 0;
  let total_penetration = 0;

  for (const childLayerKey in children) {
    if (Object.hasOwnProperty.call(children, childLayerKey)) {
      const currChildList = children[childLayerKey];
      for (let chInd = 0; chInd < currChildList.length; chInd++) {
        const currChild = currChildList[chInd];

        if (childLayerKey === "p_survey_building") {
          building_count += 1;
          home_pass += currChild.total_home_pass;
        } else if (childLayerKey === "p_survey_area") {
          survey_area_count += 1;
        } else if (childLayerKey === "p_dsa") {
          dsa_count += 1;
          total_penetration += Math.round(
            currChild.home_pass * (currChild.penetration / 100)
          );
        }
      }
    }
  }

  let penetration = 0;
  if (!!dsa_count)
    penetration = Number(((total_penetration / home_pass) * 100).toFixed(2));

  return {
    ...submitData,
    survey_area_count,
    building_count,
    home_pass,
    penetration,
  };
};

export const FILTER_FORM_CONFIG = [
  {
    label: "Penetration",
    field: "penetration",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.NumericInput,
    hasOperator: true,
  },
  {
    field: "utilization",
    label: "Utilization ( % )",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.NumericInput,
    hasOperator: true,
  },
  {
    field: "active_customer",
    label: "Active Customers",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.NumericInput,
    hasOperator: true,
  },
  {
    label: "Design Type",
    field: "design_type",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Select,
    choices: DESIGN_TYPES,
  },
  {
    label: "Home Pass",
    field: "home_pass",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.NumericInput,
    hasOperator: true,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
