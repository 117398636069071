import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TimelineIcon from "@mui/icons-material/Timeline";

import isNumber from "lodash/isNumber";
import size from "lodash/size";
import find from "lodash/find";
import get from "lodash/get";
import { formatDistanceToNow } from "date-fns";

import { getContentHeight } from "redux/selectors/appState.selectors";
import { getPlanningMapStateData } from "planning/data/planningGis.selectors";

import { ELEMENT_TABLE_TYPES } from "planning/GisMap/layers/common/configuration";
import { showGraphPopup } from "planning/data/catv/catv.actions";

import {
  FORMATTOR_USED_FOR,
  formatterFunctionsMap,
} from "planning/data/catv/live_attrbutes.utils";
import {
  getIsAdminUser,
  getIsSuperAdminUser,
} from "redux/selectors/auth.selectors";

const AttrDetailsTable = ({ dataList }) => {
  /**
   * Parent:
   *    GenericAttrsPopup
   */
  const dispatch = useDispatch();
  const mapStateData = useSelector(getPlanningMapStateData);
  const windowHeight = useSelector(getContentHeight);
  const isAdmin = useSelector(getIsAdminUser);
  const isSuperAdmin = useSelector(getIsSuperAdminUser);
  // contentHeight = windowHeight - (10% margin * 2 top & bot) - (title + action btns)
  const contentHeight = windowHeight - windowHeight * 0.1 - (60 + 70);

  const handleGraphNavigation = useCallback(
    (dataType, fieldKey) => () => {
      // pass data to generate graphTitle
      const graphTitleData = {
        hostName: get(mapStateData, "elementData.host_name"),
        interfaceKey: get(mapStateData, "selectedInterface.key"),
        interfaceDesc: get(mapStateData, "selectedInterface.description"),
      };
      dispatch(showGraphPopup({ dataType, graphTitleData, fieldKey }));
    },
    [mapStateData.elementId]
  );

  return (
    <Stack
      sx={{
        maxHeight: `${contentHeight}px`,
        overflowY: "auto",
      }}
      divider={<Divider />}
    >
      {size(dataList) ? (
        dataList.map((row) => {
          let {
            id,
            type,
            key,
            field_view_type,
            options,
            label,
            value,
            unit,
            timestamp,
            created_on,
            show_chart,
          } = row;

          let ValueCell;
          switch (field_view_type) {
            case ELEMENT_TABLE_TYPES.select: {
              const selectedOption = find(options, ["value", value]);
              ValueCell = (
                <Typography textAlign="center" width="100%">
                  {get(selectedOption, "label") || "--"}
                </Typography>
              );
              break;
            }

            default:
              const formattorFunc = formatterFunctionsMap(
                key,
                FORMATTOR_USED_FOR.table_details
              );
              if (formattorFunc) {
                const { formattedValue, unit: newUnit } = formattorFunc(value);
                value = formattedValue;
                unit = newUnit;
              }
              ValueCell = (
                <Typography textAlign="center" width="100%">
                  {/* if value 0 OR empty string show it else show "--" error placeholder */}
                  {value ?? "--"}
                  {unit ? (
                    <Typography variant="caption" color="text.secondary">
                      &nbsp;{unit}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              );
              break;
          }

          return (
            <Stack direction="row" key={id} pl={2} pr={1} py={1}>
              <Box textAlign="left" width={"50%"}>
                <Typography color="primary.main">{label}</Typography>

                <Typography
                  display="block"
                  variant="caption"
                  width="100%"
                  color="text.secondary"
                >
                  {formatDistanceToNow(new Date(timestamp), {
                    addSuffix: true,
                    includeSeconds: true,
                  })}
                </Typography>
              </Box>
              <Box width={"50%"}>
                {ValueCell}
                {isAdmin || isSuperAdmin ? (
                  <Typography
                    display="block"
                    textAlign="center"
                    variant="caption"
                    width="100%"
                    color="text.secondary"
                  >
                    fetch@{" "}
                    {formatDistanceToNow(new Date(created_on), {
                      addSuffix: true,
                      includeSeconds: true,
                    })}
                  </Typography>
                ) : null}
              </Box>
              {show_chart ? (
                <Box
                  display="flex"
                  alignItems="center"
                  className="clickable"
                  onClick={handleGraphNavigation(type, key)}
                >
                  <Tooltip title={"Show Chart"} key={"show_chart"}>
                    <TimelineIcon color="secondary" />
                  </Tooltip>
                </Box>
              ) : (
                <Box width="24px" />
              )}
            </Stack>
          );
        })
      ) : (
        <Box p={2}>
          <Typography variant="h6" color="text.secondary" textAlign="center">
            No Details Available
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default AttrDetailsTable;
