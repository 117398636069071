import React, { useCallback, useMemo, useState } from "react";

import get from "lodash/get";
import size from "lodash/size";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import difference from "lodash/difference";
import orderBy from "lodash/orderBy";
import noop from "lodash/noop";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "components/common/ExpandMore";

const CustTagCompletionTable = ({ reportData }) => {
  const [regionGroupData, baseRegionList] = useMemo(() => {
    const regionInputList = reportData || [];
    // group data by parent
    let resultGroupData = groupBy(regionInputList, "parent");
    // get list of parent keys
    const keyList = Object.keys(resultGroupData).map((k) => {
      if (k === "null") return null;
      return Number(k);
    });
    // get all the parent key list that is not in regionInputList ; e.x. null, or other
    // get list of ids
    const idList = map(regionInputList, "id");
    // get difference on keyList and idList
    const mergeList = difference(keyList, idList);
    // concat list of all groups with unknown parents that is our base layer
    let baseRegionList = [];
    for (let mListInd = 0; mListInd < mergeList.length; mListInd++) {
      const rId = mergeList[mListInd];
      baseRegionList = baseRegionList.concat(resultGroupData[String(rId)]);
    }
    // order by layer
    baseRegionList = orderBy(
      baseRegionList,
      [(region) => region.area_name.toLowerCase()],
      ["asc"]
    );
    // return cancat list as first base list to render
    return [resultGroupData, baseRegionList];
  }, [reportData]);

  const [expandedRegions, setExpandedRegions] = useState(new Set([]));

  const handleRegionExpandClick = useCallback(
    (regId) => () => {
      setExpandedRegions((regionSet) => {
        let newSet = new Set(regionSet);
        if (newSet.has(regId)) {
          newSet.delete(regId);
        } else {
          newSet.add(regId);
        }
        return newSet;
      });
    },
    [setExpandedRegions]
  );

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Region / FSA</TableCell>
          <TableCell>Active Customers</TableCell>
          <TableCell>Tagged Customers</TableCell>
          <TableCell>% Completion</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {baseRegionList.map((region) => {
          return (
            <RegionSummeryItem
              key={region.id}
              region={region}
              regionGroupData={regionGroupData}
              expandedRegions={expandedRegions}
              handleRegionExpandClick={handleRegionExpandClick}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RegionSummeryItem = ({
  region,
  regionGroupData,
  expandedRegions,
  handleRegionExpandClick,
}) => {
  const {
    id,
    area_name,
    active_customer,
    current_cust_count,
    completion_percentage,
  } = region;

  // check if childs are open
  const regionChilds = useMemo(() => {
    return orderBy(
      get(regionGroupData, id, []),
      [(region) => region.area_name.toLowerCase()],
      ["asc"]
    );
  }, [regionGroupData, id]);
  const hasChildren = !!size(regionChilds);
  const isExpanded = hasChildren && expandedRegions.has(id);

  return (
    <>
      <TableRow
        key={id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <StyledTableCell component="th" scope="row">
          <Stack direction="row" width="100%" alignItems="center">
            <Box
              sx={{
                opacity: hasChildren ? 1 : 0.3,
                borderLeft: isExpanded ? "3px solid black" : "inherit",
              }}
              onClick={hasChildren ? handleRegionExpandClick(id) : noop}
            >
              <ExpandMore
                expand={isExpanded}
                aria-expanded={isExpanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
            <span>{area_name}</span>
          </Stack>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {active_customer}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {current_cust_count}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {completion_percentage}
        </StyledTableCell>
      </TableRow>

      {isExpanded
        ? regionChilds.map((regionChild) => {
            return (
              <RegionSummeryItem
                key={regionChild.id}
                region={regionChild}
                regionGroupData={regionGroupData}
                expandedRegions={expandedRegions}
                handleRegionExpandClick={handleRegionExpandClick}
              />
            );
          })
        : null}
    </>
  );
};

export default CustTagCompletionTable;
