import Api from "utils/api.utils";
import {
  apiPostChangePassword,
  apiPostLogin,
  apiPostNotificationSubscribe,
  apiPostProfileEdit,
  apiPostResetPassword,
} from "utils/url.constants";

export const postLogin = async (data) => {
  const res = await Api.post(apiPostLogin(), data);
  return res.data;
};

export const postChangePassword = async (data) => {
  const res = await Api.post(apiPostChangePassword(), data);
  return res.data;
};

export const postResetPassword = async ({ userId, data }) => {
  const res = await Api.post(apiPostResetPassword(userId), data);
  return res.data;
};

export const postProfileEdit = async (data) => {
  const res = await Api.post(apiPostProfileEdit(), data);
  return res.data;
};

export const createPushSubscription = async (data) => {
  const res = await Api.post(apiPostNotificationSubscribe(), data);
  return res.data;
};
