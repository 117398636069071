import { FIELD_TYPES } from "components/common/DynamicForm";

export const SHOW_LABELS_DEFAULT_ZOOM = 18;

export const zIndexMapping = {
  region: 1,
  p_survey_area: 2,
  // 3 - 5 -> Polygons at the bottom
  // 6 - 7 -> Polylines at the bottom
  p_survey_building: 8,
  p_pop: 9,
  p_spop: 10,
  p_fsa: 11,
  p_dsa: 12,
  p_csa: 13,
  p_pole: 14,
  p_manhole: 15,
  p_jointcloser: 16,
  p_olt: 17,
  p_fms: 18,
  p_dp: 19,
  p_splitter: 20,
  p_onu: 21,
  p_cable: 22,
  p_customer: 23,

  edit: 50,
  highlightedTraceback: 58,
  highlightedDot: 59,
  highlighted: 60,
  mapType: 61,
};

export const LAYER_STATUS_OPTIONS = [
  { value: "L1", label: "L1 Design" },
  { value: "L2", label: "L2 Design" },
  { value: "RFS", label: "Ready For Service" },
  { value: "IA", label: "In Active" },
];

// common for pop, spop
export const POP_BUSSINESS_NATURE = [
  { value: "GTPL", label: "GTPL" },
  { value: "LBO", label: "LBO" },
];

export const POP_STRUCTURE_TYPE = [
  { value: "OD", label: "Outdoor" },
  { value: "ID", label: "Indoor" },
];

export const CABINET_TYPE_OPTIONS = [
  { value: "FA", label: "FAT" },
  { value: "FD", label: "FDC" },
  { value: "CB", label: "Compass Box" },
  { value: "MB", label: "Metal Box" },
  { value: "PT", label: "PATCH Panel" },
  { value: "BM", label: "Bamboo" },
];

// also required by backend at the time of validations
export const FEATURE_TYPES = {
  POLYLINE: "polyline",
  POINT: "point",
  POLYGON: "polygon",
  MULTI_POLYGON: "multi_polygon",
};

// mapping drawing mode from feature type values
// for google map drawing mode string
export const MAP_DRAWING_MODE = {
  polyline: "polyline",
  polygon: "polygon",
  point: "marker",
  multi_polygon: "polygon",
};

export const ELEMENT_FORM_CONFIG_ABSTRACT_SECTION = {
  title: "Configuration",
  section_type: "configuration",
  fieldConfigs: [
    {
      field_key: "configuration",
      label: "Configuration",
      field_type: FIELD_TYPES.ConfigSelect,
    },
  ],
};

export const ELEMENT_FORM_ABSTRACT_TEMPLATE = [
  {
    field_key: "name",
    label: "Name",
    field_type: FIELD_TYPES.Input,
  },
  {
    field_key: "unique_id",
    label: "Unique Id",
    field_type: FIELD_TYPES.Input,
    disabled: true,
  },
  {
    field_key: "network_id",
    label: "Network Id",
    field_type: FIELD_TYPES.Input,
    disabled: true,
  },
  {
    field_key: "ref_code",
    label: "Reff Code",
    field_type: FIELD_TYPES.Input,
  },
  {
    field_key: "status",
    label: "Status",
    field_type: FIELD_TYPES.Select,
    options: LAYER_STATUS_OPTIONS,
  },
];

export const ELEMENT_TABLE_ABSTRACT_FIELDS = [
  { label: "Name", field: "name", type: "simple" },
  { label: "Unique Id", field: "unique_id", type: "simple" },
  { label: "Location", field: "coordinates", type: "point_geometry" },
  // { label: "Network Id", field: "network_id", type: "simple" },
  { label: "Reff Code", field: "ref_code", type: "simple" },
  { label: "Status", field: "status", type: "status" },
];

export const FILTER_TYPES = {
  numeric: "numeric",
  equals: "equals",
  contains: "contains",
  configuration: "configuration",
  multi_choice: "multi_choice",
  or_multi_choice: "or_multi_choice",
  date_time: "date_time",
  date: "date",
  array_multi_choice: "array_multi_choice",
};

export const ELEMENT_TABLE_TYPES = {
  select: "select",
  status: "status",
  boolean: "boolean",
  date: "date",
  point_geometry: "point_geometry",
  multi_array: "multi_array",
};

export const OPERATOR_OPTIONS = [
  { value: "<", label: "Less then ( < )" },
  { value: ">", label: "Greater then ( > )" },
  { value: "<=", label: "Less than equal to ( <= )" },
  { value: ">=", label: "Greater than equal to ( >= )" },
  { value: "==", label: "Equal to ( == )" },
];
