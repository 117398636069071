import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import get from "lodash/get";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";

import { UploadButton } from "components/common/FilePickerDialog";

const UploadForm = ({ isLoading, onClose, handleFormSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: { caption: "" },
  });

  const [files, setFiles] = useState(null);

  const handleFileUpload = useCallback((event) => {
    setFiles(event.target.files);
  }, []);

  const onFormSubmit = useCallback(
    (data) => {
      handleFormSubmit(data, files);
    },
    [files]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Box />
        <Typography variant="h6" color="primary.dark">
          Upload File
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        dividers
        sx={{
          padding: 0,
        }}
      >
        <Box p={4}>
          <TextField
            className="full-width"
            label="Caption"
            {...register("caption", { required: "Caption is required." })}
            error={!!get(errors, ["caption"])}
            helperText={get(errors, ["caption", "message"], "")}
            InputLabelProps={{
              required: true,
            }}
          />
          <Box py={2}>
            <Typography
              pl={1}
              pb={0.5}
              variant="caption"
              color="GrayText"
              component="div"
            >
              Upload File
            </Typography>
            <UploadButton
              text="Select File"
              variant="contained"
              onChange={handleFileUpload}
            />
            <Typography variant="body2" pl={1} pt={0.5}>
              {get(files, "0.name", "")}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          onClick={handleSubmit(onFormSubmit)}
          loading={isLoading}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UploadForm;
