import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBomDataThunk,
  fetchCustomerTaggingDataThunk,
  fetchCustomerTaggingCompletionThunk,
  fetchCustomerTaggingProgressThunk,
} from "./analysis.service";

const initialState = {
  reportType: null,

  fetching: false,
  fetched: false,
  fetchError: false,
  reportData: null,
  // show report content based on this.
  fetchedReportType: null,
  // file name create when before fetch data
  fileName: "",
  reportFormUniqueId: null,
};

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    setReportType: (state, { payload }) => {
      state.reportType = payload;
      state.fileName = "";
    },
    setReportFilename: (state, { payload }) => {
      state.fileName = payload;
    },
    setReportFormUniqueId: (state, { payload }) => {
      state.reportFormUniqueId = payload;
    },
    resetReportData: (state, { payload }) => {
      state.fetching = false;
      state.fetched = false;
      state.fetchError = false;
      state.reportData = null;
      // show report content based on this.
      state.fetchedReportType = null;
    },
  },
  extraReducers: {
    [fetchBomDataThunk.pending]: (state) => {
      state.fetching = true;
      state.fetched = false;
      state.fetchError = false;
      state.reportData = null;
    },
    [fetchBomDataThunk.rejected]: (state) => {
      state.fetching = false;
      state.fetched = false;
      state.fetchError = true;
      state.reportData = null;
      state.fileName = "";
    },
    [fetchBomDataThunk.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.fetched = true;
      state.fetchError = false;
      state.fetchedReportType = state.reportType;
      state.reportData = payload;
    },

    [fetchCustomerTaggingDataThunk.pending]: (state) => {
      state.fetching = true;
      state.fetched = false;
      state.fetchError = false;
      state.reportData = null;
    },
    [fetchCustomerTaggingDataThunk.rejected]: (state) => {
      state.fetching = false;
      state.fetched = false;
      state.fetchError = true;
      state.reportData = null;
      state.fileName = "";
    },
    [fetchCustomerTaggingDataThunk.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.fetched = true;
      state.fetchError = false;
      state.fetchedReportType = state.reportType;
      state.reportData = payload;
    },

    [fetchCustomerTaggingCompletionThunk.pending]: (state) => {
      state.fetching = true;
      state.fetched = false;
      state.fetchError = false;
      state.reportData = null;
    },
    [fetchCustomerTaggingCompletionThunk.rejected]: (state) => {
      state.fetching = false;
      state.fetched = false;
      state.fetchError = true;
      state.reportData = null;
      state.fileName = "";
    },
    [fetchCustomerTaggingCompletionThunk.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.fetched = true;
      state.fetchError = false;
      state.fetchedReportType = state.reportType;
      state.reportData = payload;
    },

    [fetchCustomerTaggingProgressThunk.pending]: (state) => {
      state.fetching = true;
      state.fetched = false;
      state.fetchError = false;
      state.reportData = null;
    },
    [fetchCustomerTaggingProgressThunk.rejected]: (state) => {
      state.fetching = false;
      state.fetched = false;
      state.fetchError = true;
      state.reportData = null;
      state.fileName = "";
    },
    [fetchCustomerTaggingProgressThunk.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.fetched = true;
      state.fetchError = false;
      state.fetchedReportType = state.reportType;
      state.reportData = payload;
    },
  },
});

export const {
  setReportType,
  setReportFilename,
  setReportFormUniqueId,
  resetReportData,
} = analysisSlice.actions;
export default analysisSlice.reducer;
