import React from "react";
import { useSelector } from "react-redux";
import { Marker, Polyline } from "@react-google-maps/api";

import pick from "lodash/pick";

import {
  getTraceShowOnMap,
  getTraebackTreeData,
} from "planning/data/traceback/traceback.selectors";
import { FEATURE_TYPES } from "planning/GisMap/layers/common/configuration";
import { getSpecialLayerIcon } from "planning/GisMap/GisIcons";
import { getPlanningMapPosition } from "planning/data/planningGis.selectors";
import { getTraceBackElementViewOptions } from "planning/data/traceback/traceBack.utils";

const TraceHighlightedLayer = () => {
  /**
   * Parent:
   *    GisMap
   */
  const showOnMap = useSelector(getTraceShowOnMap);
  const traceBackElements = useSelector(getTraebackTreeData);
  const mapPosition = useSelector(getPlanningMapPosition);

  if (!showOnMap) {
    return null;
  }
  // render gis elements
  return traceBackElements.map((element, index) => {
    const { coordinates, featureType } = element;

    switch (featureType) {
      case FEATURE_TYPES.POINT: {
        // view option can have iconType that will directly use one of the icons from : src/planning/GisMap/GisIcons.js
        let viewOptions = getTraceBackElementViewOptions(element);
        const icon = getSpecialLayerIcon({
          ...viewOptions,
          ...pick(mapPosition, [
            "iconWidth",
            "iconHeight",
            "anchorX",
            "anchorY",
          ]),
        });

        return (
          <Marker
            key={index}
            clickable={false}
            icon={icon}
            zIndex={viewOptions.zIndex}
            position={coordinates}
          />
        );
      }

      case FEATURE_TYPES.POLYLINE: {
        const viewOptions = getTraceBackElementViewOptions(element);

        return (
          <Polyline
            key={index}
            options={{
              ...viewOptions,
            }}
            path={coordinates}
          />
        );
      }
    }
    return null;
  });
};

export default TraceHighlightedLayer;
