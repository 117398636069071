import { FIELD_TYPES } from "components/common/DynamicForm";
import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  ELEMENT_FORM_CONFIG_ABSTRACT_SECTION,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";
import CableIcon from "assets/markers/line_pin.svg";

import get from "lodash/get";

export const LAYER_KEY = "p_cable";
export const PRE_UID = "CBL";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POLYLINE;
export const SHOW_LABELS_AFTER_ZOOM = 17;

const dashLineSymbol = {
  path: "M 0,-2 0,2",
  strokeOpacity: 1,
};

const dotLineSymbol = {
  path: "M 0,2 0,2",
  strokeOpacity: 1,
};

export const getViewOptions = ({ color_on_map, cable_type }) => {
  switch (cable_type) {
    case "W":
      return {
        strokeColor: color_on_map,
        strokeOpacity: 0,
        strokeWeight: 5,
        clickable: false,
        draggable: false,
        editable: false,
        radius: 30000,
        geodesic: true,
        icons: [
          {
            icon: dotLineSymbol,
            offset: "0px",
            repeat: "10px",
          },
        ],
        icon: CableIcon,
        pin: CableIcon,
      };
    case "U":
      return {
        strokeColor: color_on_map,
        strokeOpacity: 0,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        radius: 30000,
        geodesic: true,
        icons: [
          {
            icon: dashLineSymbol,
            offset: "0px",
            repeat: "18px",
          },
        ],
        icon: CableIcon,
        pin: CableIcon,
      };
    default:
      // cable_type => "O"
      return {
        strokeColor: color_on_map,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color_on_map,
        fillOpacity: 1,
        clickable: false,
        draggable: false,
        editable: false,
        radius: 30000,
        icon: CableIcon,
        pin: CableIcon,
      };
  }
};

export const INITIAL_ELEMENT_DATA = {
  name: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: [],
  // editable
  cable_type: "O",
  // gis_len ,actual_len, start_reading ,end_reading
  start_reading: 0,
  end_reading: 0,
  cable_ownership: "GTPL",
};

export const CABLE_TYPE_OPTIONS = [
  { value: "O", label: "Overhead" },
  { value: "U", label: "Underground" },
  { value: "W", label: "Wall Clamped" },
];

export const CONFIG_LIST_TABLE_COL_DEFS = [
  { headerName: "Name", field: "config_name" },
  { headerName: "Tubes", field: "no_of_tube" },
  { headerName: "Ribbon count", field: "ribbon_count" },
  { headerName: "Core / Tube", field: "core_per_tube" },
  { headerName: "Color", field: "color_on_map" },
];

export const CABLE_OWNERSHIP_OPTIONS = [
  { value: "GTPL", label: "GTPL" },
  { value: "GFGNL", label: "GFGNL" },
  { value: "TATA", label: "TATA" },
  { value: "AIRTEL", label: "AIRTEL" },
];

export const CABLE_SIGNAL_TYPES = [
  { value: "Backbone Signal", label: "Backbone Signal" },
  { value: "POP Signal", label: "POP Signal" },
  { value: "SPOP Signal", label: "SPOP Signal" },
  { value: "PON PORT Signal", label: "PON PORT Signal" },
  { value: "Drop Signal", label: "Drop Signal" },
  { value: "CATV Signal", label: "CATV Signal" },
];

export const getElementFormTemplate = () => {
  return {
    title: "Cable Form",
    sections: [
      {
        title: "",
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "cable_type",
            label: "Cable Type",
            field_type: FIELD_TYPES.Select,
            options: CABLE_TYPE_OPTIONS,
          },
          {
            field_key: "cable_signal_types",
            label: "Cable signal types",
            field_type: FIELD_TYPES.SelectMultiArray,
            options: CABLE_SIGNAL_TYPES,
          },
          {
            field_key: "cable_ownership",
            label: "Cable Ownership",
            field_type: FIELD_TYPES.SelectCreatable,
            options: CABLE_OWNERSHIP_OPTIONS,
          },
          {
            field_key: "gis_len",
            label: "Gis Length (m)",
            field_type: FIELD_TYPES.Input,
            disabled: true,
          },
          {
            field_key: "actual_len",
            label: "Actual Length",
            field_type: FIELD_TYPES.NumericInput,
          },
          {
            field_key: "start_reading",
            label: "Start Reading",
            field_type: FIELD_TYPES.NumericInput,
          },
          {
            field_key: "end_reading",
            label: "End Reading",
            field_type: FIELD_TYPES.NumericInput,
          },
          {
            field_key: "is_armoured",
            label: "Armoured",
            field_type: FIELD_TYPES.CheckBox,
          },
        ],
      },
    ],
    // this shows where dependant template data comes from
    metaData: {
      geometryUpdateFields: ["gis_len"],
      getElementAddressData: (address, submitData) => {
        submitData.address = address.address;
      },
    },
  };
};

export const INITIAL_CONFIG_DATA = {
  config_name: "",
  no_of_tube: "",
  core_per_tube: "",
  specification: "",
  color_on_map: "#FF0000",
  vendor: "",
};

export const ELEMENT_CONFIG_TEMPLATE = {
  sections: [
    {
      title: "Cable Form",
      fieldConfigs: [
        {
          field_key: "config_name",
          label: "Name",
          field_type: "input",
          validationProps: {
            required: "Name is required",
          },
        },
        {
          field_key: "no_of_tube",
          label: "No Of Tubes",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "core_per_tube",
          label: "No Of Cores",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "ribbon_count",
          label: "Ribbon Count",
          field_type: "input",
          disable_on_edit: true,
        },
        {
          field_key: "color_on_map",
          label: "Color on map",
          field_type: "input",
        },
        {
          field_key: "specification",
          label: "Specification",
          field_type: "textArea",
        },
        {
          field_key: "vendor",
          label: "Vendor",
          field_type: "input",
        },
      ],
    },
  ],
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "Cable Type", field: "cable_type_display", type: "simple" },
  {
    label: "Cable signal types",
    field: "cable_signal_types",
    type: "multi_array",
  },
  { label: "Cable Ownership", field: "cable_ownership", type: "simple" },
  { label: "Config Name", field: "config_name", type: "simple" },
  { label: "Gis Length (m)", field: "gis_len", type: "simple" },
  { label: "Actual Length", field: "actual_len", type: "simple" },
  { label: "Start Reading", field: "start_reading", type: "simple" },
  { label: "End Reading", field: "end_reading", type: "simple" },
  { label: "Armoured", field: "is_armoured", type: "boolean" },
  { label: "No of tubes", field: "no_of_tube", type: "simple" },
  { label: "Ribbon Count", field: "ribbon_count", type: "simple" },
  { label: "Core / Tube", field: "core_per_tube", type: "simple" },
  { label: "Specification", field: "specification", type: "simple" },
  { label: "Vendor", field: "vendor", type: "simple" },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "ports",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    field: "ref_code",
    label: "Reff Code",
    field_type: FIELD_TYPES.Input,
    filter_type: FILTER_TYPES.contains,
  },
  {
    label: "Cable signal types",
    field: "cable_signal_types",
    filter_type: FILTER_TYPES.array_multi_choice,
    field_type: FIELD_TYPES.SelectMultiArray,
    choices: CABLE_SIGNAL_TYPES,
  },
  {
    field: "cable_ownership",
    label: "Cable Ownership",
    filter_type: FILTER_TYPES.multi_choice,
    field_type: FIELD_TYPES.SelectMulti,
    choices: [...CABLE_OWNERSHIP_OPTIONS, { value: "Others", label: "Others" }],
  },
  {
    label: "Cable Type",
    field: "cable_type",
    filter_type: FILTER_TYPES.or_multi_choice,
    field_type: FIELD_TYPES.SelectMulti,
    choices: CABLE_TYPE_OPTIONS,
  },
  {
    label: "Configuration",
    field: "configuration_id",
    filter_type: FILTER_TYPES.configuration,
    field_type: FIELD_TYPES.ConfigSelect,
  },
  {
    label: "Gis Length (m)",
    field: "gis_len",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.NumericInput,
    hasOperator: true,
  },
];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
