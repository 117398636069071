import React from "react";
import { useSelector } from "react-redux";

import BomContent from "./Bom/BomContent";
import CustomerTaggingContent from "./CustomerTaggingReport/CustomerTaggingContent";
import CustomerTaggingCompletionContent from "./CustomerTaggingCompletionReport/CustomerTaggingCompletionContent";
import CustomerTaggingProgressContent from "./CustomerTaggingProgressReport/CustomerTaggingProgressContent";

import { getFetchedReportType } from "Analysis/data/analysis.selectors";
import { REPORT_TYPES } from "Analysis/data/utils";

const AnalysisContent = () => {
  /**
   * Parent:
   *    AnalysisPage
   */
  const reportType = useSelector(getFetchedReportType);

  switch (reportType?.value) {
    case REPORT_TYPES[0].value:
      return <BomContent />;
    case REPORT_TYPES[2].value:
      return <CustomerTaggingContent />;
    case REPORT_TYPES[3].value:
      return <CustomerTaggingCompletionContent />;
    case REPORT_TYPES[4].value:
      return <CustomerTaggingProgressContent />;
    default:
      return null;
  }
};

export default AnalysisContent;
