import React from "react";

import groupBy from "lodash/groupBy";
import size from "lodash/size";
import map from "lodash/map";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import TicketWorkOrderList from "./TicketWorkOrderList";
import { LayerKeyMappings, LayerKeyNameMapping } from "planning/GisMap/utils";

const NetworkTicketCollapsibleWOList = ({
  isFilterActive,
  workOrderList = [],
}) => {
  /**
   * render collapsible wo list layer wise
   * Parent:
   *    TicketSideBar
   */
  if (!size(workOrderList)) {
    return (
      <Box px={2} py="20%">
        <Typography variant="h5">
          {isFilterActive
            ? "No workorders for selected Filter !!"
            : "No workorders added to this ticket yet !!"}
        </Typography>
      </Box>
    );
  }

  const groupedFilterList = groupBy(workOrderList, "layer_key");

  return map(groupedFilterList, (woList, layerKey) => {
    const Icon = LayerKeyMappings[layerKey]["getViewOptions"]({}).icon;
    const dataCount = size(woList);
    return (
      <Accordion defaultExpanded={dataCount < 5}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={layerKey}>
          <Stack direction="row" alignItems="center">
            <Paper
              sx={{
                width: "42px",
                height: "42px",
                lineHeight: "42px",
                textAlign: "center",
                marginRight: "16px",
                marginLeft: "-4px",
              }}
            >
              <img className="responsive-img" src={Icon} alt={layerKey} />
            </Paper>
            <Typography variant="button" lineHeight={1.1}>
              {`${LayerKeyNameMapping[layerKey]} ( ${dataCount} )`}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Divider />
          <TicketWorkOrderList workOrderList={woList} isFilterActive={false} />
        </AccordionDetails>
      </Accordion>
    );
  });
};

export default NetworkTicketCollapsibleWOList;
