import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActionBar from "planning/ActionBar";
import { getShowSidebar } from "planning/data/planningState.selectors";
import GisMapEventLayer from "planning/GisMap/components/GisMapEventLayer";

import "planning/styles/planning-page.scss";
import {
  PAGE_SELCTIONS,
  setCurrentPage,
} from "redux/reducers/appState.reducer";

const DevicesPageWrapper = () => {
  /**
   * Devices page nearly same as planning page, but without map
   * For catv module UX don't need Google map, so remove map and make content area bigger
   * remove popups and turn them into columns / content Portals with full size
   */
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(PAGE_SELCTIONS.DEVICES_PAGE));

    return () => dispatch(setCurrentPage(""));
  }, []);

  return (
    <div id="planning-page" className="page-wrapper">
      <div className="pl-sidebar-wrapper">
        <ActionBarSidebar />
        <div className="pl-content">
          <div className="pl-map-container">
            <GisMapEventLayer />
          </div>
        </div>
      </div>
    </div>
  );
};

const ActionBarSidebar = () => {
  const showSidebar = useSelector(getShowSidebar);
  return (
    <div className={`pl-sidebar pl-sidebar-abs ${showSidebar ? "show" : ""}`}>
      <ActionBar />
    </div>
  );
};

export default DevicesPageWrapper;
