import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import map from "lodash/map";
import snakeCase from "lodash/snakeCase";
import capitalize from "lodash/capitalize";
import { format } from "date-fns";

import Stack from "@mui/material/Stack";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LoadingButton from "@mui/lab/LoadingButton";

import { getLayerViewData } from "planning/data/planningGis.selectors";
import { addNotification } from "redux/reducers/notification.reducer";
import { fetchDownloadLayerData } from "planning/data/layer.services";

const DownloadLayer = ({ layerConfig }) => {
  const dispatch = useDispatch();
  const { layer_key, name } = layerConfig;

  const [loadingType, setLoadingType] = useState(null);
  // get list of elements for current key
  const layerData = useSelector(getLayerViewData(layer_key));

  const { mutate: exportMutation, isLoading: loadingExport } = useMutation(
    fetchDownloadLayerData,
    {
      onSuccess: (res) => {
        setLoadingType(null);
        const report_name =
          snakeCase(name) +
          "_" +
          format(new Date(), "dd/MM/yyyy") +
          "_" +
          format(new Date(), "hh:mm");
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        //
        let filename = "";
        if (
          res.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          filename = `${report_name}.xlsx`;
        } else if (res.type === "application/vnd.google-earth.kml+xml") {
          filename = `${report_name}.kml`;
        } else if (res.type === "application/zip") {
          filename = `${report_name}.zip`;
        }
        link.setAttribute("download", filename);

        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        setLoadingType(null);
        dispatch(
          addNotification({
            type: "error",
            title: "Error",
            text: err.message,
          })
        );
      },
    }
  );

  const handleDownload = useCallback(
    (exportType) => () => {
      setLoadingType(exportType);
      exportMutation({
        layerKey: layer_key,
        data: { export_type: exportType, element_ids: map(layerData, "id") },
      });
    },
    [layer_key, layerData]
  );

  return (
    <>
      {map(get(layerConfig, "export_as", []), (exportType) => {
        return (
          <LoadingButton
            key={exportType}
            startIcon={<DownloadForOfflineIcon />}
            onClick={handleDownload(exportType)}
            loading={loadingExport && loadingType === exportType}
          >
            {exportType === "shapefile" ? "SHP" : capitalize(exportType)}
          </LoadingButton>
        );
      })}
    </>
  );
};

export default DownloadLayer;
