import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";
import TracebackForm from "./TracebackForm";

import {
  setMapState,
  toggleMapPopupMinimize,
} from "planning/data/planningGis.reducer";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import { resetTraceReducer } from "planning/data/traceback/traceback.reducer";

const TracebackPopup = () => {
  /**
   * handle minimized via mapState
   *
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const { minimized } = useSelector(getPlanningMapState);

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handlePopupMinimize = useCallback(() => {
    dispatch(toggleMapPopupMinimize());
  }, [dispatch]);

  useEffect(() => {
    // resetting trace reducer when popup close
    return () => {
      dispatch(resetTraceReducer());
    };
  }, []);

  return (
    <GisMapPopups dragId="TracebackPopup">
      <Box minWidth="650px" maxWidth="950px">
        {/* Table header */}
        <TableHeader
          title="Trace Back"
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
        {minimized ? null : <TracebackForm />}
      </Box>
    </GisMapPopups>
  );
};

export default TracebackPopup;
