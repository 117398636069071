import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import size from "lodash/size";
import get from "lodash/get";
import format from "date-fns/format";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { GisElementTableContentLoader } from "planning/GisMap/components/GisMapPopups/GisMapPopupLoader";

import { getLoggedUserDetails } from "redux/selectors/auth.selectors";
import { fetchAlertActionsList } from "gis_alert/data/alert.actions";
import { parseErrorMessagesWithFields } from "utils/api.utils";

const AlertActionsList = ({ alertData }) => {
  const { id } = alertData;
  const [errorMessage, setErrMessage] = useState("");

  const loggedUser = useSelector(getLoggedUserDetails);

  const { isLoading, data: alertActions } = useQuery(
    ["alertActionsList", id],
    fetchAlertActionsList,
    {
      retry: false,
      onError: (err) => {
        const { messageList } = parseErrorMessagesWithFields(err);
        if (size(messageList)) setErrMessage(messageList[0]);
        else
          setErrMessage(
            "An error occured while loading data, please retry after some time"
          );
      },
    }
  );

  if (isLoading) return <GisElementTableContentLoader />;
  if (errorMessage) {
    return (
      <Box py={6} px={3}>
        <Typography align="center" color="error">
          {errorMessage}
        </Typography>
      </Box>
    );
  }

  if (!size(alertActions)) {
    return (
      <Box py={6} px={3}>
        <Typography align="center" sx={{ fontWeight: "bold" }}>
          No actions have been taken yet
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={2}>
      <Typography my={2} variant="h5" textAlign="center" color="primary">
        Alert Action History
      </Typography>
      <Divider />
      <Stack divider={<Divider />}>
        {alertActions.map((action) => {
          const {
            id,
            message,
            assign_to_user,
            closed,
            rfo_text,
            sub_rfo_text,
            created_on,
            user,
          } = action;
          // generate alternate text based on flags
          let alernateText = "";
          if (assign_to_user) {
            alernateText += "Assigned alert to self";
          }
          if (closed) {
            alernateText += `CLOSED with RFO:\n ${rfo_text}`;
            // if user selected sub rfo text than show that as well
            if (sub_rfo_text) {
              alernateText += `\n(sub-RFO): ${sub_rfo_text}`;
            }
          }
          return (
            <Stack key={id} direction="row" py={2}>
              {!!user ? (
                <Box>
                  <Typography variant="body2">
                    {loggedUser.id === user.id ? "YOU" : get(user, "name", "")}:
                  </Typography>
                  <Typography variant="caption">
                    #{get(user, "username", "")}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography>
                    User related to this action not found, maybe user was
                    removed from system.
                  </Typography>
                </Box>
              )}
              <Box flex={1} px={2}>
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                  {message}
                </Typography>
                {alernateText ? (
                  <>
                    <Divider sx={{ marginY: 1 }} />
                    <Box sx={{ fontSize: "0.8em", whiteSpace: "pre-wrap" }}>
                      <i>{alernateText}</i>
                    </Box>
                  </>
                ) : null}
              </Box>

              <Typography variant="body2">
                @{format(new Date(created_on), "dd-MM-YYY HH:mm")}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default AlertActionsList;
