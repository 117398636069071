import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import size from "lodash/size";
import get from "lodash/get";
import noop from "lodash/noop";

import { Divider, Stack, Typography, Box, Button } from "@mui/material";

import GisMapPopups from "planning/GisMap/components/GisMapPopups";
import CustomSelect from "components/common/FormFields/CustomSelect";
import TableHeader from "planning/GisMap/components/ElementDetailsTable/TableHeader";

import { setMapState } from "planning/data/planningGis.reducer";
import { getPlanningMapStateData } from "planning/data/planningGis.selectors";
import { addNotification } from "redux/reducers/notification.reducer";
import { showSplicingView } from "planning/data/event.actions";

const ElementPatching = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */
  const [leftElement, setLeftElement] = useState(null);
  const [rightElement, setRightElement] = useState(null);

  const dispatch = useDispatch();
  const { layerKey, elementId, elementList } = useSelector(
    getPlanningMapStateData
  );

  const handleClose = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handlePatchingClick = useCallback(() => {
    if (!rightElement && !leftElement) {
      // need atleast 1 input or output selected
      dispatch(
        addNotification({
          type: "error",
          title: "Selection incorrect",
          text: "Select input or output element for splicing",
        })
      );
    } else {
      let actionPayload = {
        layerKey,
        elementId,
        middle: { element_id: elementId, layer_key: layerKey },
        left: !!leftElement
          ? { element_id: leftElement.id, layer_key: leftElement.layerKey }
          : undefined,
        right: !!rightElement
          ? { element_id: rightElement.id, layer_key: rightElement.layerKey }
          : undefined,
      };
      dispatch(showSplicingView(actionPayload));
    }
  }, [layerKey, elementId, leftElement, rightElement]);

  const isListEmpty = !size(elementList);
  const showPatchingBtn = !!leftElement || !!rightElement;

  return (
    <GisMapPopups dragId="ElementPatching">
      <Box minWidth="350px" maxWidth="550px">
        {/* header */}

        <TableHeader
          title="Element Patching"
          minimized={false}
          showMinimizeBtn={false}
          handlePopupMinimize={noop}
          handleCloseDetails={handleClose}
        />

        <Stack p={1} direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled={!showPatchingBtn}
            color="secondary"
            onClick={handlePatchingClick}
          >
            Patching
          </Button>
        </Stack>
        <Divider />
        {isListEmpty ? (
          <Box p={2}>
            <Typography variant="h6" color="text.secondary" textAlign="center">
              No Elements
            </Typography>
          </Box>
        ) : (
          <Stack
            spacing={1}
            py={1}
            // divider={<Divider />}
            maxHeight="72vh"
            overflow="auto"
          >
            <Typography variant="h6" px={1}>
              Left Connections
            </Typography>

            <Box px={2} py={1}>
              <CustomSelect
                label="Select left element"
                options={elementList}
                value={leftElement}
                onChange={setLeftElement}
                className={`form-select`}
                classNamePrefix="form-select"
                placeholder=" "
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                menuPortalTarget={document.body}
                isOptionDisabled={(o) => o.id === get(rightElement, "id")}
                isClearable
              />
            </Box>

            <Divider />

            <Typography variant="h6" px={1}>
              Right Connections
            </Typography>

            <Box px={2} py={1}>
              <CustomSelect
                label="Select right element"
                options={elementList}
                value={rightElement}
                onChange={setRightElement}
                className={`form-select`}
                classNamePrefix="form-select"
                placeholder=" "
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                menuPortalTarget={document.body}
                isOptionDisabled={(o) => o.id === get(leftElement, "id")}
                isClearable
              />
            </Box>
          </Stack>
        )}
      </Box>
    </GisMapPopups>
  );
};

export default ElementPatching;
