import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import { addNotification } from "redux/reducers/notification.reducer";
import {
  setMapPosition,
  setSpecialLayerData,
} from "planning/data/planningGis.reducer";
import { FEATURE_TYPES } from "planning/GisMap/layers/common/configuration";
import { CUSTOM_ICONS } from "planning/GisMap/GisIcons";
import {
  SPECIAL_LAYER_HIGHLIGHT_TYPES,
  generateSpecialLayerId,
} from "planning/GisMap/utils";

const LatLongSearch = () => {
  /**
   * Parent:
   *    MapSearchbox
   */
  const dispatch = useDispatch();

  const [latLongVal, setLatLongVal] = useState("");

  const handleLatLongChange = useCallback((e) => {
    setLatLongVal(e.target.value);
  }, []);

  const handleSearchByLatLong = useCallback(() => {
    const inputList = latLongVal.split(",").map((item) => {
      item.trim();
      return Number(item);
    });

    // validate lat lng values
    const [lat, lng] = inputList;
    if (inputList.length !== 2 || !lat || !lng) {
      dispatch(
        addNotification({
          type: "error",
          title: "Invalid latitude, longitude values",
        })
      );

      return;
    }

    // convert lat long val to lat long object, ex : {lat: 23.0263517, lng: 72.58190130000001}
    const location = { lat, lng };

    dispatch(
      setMapPosition({
        center: location,
        currentZoom: 18,
        isHighlighted: true,
      })
    );
    const specialLayerId = generateSpecialLayerId(
      {},
      SPECIAL_LAYER_HIGHLIGHT_TYPES.google_latlng_search
    );
    dispatch(
      setSpecialLayerData([
        {
          id: specialLayerId,
          featureType: FEATURE_TYPES.POINT,
          coordinates: location,
          viewOptions: {
            iconType: CUSTOM_ICONS.blue_marker,
          },
        },
      ])
    );
    setLatLongVal("");
  }, [latLongVal]);

  return (
    <InputBase
      label="Search"
      type="search"
      placeholder="Search by latitude, longitude"
      sx={{
        width: "100%",
        height: "100%",
      }}
      value={latLongVal}
      onChange={handleLatLongChange}
      endAdornment={
        <SearchIcon
          onClick={handleSearchByLatLong}
          sx={{
            cursor: "pointer",
            color: "secondary.main",
            marginLeft: "6px",
            fontSize: "1.3rem",
          }}
        />
      }
    />
  );
};

export default LatLongSearch;
