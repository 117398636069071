import { PLANNING_EVENT } from "planning/GisMap/utils";
import { setMapState } from "../planningGis.reducer";
import { getPlanningMapState } from "../planningGis.selectors";

export const showGenericAttrsTable =
  ({ layerKey, elementData, elementId }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showGenericAttrsPopup,
        layerKey,
        data: { elementId, elementData },
      })
    );
  };

export const showInterfaceAttrsTable =
  ({ layerKey, elementData, elementId }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showInterfaceAttrsPopup,
        layerKey,
        data: { elementId, elementData },
      })
    );
  };

export const showTransportAttrsTable =
  ({ layerKey, elementData, elementId }) =>
  (dispatch) => {
    dispatch(
      setMapState({
        event: PLANNING_EVENT.showTransportAttrsPopup,
        layerKey,
        data: { elementId, elementData },
      })
    );
  };

export const showGraphPopup =
  ({ dataType, graphTitleData, fieldKey }) =>
  (dispatch, getState) => {
    const currMapState = getPlanningMapState(getState());

    let newMapState = {
      ...currMapState,
      event: PLANNING_EVENT.showGraphPopup,
      data: { ...currMapState.data, graphTitleData, dataType, fieldKey },
    };

    dispatch(setMapState(newMapState));
  };
