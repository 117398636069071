import React from "react";

import filter from "lodash/filter";
import size from "lodash/size";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import PortList from "./PortList";

const tableConfig = [
  {
    label: "Sr No",
    key: "sr_no",
  },
  {
    label: "Port",
    key: "name",
  },
  {
    label: "Status",
    key: "status_display",
  },
  {
    label: "Connection",
    key: "connected_to",
    type: "port_number",
  },
  {
    label: "Trace",
    key: "id",
    type: "get_connection",
  },
];

/**
 * Parent:
 *    ElementPortDetails
 *
 * Any element that has normal input ports, output ports setup
 * Used for elements : Splitter, Fms, Onu
 */
const DefaultElemPortDetails = ({ portDetails }) => {
  const inputList = filter(portDetails, ["is_input", true]);
  const connectedInputList = filter(inputList, (d) => !!d.connected_to);

  const outputList = filter(portDetails, ["is_input", false]);
  const connectedOutputList = filter(outputList, (d) => !!d.connected_to);

  return (
    <Box px={1} pb={1}>
      <Typography variant="h6" py={1}>
        Input ports
        <Typography variant="overline">{` ( ${
          connectedInputList.length
        } / ${size(inputList)} )`}</Typography>
      </Typography>
      <PortList portList={inputList} tableConfig={tableConfig} />
      <Typography variant="h6" py={1}>
        Output ports
        <Typography variant="overline">{` ( ${
          connectedOutputList.length
        } / ${size(outputList)} )`}</Typography>
      </Typography>
      <PortList portList={outputList} tableConfig={tableConfig} />
    </Box>
  );
};

export default DefaultElemPortDetails;
