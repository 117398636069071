import { useCallback, useState } from "react";
import { useMutation } from "react-query";

import get from "lodash/get";
import has from "lodash/has";

import { getConnectedPortService } from "planning/data/port.services";

const useGetConnectedPort = () => {
  // shape: { port_id: { isFetching, error, data } }
  const [connectedPortData, setConnectedPortData] = useState({});

  const { mutate } = useMutation(getConnectedPortService);

  const getConnectedPort = useCallback(
    (layerKey, portId, removePort = null) => {
      // toggle data remove if already showing, load and show if not there yet
      // remove data related to portId, set it undefined otherwise set loading
      const isExist = !!get(connectedPortData, portId);
      if (isExist) {
        setConnectedPortData((prevObj) => {
          const newObj = { ...prevObj };
          delete newObj[portId];
          return newObj;
        });
      } else {
        setConnectedPortData((prevObj) => {
          const newObj = { ...prevObj };
          newObj[portId] = { isFetching: true };
          // remove selected port; specially used for cable, need to remove B end portId if A end trace is being fetched
          if (!!removePort) delete newObj[removePort];
          return newObj;
        });
        // fetch new port trace data
        mutate(
          {
            layerKey,
            portId,
          },
          {
            onSuccess: (res) => {
              setConnectedPortData((prevObj) => ({
                ...prevObj,
                [portId]: { isFetching: false, data: res },
              }));
            },
            onError: (err) => {
              let errorMsg;
              const status = get(err, "response.status");
              if (status === 404) {
                errorMsg = "Port trace is not complete yet";
              } else if (status === 500) {
                errorMsg = "Server error. please contact admin";
              } else if (status === 401) {
                errorMsg = "Please Login Again.";
              } else {
                errorMsg = "Unknown error with code " + status;
              }
              setConnectedPortData((prevObj) => ({
                ...prevObj,
                [portId]: {
                  isFetching: false,
                  error: errorMsg,
                },
              }));
            },
          }
        );
      }
    },
    [setConnectedPortData, connectedPortData]
  );

  return { getConnectedPort, connectedPortData };
};

export default useGetConnectedPort;
