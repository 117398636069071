import React, { Component } from "react";
import PropTypes from "prop-types";

import get from "lodash/get";
import has from "lodash/has";
import isArray from "lodash/isArray";

import { pie, arc } from "d3-shape";
import { uniq_colors } from "./graph.utils";

export default class PieChart extends Component {
  /**
   * Parent:
   *      FullReport
   */

  static defaultProps = {
    valueKey: "value",
    colorKey: "color",
    pie: true,
    ringWidth: 10,
    // extra outerRadius for data to be highlighted
    highlight: 4,
  };

  createArcConfigList = (data) => {
    // creates list of objects for each data section of pie chart
    const { valueKey } = this.props;
    // accessor for pie, with this method we will get extra "data" in every data point of pie
    const pieGenerator = pie().value((d) => d[valueKey]);
    return pieGenerator(data);
  };

  arcGenerator = (innerRadius, outerRadius) => {
    return arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .padAngle(0.01)
      .padRadius(100); //.cornerRadius(4);
  };

  getArcColor = (index) => {
    const { data, colorKey } = this.props,
      curr_data = data[index];

    if (has(curr_data, colorKey) && Boolean(curr_data[colorKey])) {
      const color = isArray(curr_data[colorKey])
        ? curr_data[colorKey][index]
        : curr_data[colorKey];
      return color;
    } else {
      // data has no color
      // generate auto
      return uniq_colors[index];
    }
  };

  handlePieClick = (arcData) => () => {
    const { onPieClick } = this.props;
    if (onPieClick) {
      const { field_key, value_key } = arcData;
      onPieClick({ field_key, value_key });
    }
  };

  render = () => {
    const {
        ringWidth,
        highlight, // width of the pie data ring
        pie, // if this is pie chart or donut chart
        data,
      } = this.props,
      arc_config_list = this.createArcConfigList(data),
      // this is more of like setting square aspect ratio
      width = 100,
      height = 100,
      radius = width / 2,
      innerRadius = pie ? 0 : radius - ringWidth - highlight,
      outerRadius = radius - highlight,
      arcGen = this.arcGenerator(innerRadius, outerRadius);
    const arcHighlight = this.arcGenerator(
      innerRadius,
      outerRadius + highlight
    );

    return (
      <div className="pie-chart">
        <svg
          preserveAspectRatio="xMidYMid meet"
          viewBox={`0 0 ${width} ${height}`}
        >
          <g transform={`translate(${width / 2}, ${height / 2})`}>
            {arc_config_list.map((arc_config, i) => {
              const arc_path = get(arc_config, "data.highlight", false)
                ? arcHighlight(arc_config)
                : arcGen(arc_config);
              // const arc_path = arcGen(arc_config)
              const color = this.getArcColor(i);

              return (
                <path
                  className="clickable"
                  key={arc_config.index}
                  d={arc_path}
                  fill={color}
                  onClick={this.handlePieClick(arc_config.data)}
                />
              );
            })}
          </g>
        </svg>
      </div>
    );
  };
}

PieChart.propTypes = {
  // default : 'value'
  valueKey: PropTypes.string.isRequired,
  // default: 'color'
  colorKey: PropTypes.string.isRequired,
  // if pie ? pie chart : donut chart
  pie: PropTypes.bool.isRequired,

  ringWidth: PropTypes.number.isRequired,
  // extra outerRadius for data to be highlighted
  highlight: PropTypes.number.isRequired,
  // data: [{tag_type, label, id, data, color}, ...], take data related to valueKey and generate process data
  data: PropTypes.array.isRequired,
};
