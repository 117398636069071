import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Xarrow from "react-xarrows";

import size from "lodash/size";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import CableIcon from "@mui/icons-material/Cable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import CableSplicingBlock from "./CableSplicingBlock";
import PassiveElemSplicingBlock from "./PassiveElemSplicingBlock";
import JcSplicingBlock from "./JcSplicingBlock";
import CableThroughConnect from "./CableThroughConnect";
import OltPatchingBlock from "./OltPatchingBlock";

import {
  getSplicingElement,
  getSplicingConnections,
  isPortUpdateLoading,
} from "planning/data/splicing.selectors";
import { getPlanningMapStateData } from "planning/data/planningGis.selectors";

const SplicingContainer = ({ onConnectionAdd }) => {
  /**
   * Parent:
   *    SplicingView
   */

  const [showThroughConnectionBlock, setshowThroughConnectionBlock] =
    useState(false);
  const [showConnected, setShowConnected] = useState(true);

  const portUpdateLoading = useSelector(isPortUpdateLoading);
  // middle will always be there which is selected element, left and right is optional but one of them will be there
  const left = useSelector(getSplicingElement("left"));
  const right = useSelector(getSplicingElement("right"));
  const middle = useSelector(getSplicingElement("middle"));
  const connections = useSelector(getSplicingConnections);
  const splicingPostData = useSelector(getPlanningMapStateData);

  const handleConnected = useCallback(() => {
    setShowConnected((currConnState) => !currConnState);
  }, []);

  const handleSettings = useCallback(() => {
    setshowThroughConnectionBlock((currBlockState) => !currBlockState);
  }, []);

  // render helpers
  const hasLeft = !!left;
  const hasRight = !!right;
  // through connection can be done for cable, FMS
  // require more than 1 element
  let throughConnectElements = [];
  if (left?.layer_key === "p_cable" || left?.layer_key === "p_fms") {
    // check if cable is connecting inverted
    let isInverted = false;
    if (
      left?.layer_key === "p_cable" &&
      splicingPostData["left"].cable_end === "A"
    ) {
      isInverted = true;
    }
    throughConnectElements.push({ ...left, isInverted });
  }
  if (middle?.layer_key === "p_cable" || middle?.layer_key === "p_fms") {
    throughConnectElements.push(middle);
  }
  if (right?.layer_key === "p_cable" || right?.layer_key === "p_fms") {
    let isInverted = false;
    if (
      right?.layer_key === "p_cable" &&
      splicingPostData["right"].cable_end === "B"
    ) {
      isInverted = true;
    }
    throughConnectElements.push({ ...right, isInverted });
  }
  const showThroughConnection = size(throughConnectElements) > 1;

  const renderSplicingElement = (elementData, side) => {
    if (!elementData) {
      return null;
    } else if (elementData.layer_key === "p_cable") {
      return (
        <CableSplicingBlock
          portData={elementData}
          side={side}
          cable_end={splicingPostData[side].cable_end}
        />
      );
    } else if (
      elementData.layer_key === "p_splitter" ||
      elementData.layer_key === "p_fms" ||
      elementData.layer_key === "p_olt" ||
      elementData.layer_key === "p_onu" ||
      elementData.layer_key === "p_customer"
    ) {
      // handle showing of left and right side ports
      let showLeft, showRight;
      if (side === "middle") {
        showLeft = hasLeft;
        showRight = hasRight;
      } else {
        showRight = side === "left";
        showLeft = !showRight;
      }
      // separate block for olt, handle card
      if (elementData.layer_key === "p_olt") {
        return (
          <OltPatchingBlock
            onConnectionAdd={onConnectionAdd}
            portData={elementData}
            side={side}
            hasLeft={showLeft}
            hasRight={showRight}
          />
        );
      }
      // simple input / output port blocks
      return (
        <PassiveElemSplicingBlock
          onConnectionAdd={onConnectionAdd}
          portData={elementData}
          side={side}
          hasLeft={showLeft}
          hasRight={showRight}
        />
      );
    } else if (elementData.layer_key === "p_jointcloser") {
      return <JcSplicingBlock portData={elementData} side={side} />;
    }
  };

  return (
    <>
      <Stack
        sx={{ boxShadow: "0px 5px 7px -3px rgba(122,122,122,0.51)" }}
        p={2}
        direction="row"
        spacing={2}
      >
        {showThroughConnection ? (
          <Tooltip title="Show through connection">
            <IconButton
              aria-label={"Show through connection"}
              color={showThroughConnectionBlock ? "secondary" : undefined}
              sx={{
                border: "1px solid",
                borderRadius: 1,
              }}
              onClick={handleSettings}
            >
              <CableIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="Show Connected">
          <IconButton
            aria-label={"Show Connected"}
            color={showConnected ? "secondary" : undefined}
            sx={{
              border: "1px solid",
              borderRadius: 1,
            }}
            onClick={handleConnected}
          >
            {showConnected ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Tooltip>
      </Stack>
      <Box
        id="splicing-container"
        p={5}
        sx={{
          maxHeight: "72vh",
          overflowY: portUpdateLoading ? "hidden" : "auto",
          position: "relative",
        }}
      >
        {showThroughConnection && showThroughConnectionBlock ? (
          <CableThroughConnect
            fromData={throughConnectElements[0]}
            toData={throughConnectElements[1]}
          />
        ) : null}
        <Stack spacing={15} direction="row" overflow="auto">
          {renderSplicingElement(left, "left")}
          {renderSplicingElement(middle, "middle")}
          {renderSplicingElement(right, "right")}
        </Stack>
        {portUpdateLoading ? (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        ) : null}
        {showConnected ? (
          <>
            {connections.map((currConn, ind) => {
              return (
                <Xarrow
                  key={ind}
                  start={currConn.elem1}
                  end={currConn.elem2}
                  strokeWidth={2}
                  color="#ff7f50"
                  showHead={false}
                  showTail={false}
                />
              );
            })}
          </>
        ) : null}
      </Box>
    </>
  );
};

export default SplicingContainer;
