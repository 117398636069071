import React, { useCallback } from "react";

import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import get from "lodash/get";
import pick from "lodash/pick";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

import { FormSelect } from "components/common/FormFields";
import { OLT_PORT_TYPE_OPTIONS } from "planning/GisMap/layers/p_olt";
import { postPortEdit } from "ElementConfig/data/services";
import { addNotification } from "redux/reducers/notification.reducer";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";

const OltPortForm = ({ onCancel, portConfig, layerKey }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate: portEditMutation, isLoading } = useMutation(
    (mutationData) => postPortEdit(mutationData, layerKey, portConfig.id),
    {
      onSuccess: (res) => {
        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            title: "Port Update",
            text: "Port updated successfully.",
          })
        );
        onCancel();
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[2] === "fetchElementPortConfigList",
        });
      },
      onError: (err) => {
        const errStatus = get(err, "response.status");
        let notiText;
        if (errStatus === 400) {
          let errData = get(err, "response.data");

          for (const fieldKey in errData) {
            if (Object.hasOwnProperty.call(errData, fieldKey)) {
              const errList = errData[fieldKey];
              setError(fieldKey, {
                type: "custom",
                message: get(errList, "0", ""),
              });
            }
          }
          notiText = "Please correct input errors and submit again";
        } else {
          // maybe Internal server or network error
          // --- can not set error as not possible to clear this ---
          // formRef.current.onError(
          //   "__all__",
          //   "Something went wrong. Can not perform operation"
          // );
          notiText =
            "Something went wrong at our side. Please try again after refreshing the page.";
        }
        dispatch(
          addNotification({
            type: NOTIFICATION_TYPE.ERROR,
            title: "Port Update",
            text: notiText,
          })
        );
      },
    }
  );

  const onFormSubmit = useCallback((data) => {
    portEditMutation(data);
  }, []);

  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    setError,
  } = useForm({ defaultValues: pick(portConfig, ["capacity", "port_type"]) });

  return (
    <Box
      px={1}
      pb={3}
      pt={2}
      component="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Box>
        <Typography
          color="primary.dark"
          flex={1}
          variant="h6"
          textAlign="center"
          pb={2}
        >
          Element Port Form
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: 4 }} />

      <Stack spacing={2} direction="row">
        <Box flex={1}>
          <FormSelect
            label={"Port Type"}
            name={"port_type"}
            control={control}
            options={OLT_PORT_TYPE_OPTIONS}
            error={!!get(errors, ["port_type"])}
            helperText={get(errors, ["port_type", "message"], "")}
          />
        </Box>
        <Box flex={1}>
          <TextField
            className="full-width"
            label={"Capacity"}
            {...register("capacity")}
            error={!!get(errors, ["capacity"])}
            helperText={get(errors, ["capacity", "message"], "")}
          />
        </Box>
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="flex-end" pt={3}>
        <Button sx={{ minWidth: "10em" }} color="error" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          sx={{ minWidth: "10em" }}
          variant="contained"
          disableElevation
          color="success"
          type="submit"
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default OltPortForm;
