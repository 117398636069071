import get from "lodash/get";
import map from "lodash/map";
import isNull from "lodash/isNull";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import isNaN from "lodash/isNaN";
import size from "lodash/size";
import round from "lodash/round";
import has from "lodash/has";
import sumBy from "lodash/sumBy";

import format from "date-fns/format";

import {
  FORMATTOR_USED_FOR,
  formatterFunctionsMap,
} from "./live_attrbutes.utils";

export function tooltipFormatter() {
  return this.points.reduce(function (s, point) {
    /**
     * point will get all fields we add during data transformation step
     * general shape : {x, y, fieldKey, optionObj}
     */
    const hasOptions = !!size(get(point, ["point", "optionObj"]));
    const fieldKey = get(point, "point.fieldKey");

    let value;
    const formattorFunc = formatterFunctionsMap(
      fieldKey,
      FORMATTOR_USED_FOR.graph_tooltip
    );
    // formate value if required
    if (formattorFunc) {
      const { formattedValue, unit: newUnit } = formattorFunc(point.y);
      value = `${formattedValue} ${newUnit ? newUnit : ""}`;
    } else {
      const unit = get(point.series, "userOptions.tooltip.valueSuffix", "");
      value = hasOptions
        ? get(point, ["point", "optionObj", point.y], "")
        : `${round(point.y, 2)} ${unit}`;
    }

    return (
      s +
      "<br/>" +
      `<span style="color:${point.color}">●</span>` +
      " " +
      point.series.name +
      ": " +
      value
    );
  }, "<b>" + format(this.x, "dd-MM-yyyy HH:mm:ss") + "</b>");
}

/**
 * Returns an operator function, which in turn takes valuesList ( {x, y} ) and ts when that value was sampled
 * Operator function returns list of data points that we can add to graph prcessed data
 * Generally they will only have x, y to be accurate return values
 * @param {String} operation name of operation
 * @returns List; shape: [ {x, y}, ...]
 */
function _graphOperationsMap(operation) {
  switch (operation) {
    case "noop":
      return (valuesList, ts) => valuesList;

    case "avg":
      return (valuesList, ts) => {
        const finalVal = sumBy(valuesList, "y") / valuesList.length;
        return [{ x: ts, y: finalVal }];
      };

    default:
      break;
  }
}

export const getChartConfig = (chartData, chartOptions) => {
  let chartConfig = {
    chart: {
      type: "spline",
      panKey: "shift",
      // in bigger data set chart changes values that looks wrong, make that stop
      allowMutatingData: false,
      zooming: {
        type: "x",
      },
    },
    xAxis: {
      type: "datetime",
    },
    rangeSelector: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        turboThreshold: 0,
      },
    },
    time: { timezone: "Asia/Kolkata" },
  };

  // apply custom height width if provided
  if (has(chartOptions, "width")) {
    chartConfig.chart.width = chartOptions.width;
  }

  if (has(chartOptions, "height")) {
    chartConfig.chart.height = chartOptions.height;
  }

  /**
   * Process data for each field
   * by Unit shape : {unit : { label, min, max, data: {label: seriesData}, formattorFunc}, ... }
   *
   * unit will be empty string "" for null unit fields
   */
  const unitMap = {};
  let emptyDataMessages = [];

  for (const fieldKey in chartData) {
    if (Object.hasOwnProperty.call(chartData, fieldKey)) {
      const { config, data } = chartData[fieldKey];
      // validate empty data
      if (!size(data)) {
        emptyDataMessages.push(`${config?.label} data is not available.`);
        continue;
      }

      // process options ( for choice fields )
      const options = get(config, "options") || [];
      const optionObj = {};
      for (let index = 0; index < options.length; index++) {
        const value = options[index];
        optionObj[value.value] = value.label;
      }
      // convert data -> value to number, date
      let transformedData = map(data, (d) => {
        let value;
        // null check
        if (isNull(d.value)) {
          value = null;
        } else {
          // convert to number
          value = Number(d.value);
          // revert to null value if not a number
          if (isNaN(value)) value = null;
        }
        return {
          x: +new Date(d.timestamp),
          y: value,
          fieldKey,
          optionObj,
        };
      });
      // orderBy time
      // transformedData = orderBy(transformedData, "x", ["asc"]);

      /**
       * MOST IMPORTANT STAGE IN PIPELINE - Process data
       * - Update chart data interval by extrapolation, avg value
       * - if any interval don't have value set as null so graph will show disconnected line
       */
      // Graph will add null values only if data break bigger than this break interval
      const { endTime, interval, breakInterval } = chartOptions;
      let processedData = [];
      let dataIndex = 0;
      let pointerTs = transformedData[dataIndex].x;
      // list of points to process in given interval
      let pointsOfInterval = [],
        pointCount = 0;
      const operation = "noop";
      const operationFunc = _graphOperationsMap(operation);

      let breakIntCurrCount = 0;
      // add padding around interval of 10%
      const intervalPadding = interval * 0.1;

      while (dataIndex < transformedData.length) {
        // shape: { x: timestamp (ms), y: value }
        const currDPoint = transformedData[dataIndex];

        // if (currDPoint.x <= pointerTs) {
        if (currDPoint.x <= pointerTs + intervalPadding) {
          if (isNull(currDPoint.y)) {
            // handle null values comming from server separatly
            // null are very important and shows break in graph so put it in as it is without any processing
            processedData.push({ ...currDPoint });
          } else {
            // create a list of points between an interval, so we can run required operation at the end of interval
            pointsOfInterval.push({ ...currDPoint });
            pointCount += 1;
            // reset break interval
            breakIntCurrCount = 0;
          }
          // go to next index
          dataIndex += 1;
        } else {
          // if there is no value betweeen current time interval, set value to null
          if (pointCount) {
            // normal data update, process and add data
            const pushData = operationFunc(pointsOfInterval, pointerTs);
            for (let pdInd = 0; pdInd < pushData.length; pdInd++) {
              const currPushPoint = pushData[pdInd];
              processedData.push({
                ...currDPoint,
                y: currPushPoint.y,
                x: currPushPoint.x,
              });
            }
          } else {
            // logic for break interval
            breakIntCurrCount += interval;
            if (breakIntCurrCount >= breakInterval) {
              processedData.push({
                ...currDPoint,
                y: null,
                x: pointerTs,
              });
            }
          }
          // update variables for next interval iteration
          pointerTs = pointerTs + interval;
          // DONT NEED break condition: while will break with data index, hanging points will be added after loop end
          // if (pointerTs > endTime) break; // break condition
          pointsOfInterval = [];
          pointCount = 0;
        }
      }
      // add last point to processedData if we missed it
      if (pointCount) {
        const pushData = operationFunc(pointsOfInterval, pointerTs);
        for (let pdInd = 0; pdInd < pushData.length; pdInd++) {
          const currPushPoint = pushData[pdInd];
          processedData.push({
            ...transformedData[dataIndex],
            y: currPushPoint.y,
            x: currPushPoint.x,
          });
        }
      }

      // find min / max
      let minValue = minBy(processedData, "y");
      minValue = Number(get(minValue, "y"));

      let maxValue = maxBy(processedData, "y");
      maxValue = Number(get(maxValue, "y"));

      // can add padding to min max with avgVal ----------

      let unit = get(config, "unit") || "";
      // start unit processing we get new unit by formatting avg value
      const formattorFunc = formatterFunctionsMap(
        fieldKey,
        FORMATTOR_USED_FOR.graph_axes
      );
      if (formattorFunc) {
        // also get average value to check unit and padding around graph
        const avgVal = (maxValue - minValue) / 2;
        const { unit: newUnit } = formattorFunc(avgVal);
        // PATCH - for bps unit don't update to new unit all bps will be shown in same graph
        if (unit !== "bps") unit = newUnit || "";
      }

      // check if unit already processed for any other field
      if (has(unitMap, unit)) {
        // update existing map with new data series
        if (unitMap[unit].minValue > minValue) {
          unitMap[unit].minValue = minValue;
        }
        if (unitMap[unit].maxValue < maxValue) {
          unitMap[unit].maxValue = maxValue;
        }
        // update label
        unitMap[unit].label += `, ${config?.label}`;
        unitMap[unit].data[config?.label] = [...processedData];
      } else {
        // add new unit to the map
        unitMap[unit] = {
          minValue,
          maxValue,
          label: config?.label || "",
          data: { [config?.label]: [...processedData] },
          formattorFunc,
        };
      }
    }
  }

  let indCounter = 0;
  let yAxis = [];
  let series = [];

  for (const eachUnit in unitMap) {
    if (Object.hasOwnProperty.call(unitMap, eachUnit)) {
      const { minValue, maxValue, label, data, formattorFunc } =
        unitMap[eachUnit];
      // create yAxis for each unit
      yAxis.push({
        title: {
          text: label,
        },
        labels: {
          formatter: function () {
            if (formattorFunc) {
              const { formattedValue, unit: newUnit } = formattorFunc(
                this.value
              );
              return `${formattedValue} ${newUnit ? newUnit : ""}`;
            }
            return `${this.value} ${eachUnit}`;
          },
        },
        max: maxValue,
        min: minValue,
        opposite: !!(indCounter % 2),
        showLastLabel: true,
        endOnTick: true,
      });
      // create series for each data
      for (const dataLabel in data) {
        if (Object.hasOwnProperty.call(unitMap, eachUnit)) {
          const currData = data[dataLabel];
          series.push({
            name: dataLabel,
            data: currData,
            tooltip: {
              valueSuffix: eachUnit,
            },
            yAxis: indCounter,
          });
        }
      }

      indCounter++;
    }
  }

  chartConfig.yAxis = yAxis;
  chartConfig.series = series;
  if (size(series) > 1) {
    chartConfig.legend = {
      enabled: true,
    };
  }
  console.log("file: catv.utils.js:134 ~ chartConfig:", chartConfig);

  return { chartConfig, emptyDataMessages };
};
