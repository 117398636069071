import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

import { FormChipSelect, FormFileField } from "components/common/FormFields";
import { uploadKml } from "../data/services";
import { coordsToLatLongMap } from "utils/map.utils";
import { parseErrorMessagesWithFields } from "../../utils/api.utils";
import { addNotification } from "redux/reducers/notification.reducer";

const IMPORT_OPTIONS = [
  { label: "KML", value: "kml" },
  { label: "Shapefile", value: "shapefile" },
];

const RegionUploadForm = ({ onSuccess, onClose }) => {
  /**
   * Parent
   *    AddElementContent
   */
  const dispatch = useDispatch();

  const { mutate: uploadKmlMutation, isLoading } = useMutation(uploadKml, {
    onError: (err) => {
      parseErrorMessagesWithFields(err);
    },
    onSuccess: (res) => {
      dispatch(
        addNotification({
          type: "success",
          title: "Upload File",
          text: "File uploaded successfully",
        })
      );

      onSuccess(coordsToLatLongMap(res.coordinates));
      onClose();
    },
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: { file: null, fileType: null },
  });

  const onFormSubmit = useCallback((data) => {
    const formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("file_type", data.fileType);

    uploadKmlMutation(formData);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Box />
        <Typography variant="h6" color="primary.dark">
          Upload File
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        dividers
        sx={{
          padding: 0,
        }}
      >
        <Box p={4}>
          <Box pt={1.5}>
            <FormChipSelect
              label="File Type"
              name="fileType"
              control={control}
              rules={{
                required: "File Type is required",
              }}
              required={true}
              options={IMPORT_OPTIONS}
              error={!!get(errors, "fileType")}
              helperText={get(errors, "fileType.message", "")}
            />
          </Box>
          <Box py={1.5}>
            <FormFileField
              label="Upload File"
              name="file"
              control={control}
              rules={{
                required: "File is required",
              }}
              required={true}
              error={!!get(errors, "file")}
              helperText={get(errors, "file.message", "")}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          onClick={handleSubmit(onFormSubmit)}
          loading={isLoading}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RegionUploadForm;
