import { format } from "date-fns";
import isDate from "lodash/isDate";

export const getDateValue = (formDate, showDateTime = false) => {
  let value = "";
  if (formDate) {
    const formatStr = showDateTime ? "dd/MM/yy HH:mm:ss" : "dd/MM/YYY";
    if (isDate(formDate)) {
      value = format(formDate, formatStr);
    } else {
      value = format(new Date(formDate), formatStr);
    }
  }
  return value;
};
