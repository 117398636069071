import React, { useCallback, useState, Fragment } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TableHeader from "../ElementDetailsTable/TableHeader";
import GisMapPopups from "../GisMapPopups";
import ImageGrid from "./ImageGrid";
import DocumentList from "./DocumentList";

import { setMapState } from "planning/data/planningGis.reducer";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box maxHeight="72vh" overflow="auto">
          {children}
        </Box>
      )}
    </div>
  );
};

const Attachments = () => {
  const [minimized, setMinimized] = useState(false);
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
  }, [dispatch]);

  const handlePopupMinimize = useCallback(() => {
    setMinimized((val) => !val);
  }, []);

  return (
    <GisMapPopups dragId="Attachments">
      <Box minWidth="650px" maxWidth="950px">
        {/* Table header */}
        <TableHeader
          title="Attachments"
          minimized={minimized}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
        {minimized ? null : (
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs"
            >
              <Tab label="Images" {...a11yProps(0)} />
              <Tab label="Documents" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <ImageGrid />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DocumentList />
            </TabPanel>
          </Box>
        )}
      </Box>
    </GisMapPopups>
  );
};

export default Attachments;
