import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { addNotification } from "redux/reducers/notification.reducer";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import { refreshElementAttrData } from "planning/data/catv/catv.services";

const useOnDemand = ({
  layerKey,
  elementId,
  refreshType,
  queryName,
  onApiSuccess,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(refreshElementAttrData);

  const handleOnDemandClick = useCallback(() => {
    mutate(
      {
        layerKey,
        elementId,
        refreshType,
      },
      {
        onSuccess: () => {
          if (queryName) {
            // invalidate query
            queryClient.invalidateQueries(queryName);
          }
          if (onApiSuccess) {
            onApiSuccess();
          }
        },
        onError: (err) => {
          const { fieldList, messageList } = parseErrorMessagesWithFields(err);
          for (let index = 0; index < fieldList.length; index++) {
            const field = fieldList[index];
            const errorMessage = messageList[index];
            dispatch(
              addNotification({
                type: "error",
                title: field,
                text: errorMessage,
              })
            );
          }
        },
      }
    );
  }, [onApiSuccess]);

  return {
    handleOnDemandClick,
    isOnDemandLoading: isLoading,
  };
};

export default useOnDemand;
