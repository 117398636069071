import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import { getRequiredFieldMessage } from "utils/constant";
import { postResetPassword } from "pages/Authentication/services";
import { parseErrorMessagesWithFields } from "utils/api.utils";
import { addNotification } from "redux/reducers/notification.reducer";

const UserResetPassword = ({ resetPassUserId, onClose }) => {
  /**
   * Parent:
   *    UserListPage
   */
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      new_password: "",
      confirm_password: "",
    },
  });

  const { mutate: resetPasswordMutation, isLoading } = useMutation(
    postResetPassword,
    {
      onSuccess: () => {
        dispatch(
          addNotification({
            type: "success",
            title: "Password reset successfully.",
          })
        );
        onClose();
      },
      onError: (err) => {
        const { fieldList, messageList } = parseErrorMessagesWithFields(err);
        for (let index = 0; index < fieldList.length; index++) {
          const field = fieldList[index];
          const errorMessage = messageList[index];
          dispatch(
            addNotification({
              type: "error",
              title: field,
              text: errorMessage,
            })
          );
        }
      },
    }
  );

  const onHandleSubmit = (resData) => {
    resetPasswordMutation({
      userId: resetPassUserId,
      data: { new_password: resData.confirm_password },
    });
  };

  return (
    <Box minWidth="500px">
      <Stack
        p={2}
        pb={1}
        direction="row"
        spacing={2}
        width="100%"
        alignItems="center"
        sx={{
          backgroundColor: "primary.main",
          color: "background.default",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      >
        <Typography color="white" flex={1} className="dtl-title" variant="h5">
          Reset Password
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box component="form" onSubmit={handleSubmit(onHandleSubmit)}>
        <Stack
          p={4}
          sx={{
            overflow: "auto",
          }}
          spacing={2}
        >
          <TextField
            className="full-width"
            required
            error={!!errors.new_password}
            label="New Password"
            type="password"
            {...register("new_password", {
              required: getRequiredFieldMessage("New Password"),
            })}
            helperText={errors.new_password?.message}
          />
          <TextField
            className="full-width"
            required
            error={!!errors.confirm_password}
            label="Confirm Password"
            type="password"
            {...register("confirm_password", {
              required: getRequiredFieldMessage("Confirm Password"),
              validate: (val) => {
                if (watch("new_password") != val) {
                  return "New password and confirm password do not match, Please type again";
                }
              },
            })}
            helperText={errors.confirm_password?.message}
          />

          <Box textAlign="right" mt={1.5}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              sx={{
                minWidth: "15em",
                py: 2,
              }}
              loading={isLoading}
              size="small"
              type="submit"
            >
              Submit
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default UserResetPassword;
