import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";

import EditIcon from "@mui/icons-material/Edit";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import CableIcon from "@mui/icons-material/Cable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import LanguageIcon from "@mui/icons-material/Language";
import LanIcon from "@mui/icons-material/Lan";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SettingsInputCompositeIcon from "@mui/icons-material/SettingsInputComposite";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import HistoryIcon from "@mui/icons-material/History";
import AttachmentIcon from "@mui/icons-material/Attachment";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PollIcon from "@mui/icons-material/Poll";
import GridViewIcon from "@mui/icons-material/GridView";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";

import FileUploadForm from "./FileUploadForm";
import ConfirmDialog from "components/common/ConfirmDialog";

import {
  checkIsCatvUser,
  checkUserPermission,
  getIsSuperAdminUser,
} from "redux/selectors/auth.selectors";
import { setMapState } from "planning/data/planningGis.reducer";
import { LayerKeyMappings, PLANNING_EVENT } from "planning/GisMap/utils";
import {
  onElementListConnectionEvent,
  onHistoryClick,
  onTableDetailsShowOnMapClick,
  showPossiblePatchingElements,
} from "planning/data/planning.actions";
import {
  editElementGeometry,
  showAssociatiationList,
  showAttachmentView,
  showElementPortDetails,
  showPossibleAddAssociatiation,
} from "planning/data/event.actions";
import {
  showGenericAttrsTable,
  showInterfaceAttrsTable,
  showTransportAttrsTable,
} from "planning/data/catv/catv.actions";

import { deleteLayer } from "planning/data/layer.services";
import {
  getSelectedLayerKeys,
  getSelectedRegionIds,
} from "planning/data/planningState.selectors";
import { getCurrentPage } from "redux/selectors/appState.selectors";
import { addNotification } from "redux/reducers/notification.reducer";
import {
  getPlanningTicketPage,
  getTicketWorkorderPage,
} from "utils/url.constants";
import { fetchLayerDataThunk } from "planning/data/actionBar.services";
import { useDownloadHomePassData } from "./tableActions.hook";
import { PAGE_SELCTIONS } from "redux/reducers/appState.reducer";

/**
 * Parent:
 *    ElementDetailsTable
 */
const TableActions = ({
  layerKey,
  elemData,
  onEditDataConverter,
  handleCloseDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const currentPage = useSelector(getCurrentPage);
  const isSuperUser = useSelector(getIsSuperAdminUser);
  const selectedLayerKeys = useSelector(getSelectedLayerKeys);
  const regionIdList = useSelector(getSelectedRegionIds);
  const hasLayerEditDetailsPermission = useSelector(
    checkUserPermission(`${layerKey}_edit`)
  );
  const hasLayerEditLocationPermission = useSelector(
    checkUserPermission(`${layerKey}_edit_location`)
  );
  const hasLayerDeletePermission = useSelector(
    checkUserPermission(`${layerKey}_delete`)
  );
  const hasFsaHpDownloadPermission = useSelector(
    checkUserPermission("fsa_hp_data_download")
  );

  const isCatvUser = useSelector(checkIsCatvUser());

  const { mutate: deleteMutation, isLoading: deleteLoading } = useMutation(
    () => deleteLayer({ layerKey, elementId: elemData.id }),
    {
      onSuccess: () => {
        handleCloseDetails();
        dispatch(
          addNotification({
            type: "success",
            title: "Element Delete",
            text: "Element deleted successfully",
          })
        );
        for (let l_ind = 0; l_ind < selectedLayerKeys.length; l_ind++) {
          const currLayerKey = selectedLayerKeys[l_ind];
          dispatch(
            fetchLayerDataThunk({ regionIdList, layerKey: currLayerKey })
          );
        }
      },
      onError: ({ response }) => {
        // close confirm dialog
        handleHidePopup();
        // show error notification
        let notiText;
        if (response.status === 400) {
          // handle bad data
          let errData = get(response, "data", {});
          for (const fieldKey in errData) {
            if (Object.hasOwnProperty.call(errData, fieldKey)) {
              const errList = errData[fieldKey];
              notiText = get(errList, "0", "");
              break;
            }
          }
        } else {
          notiText = "Something went wrong, Delete element failed !";
        }
        dispatch(
          addNotification({
            type: "error",
            title: "Element Delete",
            text: notiText,
          })
        );
      },
    }
  );

  const handleShowPopup = useCallback(() => setShowPopup(true), []);
  const handleHidePopup = useCallback(() => setShowPopup(false), []);

  const handleShowUploadPopup = useCallback(() => setShowUploadForm(true), []);
  const handleHideUploadPopup = useCallback(() => setShowUploadForm(false), []);

  const { handleDownloadHomePassData, downloadLoading } =
    useDownloadHomePassData("p_survey_area", elemData.coordinates);

  const isLoading = deleteLoading || downloadLoading;
  // connections | associations
  const extraControls = get(
    LayerKeyMappings,
    [layerKey, "elementTableExtraControls"],
    []
  );

  const baseActionsList = useMemo(() => {
    const baseActionsList = [];

    if (hasLayerEditDetailsPermission) {
      baseActionsList.push({
        name: "Edit",
        Icon: EditIcon,
        onClick: () => {
          let data = onEditDataConverter
            ? onEditDataConverter(elemData)
            : elemData;
          // PATCH
          if (layerKey === "region") {
            data.parentId = elemData?.parent;
            if (data.parentId) {
              data.restriction_ids = { layerKey: data.parentId };
            }
          }
          dispatch(
            setMapState({
              event: PLANNING_EVENT.editElementForm,
              layerKey,
              data,
            })
          );
        },
      });
    }

    if (
      hasLayerEditLocationPermission &&
      currentPage === PAGE_SELCTIONS.PLANNING_PAGE
    ) {
      // show edit location on planning page only
      baseActionsList.push({
        name: "Location",
        Icon: EditLocationAltIcon,
        onClick: () => {
          dispatch(editElementGeometry({ layerKey, elementData: elemData }));
        },
      });
    }

    if (currentPage === PAGE_SELCTIONS.PLANNING_PAGE) {
      baseActionsList.push({
        name: "Show on map",
        Icon: LanguageIcon,
        onClick: () => {
          dispatch(onTableDetailsShowOnMapClick(elemData, layerKey));
        },
      });
    }

    if (extraControls?.length) {
      for (let index = 0; index < extraControls.length; index++) {
        const currControl = extraControls[index];
        const { control, data } = currControl;

        if (control === "connections") {
          baseActionsList.push({
            name: "Connections",
            Icon: CableIcon,
            onClick: () =>
              dispatch(
                onElementListConnectionEvent({
                  layerKey,
                  elementId: elemData.id,
                  elementGeometry: elemData.coordinates,
                })
              ),
          });
        }
        //
        else if (control === "patching") {
          baseActionsList.push({
            name: "Patch",
            Icon: SettingsEthernetIcon,
            onClick: () =>
              dispatch(
                showPossiblePatchingElements({
                  layerKey,
                  elementId: elemData.id,
                  elementGeometry: elemData.coordinates,
                  canPatchWith: data.can_patch_with,
                  extraFilter: data?.extraFilter || undefined,
                })
              ),
          });
        }
        //
        else if (control === "workorders") {
          baseActionsList.push({
            name: "Show Workorders",
            Icon: VisibilityIcon,
            onClick: () => {
              if (
                elemData.ticket_type === "P" ||
                elemData.ticket_type === "C"
              ) {
                navigate(getPlanningTicketPage(elemData.id));
              } else {
                navigate(getTicketWorkorderPage(elemData.id));
              }
            },
          });
        }
        //
        else if (control === "add_associations") {
          baseActionsList.push({
            name: "Add Associated Elements",
            Icon: AddIcon,
            // data = [ layerKeys, ...]
            onClick: () => {
              dispatch(
                showPossibleAddAssociatiation({
                  layerKey,
                  elementData: elemData,
                  listOfLayers: data,
                })
              );
            },
          });
        }
        //
        else if (control === "association_list") {
          baseActionsList.push({
            name: "Show Associations",
            Icon: LanIcon,
            onClick: () => {
              dispatch(
                showAssociatiationList({
                  layerKey,
                  elementId: elemData.id,
                })
              );
            },
          });
        }
        //
        else if (control === "ports") {
          baseActionsList.push({
            name: "Show Port Details",
            Icon: SettingsInputCompositeIcon,
            onClick: () => {
              dispatch(
                showElementPortDetails({
                  layerKey,
                  elementId: elemData.id,
                })
              );
            },
          });
        }
        //
        else if (control === "attachments") {
          baseActionsList.push({
            name: "Attachments",
            Icon: AttachmentIcon,
            onClick: () => {
              dispatch(
                showAttachmentView({
                  layerKey,
                  elementId: elemData.id,
                })
              );
            },
          });
        }
        //
        else if (control === "update_geometry") {
          baseActionsList.push({
            name: "Update Geometry",
            Icon: UploadFileIcon,
            onClick: handleShowUploadPopup,
          });
        }
        // Any polygon layer can hit this btn to get home pass excel file
        else if (control === "dwnld_survey_area_excel") {
          if (hasFsaHpDownloadPermission) {
            baseActionsList.push({
              name: "Download Home Pass Data",
              Icon: PollIcon,
              onClick: handleDownloadHomePassData,
            });
          }
        }
        //
        else if (control === "show_generic_attrs_table") {
          if (isCatvUser) {
            baseActionsList.push({
              name: "Generic Attribute",
              Icon: SettingsInputSvideoIcon,
              onClick: () => {
                dispatch(
                  showGenericAttrsTable({
                    layerKey,
                    elementData: { ...elemData },
                    elementId: elemData.id,
                  })
                );
              },
            });
          }
        }
        //
        else if (control === "show_interface_attrs_table") {
          if (isCatvUser) {
            baseActionsList.push({
              name: "Interface Attribute",
              Icon: GridViewIcon,
              onClick: () => {
                dispatch(
                  showInterfaceAttrsTable({
                    layerKey,
                    elementData: { ...elemData },
                    elementId: elemData.id,
                  })
                );
              },
            });
          }
        }
        //
        else if (control === "show_transport_attrs_table") {
          if (isCatvUser) {
            baseActionsList.push({
              name: "Transport Attribute",
              Icon: DynamicFeedIcon,
              onClick: () => {
                dispatch(
                  showTransportAttrsTable({
                    layerKey,
                    elementData: { ...elemData },
                    elementId: elemData.id,
                  })
                );
              },
            });
          }
        }
      }
    }

    baseActionsList.push({
      name: "History",
      Icon: HistoryIcon,
      onClick: () => {
        dispatch(onHistoryClick(layerKey, elemData.id));
      },
    });

    if (isSuperUser || hasLayerDeletePermission) {
      baseActionsList.push({
        name: "Delete",
        Icon: DeleteOutlineIcon,
        onClick: handleShowPopup,
        color: "error",
      });
    }

    return baseActionsList;
  }, [
    hasLayerEditDetailsPermission,
    hasLayerEditLocationPermission,
    hasLayerDeletePermission,
    dispatch,
    layerKey,
    elemData,
    onEditDataConverter,
    extraControls,
    isSuperUser,
  ]);

  return (
    <Stack
      sx={{ boxShadow: "0px 5px 7px -3px rgba(122,122,122,0.51)" }}
      p={2}
      direction="row"
      spacing={2}
    >
      {baseActionsList.map(({ name, color = "secondary", Icon, onClick }) => {
        return (
          <Tooltip title={name} key={name}>
            <IconButton
              aria-label={name}
              color={color}
              sx={{
                border: "1px solid",
                borderRadius: 1,
              }}
              onClick={onClick}
            >
              <Icon />
            </IconButton>
          </Tooltip>
        );
      })}
      <ConfirmDialog
        show={showPopup}
        onClose={handleHidePopup}
        onConfirm={deleteMutation}
        isLoading={isLoading}
        text={
          <>
            Are you sure you want to delete <b>{get(elemData, "name", "")}</b> ?
          </>
        }
      />
      <Dialog
        onClose={handleHideUploadPopup}
        open={showUploadForm}
        scroll="paper" // used to scroll content into dialog
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {showUploadForm ? (
          <FileUploadForm
            onClose={handleHideUploadPopup}
            layerKey={layerKey}
            elementData={elemData}
          />
        ) : null}
      </Dialog>
    </Stack>
  );
};

export default TableActions;
