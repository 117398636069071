import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";

import TableHeader from "../ElementDetailsTable/TableHeader";
import GisMapPopups from "../GisMapPopups";
import HistoryTable from "./HistoryTable";
import { GisElementTableLoader } from "../GisMapPopups/GisMapPopupLoader";

import { getPlanningMapState } from "planning/data/planningGis.selectors";
import {
  removeSpecialLayerDataById,
  setMapState,
  setPopupMinimize,
} from "planning/data/planningGis.reducer";
import { fetchHistory } from "planning/data/layer.services";
import { size } from "lodash";

const History = () => {
  const dispatch = useDispatch();

  const { data: mapStateData, isPopupMinimize } =
    useSelector(getPlanningMapState);
  const { elementId, layerKey } = mapStateData;

  const {
    isLoading,
    data: historyList,
    isError,
  } = useQuery(["historyList", layerKey, elementId], fetchHistory);

  const handleCloseDetails = useCallback(() => {
    dispatch(setMapState({}));
    dispatch(removeSpecialLayerDataById("history"));
  }, [dispatch]);

  const handlePopupMinimize = useCallback(() => {
    dispatch(setPopupMinimize(!isPopupMinimize));
  }, [isPopupMinimize]);

  if (isLoading) {
    return <GisElementTableLoader />;
  }

  return (
    <GisMapPopups dragId="HistoryView">
      <Box minWidth="350px" maxWidth="85vw">
        <TableHeader
          title="History"
          minimized={isPopupMinimize}
          handlePopupMinimize={handlePopupMinimize}
          handleCloseDetails={handleCloseDetails}
        />
        {isError ? (
          <Box p={2}>
            <Typography variant="h5">
              Error occured while fetching data
            </Typography>
          </Box>
        ) : isPopupMinimize ? null : (
          <Box maxHeight="72vh" overflow="auto" p={2}>
            {size(historyList) ? (
              historyList.map((history) => {
                return <HistoryTable key={history.id} history={history} />;
              })
            ) : (
              <Typography variant="h5" textAlign="center">
                No history
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </GisMapPopups>
  );
};

export default History;
