import React, { useCallback, useEffect, useState } from "react";

import countBy from "lodash/countBy";
import get from "lodash/get";
import size from "lodash/size";
import some from "lodash/some";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import PieChart from "components/graphs/PieChart";
import PieChartLegend from "./PieChartLegend";

function PieChartWidget({ alertListData, onPieClick, clearFilters }) {
  const [severityPieChartData, setSeverityPieChartData] = useState([]);

  useEffect(() => {
    if (!size(alertListData)) setSeverityPieChartData([]);

    const severityCountMap = countBy(alertListData, "severity_display");

    let newSeverityPieChartData = [
      {
        field_key: "severity_display",
        label: "Information",
        value: get(severityCountMap, "Information", 0),
        value_key: "Information",
        color: "#799cf8",
      },
      {
        field_key: "severity_display",
        label: "Warning",
        value: get(severityCountMap, "Warning", 0),
        value_key: "Warning",
        color: "#ffc56d",
      },
      {
        field_key: "severity_display",
        label: "High",
        value: get(severityCountMap, "High", 0),
        value_key: "High",
        color: "#fe8c3e",
      },
      {
        field_key: "severity_display",
        label: "Critical",
        value: get(severityCountMap, "Critical", 0),
        value_key: "Critical",
        color: "#f73837",
      },
      {
        field_key: "severity_display",
        label: "Disaster",
        value: get(severityCountMap, "Disaster", 0),
        value_key: "Disaster",
        color: "#7c1c1c",
      },
    ];
    setSeverityPieChartData(newSeverityPieChartData);
  }, [alertListData]);

  const handlePieClick = useCallback(
    (hightlightData) => {
      if (onPieClick) {
        const { value_key } = hightlightData;
        onPieClick(hightlightData);
        // get value_key from data and add highlight flag to it
        setSeverityPieChartData((dataList) => {
          let newList = [...dataList];
          for (let dInd = 0; dInd < newList.length; dInd++) {
            const currD = newList[dInd];
            currD.highlight = currD.value_key === value_key;
          }
          return newList;
        });
      }
    },
    [onPieClick]
  );

  const handleClearFilters = useCallback(() => {
    if (clearFilters) {
      clearFilters("severity_display");
      // set all highlights to false
      setSeverityPieChartData((dataList) => {
        let newList = [...dataList];
        for (let dInd = 0; dInd < newList.length; dInd++) {
          const currD = newList[dInd];
          currD.highlight = false;
        }
        return newList;
      });
    }
  }, [clearFilters]);

  const showFilterBtn = some(severityPieChartData, "highlight");

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between">
        <Typography mb={2} variant="h5" sx={{ color: "#e0e0e3" }}>
          Alerts based on severity
        </Typography>
        {showFilterBtn ? (
          <IconButton
            onClick={handleClearFilters}
            size="large"
            color="secondary"
          >
            <FilterAltOffIcon />
          </IconButton>
        ) : null}
      </Stack>

      <Divider flexItem sx={{ borderColor: "#9d9d9f" }} />

      <Stack direction="row" spacing={2} pt={4} height="100%">
        <PieChartLegend
          pieChartData={severityPieChartData}
          onPieLegendClick={handlePieClick}
        />
        <PieChart
          pie={false}
          ringWidth={20}
          data={severityPieChartData}
          onPieClick={handlePieClick}
        />
      </Stack>
    </Stack>
  );
}

export default PieChartWidget;
