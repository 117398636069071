import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import isNumber from "lodash/isNumber";
import size from "lodash/size";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import StopCircleIcon from "@mui/icons-material/StopCircle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import {
  getIsMapFilterActive,
  getPlanningMapFilters,
  getPlanningMapStateEvent,
  getTicketMapHighlighted,
  isSpecialLayerDataExist,
} from "planning/data/planningGis.selectors";
import {
  resetFilters,
  resetTicketMapHighlight,
  setFilter,
  setSpecialLayerData,
} from "planning/data/planningGis.reducer";
import { LAYER_STATUS_OPTIONS } from "planning/GisMap/layers/common/configuration";

import { PLANNING_EVENT } from "planning/GisMap/utils";
import { selectElementsOnMapClick } from "planning/data/event.actions";
import {
  getSelectedRegionIds,
  getShowSidebar,
} from "planning/data/planningState.selectors";
import { setShowSideBar } from "planning/data/planningState.reducer";
import { openLCForm } from "planning/data/planning.actions";
import { checkUserPermission } from "redux/selectors/auth.selectors";
import { addNotification } from "redux/reducers/notification.reducer";

import "planning/styles/map-actionbar.scss";

/**
 * Parent:
 *    PlanningPage
 */
const MapActionBar = () => {
  const dispatch = useDispatch();

  const mapStateEvent = useSelector(getPlanningMapStateEvent);
  const ticketMapHighlight = useSelector(getTicketMapHighlighted);
  const specialLayerDataExist = useSelector(isSpecialLayerDataExist);
  const showSidebar = useSelector(getShowSidebar);
  const canTraceBackView = useSelector(checkUserPermission("trace_back_view"));
  const regionIdList = useSelector(getSelectedRegionIds);

  const handleResetSpecialLayerData = useCallback((e) => {
    e.preventDefault();
    dispatch(setSpecialLayerData());
  }, []);

  const handleResetTicketHighlight = useCallback((e) => {
    e.preventDefault();
    dispatch(resetTicketMapHighlight());
  }, []);

  const handleLCFormClick = useCallback(
    (e) => {
      e.preventDefault();
      if (!size(regionIdList)) {
        // show error notification to select regions first
        dispatch(
          addNotification({
            type: "error",
            title: "Select Region first",
            text: "Please select region to narrow down your search of elements.",
          })
        );
        return;
      } else {
        dispatch(openLCForm());
      }
    },
    [regionIdList]
  );

  return (
    <Box className={`map-actionbar ${showSidebar ? "sidebar-open" : ""}`}>
      <Tooltip
        title={showSidebar ? "Hide sidebar" : "Show sidebar"}
        placement="right"
      >
        <Box
          className={`icon-button`}
          onClick={() => dispatch(setShowSideBar(!showSidebar))}
          mb={1}
        >
          {showSidebar ? <MenuOpenIcon /> : <MenuIcon />}
        </Box>
      </Tooltip>
      <Tooltip title="select elements on map" placement="right">
        <Box
          className={`${
            mapStateEvent === PLANNING_EVENT.selectElementsOnMapClick
              ? "active"
              : ""
          } icon-button`}
          onClick={() => dispatch(selectElementsOnMapClick)}
          mb={1}
        >
          <TouchAppIcon />
        </Box>
      </Tooltip>

      {specialLayerDataExist ? (
        <Tooltip title="Stop Highlight" placement="right">
          <Box
            className="icon-button"
            onClick={handleResetSpecialLayerData}
            mb={1}
          >
            <StopCircleIcon />
          </Box>
        </Tooltip>
      ) : null}
      {isNumber(ticketMapHighlight) ? (
        <Tooltip title="Stop Highlight" mb={1} placement="right">
          <Box className="icon-button" onClick={handleResetTicketHighlight}>
            <StopCircleIcon />
          </Box>
        </Tooltip>
      ) : null}
      <LayerStatusFilter />
      {canTraceBackView ? (
        <Tooltip title="Open Logical Connection" placement="right">
          <Box className="icon-button" onClick={handleLCFormClick} mb={1}>
            <AccountTreeIcon />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
};

const LayerStatusFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const isFilterActive = useSelector(getIsMapFilterActive);

  const handleShow = useCallback((e) => {
    setAnchorEl(e.target);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FilterBlock onClose={handleClose} />
      </Popover>
      <Tooltip title="Filter" mb={1} placement="right">
        <Box
          className={`${isFilterActive ? "active" : ""} icon-button`}
          onClick={handleShow}
        >
          <FilterAltIcon className="no-click" />
        </Box>
      </Tooltip>
    </>
  );
};

const FilterBlock = ({ onClose }) => {
  const dispatch = useDispatch();
  const appliedFilters = useSelector(getPlanningMapFilters);

  const [filters, setFilters] = useState(appliedFilters);

  const handleStatusChange = useCallback((event) => {
    const filterVal = event.target.name;
    setFilters((currFilters) => {
      const newStatusFilter = [...currFilters.status];
      const filtInd = newStatusFilter.indexOf(filterVal);
      if (filtInd > -1) {
        newStatusFilter.splice(filtInd, 1); // 2nd parameter means remove one item only
      } else {
        newStatusFilter.push(filterVal);
      }

      return { ...currFilters, status: newStatusFilter };
    });
  }, []);

  const handleReset = useCallback((event) => {
    event.preventDefault();
    dispatch(resetFilters());
    onClose();
  }, []);

  const handleApply = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(setFilter(filters));
      onClose();
    },
    [filters]
  );

  return (
    <Box p={1}>
      <Box p={1}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Status</FormLabel>
          <FormGroup>
            {LAYER_STATUS_OPTIONS.map((ops) => {
              const isSelected = filters.status.indexOf(ops.value) !== -1;

              return (
                <FormControlLabel
                  key={ops.value}
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={handleStatusChange}
                      name={ops.value}
                    />
                  }
                  label={ops.label}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
      <Stack flexDirection="row" justifyContent="space-between">
        <Button variant="outlined" color="error" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleApply}>
          Apply
        </Button>
      </Stack>
    </Box>
  );
};

export default memo(MapActionBar);
