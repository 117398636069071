import React from "react";

import get from "lodash/get";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";
import { LayerKeyNameMapping } from "planning/GisMap/utils";

const getLayerNameFromLayerKey = (layerKey) => LayerKeyNameMapping[layerKey];

const TABLE_CONFIG = [
  {
    label: "Source Type",
    key: "A Type",
    formatter: getLayerNameFromLayerKey,
  },
  {
    label: "Source Config",
    key: "A Config",
  },
  {
    label: "Source Name",
    key: "A Name",
  },
  {
    label: "Source Port",
    key: "A Port",
  },
  {
    label: "Destination Type",
    key: "B Type",
    formatter: getLayerNameFromLayerKey,
  },
  {
    label: "Destination Config",
    key: "B Config",
  },
  {
    label: "Destination Name",
    key: "B Name",
  },
  {
    label: "Destination Port",
    key: "B Port",
  },
  {
    label: "Through At",
    key: "Through At",
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };
});

const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    color: theme.palette.primary.contrastText,
    textAlign: "center",
  };
});

const TraceTable = ({ traceBackTableEntries }) => {
  /**
   * Parent:
   *    TracebackDetails
   */

  return traceBackTableEntries.map((tracebackEntry, index) => {
    return (
      <TableContainer key={index} component={Paper} sx={{ marginBottom: 1 }}>
        <Table>
          <StyledTableHead>
            <TableRow>
              {TABLE_CONFIG.map((conf) => {
                return (
                  <StyledTableCell key={conf.label}>
                    {conf.label}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {tracebackEntry.map((entry, eInd) => {
              return (
                <StyledTableRow
                  key={eInd}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {TABLE_CONFIG.map((conf) => {
                    let value = get(entry, conf.key, "");
                    if (conf.formatter) {
                      value = conf.formatter(value);
                    }
                    return (
                      <TableCell
                        key={conf.key}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  });
};

export default TraceTable;
