import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
  FILTER_TYPES,
} from "../common/configuration";
import { FIELD_TYPES } from "components/common/DynamicForm";

import { default as CustomerActiveViewIcon } from "assets/markers/customer_active.svg";
import { default as CustomerInActiveViewIcon } from "assets/markers/customer_inactive.svg";
import { default as CustomerActiveEditIcon } from "assets/markers/green_marker.svg";
import { default as CustomerInActiveEditIcon } from "assets/markers/red_marker.svg";

import get from "lodash/get";

export const LAYER_KEY = "p_customer";
export const PRE_UID = "CU";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;

export const getViewOptions = (props = {}) => {
  const { plan_status } = props;
  if (plan_status === "IA") {
    return {
      icon: CustomerInActiveViewIcon,
      pin: CustomerInActiveEditIcon,
    };
  } else {
    return {
      icon: CustomerActiveViewIcon,
      pin: CustomerActiveEditIcon,
    };
  }
};

export const INITIAL_ELEMENT_DATA = {
  name: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "RFS",
  coordinates: {},
};

export const PLAN_STATUS_OPTIONS = [
  { value: "AC", label: "ACTIVE" },
  { value: "IA", label: "IN ACTIVE" },
];

export const CUST_CAT_OPTIONS = [
  { value: "RES", label: "RESIDENTIAL" },
  { value: "COM", label: "COMMERCIAL" },
  { value: "ENT", label: "ENTERPRISE" },
  { value: "DMO", label: "DEMO" },
];

export const ONU_MODEL_OPTIONS = [
  { value: "C30281", label: "C30-281" },
  { value: "C30320", label: "C30-320" },
  { value: "C30420", label: "C30-420" },
  { value: "C304450", label: "C30-4450" },
  { value: "C30695", label: "C30-695" },
  { value: "C30877", label: "C30-877" },
  { value: "C30958", label: "C30-958" },
  { value: "C30980", label: "C30-980" },
  { value: "C30997", label: "C30-997" },
  { value: "C40210", label: "C40-210" },
  { value: "ZTEF601", label: "ZTE-F601" },
  { value: "ZTEF602W", label: "ZTE-F602W" },
  { value: "ZTEF612W", label: "ZTE-F612W" },
  { value: "ZTEF660", label: "ZTE-F660" },
  { value: "ZTEF670L", label: "ZTE-F670L" },
  { value: "Earth1010", label: "Earth-1010" },
  { value: "OP2200H", label: "OP2200H" },
];

export const getElementFormTemplate = () => {
  return {
    title: "Customer Form",
    sections: [
      {
        title: "",
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_key: "mobile_no",
            label: "Mobile No",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "address",
            label: "Address",
            field_type: FIELD_TYPES.TextArea,
          },
          {
            field_key: "zipcode",
            label: "Zipcode",
            field_type: FIELD_TYPES.Input,
          },
        ],
      },
      {
        title: "Plan Details",
        fieldConfigs: [
          {
            field_key: "plan_name",
            label: "Plan Name",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "plan_status",
            label: "Plan Status",
            field_type: FIELD_TYPES.Select,
            options: PLAN_STATUS_OPTIONS,
          },
          {
            field_key: "cust_nbr",
            label: "Customer Number",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "user_id",
            label: "User Id",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "cust_category",
            label: "Customer Category",
            field_type: FIELD_TYPES.Select,
            options: CUST_CAT_OPTIONS,
          },
          {
            field_key: "renewal_date",
            label: "Renewal Date",
            field_type: FIELD_TYPES.Date,
          },
        ],
      },
      {
        title: "CPE Details",
        fieldConfigs: [
          {
            field_key: "onu_model",
            label: "ONU Model",
            field_type: FIELD_TYPES.Select,
            options: ONU_MODEL_OPTIONS,
          },
          {
            field_key: "mac_address",
            label: "Mac Address",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "serial_no",
            label: "Serial No",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "onu_tx",
            label: "ONU Tx ( in dBm	)",
            field_type: FIELD_TYPES.Input,
            validationProps: {
              pattern: {
                value: /^-?\d*\.?\d*$/,
                message:
                  "Invalid input. we accept numbers like 1, 1.55, -2.66 ",
              },
            },
          },
          {
            field_key: "onu_rx",
            label: "ONU Rx ( in dBm	)",
            field_type: FIELD_TYPES.Input,
            validationProps: {
              pattern: {
                value: /^-?\d*\.?\d*$/,
                message:
                  "Invalid input. we accept numbers like 1, 1.55, -2.66 ",
              },
            },
          },
        ],
      },
      {
        title: "Network Details (NOC)",
        fieldConfigs: [
          {
            field_key: "olt_name",
            label: "OLT Name",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "olt_model",
            label: "OLT Model",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "olt_card",
            label: "OLT Card",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "olt_ip",
            label: "OLT IP",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "olt_port",
            label: "OLT Port",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "port_tx",
            label: "Port Tx",
            field_type: FIELD_TYPES.NumericInput,
          },
        ],
      },
    ],
  };
};

export const getElementTableFields = () => [
  ...ELEMENT_TABLE_ABSTRACT_FIELDS,
  { label: "Mobile No", field: "mobile_no", type: "simple" },
  { label: "Address", field: "address", type: "simple" },
  { label: "Zipcode", field: "zipcode", type: "simple" },
  { label: "Connected", field: "is_connected", type: "boolean" },
  { label: "Plan Name", field: "plan_name", type: "simple" },
  { label: "Plan Status", field: "plan_status_display", type: "simple" },
  { label: "Customer Number", field: "cust_nbr", type: "simple" },
  { label: "User Id", field: "user_id", type: "simple" },
  {
    label: "Customer Category",
    field: "cust_category_display",
    type: "simple",
  },
  {
    label: "Renewal Date",
    field: "renewal_date",
    type: "date",
  },
  { label: "ONU Model", field: "onu_model_display", type: "simple" },
  { label: "Mac Address", field: "mac_address", type: "simple" },
  {
    field: "serial_no",
    label: "Serial No",
    type: "simple",
  },
  {
    field: "onu_tx",
    label: "ONU Tx ( in dBm	)",
    type: "simple",
  },
  {
    field: "onu_rx",
    label: "ONU Rx ( in dBm	)",
    type: "simple",
  },
  {
    field: "olt_name",
    label: "OLT Name",
    type: "simple",
  },
  {
    field: "olt_model",
    label: "OLT Model",
    type: "simple",
  },
  {
    field: "olt_card",
    label: "OLT Card",
    type: "simple",
  },
  {
    field: "olt_ip",
    label: "OLT IP",
    type: "simple",
  },
  {
    field: "olt_port",
    label: "OLT Port",
    type: "simple",
  },
  {
    field: "port_tx",
    label: "Port Tx",
    type: "simple",
  },
];

export const FILTER_FORM_CONFIG = [
  {
    field: "address",
    label: "Address",
    field_type: FIELD_TYPES.Input,
    filter_type: FILTER_TYPES.contains,
  },
  {
    field: "name",
    label: "Name",
    field_type: FIELD_TYPES.Input,
    filter_type: FILTER_TYPES.contains,
  },
  {
    field: "is_connected",
    label: "Connected",
    field_type: FIELD_TYPES.CheckBox,
    filter_type: FILTER_TYPES.equals,
  },
  {
    field: "user_id",
    label: "User Id",
    field_type: FIELD_TYPES.Input,
    filter_type: FILTER_TYPES.equals,
  },
  {
    field: "cust_nbr",
    label: "Customer Number",
    field_type: FIELD_TYPES.Input,
    filter_type: FILTER_TYPES.equals,
  },
  {
    field: "cust_category",
    label: "Customer Category",
    field_type: FIELD_TYPES.Select,
    filter_type: FILTER_TYPES.equals,
    choices: CUST_CAT_OPTIONS,
  },
  {
    field: "onu_model",
    label: "ONU Model",
    field_type: FIELD_TYPES.Select,
    filter_type: FILTER_TYPES.equals,
    choices: ONU_MODEL_OPTIONS,
  },
  {
    field: "onu_tx",
    label: "ONU Tx ( in dBm	)",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.Input,
    hasOperator: true,
    pattern: {
      value: /^-?\d*\.?\d*$/,
      message: "Invalid input. we accept numbers like 1, 1.55, -2.66 ",
    },
  },
  {
    field: "onu_rx",
    label: "ONU Rx ( in dBm	)",
    filter_type: FILTER_TYPES.numeric,
    field_type: FIELD_TYPES.Input,
    hasOperator: true,
    pattern: {
      value: /^-?\d*\.?\d*$/,
      message: "Invalid input. we accept numbers like 1, 1.55, -2.66 ",
    },
  },
  {
    field: "olt_model",
    label: "OLT Model",
    filter_type: FILTER_TYPES.contains,
    field_type: FIELD_TYPES.Input,
  },
  {
    field: "olt_card",
    label: "OLT Card",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Input,
  },
  {
    field: "olt_ip",
    label: "OLT IP",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Input,
  },
  {
    field: "olt_port",
    label: "OLT Port",
    filter_type: FILTER_TYPES.equals,
    field_type: FIELD_TYPES.Input,
  },
];

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "patching",
    data: {
      can_patch_with: ["p_onu"],
      extraFilter: function (onu_element) {
        // ONU_TYPE_OPTIONS -> "wifi"
        return onu_element.onu_type === "W";
      },
    },
  },
  {
    control: "connections",
  },
  {
    control: "ports",
  },
  {
    control: "attachments",
  },
];

// search related params
export const LAYER_SEARCH_SHOW_EXTRA_FIELDS = ["address"];
export const LAYER_SEARCH_BY_FIELDS = ["address"];

// return text for mapLabel
export const getMarkerLabel = (element) => {
  return get(element, "name");
};
