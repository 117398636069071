import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";
import size from "lodash/size";
import trim from "lodash/trim";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import BackupIcon from "@mui/icons-material/Backup";
import CloseIcon from "@mui/icons-material/Close";

import { FormFileField } from "components/common/FormFields";

import { addNotification } from "redux/reducers/notification.reducer";
import { NOTIFICATION_TYPE } from "components/common/Notification/Notification";
import { uploadBulkAlertActions } from "gis_alert/data/alert.actions";

const AlertBulkActionUpload = () => {
  /**
   * show upload button above alert list
   * on upload button show file upload form and show error message list if any after upload response
   *
   * Parent:
   *    AlertList
   */
  const dispatch = useDispatch();

  const [errorList, setErrorList] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [messageType, setMessageType] = useState(null);

  const [showForm, setShowForm] = useState(false);

  // upload file data via mutation
  const { mutate: uploadBulkAlertActionMutation, isLoading } = useMutation(
    uploadBulkAlertActions,
    {
      onError: (err) => {
        const statusCode = get(err, "response.status");
        if (statusCode === 403) {
          dispatch(
            addNotification({
              type: "error",
              title: "Alert Bulk action",
              text: "Permission required to upload bulk actions.",
            })
          );
        } else {
          dispatch(
            addNotification({
              type: "error",
              title: "Alert Bulk action",
              text: "Invalid file data",
            })
          );
        }
      },
      onSuccess: (res) => {
        const success_count = get(res, "success_count", 0);
        const error_list = get(res, "error_list", []);
        // check success and error count, update msg and error list accordingly.
        if (error_list?.length) {
          let successMsg = "";
          if (success_count) {
            successMsg = `${success_count} bulk action(s) are completed.`;
          }

          if (error_list?.length) {
            successMsg =
              successMsg + ` ${error_list?.length} bulk action(s) are failed`;
          }

          setSuccessMsg(trim(successMsg));
          setMessageType("warning.main");
          setErrorList(error_list);
        }
        //
        else {
          let successMsg = `${success_count} bulk action(s) are completed.`;

          dispatch(
            addNotification({
              type: NOTIFICATION_TYPE.SUCCESS,
              title: "Alert Bulk action",
              text: trim(successMsg),
            })
          );

          handleCloseForm();
        }
      },
    }
  );

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: { file: null },
  });

  const handleShowForm = useCallback(() => setShowForm(true), []);

  const handleCloseForm = useCallback(() => setShowForm(false), []);

  const onFormSubmit = useCallback((data) => {
    setErrorList([]);
    setSuccessMsg("");
    setMessageType(null);

    const formData = new FormData();
    formData.append("file", data.file, data.file.name);

    uploadBulkAlertActionMutation(formData);
  }, []);

  return (
    <Box>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<BackupIcon />}
        onClick={handleShowForm}
        sx={{ ml: 3 }}
      >
        Upload Bulk Actions
      </Button>
      <Dialog
        onClose={handleCloseForm}
        open={showForm}
        scroll="paper" // used to scroll content into dialog
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {showForm ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px 24px",
              }}
            >
              <Box />
              <Typography variant="h6" color="primary.dark">
                Alert Bulk Action Upload
              </Typography>
              <IconButton aria-label="close" onClick={handleCloseForm}>
                <CloseIcon />
              </IconButton>
            </Box>

            <DialogContent
              dividers
              sx={{
                padding: 0,
              }}
            >
              <Box p={4}>
                <Stack direction="row">
                  <Box py={1.5} flex={1} display="flex" justifyContent="center">
                    <FormFileField
                      label="Upload XLSX file"
                      name="file"
                      control={control}
                      rules={{
                        required: "File is required",
                      }}
                      required={true}
                      error={!!get(errors, "file")}
                      helperText={get(errors, "file.message", "")}
                    />
                  </Box>
                </Stack>
              </Box>
              {!!size(errorList) ? (
                <Box>
                  <Typography
                    variant="h6"
                    color="primary.dark"
                    textAlign="center"
                    py={1}
                  >
                    Errors
                  </Typography>
                  <Divider />
                  {successMsg ? (
                    <Typography
                      variant="body2"
                      color={messageType}
                      textAlign="center"
                      py={2}
                    >
                      {successMsg}
                    </Typography>
                  ) : null}
                  <TableContainer
                    component={Paper}
                    sx={{
                      width: "88%",
                      margin: "0 auto",
                      marginBottom: "1em",
                      // marginTop: "1em",
                    }}
                  >
                    <Table
                      stickyHeader
                      size="small"
                      aria-label="sticky dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "90px" }}>Index</TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            Error
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {errorList.map((error, index) => {
                          let errMsg = error.message;

                          if (errMsg && typeof errMsg === "object") {
                            errMsg = JSON.stringify(errMsg);
                          }

                          return (
                            <TableRow key={index}>
                              <TableCell sx={{ width: "90px" }}>
                                {error.index + 1}
                              </TableCell>
                              <TableCell>{errMsg}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseForm}
                variant="outlined"
                color="error"
              >
                Cancel
              </Button>
              <LoadingButton
                variant="outlined"
                onClick={handleSubmit(onFormSubmit)}
                loading={isLoading}
              >
                Upload
              </LoadingButton>
            </DialogActions>
          </Box>
        ) : null}
      </Dialog>
    </Box>
  );
};

export default AlertBulkActionUpload;
