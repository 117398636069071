import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import has from "lodash/has";
import size from "lodash/size";

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTracebackLayerDataThunk,
  generateTraceBackThunk,
} from "./traceback.services";
import { convertLayerServerData } from "planning/GisMap/utils";
import { defaultLayerNetworkState } from "../planningGis.reducer";
import { convertTraceBackServerData } from "./traceBack.utils";

const initialState = {
  // logical connection form, set all objects which will direct use in select.
  tracebackForm: {
    // planning.constants.js -> LC_TRACE_TYPE
    // D | U | B
    traceType: null,
    from_layer: null,
    from_element: null,
    from_port: null,
    to_layer: null,
    to_element: null,
    to_port: null,
  },
  // shape : { layer-key: { ...defaultLayerNetworkState } }
  layerNetworkState: {},
  // shape : { layer-key: [ {...Gis data, ...}, ...] }
  layerData: {},
  // trace data after submit
  traceData: {
    isLoading: false,
    isFetched: false,
    error: null,
    table_entries: [],
    tree: [],
  },
  showElementList: false,
  showOnMap: false,
};

const tracebackSlice = createSlice({
  name: "traceback",
  initialState,
  reducers: {
    setTracebackForm: (state, { payload }) => {
      // can be partial update
      state.tracebackForm = {
        ...state.tracebackForm,
        ...payload,
      };
    },
    setShowElementList: (state, { payload }) => {
      state.showElementList = payload;
    },
    setShowOnMap: (state, { payload }) => {
      state.showOnMap = payload;
    },
    resetTracebackData: (state) => {
      state.traceData = {
        isLoading: false,
        isFetched: false,
        error: null,
        table_entries: [],
        tree: [],
      };
      state.showElementList = false;
      state.showOnMap = false;
    },
    resetTraceReducer: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    // start loading
    [fetchTracebackLayerDataThunk.pending]: (state, action) => {
      const layerKey = get(action, "meta.arg.layerKey", "");
      if (has(state, ["layerNetworkState", layerKey])) {
        state.layerNetworkState[layerKey].isLoading = true;
        state.layerNetworkState[layerKey].isSelected = true;
      } else {
        // initialise new layer
        state.layerNetworkState[layerKey] = {
          ...defaultLayerNetworkState,
          isLoading: true,
          isSelected: true,
        };
        state.layerData[layerKey] = [];
      }
    },
    // fetch success
    [fetchTracebackLayerDataThunk.fulfilled]: (state, action) => {
      const layerKey = get(action, "meta.arg.layerKey", "");
      state.layerNetworkState[layerKey].isLoading = false;
      state.layerNetworkState[layerKey].isFetched = true;
      state.layerNetworkState[layerKey].count = size(action.payload);
      // convert payload coordinates into google coordinates data
      const convertedLayerGisData = cloneDeep(
        convertLayerServerData(layerKey, action.payload)
      );
      state.layerData[layerKey] = convertedLayerGisData;
    },
    // handle error
    [fetchTracebackLayerDataThunk.rejected]: (state, action) => {
      const layerKey = get(action, "meta.arg.layerKey", "");
      state.layerNetworkState[layerKey].isError = true;
    },
    [generateTraceBackThunk.pending]: (state) => {
      state.traceData.isLoading = true;
      state.traceData.isFetched = false;
      state.traceData.error = null;
      state.traceData.table_entries = [];
      state.traceData.tree = [];
    },
    [generateTraceBackThunk.fulfilled]: (state, { payload }) => {
      // payload: {table_entries, tree}
      state.traceData.isLoading = false;
      state.traceData.isFetched = true;
      state.traceData.error = null;
      state.traceData.table_entries = payload.table_entries;
      state.traceData.tree = convertTraceBackServerData(payload.tree);
    },
    [generateTraceBackThunk.rejected]: (state, action) => {
      const { status, data } = action.payload || {};
      state.traceData.isLoading = false;
      state.traceData.isFetched = false;
      state.traceData.table_entries = [];
      state.traceData.tree = [];
      // set custom error message as per Api status
      if (status === 400) {
        state.traceData.error = data.traceElementData[0];
      } else if (status === 404) {
        state.traceData.error = "Entry not found";
      } else {
        state.traceData.error =
          "Something went wrong at our side. Please contact admin.";
      }
    },
  },
});

export const {
  setTracebackForm,
  resetTraceReducer,
  setShowElementList,
  setShowOnMap,
  resetTracebackData,
} = tracebackSlice.actions;
export default tracebackSlice.reducer;
