import React, { useCallback, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import filter from "lodash/filter";
import get from "lodash/get";
import size from "lodash/size";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CustomSelect from "components/common/FormFields/CustomSelect";
import { fetchRegionList } from "region/data/services";
import {
  fetchLayerData,
  fetchLayerList,
} from "planning/data/actionBar.services";
import { LayerKeyMappings } from "planning/GisMap/utils";
import {
  FEATURE_TYPES,
  LAYER_STATUS_OPTIONS,
} from "planning/GisMap/layers/common/configuration";
import { Stack, Chip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { fetchBomDataThunk } from "Analysis/data/analysis.service";
import { getReportDatafetching } from "Analysis/data/analysis.selectors";
import { addNotification } from "redux/reducers/notification.reducer";
import {
  getIsSuperAdminUser,
  getUserPermissions,
} from "redux/selectors/auth.selectors";
import { setReportFilename } from "Analysis/data/analysis.reducer";

const REPORT_LAYER_STATUS_OPTIONS = [
  { value: null, label: "All" },
  ...LAYER_STATUS_OPTIONS,
];

const BomForm = () => {
  const dispatch = useDispatch();

  const [region, setRegion] = useState(null);
  const [isAdvance, setAdvance] = useState(false);
  const [layer, setLayer] = useState(null);
  const [element, setElement] = useState(null);
  const [status, setStatus] = useState(null);

  const reportFetching = useSelector(getReportDatafetching);
  const permissions = useSelector(getUserPermissions);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);

  const { isLoading: regionListLoading, data: regionList = [] } = useQuery(
    ["regionList", "data"],
    fetchRegionList
  );

  const { isLoading, data: layerCofigs = [] } = useQuery(
    "planningLayerConfigs",
    fetchLayerList,
    {
      staleTime: Infinity,
      select: (res) => {
        return filter(res, (config) => {
          const layerKey = config.layer_key;
          const featureType = get(LayerKeyMappings, [layerKey, "featureType"]);
          return (
            (featureType === FEATURE_TYPES.POLYGON ||
              featureType === FEATURE_TYPES.MULTI_POLYGON) &&
            (get(permissions, `${layerKey}_view`) || isSuperAdminUser)
          );
        });
      },
    }
  );

  const {
    mutate: fetchLayerDataMutation,
    isLoading: fetchingLayerData,
    data: elementList,
  } = useMutation(fetchLayerData);

  // handlers

  const handleRegionChange = useCallback((newValue) => {
    setRegion(newValue);
    setAdvance(false);
    setLayer(null);
    setElement(null);
  }, []);

  const handleLayerChange = useCallback(
    (newValue) => {
      setLayer(newValue);
      setElement(null);
      if (!newValue) return;
      fetchLayerDataMutation({
        regionIdList: [region.id],
        layerKey: newValue.layer_key,
      });
    },
    [region]
  );

  const handleElementChange = useCallback((newValue) => {
    setElement(newValue);
  }, []);

  const handleAdvanceChange = useCallback(() => {
    setAdvance(!isAdvance);
  }, [isAdvance]);

  const handleStatusChange = useCallback(
    (newValue) => () => {
      if (status === newValue) {
        setStatus(null);
      } else {
        setStatus(newValue);
      }
    },
    [status]
  );

  const handleSubmit = () => {
    let data;
    if (isAdvance) {
      if (!layer) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select layer",
          })
        );
        return;
      }
      if (!element) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select element you want to generate report for",
          })
        );
        return;
      }
      data = {
        layer_key: layer.layer_key,
        element_id: element.id,
        status: status,
      };
    } else {
      if (!region) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select region",
          })
        );
        return;
      }
      data = {
        layer_key: "region",
        element_id: region.id,
        status: status,
      };
    }
    dispatch(
      setReportFilename(
        `${isAdvance ? element.name : region.name}_${status || "all"}`
      )
    );
    dispatch(fetchBomDataThunk(data));
  };

  return (
    <Stack sx={{ margin: "0 auto", maxWidth: "480px" }} spacing={2}>
      <CustomSelect
        label="Select Region"
        name="region"
        options={regionList}
        isClearable
        isSearchable
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.id}
        menuPortalTarget={document.body}
        isLoading={regionListLoading}
        value={region}
        onChange={handleRegionChange}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdvance}
              onChange={handleAdvanceChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Advance"
        />
      </FormGroup>
      {isAdvance ? (
        <>
          <CustomSelect
            label="Select Layer"
            name="layer"
            options={layerCofigs}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.layer_key}
            menuPortalTarget={document.body}
            isLoading={isLoading}
            value={layer}
            onChange={handleLayerChange}
            isDisabled={!size(region)}
          />
          <CustomSelect
            label="Select Element"
            name="element"
            options={layer && size(elementList) ? elementList : []}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            menuPortalTarget={document.body}
            isLoading={fetchingLayerData}
            value={element}
            onChange={handleElementChange}
            isDisabled={!size(layer)}
          />
        </>
      ) : null}
      <Stack direction="row" spacing={1}>
        {REPORT_LAYER_STATUS_OPTIONS.map((currStatus) => {
          return (
            <Chip
              label={currStatus.label}
              key={currStatus.value}
              onClick={handleStatusChange(currStatus.value)}
              variant={"outlined"}
              color={currStatus.value === status ? "primary" : undefined}
              sx={{
                minWidth: "54px",
              }}
            />
          );
        })}
      </Stack>
      <LoadingButton
        variant="outlined"
        color="secondary"
        onClick={handleSubmit}
        loading={reportFetching}
      >
        Generate Report
      </LoadingButton>
    </Stack>
  );
};

export default BomForm;
