import React from "react";
import { useSelector } from "react-redux";

import size from "lodash/size";

import {
  Container,
  Paper,
  Skeleton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import {
  getFetchedReportType,
  getReportDataState,
} from "Analysis/data/analysis.selectors";
import CustTagCompletionTable from "./CustTagCompletionTable";

import "../report-table.scss";

const ReportWrapper = ({ title, children }) => {
  return (
    <Container sx={{ marginTop: 2, paddingBottom: 6 }}>
      <Paper sx={{ padding: 3, paddingTop: 0, position: "relative" }}>
        <Typography
          color="primary.dark"
          flex={1}
          variant="h5"
          textAlign="center"
          py={2}
        >
          {title}
        </Typography>
        <Divider />
        {children}
      </Paper>
    </Container>
  );
};

const CustomerTaggingCompletionContent = () => {
  /**
   * Parent:
   *    AnalysisContent
   */
  const reportType = useSelector(getFetchedReportType);
  const reportStateData = useSelector(getReportDataState);
  const { fetching, fetched, fetchError, reportData } = reportStateData;

  if (fetching) {
    return (
      <ReportWrapper title={reportType?.label}>
        <Skeleton animation="wave" height="200px" />
      </ReportWrapper>
    );
  } else if (fetchError) {
    return (
      <ReportWrapper title={reportType?.label}>
        <Box pt={3}>
          <Typography variant="h6" color="error.main" textAlign="center">
            Failed to fetch data
          </Typography>
        </Box>
      </ReportWrapper>
    );
  } else if (fetched) {
    if (size(reportData)) {
      return (
        <ReportWrapper title={reportType?.label}>
          <CustTagCompletionTable reportData={reportData} />
        </ReportWrapper>
      );
    } else {
      return (
        <ReportWrapper title={reportType?.label}>
          <Box pt={3}>
            <Typography variant="h6" color="text.secondary" textAlign="center">
              No element available in selected area
            </Typography>
          </Box>
        </ReportWrapper>
      );
    }
  }
  return null;
};

export default CustomerTaggingCompletionContent;
