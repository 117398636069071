import {
  ELEMENT_FORM_ABSTRACT_TEMPLATE,
  ELEMENT_TABLE_ABSTRACT_FIELDS,
  FEATURE_TYPES,
} from "../common/configuration";

import size from "lodash/size";
import filter from "lodash/filter";

import { default as Icon } from "assets/markers/p_firewall_view.svg";
import { default as EditIcon } from "assets/markers/p_firewall_edit.svg";
import { FIELD_TYPES } from "components/common/DynamicForm";

import OptionalGroupSelect from "./OptionalGroupSelect";
import GeneralGroupSelect from "./GeneralGroupSelect";
import TemplateSelect from "./TemplateSelect";

export const LAYER_KEY = "p_firewall";
export const PRE_UID = "FW";
export const LAYER_FEATURE_TYPE = FEATURE_TYPES.POINT;

export const getViewOptions = () => ({
  icon: Icon,
  pin: EditIcon,
});

export const INITIAL_ELEMENT_DATA = {
  name: "",
  address: "",
  unique_id: "",
  network_id: "",
  ref_code: "",
  status: "L1",
  coordinates: {},
};

// this will become function -> generate From Configs
export const getElementFormTemplate = (config) => {
  return {
    title: "Firewall Form",
    sections: [
      {
        fieldConfigs: [
          ...ELEMENT_FORM_ABSTRACT_TEMPLATE,
          {
            field_type: FIELD_TYPES.CustomField,
            field_key: "groups_general",
            FormFieldComponent: GeneralGroupSelect,
          },
          {
            field_type: FIELD_TYPES.CustomField,
            field_key: "groups",
            FormFieldComponent: OptionalGroupSelect,
          },
          {
            field_type: FIELD_TYPES.CustomField,
            field_key: "templates",
            FormFieldComponent: TemplateSelect,
          },
          {
            field_key: "location_name",
            label: "Location Name",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "state_name",
            label: "State Name",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "host_id",
            label: "Device Id",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "host_name",
            label: "Device Name",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "ip_address",
            label: "IP address",
            field_type: FIELD_TYPES.Input,
          },
          {
            field_key: "port",
            label: "Port",
            field_type: FIELD_TYPES.NumericInput,
          },
        ],
      },
    ],
  };
};

export const getElementTableFields = () => {
  // remove unique_id and reff_code from common fields
  const abstractFields = filter(
    ELEMENT_TABLE_ABSTRACT_FIELDS,
    (d) => d.field !== "ref_code" && d.field !== "unique_id"
  );
  return [
    ...abstractFields,
    // {
    //   label: "Groups",
    //   field: "groups",
    //   type: "multi_array",
    // },
    // {
    //   label: "Templates",
    //   field: "templates",
    //   type: "multi_array",
    // },
    { label: "Location Name", field: "location_name", type: "simple" },
    { label: "State Name", field: "state_name", type: "simple" },
    { label: "Model Name", field: "model_name", type: "simple" },
    // { label: "Device Id", field: "host_id", type: "simple" },
    { label: "Device Name", field: "host_name", type: "simple" },
    { label: "IP address", field: "ip_address", type: "simple" },
    // { label: "Port", field: "port", type: "simple" },
  ];
};

export const transformAndValidateData = (formData) => {
  let groups = [];
  if (size(formData.groups_general)) {
    groups = groups.concat(formData.groups_general);
  }
  if (size(formData.groups)) {
    groups = groups.concat(formData.groups);
  }

  return {
    ...formData,
    groups,
    groups_general: undefined,
    groups_optional: undefined,
  };
};

export const ELEMENT_TABLE_EXTRA_CONTROLS = [
  {
    control: "show_generic_attrs_table",
  },
  {
    control: "show_interface_attrs_table",
  },
];

export const FILTER_FORM_CONFIG = [];
