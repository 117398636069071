import React, { useRef, useCallback } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useDispatch } from "react-redux";

import noop from "lodash/noop";
import { featureCollection, envelope, point } from "@turf/turf";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import {
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM,
  MAP_LIBRARIES,
} from "./map.constants";
import {
  handleMapBoundsChanges,
  setMapPosition,
} from "planning/data/planningGis.reducer";
import { getIconScaledDataFromZoom } from "utils/map.utils";

const DEFAULT_CONTAINER_STYLE = {
  width: "100%",
  height: "100%",
};

/**
 *
 */
const GoogleMapWrapper = ({
  children,
  center = DEFAULT_MAP_CENTER,
  zoom = DEFAULT_MAP_ZOOM,
  containerStyle = DEFAULT_CONTAINER_STYLE,
  onClick = noop,
  ...restMapProps
}) => {
  const mapRef = useRef();
  const dispatch = useDispatch();

  const handleonZoomChanged = useCallback(() => {
    // get zoom leven on every zoom change
    const currentZoom = mapRef?.current?.getZoom();
    if (currentZoom) {
      // calculate icon height width and anchor based on zoom
      const { iconWidth, iconHeight, anchorX, anchorY } =
        getIconScaledDataFromZoom(currentZoom);
      dispatch(
        setMapPosition({
          currentZoom,
          iconWidth,
          iconHeight,
          anchorX,
          anchorY,
        })
      );
    }
  }, []);

  const handleBoundsChanged = useCallback(() => {
    const bounds = mapRef?.current?.getBounds();
    if (bounds) {
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();

      // create collection of both corner points
      const tf_featCollection = featureCollection([
        point([northeast.lng(), northeast.lat()]),
        point([southwest.lng(), southwest.lat()]),
      ]);

      // create an envelop polygon around that points
      var tf_enveloped = envelope(tf_featCollection);
      const polygonCoordinates = tf_enveloped.geometry.coordinates;
      const currentZoom = mapRef?.current?.getZoom();

      dispatch(
        handleMapBoundsChanges({ coordinates: polygonCoordinates, currentZoom })
      );
    }
  }, []);

  const handleOnLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  return (
    <LoadScript
      libraries={MAP_LIBRARIES}
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      loadingElement={
        <Box p={5} display="flex">
          <Skeleton height="400px" width="100%" sx={{ transform: "unset" }} />
        </Box>
      }
    >
      <GoogleMap
        {...restMapProps}
        clickableIcons={false}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onClick={onClick}
        onZoomChanged={handleonZoomChanged}
        onIdle={handleBoundsChanged}
        onLoad={handleOnLoad}
        options={(maps) => {
          return {
            zoomControl: true,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.SATELLITE,
            mapTypeControlOptions: {
              style: maps.MapTypeControlStyle.DEFAULT,
              position: maps.ControlPosition.BOTTOM_CENTER,
              mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.SATELLITE,
                maps.MapTypeId.HYBRID,
              ],
            },
          };
        }}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapWrapper;
