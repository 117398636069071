import React, { useCallback, useMemo, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import filter from "lodash/filter";
import get from "lodash/get";
import size from "lodash/size";
import map from "lodash/map";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CustomSelect from "components/common/FormFields/CustomSelect";
import { fetchRegionList } from "region/data/services";
import {
  fetchLayerData,
  fetchLayerList,
} from "planning/data/actionBar.services";
import { LayerKeyMappings } from "planning/GisMap/utils";
import {
  FEATURE_TYPES,
  LAYER_STATUS_OPTIONS,
} from "planning/GisMap/layers/common/configuration";
import { Stack, Chip, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { fetchLayerDataReport } from "Analysis/data/analysis.service";
import { addNotification } from "redux/reducers/notification.reducer";
import {
  getIsSuperAdminUser,
  getUserPermissions,
} from "redux/selectors/auth.selectors";
import { setReportFilename } from "Analysis/data/analysis.reducer";
import { getReportFileName } from "Analysis/data/analysis.selectors";

const FILE_TYPES = [
  { label: "Shape File", value: "shapefile" },
  { label: "KML", value: "kml" },
];

const REPORT_LAYER_STATUS_OPTIONS = [
  { value: null, label: "All" },
  ...LAYER_STATUS_OPTIONS,
];

const LayerData = () => {
  const dispatch = useDispatch();

  const [region, setRegion] = useState(null);
  const [isAdvance, setAdvance] = useState(false);
  const [layer, setLayer] = useState(null);
  const [element, setElement] = useState(null);
  const [status, setStatus] = useState(null);
  const [fileType, setfileType] = useState(null);
  const [layerKeyList, setLayerKeyList] = useState([]);

  const permissions = useSelector(getUserPermissions);
  const isSuperAdminUser = useSelector(getIsSuperAdminUser);
  const reportFileName = useSelector(getReportFileName);

  const { isLoading: regionListLoading, data: regionList = [] } = useQuery(
    ["regionList", "data"],
    fetchRegionList
  );

  const { isLoading, data: layerCofigs = [] } = useQuery(
    "planningLayerConfigs",
    fetchLayerList,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: exportMutation, isLoading: isFileLoading } = useMutation(
    fetchLayerDataReport,
    {
      onSuccess: (res) => {
        const fileBlob = new Blob([res], {
          type: res.type,
        });
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = url;

        let filename = "";
        if (res.type === "application/vnd.google-earth.kml+xml") {
          filename = `${reportFileName}.kml`;
        } else if (res.type === "application/zip") {
          filename = `${reportFileName}.zip`;
        }
        link.setAttribute("download", filename);

        // have to add element to doc for firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: (err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Error",
            text: err.message,
          })
        );
      },
    }
  );

  const areaLayerKeys = useMemo(() => {
    return filter(layerCofigs, (config) => {
      const layerKey = config.layer_key;
      const featureType = get(LayerKeyMappings, [layerKey, "featureType"]);
      return (
        (featureType === FEATURE_TYPES.POLYGON ||
          featureType === FEATURE_TYPES.MULTI_POLYGON) &&
        (get(permissions, `${layerKey}_view`) || isSuperAdminUser)
      );
    });
  }, [layerCofigs]);

  const downloadLayers = useMemo(() => {
    return filter(layerCofigs, (config) => {
      const layerKey = config.layer_key;
      return (
        !(layerKey === "region" || layerKey === "ticket") &&
        (get(permissions, `${layerKey}_download`) || isSuperAdminUser)
      );
    });
  }, [layerCofigs]);

  const {
    mutate: fetchLayerDataMutation,
    isLoading: fetchingLayerData,
    data: elementList,
  } = useMutation(fetchLayerData);

  // handlers

  const handleRegionChange = useCallback((newValue) => {
    setRegion(newValue);
    setAdvance(false);
    setLayer(null);
    setElement(null);
  }, []);

  const handleLayerChange = useCallback(
    (newValue) => {
      setLayer(newValue);
      setElement(null);
      if (!newValue) return;
      fetchLayerDataMutation({
        regionIdList: [region.id],
        layerKey: newValue.layer_key,
      });
    },
    [region]
  );

  const handleDwLayerChange = useCallback((newValue) => {
    setLayerKeyList(newValue);
  }, []);

  const handleElementChange = useCallback((newValue) => {
    setElement(newValue);
  }, []);

  const handleAdvanceChange = useCallback(() => {
    setAdvance(!isAdvance);
  }, [isAdvance]);

  const handleStatusChange = useCallback(
    (newValue) => () => {
      if (status === newValue) {
        setStatus(null);
      } else {
        setStatus(newValue);
      }
    },
    [status]
  );

  const handleTypeChange = useCallback(
    (newValue) => () => {
      setfileType(newValue);
    },
    []
  );

  const handleSubmit = () => {
    let data;
    if (isAdvance) {
      if (!layer) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select layer",
          })
        );
        return;
      }
      if (!element) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select element you want to download report for",
          })
        );
        return;
      }
      if (!size(layerKeyList)) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select layer you want to download report for",
          })
        );
        return;
      }
      if (!fileType) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select file type to download report for",
          })
        );
        return;
      }
      data = {
        layer_key: layer.layer_key,
        element_id: element.id,
        element_status: status,
        selected_layer_keys: map(layerKeyList, "layer_key"),
        file_type: fileType,
      };
    } else {
      if (!region) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select region",
          })
        );
        return;
      }
      if (!size(layerKeyList)) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select layer you want to download report for",
          })
        );
        return;
      }
      if (!fileType) {
        dispatch(
          addNotification({
            type: "error",
            title: "Please select file type to download report for",
          })
        );
        return;
      }
      data = {
        layer_key: "region",
        element_id: region.id,
        element_status: status,
        selected_layer_keys: map(layerKeyList, "layer_key"),
        file_type: fileType,
      };
    }

    dispatch(
      setReportFilename(
        `${isAdvance ? element.name : region.name}_${status || "all"}`
      )
    );
    setTimeout(() => {
      exportMutation(data);
    }, 500);
  };

  return (
    <Stack sx={{ margin: "0 auto", maxWidth: "480px" }} spacing={2}>
      <CustomSelect
        label="Select Region"
        name="region"
        options={regionList}
        isClearable
        isSearchable
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.id}
        menuPortalTarget={document.body}
        isLoading={regionListLoading}
        value={region}
        onChange={handleRegionChange}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdvance}
              onChange={handleAdvanceChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Advance"
        />
      </FormGroup>
      {isAdvance ? (
        <>
          <CustomSelect
            label="Select Layer"
            name="layer"
            options={areaLayerKeys}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.layer_key}
            menuPortalTarget={document.body}
            isLoading={isLoading}
            value={layer}
            onChange={handleLayerChange}
            isDisabled={!size(region)}
          />
          <CustomSelect
            label="Select Element"
            name="element"
            options={layer && size(elementList) ? elementList : []}
            isClearable
            isSearchable
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            menuPortalTarget={document.body}
            isLoading={fetchingLayerData}
            value={element}
            onChange={handleElementChange}
            isDisabled={!size(layer)}
          />
        </>
      ) : null}
      <CustomSelect
        label="Select Download Layer"
        name="dw_layer"
        options={downloadLayers}
        isClearable
        isSearchable
        isMulti
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.layer_key}
        menuPortalTarget={document.body}
        isLoading={isLoading}
        value={layerKeyList}
        onChange={handleDwLayerChange}
        isDisabled={!size(region)}
      />
      <Box>
        <Typography variant="caption" pb={1} component="div">
          Status
        </Typography>
        <Stack direction="row" spacing={1}>
          {REPORT_LAYER_STATUS_OPTIONS.map((currStatus) => {
            return (
              <Chip
                label={currStatus.label}
                key={currStatus.value}
                onClick={handleStatusChange(currStatus.value)}
                variant={"outlined"}
                color={currStatus.value === status ? "primary" : undefined}
                sx={{
                  minWidth: "54px",
                }}
              />
            );
          })}
        </Stack>
      </Box>
      <Box>
        <Typography variant="caption" pb={1} component="div">
          File Type
        </Typography>
        <Stack direction="row" spacing={1}>
          {FILE_TYPES.map((currType) => {
            return (
              <Chip
                label={currType.label}
                key={currType.value}
                onClick={handleTypeChange(currType.value)}
                variant={"outlined"}
                color={currType.value === fileType ? "primary" : undefined}
                sx={{
                  minWidth: "54px",
                }}
              />
            );
          })}
        </Stack>
      </Box>
      <LoadingButton
        variant="outlined"
        color="secondary"
        onClick={handleSubmit}
        loading={isFileLoading}
      >
        Download
      </LoadingButton>
    </Stack>
  );
};

export default LayerData;
