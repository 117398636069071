import React from "react";
import { useQuery } from "react-query";

import get from "lodash/get";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { fetchActiveUserCount } from "gis_user/data/services";

const ActiveUserCount = () => {
  const { isLoading, data } = useQuery("activeUserCount", fetchActiveUserCount);
  const curr_web_count = get(data, "curr_web_count", "--");
  const curr_mobile_count = get(data, "curr_mobile_count", "--");
  const max_web_count = get(data, "max_web_count", "--");
  const max_mobile_count = get(data, "max_mobile_count", "--");

  return (
    <Tooltip
      title={
        <Stack fontSize="1.2em">
          <Box>
            <b>Web</b>:{" "}
            <span
              style={{
                color: curr_web_count > max_web_count ? "#F88379" : "#32de84",
              }}
            >
              {curr_web_count}
            </span>{" "}
            / {max_web_count}
          </Box>
          <Box>
            <b>Mobile</b>:{" "}
            <span
              style={{
                color:
                  curr_mobile_count > max_mobile_count ? "#F88379" : "#32de84",
              }}
            >
              {curr_mobile_count}
            </span>{" "}
            / {max_mobile_count}
          </Box>
        </Stack>
      }
    >
      <Typography variant="caption">
        ( Active Devices :{" "}
        {isLoading
          ? "--"
          : `${curr_web_count + curr_mobile_count} / ${
              max_web_count + max_mobile_count
            }`}{" "}
        )
      </Typography>
    </Tooltip>
  );
};

export default ActiveUserCount;
